
import Vue, { nextTick } from 'vue';
import { PebbleTable } from '@pebble/tables';
import { RowComponent } from 'tabulator-tables';
import { mapGetters, mapActions } from 'vuex';
import { columnDefinition } from './helpers/column-definition';
import { EventBus } from '@/utils';
import BulkEditConfirmModal from '@/components/approvals/modals/bulk-edit-confirm-modal.vue';

export default Vue.extend({
    name: 'StandardEmissionTable',
    components: {
        BulkEditConfirmModal,
    },
    data(): { table: PebbleTable | null; tableData: any; bulkEdits: number[]; anySelected: boolean } {
        return {
            table: null,
            tableData: [],
            bulkEdits: [],
            anySelected: false,
        };
    },
    computed: {
        ...mapGetters('standardEmissionFactor', {
            fetchTableData: 'tableData',
        }),
        ...mapGetters('tables', {
            tracker: 'standardEFExpansionTracker',
        }),
        columnDefinition(): any[] {
            return (columnDefinition || []).map((col) => ({
                ...col,
                title: this.$t(`standardEF.table.column.${col.field}`),
            }));
        },
    },
    watch: {
        fetchTableData() {
            this.table?.replaceData(this.fetchTableData);
        },
    },
    async mounted() {
        this.tableData = this.fetchTableData;
        this.setupGlobalEventListeners();
        this.buildTable();
        this.setTracker({ activities: this.tableData.map((entry: any) => entry.activity), scopeId: 3 });
    },
    beforeDestroy(): void {
        EventBus.$off(EventBus.VIEWS.STANDARD_EMISSION.BULK_EDIT);
        EventBus.$off(EventBus.VIEWS.STANDARD_EMISSION.CHANGE_BULK_ASSIGNMENT);
    },
    methods: {
        ...mapActions('standardEmissionFactor', {
            fetchEmissionFactors: 'FETCH_STANDARD_EMISSION_FACTORS',
        }),
        ...mapActions('tables', {
            setTracker: 'SET_STANDARDEF_TRACKERS',
            updateTracker: 'UPDATE_STANDARDEF_TRACKER',
        }),
        buildTable(): void {
            this.table = new PebbleTable('#standard-emission-table', {
                data: this.tableData || [],
                height: 'calc(100vh - 270px)',
                pagination: false,
                layout: 'fitDataStretch',
                dataTree: true,
                dataTreeStartExpanded: (row: RowComponent): boolean => this.startExpanded(row),
                dataTreeBranchElement: false,
                dataTreeChildIndent: 32,
                columns: this.columnDefinition,
                selectable: false,
                columnDefaults: {
                    vertAlign: 'middle',
                },
            });

            this.table.on('dataTreeRowExpanded', (row: RowComponent) => this.onExpand(row));
            this.table.on('dataTreeRowCollapsed', (row: RowComponent) => this.onCollapse(row));
        },

        startExpanded(row: RowComponent): boolean {
            const data = row.getData() as any;
            return this.tracker[data.activity];
        },

        onExpand(row: RowComponent): void {
            const data = row.getData() as any;
            this.updateTracker({ [data.activity]: true });
        },

        onCollapse(row: RowComponent): void {
            const data = row.getData() as any;
            this.updateTracker({ [data.activity]: false });
        },

        resetBulkApproval(): void {
            this.bulkEdits = [];
        },

        setupGlobalEventListeners() {
            EventBus.$on(EventBus.VIEWS.STANDARD_EMISSION.BULK_EDIT, (data: number[]): void => {
                (this as any).$refs['bulkEditConfirmModal'].open(this.bulkEdits);
            });
            EventBus.$on(EventBus.VIEWS.STANDARD_EMISSION.CHANGE_BULK_ASSIGNMENT, (data: Record<string, any>): void => {
                this.setBulkApprove(data);
                this.anySelected = Object.keys(this.bulkEdits).length !== 0;
            });
        },

        setBulkApprove(data: Record<string, any>): void {
            const { activity, checked } = data as { activity: any; checked: boolean };

            if (checked) {
                this.bulkEdits.push(activity.meta.dataCO2.id);
                this.bulkEdits.push(activity.meta.dataN2O.id);
                this.bulkEdits.push(activity.meta.dataCH4.id);
            } else {
                const co2Index = this.bulkEdits.indexOf(activity.meta.dataCO2.id, 0);
                if (co2Index > -1) {
                    this.bulkEdits.splice(co2Index, 1);
                }
                const n2oIndex = this.bulkEdits.indexOf(activity.meta.dataN2O.id, 0);
                if (n2oIndex > -1) {
                    this.bulkEdits.splice(n2oIndex, 1);
                }
                const ch4Index = this.bulkEdits.indexOf(activity.meta.dataCH4.id, 0);
                if (ch4Index > -1) {
                    this.bulkEdits.splice(ch4Index, 1);
                }
            }
        },

        bulkEdit(): void {
            EventBus.$emit(EventBus.VIEWS.STANDARD_EMISSION.BULK_EDIT);
        },

        openAddActivityModal(): void {
            EventBus.$emit(EventBus.VIEWS.STANDARD_EMISSION.OPEN_ADD_ACTIVITY_MODAL);
        },
    },
});
