import {
    Granularity,
    LocationType,
    ScopeDto,
    ScopeGetEndpointResponseOkResponse,
    ScopeGetSingleEndpointResponseOkResponse,
    ScopeServiceProxy,
} from '@/service-proxies/service-proxies.g';
import { ScopeListEndpointRequest } from '@/models/scope';

/**
 * @name ScopeService
 * @description This class is used to query the backend server for scope data
 */
export class ScopeService {
    private scopeServiceProxy: ScopeServiceProxy;

    constructor() {
        this.scopeServiceProxy = new ScopeServiceProxy();
    }

    /**
     * Get filtered list of scopes.
     * @param filter Scope Filter.
     */
    public getScopes = async (
        filter: ScopeListEndpointRequest | undefined = undefined,
    ): Promise<ScopeGetEndpointResponseOkResponse> => {
        const response = await this.scopeServiceProxy.getScope(
            filter?.ids ?? undefined,
            filter?.subScopes ?? undefined,
            filter?.sortDirection,
            filter?.sortProperty,
            filter?.page,
            filter?.size,
            filter?.Term ?? undefined,
            filter?.TermToLower ?? undefined,
        );
        response.result?.items?.forEach((x) => this.replaceEnums(x));
        return response;
    };

    /**
     * Get scope by id.
     * @param id
     */
    public getScope = async (id: number): Promise<ScopeGetSingleEndpointResponseOkResponse> => {
        const response = await this.scopeServiceProxy.getSingleScope(id);
        this.replaceEnums(response.result);
        return response;
    };

    /**
     * Helper function which mutates the given EmissionDto and replaces all enums with their integer values.
     */
    private replaceEnums = (scopeDto: ScopeDto | undefined): void => {
        if (typeof scopeDto?.granularity === 'string') {
            scopeDto.granularity = scopeDto.granularity
                ? (Granularity[scopeDto.granularity] as unknown as Granularity)
                : scopeDto.granularity;
        }

        if (scopeDto?.locationTypes) {
            scopeDto.locationTypes = scopeDto.locationTypes.map((locType) => {
                if (typeof locType === 'string') {
                    return locType ? (LocationType[locType] as unknown as LocationType) : locType;
                }
            }) as LocationType[];
        }
    };
}
