
import Vue, { ComputedRef, computed, inject } from 'vue';
import { mapGetters } from 'vuex';
import EmissionTable from '@/components/tables/emission-table/emission-table.vue';
import Modals from './modals/index.vue';
import { ActivitySelectModalActions, EmissionModalActions } from '@/components/data-input/modals/modalsActionsTypes';
import GranularitySection from '@/components/granularity/granularity-section.vue';
import PageHeader from '@/components/page-headers/header-with-country-scope-and-monthly.vue';
import FleetSkeleton from '../ui/loading/skeletons/fleet-skeleton.vue';
import {
    EmissionWithParkedEmissionDto,
    FleetDto,
    Granularity,
    BadRequestResponse,
} from '@/service-proxies/service-proxies.g';
import { HttpStatusCodes } from '@/enums/http-status-codes';
import { ConfirmAction } from '@/models/utils';
import { LoaderProps } from '@/models/utils';
import { LocationTableData } from '@/models/table';
import { EventBus } from '@/utils';
import { createDates } from '@/utils/helpers/emission/new-emission-dates';
import { TableType } from '@/utils/tables/table-config';

const DataInput = Vue.extend({
    name: 'DataInput',

    components: {
        PageHeader,
        EmissionTable,
        Modals,
        GranularitySection,
        FleetSkeleton,
    },

    setup() {
        return {
            confirmAction: inject<ConfirmAction>('ConfirmAction'),
        };
    },

    data(): {
        firstSelectionDone: boolean;
        noFleetsFound: boolean;
        getActivityDataSelectModal: ActivitySelectModalActions | null;
        getDetailedEmissionModal: EmissionModalActions | null;
        getWithdrawCompleteModal: EmissionModalActions | null;
        getWithdrawApprovalModal: EmissionModalActions | null;
        getOverwriteApprovalModal: EmissionModalActions | null;
        getDeleteEmissionModal: EmissionModalActions | null;
        tableType: TableType;
    } {
        return {
            firstSelectionDone: false,
            noFleetsFound: false,
            getActivityDataSelectModal: null,
            getDetailedEmissionModal: null,
            getWithdrawCompleteModal: null,
            getWithdrawApprovalModal: null,
            getOverwriteApprovalModal: null,
            getDeleteEmissionModal: null,
            tableType: TableType.DATA_ENTRY,
        };
    },

    provide(): {
        getActivityDataSelectModal: ActivitySelectModalActions | null;
        getDetailedEmissionModal: EmissionModalActions | null;
        getWithdrawCompleteModal: EmissionModalActions | null;
        getWithdrawApprovalModal: EmissionModalActions | null;
        getOverwriteApprovalModal: EmissionModalActions | null;
        getDeleteEmissionModal: EmissionModalActions | null;
        getScopeId: ComputedRef<number>;
    } {
        return {
            getActivityDataSelectModal: this.getActivityDataSelectModal,
            getDetailedEmissionModal: this.getDetailedEmissionModal,
            getWithdrawCompleteModal: this.getWithdrawCompleteModal,
            getWithdrawApprovalModal: this.getWithdrawApprovalModal,
            getOverwriteApprovalModal: this.getOverwriteApprovalModal,
            getDeleteEmissionModal: this.getDeleteEmissionModal,
            getScopeId: computed(() => this.selectedScope?.id ?? 0),
        };
    },

    computed: {
        ...mapGetters('country', {
            selectedCountry: 'GET_SELECTED_COUNTRY',
        }),
        ...mapGetters('fleet', {
            fleets: 'fleets',
            fleetIsLoading: 'loadFleet',
        }),
        ...mapGetters('scope', {
            selectedScope: 'GET_SELECTED_SCOPE',
        }),
        ...mapGetters('location', {
            start: 'start',
        }),

        loaderError(): LoaderProps {
            // First case: selection done but no fleets available
            if (this.noFleetsFound) {
                return {
                    title: this.$t('fleetLoader.noFleetFound.title').toString(),
                    message: this.$t('fleetLoader.noFleetFound.message').toString(),
                };
            }
            // second case: selection not yet done
            else {
                return {
                    title: this.$t('fleetLoader.selectionIncomplete.title').toString(),
                    message: this.$t('fleetLoader.selectionIncomplete.message').toString(),
                };
            }
        },

        selectedScopeName(): string {
            return this.$t(`scope${this.selectedScope?.subScope?.replace('.', '_') ?? ''}`).toString();
        },

        sortedFleets(): FleetDto[] {
            return [...this.fleets].sort((a, b) =>
                a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()),
            );
        },
    },

    watch: {
        selectedCountry(): void {
            this.loadFleet();
        },

        selectedScope(): void {
            this.loadFleet();
        },
    },

    mounted(): void {
        const modals = this.$refs.modals as any;
        this.getActivityDataSelectModal = modals.$refs.activitySelectModal;
        this.getDetailedEmissionModal = modals.$refs.detailedEmissionModal;
        this.getWithdrawCompleteModal = modals.$refs.withdrawCompleteModal;
        this.getWithdrawApprovalModal = modals.$refs.withdrawApprovalModal;
        this.getOverwriteApprovalModal = modals.$refs.overwriteApprovalModal;
        this.getDeleteEmissionModal = modals.$refs.deleteEmissionModal;
        this.setupGlobalEventListeners();

        this.loadFleet();

        const currentLanguage = localStorage.getItem('translation-plugin-currentLanguage');
        if (currentLanguage === null) localStorage.setItem('translation-plugin-currentLanguage', this.$i18n.locale);
    },

    beforeDestroy(): void {
        EventBus.$off(EventBus.VIEWS.MAIN.OPEN_ADD_ACTIVITY_DATA_MODAL);
        EventBus.$off(EventBus.VIEWS.MAIN.OPEN_DETAILED_EMISSIONS_MODAL);
        EventBus.$off(EventBus.VIEWS.MAIN.DELETE_EMISSION);
        EventBus.$off(EventBus.VIEWS.MAIN.SAVE_TABLE_CHANGES);
        EventBus.$off(EventBus.VIEWS.MAIN.OPEN_WITHDRAW_COMPLETENESS);
        EventBus.$off(EventBus.VIEWS.MAIN.OPEN_OVERWRITE_APPROVAL);
        EventBus.$off(EventBus.VIEWS.MAIN.OPEN_WITHDRAW_APPROVAL);
    },

    methods: {
        async loadFleet(): Promise<void> {
            if (!this.selectedCountry || !this.selectedScope) return;

            this.$store.commit('fleet/setLoadFleet', true);
            this.firstSelectionDone = true;
            this.noFleetsFound = false;

            const scopeId = this.selectedScope.id;
            const countryId = this.selectedCountry.id;

            try {
                // now load the new data
                const fleetsByScopeAndCountry = this.$store.dispatch('fleet/LOAD_FLEETS', {
                    scopeId,
                    countryId,
                });

                const plantsByScopeAndCountry = this.$store.dispatch('location/LOAD_LOCATIONS', {
                    scopeId,
                    countryId,
                    granularity: this.selectedScope?.granularity ?? Granularity.Monthly,
                });

                const masterDataLevelByScopeAndCountry = this.loadMasterDataLevel(scopeId, countryId);

                await Promise.all([fleetsByScopeAndCountry, plantsByScopeAndCountry, masterDataLevelByScopeAndCountry]);
            } catch (e) {
                const message = (e as BadRequestResponse).message ?? '';
                (this as any).$pui.toast({
                    type: 'error',
                    title: this.$t('toast.error'),
                    copy: message,
                });
                console.log(e);
                throw e;
            }

            if (!this.fleets.length) {
                this.noFleetsFound = true;
            }

            this.$store.commit('fleet/setLoadFleet', false);
        },

        async loadMasterDataLevel(scopeId: number, countryId: number): Promise<void> {
            const statusCode = await this.$store.dispatch('masterDataLevel/GET_MASTER_DATA_LEVEL', {
                scopeId,
                countryId,
            });
            if (statusCode !== HttpStatusCodes.Ok) {
                (this as any).$pui.toast({
                    type: 'error',
                    title: this.$t('error.loadingMasterDataLevel.title'),
                    copy: this.$t('error.loadingMasterDataLevel.text'),
                });
            }
        },

        setupGlobalEventListeners(): void {
            EventBus.$on(EventBus.VIEWS.MAIN.OPEN_ADD_ACTIVITY_DATA_MODAL, (location: LocationTableData): void => {
                this.getActivityDataSelectModal!.open(location, (_selectedActivitiesIds) => {
                    return;
                });
            });
            EventBus.$on(EventBus.VIEWS.MAIN.OPEN_DETAILED_EMISSIONS_MODAL, (data: Record<string, any>): void => {
                this.getDetailedEmissionModal!.open(data);
            });
            EventBus.$on(
                EventBus.VIEWS.MAIN.SAVE_TABLE_CHANGES,
                async (emission: EmissionWithParkedEmissionDto): Promise<void> => {
                    this.$store.commit('loading');
                    //TODO remove when emission drafts add dates in backend
                    let requestObject = { ...emission };
                    if (emission.id === 0) {
                        const dates = createDates(this.start);
                        requestObject = { ...requestObject, ...dates };
                    }
                    try {
                        await this.$store.dispatch('location/UPDATE_EMISSION', requestObject);
                        (this as any).$pui.toast({
                            type: 'success',
                            title: this.$t('notification.emissions.save.title'),
                            copy: '',
                        });
                    } catch (e) {
                        const message = (e as BadRequestResponse).message ?? '';
                        (this as any).$pui.toast({
                            type: 'error',
                            title: this.$t('error.emissions.save.title'),
                            copy: message,
                        });
                        throw new Error();
                    } finally {
                        this.$store.commit('loading');
                    }
                },
            );
            EventBus.$on(EventBus.VIEWS.MAIN.DELETE_EMISSION, (emission: EmissionWithParkedEmissionDto): void => {
                this.getDeleteEmissionModal!.open(emission);
            });
            EventBus.$on(EventBus.VIEWS.MAIN.OPEN_WITHDRAW_COMPLETENESS, (data: Record<string, any>): void => {
                this.getWithdrawCompleteModal!.open(data);
            });
            EventBus.$on(EventBus.VIEWS.MAIN.OPEN_WITHDRAW_APPROVAL, (data: Record<string, any>): void => {
                this.getWithdrawApprovalModal!.open(data);
            });
            EventBus.$on(EventBus.VIEWS.MAIN.OPEN_OVERWRITE_APPROVAL, (data: Record<string, any>): void => {
                this.getOverwriteApprovalModal!.open(data);
            });
        },
    },
});

export default DataInput;
