import VueI18n from 'vue-i18n';
import Vue from 'vue';
import { getTranslationManager } from '../translation-plugin/translation-manager';

export const getVueI18n = (): VueI18n => {
    const translationManager = getTranslationManager();
    if (!translationManager || !translationManager.vueI18n) {
        throw new Error('Can not get vueI18n instance from TranslationManager');
    }

    return translationManager.vueI18n;
};

const translationInjector = (key: string): string => {
    const instance = new Vue({
        i18n: getTranslationManager().vueI18n,
    });

    return instance.$t(key) as string;
};

// TODO: Temporary fix until we can get the i18n working with placeholders
export const translateWithPlaceholders = (key: string, placeholders: Record<string, string> = {}): string => {
    let str = translationInjector(key) as string;

    // Placeholder aren't working so manually replace them
    Object.entries(placeholders).forEach(([key, value]) => {
        str = str.replace(`{{ ${key} }}`, value);
    });

    return str;
};
