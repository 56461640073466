import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCopy, faCaretRight, faCaretUp, faCaretDown, faSort, faGripLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import '@pebble/core/dist/pebble.css';
import '@pebble/tables/dist/pebble-tables.css';
import 'adam.ui-core/dist/umd';
import './registerServiceWorker';
import { VNode } from 'vue/types/umd';
import PebbleUI from '@enerlytics/pebble-ui/dist/pebble-ui';
import TranslationPlugin, { TranslationPluginOptions } from '@translations/translation-plugin';
import { getTranslationManager } from '@/utils/translation-plugin/translation-manager';
import { CoodeSdkPlugin } from '@coode/fe-sdk';
import { sdkPluginConfig } from '@/utils/fe-sdk/sdk-plugin-config';
import { Suspense } from './components/ui/loading/suspense';

Vue.config.productionTip = false;

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('Suspense', Suspense);

library.add(
    faCopy as any,
    faCaretRight as any,
    faCaretUp as any,
    faCaretDown as any,
    faSort as any,
    faGripLines as any,
);

Vue.use(new CoodeSdkPlugin() as any, sdkPluginConfig);

Vue.use(TranslationPlugin, {
    useCaseClientId: 'cama',
    phraseProjectId: '42679085592a95d119f9b6f0ebebe207',
    enableDebugLogging: false,
} as TranslationPluginOptions);

const i18n = getTranslationManager().vueI18n;
const lang = i18n.locale;

Vue.use(PebbleUI, {
    lang: lang,
    dateTimeLocale: lang,
    dateTimeTimeZone: 'Europe/Berlin', // all IANA time zones are valid
});

new Vue({
    i18n,
    router,
    store,
    render: (h): VNode => h(App),
}).$mount('#app');
