
import Vue from 'vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { AttachmentDto } from '@/service-proxies/service-proxies.g';
import EmissionAttachment from '@/components/file-upload/emission-attachment.vue';
import { AttachmentService } from '@/services/attachment-service';
import { apiCallWithErrorHandling } from '@/services/utils';
import { WAITING_MODAL_DEFAULT_TEXT, WAITING_MODAL_DEFAULT_TITLE } from '@/store/modules/waiting-modal';

const AdditionalInformation = Vue.extend({
    name: 'AdditionalInformation',
    components: {
        EmissionAttachment,
    },
    props: {
        isEditable: {
            type: Boolean,
            default: false,
        },
    },
    data(): {
        formValues: any;
        valuesChanged: boolean;
    } {
        return {
            formValues: {
                comment: {
                    isValid: true,
                },
            },
            valuesChanged: false,
        };
    },
    computed: {
        ...mapGetters('detailedView', {
            importedEmission: 'GET_EMISSION',
            emission: 'GET_EDITED_EMISSION',
        }),
        ...mapState('location', {
            attachments: 'attachments',
        }),

        commentBoxGrowth(): Record<string, any> {
            const value = this.formValues.comment.value;
            if (!value)
                return {
                    grow: true,
                    rows: 1,
                };
            const textArea = document.querySelector('#comment') as HTMLTextAreaElement;
            let pastMaxHeight;
            if (textArea) pastMaxHeight = textArea.clientHeight > 100;
            const lineBreaks = value.split('\n') as any[];
            if ((pastMaxHeight && lineBreaks.length > 3) || value.length > 250) {
                return {
                    grow: false,
                    rows: 3,
                };
            }
            return {
                grow: true,
                rows: 1,
            };
        },
        disabledMessage(): string {
            return this.emission.id === 0 ? (this.$t('detailedView.disabled.attachments') as string) : '';
        },
        filteredAttachments(): AttachmentDto[] {
            const locationId = this.emission.locationId;
            const activityId = this.emission.activityData.id;
            const linkStart = `${locationId}-${activityId}`;
            return this.attachments.filter((attachment: AttachmentDto) => {
                return attachment.link?.startsWith(linkStart);
            });
        },
    },
    mounted() {
        this.formValues.comment.value = this.emission?.comment ? this.emission.comment : '';

        // this is needed because otherwise otherwise on closing and opening the lightbox, the old values
        // will still be visible
        this.formValues = { ...this.formValues };
    },
    methods: {
        ...mapMutations('detailedView', {
            setEmission: 'SET_EDITED_EMISSION',
        }),
        ...mapActions('location', {
            setAttachment: 'ADD_ATTACHMENT',
        }),
        validate(inputName: string): void {
            const input = this.formValues[inputName];
            if (input.validator) {
                const isValid = input.validator(input.value);
                input.isValid = isValid;
            }
        },

        handleCommentInput(newValue: string): void {
            this.formValues.comment.value = newValue;
            this.formValues = { ...this.formValues };
            this.setEmission({
                ...this.emission,
                comment: this.formValues.comment.value,
            });
        },

        commentValueChanged(): boolean {
            if (this.emission?.comment == undefined) {
                return this.formValues.comment.value !== '';
            } else {
                return this.emission?.comment !== this.formValues.comment.value;
            }
        },

        attachFileHandler(): void {
            document.getElementById(`fileinput-${this.emission?.id}`)?.click();
        },

        async uploadFileHandler(change: Event): Promise<void> {
            const inputField = change.target as HTMLInputElement;
            const files = inputField.files;
            if (!files) {
                return;
            }

            const file = files[0];
            const attachmentService = new AttachmentService();
            const response = await apiCallWithErrorHandling(
                attachmentService.postAttachment,
                {
                    emissionId: this.emission?.id ?? 0,
                    file: { data: file, fileName: file.name },
                },
                (this as any).$pui.toast,
                {
                    successTitle: this.$t('toast.success').toString(),
                    successText: this.$t('attachment.upload.successText').toString(),
                    errorTitle: this.$t('toast.error').toString(),
                    errorText: this.$t('attachment.upload.errorText').toString(),
                    waitingTitle: this.$t(WAITING_MODAL_DEFAULT_TITLE).toString(),
                    waitingText: this.$t(WAITING_MODAL_DEFAULT_TEXT).toString(),
                    conflictErrorText: this.$t('attachment.upload.fileExistsErrorText').toString(),
                },
                this.$store,
                0,
                true,
            );

            // If we get here the request was a success.
            if (response && response?.result) {
                const attachment = new AttachmentDto(response.result);

                //save the new attachment to the table data
                this.setAttachment(attachment);

                //save the attachment to the local state so it immediately shows
                this.setEmission({
                    ...this.emission,
                    attachments: [...this.emission.attachments, attachment],
                });

                inputField.value = '';
            }
        },
    },
});

export default AdditionalInformation;
