
import Vue from 'vue';

export default Vue.extend({
    name: 'FloatInput',
    props: {
        locale: {
            type: [Array, String],
            // Default for CAMA project, more commonly should be empty string or array, either one will cause navigator.languages to be used
            default() {
                return this.$i18n.locale;
            },
        },
        showThousands: {
            type: Boolean,
            default: false,
        },
        maximumDecimals: {
            type: Number,
            default: 20,
        },
        minimumDecimals: {
            type: Number,
            default: 0,
        },
        valueAsNumber: {
            type: Boolean,
            default: false,
        },
        // puts classes and styles directly onto the input rather than the container
        childclass: {
            type: Object,
            default: () => {},
        },
        childstyle: {
            type: Object,
            default: () => {},
        },
        //There are different advantages for either inputs to have ids, so provide the option to give each a different id
        valueId: {
            type: String,
            default: undefined,
        },
        displayedId: {
            type: String,
            default: undefined,
        },
        //Below are standard HTML attributes as props
        name: {
            //Required if using HTML form submit event
            type: String,
            default: undefined,
        },
        form: {
            //Required if using HTML form submit event
            type: String,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        invalid: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            default: undefined,
        },
        placeholder: {
            type: String,
            default: undefined,
        },
        maxlength: {
            type: Number,
            default: undefined,
        },
        minlength: {
            type: Number,
            default: undefined,
        },
        max: {
            type: Number,
            default: undefined,
        },
        min: {
            type: Number,
            default: undefined,
        },
        step: {
            type: [Number, String],
            default: 'any',
        },
        autocomplete: {
            type: String,
            default: undefined,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        list: {
            type: String,
            default: undefined,
        },
        size: {
            type: Number,
            default: undefined,
        },
    },
    data(): {
        stringValue: string;
        decimalSeparator: string;
        thousandSeparator: string;
        numberValue: string;
        localeObject: Intl.NumberFormat | null;
        invalidInput: boolean;
    } {
        return {
            stringValue: '',
            decimalSeparator: '.',
            thousandSeparator: ',',
            numberValue: '',
            localeObject: null,
            invalidInput: false,
        };
    },
    computed: {
        conditionalClasses(): Record<string, boolean> {
            return {
                'pui-form-input-field--invalid': this.invalidInput,
                'pui-form-input-field--readonly': this.readonly,
            };
        },
    },
    watch: {
        showThousands(): void {
            this.updateValue();
        },
        locale(): void {
            this.generateLocale();
            this.updateValue();
        },
        maximumDecimals(): void {
            this.generateLocale();
            this.updateValue();
        },
        minimumDecimals(): void {
            this.generateLocale();
            this.updateValue();
        },
        value(): void {
            this.numberValue = this.value;
            this.updateValue();
            this.invalidInput = this.isInputInvalid();
        },
        invalid(): void {
            this.invalidInput = this.isInputInvalid();
        },
    },
    mounted() {
        this.generateLocale();
        this.numberValue = this.value;
        this.generateDisplayedValue();
        this.invalidInput = this.isInputInvalid();
    },
    methods: {
        onInput(payload: Event): void {
            const target = payload.target as HTMLInputElement;
            let newValue = target.value;
            newValue = this.cleanupThousandSeparators(newValue);
            newValue = this.cleanupLeadingZeroes(newValue);
            const numberValue = newValue.replace(this.decimalSeparator, '.');
            if (this.catchNegative(newValue)) {
                this.stringValue = newValue;
                (this.$refs.displayedInput! as HTMLInputElement).value = this.stringValue;
                return;
            }
            const invalidInput = this.isNotANumber(numberValue) || this.containsLetter(newValue);
            if (invalidInput) {
                payload.stopImmediatePropagation();
                (this.$refs.displayedInput! as HTMLInputElement).value = this.stringValue;
                return;
            }
            this.stringValue = newValue;
            this.numberValue = numberValue;
            (this.$refs.displayedInput! as HTMLInputElement).value = this.stringValue;
        },
        onBlur(): void {
            if (this.readonly || this.disabled) return;
            this.updateValue();
            this.invalidInput = this.isInputInvalid();
        },
        onFocus(): void {
            if (this.readonly || this.disabled) return;
            if (this.numberValue === null) this.stringValue = '';
            else this.stringValue = this.numberValue.toString().replace('.', this.decimalSeparator);
        },
        cleanupLeadingZeroes(value: string): string {
            let newValue = value;
            if (newValue.startsWith(this.decimalSeparator)) {
                return '0' + newValue;
            }
            while (newValue.length > 1) {
                if (newValue.startsWith(`0${this.decimalSeparator}`)) return newValue;
                if (!newValue.startsWith('0')) return newValue;
                newValue = newValue.slice(1);
            }
            return newValue;
        },
        cleanupTrailingZeroes(value: string): string {
            let newValue = value;
            while (newValue.length > 1) {
                if (!newValue.endsWith('0')) return newValue;
                newValue = newValue.slice(0, newValue.length - 1);
            }
            return newValue;
        },
        cleanupThousandSeparators(value: string): string {
            return value.split(this.thousandSeparator).join('');
        },
        isNotANumber(stringValue: string): boolean {
            return Number.isNaN(Number(stringValue));
        },
        containsLetter(stringValue: string): boolean {
            const expression = /[a-zA-Z]/;
            return expression.test(stringValue);
        },
        isJSSafeNumber(value: string): boolean {
            if (Number.isNaN(Number(value))) return false;
            const splitNumber = value.split('.');
            if (!Number.isSafeInteger(Number(splitNumber[0]))) return false;
            if (splitNumber.length === 1) return splitNumber[0].length <= 16;
            return splitNumber[0].length + splitNumber[1].length <= 16;
        },
        catchNegative(value: string): boolean {
            return value.startsWith('-') && value.length < 2;
        },
        generateLocale(): void {
            const locale = this.locale && this.locale.length > 0 ? (this.locale as string[]) : [...navigator.languages];
            const options = {
                maximumFractionDigits: this.maximumDecimals,
                minimumFractionDigits:
                    this.minimumDecimals < this.maximumDecimals ? this.minimumDecimals : this.maximumDecimals,
            };
            this.localeObject = new Intl.NumberFormat(locale, options);
            const numberWithGroupAndDecimalSeparator = 10000.1;
            const separatedNumber = this.localeObject!.formatToParts(numberWithGroupAndDecimalSeparator);
            this.decimalSeparator = separatedNumber.find((part) => part.type === 'decimal')?.value ?? '.';
            this.thousandSeparator = separatedNumber.find((part) => part.type === 'group')?.value ?? ',';
        },
        generateDisplayedValue(): void {
            if (this.catchNegative(this.stringValue)) {
                this.stringValue = '';
                this.numberValue = '';
            } else if (this.numberValue !== '') {
                if (this.isNotANumber(this.numberValue)) {
                    this.numberValue = '';
                } else if (!this.isJSSafeNumber(this.numberValue)) {
                    const endpoint = this.numberValue.includes('.') ? 17 : 16;
                    this.numberValue = this.numberValue.slice(0, endpoint);
                }
                this.numberValue = this.formatDecimal(this.numberValue);
                let formattedValue = this.localeObject!.format(Number(this.numberValue));
                if (!this.showThousands) formattedValue = this.cleanupThousandSeparators(formattedValue);
                this.stringValue = formattedValue;
            } else this.stringValue = '';
            if (this.numberValue.endsWith(this.decimalSeparator))
                this.numberValue = this.numberValue.slice(0, this.numberValue.length - 1);
        },
        updateValue(): void {
            this.generateDisplayedValue();
            this.$emit('displayedValue', this.stringValue);
            const potentialNumber = this.numberValue !== '' ? Number(this.numberValue) : null;
            this.$emit('input', this.valueAsNumber ? potentialNumber : this.numberValue);
        },
        isInputInvalid(): boolean {
            const invalidMaxLength = this.maxlength !== undefined ? this.stringValue.length > this.maxlength : false;
            const invalidMinLength = this.minlength !== undefined ? this.stringValue.length < this.minlength : false;
            const numberValue = Number(this.numberValue);
            const invalidMax = this.max !== undefined ? numberValue > this.max : false;
            const invalidMin = this.min !== undefined ? numberValue < this.min : false;
            let invalidStep = false;
            if (typeof this.step === 'number' && this.step !== 0) {
                invalidStep = numberValue % this.step !== 0;
            }
            return this.invalid || invalidMaxLength || invalidMinLength || invalidMax || invalidMin || invalidStep;
        },
        formatDecimal(input: string): string {
            const splitNumber = input.split('.');
            if (splitNumber.length === 1) return input;
            if (splitNumber[1].length <= this.maximumDecimals) return input;
            return this.cleanupTrailingZeroes(Number(input).toFixed(this.maximumDecimals));
        },
    },
});
