var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"product"},[_c('span',{staticClass:"title"},[_vm._v("\n        "+_vm._s(_vm.name)+"\n        "),_c('span',[_c('pui-icon',{class:[
                    'icon',
                    {
                        'icon--hide': !_vm.hasChanges,
                        'icon--disabled': _vm.isSaving,
                    },
                ],attrs:{"icon-name":"check-solid","size":"3rem"},nativeOn:{"click":function($event){return _vm.onSave.apply(null, arguments)}}}),_vm._v(" "),_c('pui-icon',{class:[
                    'icon',
                    {
                        'icon--hide': !_vm.hasChanges,
                        'icon--disabled': _vm.isSaving,
                    },
                ],attrs:{"icon-name":"dismiss","size":"3rem"},nativeOn:{"click":function($event){return _vm.onReset.apply(null, arguments)}}})],1)]),_vm._v(" "),_vm._l((5),function(_,i){return _c('float-input',{key:i,attrs:{"id":`${_vm.name[0]}-${_vm.unitSid}-${i}`,"value":_vm.getValueForIndex(i).toString(),"valid":_vm.isIndexValid(i),"step":1,"min":0,"value-as-number":true},on:{"input":(event) => _vm.onInput(event, i)}})}),_vm._v(" "),_c('comment-field',{ref:"commentfield",staticClass:"comment",attrs:{"text":_vm.commentText,"last-changed":_vm.commentLastChanged,"last-changed-by":_vm.commentLastChangedBy,"has-saved-comment":_vm.hasSavedComment,"deletion-pending":_vm.deletionPending},on:{"change":_vm.onCommentChange}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }