import { LocationTableDataMapper } from '@/components/tables/emission-table/helpers/location-table-data-mapper';
import { PowerPlantListEndpointRequest } from '@/models/power-plant';
import { LocationTableData } from '@/models/table';
import {
    AttachmentDto,
    CompletenessPostEndpointResponse,
    EmissionDto,
    Granularity,
    PowerPlantDto,
    UnitDto,
} from '@/service-proxies/service-proxies.g';
import { CompletenessService } from '@/services/completeness-service';
import { EmissionService } from '@/services/emission-service';
import { LocationService } from '@/services/location-service';
import { ApplicationState } from '@/store';
import { getAttachments } from '@/utils/helpers/emission/attachments';
import { fillMissingEmissionValues } from '@/utils/helpers/emission/fill-missing-emission-values';
import { findLocation } from '@/utils/helpers/emission/find-location';
import { fixDraft } from '@/utils/helpers/emission/fix-draft';
import { getInitialStartDate } from '@/utils/helpers/formatters';
import { TableType } from '@/utils/tables/table-config';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';

export interface LocationState {
    api: {
        completeness: CompletenessService;
        emissions: EmissionService;
        locations: LocationService;
    };
    attachments: AttachmentDto[];
    granularity: Granularity;
    locations: PowerPlantDto[];
    mapper: LocationTableDataMapper;
    start: string;
    emissionTableData: LocationTableData[];
    approvalTableData: LocationTableData[];
}

const state: LocationState = {
    api: {
        completeness: new CompletenessService(),
        emissions: new EmissionService(),
        locations: new LocationService(),
    },
    attachments: [] as AttachmentDto[],
    granularity: Granularity.Monthly,
    locations: [] as PowerPlantDto[],
    mapper: new LocationTableDataMapper(),
    start: getInitialStartDate(),
    emissionTableData: [] as LocationTableData[],
    approvalTableData: [] as LocationTableData[],
};

const getters: GetterTree<LocationState, ApplicationState> = {
    locations: (state) => state.locations,
    emissionTableData: (state) => state.emissionTableData,
    approvalTableData: (state) => state.approvalTableData,
    start: (state) => state.start,
    granularity: (state) => state.granularity,
    attachments: (state) => state.attachments,
};

const mutations: MutationTree<LocationState> = {
    setLocations(state: LocationState, plants: PowerPlantDto[]) {
        state.locations = plants;
    },
    setTableData(state: LocationState) {
        state.emissionTableData = state.mapper.mapLocationsToTableData(state.locations, TableType.DATA_ENTRY);
        state.approvalTableData = state.mapper.mapLocationsToTableData(state.locations, TableType.APPROVAL);
    },
    setStart(state: LocationState, start: string) {
        state.start = start;
    },
    setEmission(state: LocationState, emission: EmissionDto) {
        // If we find the emission on the powerplant we update it there,
        const location = findLocation(state, emission);

        if (location) {
            const EMISSION_DRAFT_ID = 0;
            let currentEmission = location.emissions?.find(
                (e) => emission.id !== EMISSION_DRAFT_ID && e.id === emission.id,
            );

            // If we could not find an emission by id, then it was probably a draft emission before so lets update that
            if (!currentEmission) {
                // An emission is additionally uniquely identified by asset-and-fuel, so lets try that
                currentEmission = location.emissions?.find(
                    (e) => e.locationId === emission.locationId && e.activityData?.id === emission.activityData?.id,
                );
            }

            // If we could not find an emission at all, then the user wants to add a new emission draft
            if (!currentEmission) {
                const newEmission = fillMissingEmissionValues(emission);
                location.emissions?.push(newEmission);
                return;
            }

            currentEmission = {
                ...currentEmission,
                ...emission,
            } as EmissionDto;
            const newEmission = fillMissingEmissionValues(currentEmission);

            if (!location.emissions) return;
            const index = location.emissions?.findIndex(
                (e) => e.locationId === emission.locationId && e.activityData?.id === emission.activityData?.id,
            );
            location.emissions[index] = newEmission;
        }
    },
    removeEmission(state: LocationState, emission: EmissionDto) {
        const location = findLocation(state, emission);

        if (!location || !location.emissions) return;
        const index = location.emissions?.findIndex(
            (e) => e.locationId === emission.locationId && e.activityData?.id === emission.activityData?.id,
        );
        location.emissions.splice(index, 1);
    },
    setGranularity(state: LocationState, granularity: Granularity) {
        state.granularity = granularity;
    },
    setAttachments(state: LocationState, attachments: AttachmentDto[]) {
        state.attachments = attachments;
    },
    setLocationIsComplete(state: LocationState, data: CompletenessPostEndpointResponse) {
        const location = findLocation(state, data) as UnitDto;
        if (!location) return;

        location.isComplete = data.isComplete;
    },
};

const actions: ActionTree<LocationState, ApplicationState> = {
    async LOAD_LOCATIONS(
        { state, commit, dispatch }: ActionContext<LocationState, ApplicationState>,
        input: {
            powerPlantGroupId: number;
            scopeId: number | undefined;
            locationTypeIds: number[] | undefined;
            fleetId: number;
            countryId: number;
            granularity: Granularity;
        },
    ): Promise<void> {
        const filter = {
            granularity: input.granularity,
            startDate: state.start,
            endDate: undefined,
            scopeId: input.scopeId,
            locationTypeIds: input.locationTypeIds,
            fleetId: input.fleetId,
            countryId: input.countryId,
        } as PowerPlantListEndpointRequest;

        const powerPlants = (await state.api.locations.getPowerPlants(filter)).result ?? [];

        const processEmissions = (asset: PowerPlantDto | UnitDto): void => {
            asset.emissions?.forEach((emission: EmissionDto) => {
                fillMissingEmissionValues(emission);
            });
        };

        powerPlants.forEach((powerPlant) => {
            processEmissions(powerPlant);
            powerPlant.units?.forEach((unit) => {
                processEmissions(unit);
            });
        });
        commit('setLocations', powerPlants);
        commit('setAttachments', getAttachments(powerPlants));
        commit('setTableData');
        const trackerInput = {
            powerPlants,
            scopeId: input.scopeId,
            countryId: input.countryId,
        };
        dispatch('tables/SET_TRACKERS', trackerInput, { root: true });
    },
    async UPDATE_EMISSION(
        { state, commit }: ActionContext<LocationState, ApplicationState>,
        emission: EmissionDto,
    ): Promise<void> {
        const updatedEmission = (await state.api.emissions.postEmission(emission)).result as EmissionDto;
        commit('setEmission', updatedEmission);
        commit('setTableData');
    },
    async DELETE_EMISSION(
        { state, commit }: ActionContext<LocationState, ApplicationState>,
        emission: EmissionDto,
    ): Promise<void> {
        if (emission.id !== 0) await state.api.emissions.deleteEmission(emission.id!);
        commit('removeEmission', emission);
        commit('setTableData');
    },
    RESET_PLANTS({ commit }: ActionContext<LocationState, ApplicationState>) {
        commit('setLocations', []);
        commit('setTableData');
    },
    SET_GRANULARITY({ commit }: ActionContext<LocationState, ApplicationState>, granularity: Granularity) {
        commit('setGranularity', granularity);
    },
    async SET_START({ commit, dispatch, rootGetters }: ActionContext<LocationState, ApplicationState>, start: string) {
        commit('setStart', start);
        const selectedScope = rootGetters['scope/GET_SELECTED_SCOPE'];
        const country = rootGetters['country/GET_SELECTED_COUNTRY'];
        if (selectedScope && country) {
            commit('fleet/setLoadFleet', true, { root: true });
            await dispatch('LOAD_LOCATIONS', {
                scopeId: selectedScope.id,
                countryId: country.id,
                granularity: selectedScope?.granularity ?? Granularity.Monthly,
            });
            commit('fleet/setLoadFleet', false, { root: true });
        }
    },
    async ADD_DRAFT_EMISSIONS_TO_LOCATION(
        { commit }: ActionContext<LocationState, ApplicationState>,
        {
            selectedActivityDataIds,
            assetId,
            scopeId,
        }: {
            selectedActivityDataIds: number[];
            assetId: number;
            scopeId: number;
        },
    ): Promise<void> {
        const filter = {
            activityDataInformation: selectedActivityDataIds,
            scopeId: scopeId,
            startDate: state.start,
            locationId: assetId,
        };

        const response = await state.api.emissions.getEmissionDrafts(filter);
        const result = response.result;

        if (result) {
            const tonUnitOfMeasurement = {
                id: 3,
                shortName: 't',
                name: 'Tons',
            };
            for (const emissionDraft of result) {
                let emission = fixDraft(emissionDraft);
                commit('setEmission', emission);
            }
        }
        commit('setTableData');
    },
    ADD_ATTACHMENT({ state, commit }: ActionContext<LocationState, ApplicationState>, attachment: AttachmentDto): void {
        commit('setAttachments', [...state.attachments, attachment]);
    },
    DELETE_ATTACHMENT(
        { state, commit }: ActionContext<LocationState, ApplicationState>,
        attachment: AttachmentDto,
    ): void {
        const deletedIndex = state.attachments.findIndex((entry) => entry.id === attachment.id);
        const updatedArray = [
            ...state.attachments.slice(0, deletedIndex),
            ...state.attachments.slice(deletedIndex + 1, state.attachments.length),
        ];
        commit('setAttachments', updatedArray);
    },
    async SET_COMPLETENESS(
        { state, commit }: ActionContext<LocationState, ApplicationState>,
        body: any,
    ): Promise<void> {
        const res = (await state.api.completeness.addCompleteness(body)).result;
        commit('setLocationIsComplete', res);
        commit('setTableData');
    },
    async APPROVE_BULK_EMISSIONS(
        { state, commit }: ActionContext<LocationState, ApplicationState>,
        body: number[],
    ): Promise<void> {
        const res = (await state.api.emissions.approveBulkEmissions(body)).result;
        if (!res || !res.emissions) throw new Error();
        for (const updatedEmission of res?.emissions) {
            commit('setEmission', updatedEmission);
        }
        commit('setTableData');
    },
    async APPROVE_SINGULAR_EMISSION(
        { state, commit }: ActionContext<LocationState, ApplicationState>,
        body: {
            emissionId: number;
            comment: string;
        },
    ): Promise<void> {
        const res = (await state.api.emissions.approveAndCommentEmission(body.emissionId, body.comment))?.result;
        if (!res) throw new Error();
        commit('setEmission', res);
        commit('setTableData');
    },
    async WITHDRAW_APPROVAL(
        { state, commit }: ActionContext<LocationState, ApplicationState>,
        body: number,
    ): Promise<void> {
        const res = (await state.api.emissions.withdrawApproval(body)).result;
        if (!res || !res.emission) throw new Error();
        commit('setEmission', res.emission);
        commit('setTableData');
    },
    async OVERRIDE_APPROVAL(
        { state, commit }: ActionContext<LocationState, ApplicationState>,
        body: number,
    ): Promise<void> {
        const res = (await state.api.emissions.acceptParked(body)).result;
        if (!res || !res.emission) throw new Error();
        // have to manually remove parkedEmission otherwise the old parked emission remains
        // this is due to how the setEmission setter is built
        const emission = { ...res.emission, parkedEmission: undefined };
        commit('setEmission', emission);
        // The attachments belonging to the old emission will still be in state
        // This function manually removes them rather than reload locations as it is a long load time
        const attachmentPrefix = `${emission.locationId}-${emission.activityData?.id}`;
        const reducedAttachments = state.attachments.filter(
            (attachment) => !attachment.link?.startsWith(attachmentPrefix),
        );
        commit('setAttachments', reducedAttachments);
        commit('setTableData');
    },
};

const namespaced = true;

export const location: Module<LocationState, ApplicationState> = {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};
