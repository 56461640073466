
import Vue, { PropType } from 'vue';
import FloatInput from '@/components/inputs/float-input.vue';
import { EmissionFactorDto, GlobalEmissionFactorDto } from '@/service-proxies/service-proxies.g';
import { checkDecimalPlaces, EventBus } from '@/utils';
import { MaxDecimalPlaces, MaxValue } from '@/constants/input-precision';

export default Vue.extend({
    name: 'EmissionFactor',
    components: {
        FloatInput,
    },
    props: {
        value: {
            type: Number,
            required: true,
        },
        emissionFactor: {
            type: Object as PropType<EmissionFactorDto>,
            required: true,
        },
    },
    data(): {
        localValue: string;
        valueIsValid: boolean;
    } {
        return {
            localValue: '',
            valueIsValid: true,
        };
    },
    computed: {
        valueHasBeenEdited(): boolean {
            return this.isValid() && this.localValue !== this.value.toString();
        },
        hasValue(): boolean {
            return this.value !== undefined && this.value !== null;
        },
        printedValue(): string {
            return this.hasValue ? this.value.toString() : '';
        },
        noValue(): boolean {
            return this.emissionFactor.id === 0;
        },
    },
    mounted() {
        this.localValue = this.printedValue;
    },
    methods: {
        changeEmissionFactor(value: string): void {
            this.localValue = value;
            this.valueIsValid = this.isValid();
        },
        isValid(): boolean {
            return (
                this.hasValue &&
                checkDecimalPlaces(Number(this.localValue), MaxDecimalPlaces.EMISSION_FACTOR) &&
                Number(this.localValue) < MaxValue.EMISSION_FACTOR
            );
        },
        openModal(): void {
            EventBus.$emit(EventBus.VIEWS.STANDARD_EMISSION.EDIT, this.emissionFactor);
        },
        async saveChange(): Promise<void> {
            this.valueIsValid = this.isValid();
            if (this.valueIsValid) {
                const newEmissionFactor = {
                    ...this.emissionFactor,
                    emissionsFactor: Number(this.localValue),
                } as GlobalEmissionFactorDto;
                EventBus.$emit(EventBus.VIEWS.STANDARD_EMISSION.SAVE_CHANGE, newEmissionFactor);
            }
        },
        cancelChange(): void {
            this.localValue = this.printedValue;
        },
    },
});
