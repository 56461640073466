
import { LocationTableData } from '@/models/table';
import { BadRequestResponse, PowerPlantDto } from '@/service-proxies/service-proxies.g';
import { DateDisplayOptions, formatDate, formatLocationName } from '@/utils/helpers/formatters';
import Vue, { nextTick } from 'vue';
import { mapActions, mapGetters } from 'vuex';

interface Data {
    bodyMessage: string;
    rowData: LocationTableData | null;
}

export default Vue.extend({
    name: 'CompleteConfirmModal',

    data(): Data {
        return {
            bodyMessage: '',
            rowData: null,
        };
    },

    computed: {
        ...mapGetters({
            isLoading: 'isLoading',
        }),
        ...mapGetters('country', {
            getCurrentCountry: 'GET_SELECTED_COUNTRY',
        }),
        ...mapGetters('location', {
            getStartDate: 'start',
        }),
        ...mapGetters('scope', {
            getSelectedScope: 'GET_SELECTED_SCOPE',
        }),
    },

    methods: {
        ...mapActions('location', {
            setCompleteness: 'SET_COMPLETENESS',
        }),

        open(data: Record<string, LocationTableData>) {
            const { parentData, rowData } = data;
            this.rowData = rowData;

            const placeholders = {
                country: this.getCurrentCountry.name,
                location: formatLocationName(parentData?.location, rowData?.location),
                date: formatDate(this.getStartDate, this.$i18n.locale, DateDisplayOptions.MonthOnly),
            };

            // this.bodyMessage = this.$t('approvals.completeModal.body', placeholders) as string;
            this.bodyMessage = this.$t('approvals.completeModal.body') as string;
            // Placeholder aren't working so manually replace them
            Object.entries(placeholders).forEach(([key, value]) => {
                this.bodyMessage = this.bodyMessage.replace(`{{ ${key} }}`, value);
            });

            (this as any).$refs['confirm-warning-modal'].open();
        },

        onConfirmCallback() {
            if (this.isLoading) return;
            this.completeLocation();
            (this as any).$refs['confirm-warning-modal'].close();
        },

        onCloseCallback() {
            this.rowData = null;
        },

        async completeLocation() {
            this.$store.commit('loading');

            try {
                const [year, month, _day] = this.getStartDate.split('-').map(Number);
                // Set the end date to the last day of the month
                // Note: month is 0-indexed so no need to add 1
                const endDate = new Date(Date.UTC(year, month, 0)).toISOString().split('T')[0];

                const locationType = (this.rowData?.meta?.object as PowerPlantDto)?.locationType ?? null;

                await this.setCompleteness({
                    locationId: this.rowData!.meta.id,
                    startDate: this.getStartDate,
                    endDate,
                    isComplete: true,
                    scope: this.getSelectedScope,
                    locationType: locationType,
                });
                (this as any).$pui.toast({
                    type: 'success',
                    title: this.$t('notification.complete.markedAsComplete'),
                    copy: '',
                });
            } catch (e) {
                const message = (e as BadRequestResponse).message ?? '';
                (this as any).$pui.toast({
                    type: 'error',
                    title: this.$t('toast.error'),
                    copy: message,
                });
                console.log(e);
                throw e;
            } finally {
                this.$store.commit('loading');
                await nextTick();
                this.onCloseCallback();
            }
        },
    },
});
