
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
    name: 'InvalidPermissionPage',
    computed: {
        ...mapGetters('roles', { rolesIds: 'getRolesIds' }),

        roleCount(): string {
            const count = this.rolesIds.length;
            const message = this.$t('errorPage.permissions.roleCount') as string;
            return message.replace('{{ count }}', count);
        },
    },
});
