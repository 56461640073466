
import Vue from 'vue';
import { mapGetters } from 'vuex';
import './main.less';
import { ClickTelemetry } from '@coode/fe-sdk/dist/telemetry/click';
import { HttpStatusCodes } from '@/enums/http-status-codes';
import { Confirmation, ConfirmationOptions } from '@/models';
import { camaScope } from './utils/fe-sdk/sdk-plugin-config';
import { getTranslationManager } from '@/utils/translation-plugin/translation-manager';
import { getSdk } from './utils/fe-sdk/sdk-helper';
import HeaderWrapper from '@/components/header/header.vue';
import Loader from '@/components/loader/loader.vue';
import WaitingModal from '@/components/waiting-modal/waiting-modal.vue';
import InvalidPermissionPage from '@/components/error/invalid-permission.vue';
import AppSkeleton from '@/components/ui/loading/skeletons/app-skeleton.vue';
import { RouteNames } from './constants/routes';
import { functionalPermissions } from './utils';

const App = Vue.extend({
    name: 'App',
    components: {
        AppSkeleton,
        HeaderWrapper,
        Loader,
        WaitingModal,
        InvalidPermissionPage,
    },
    data(): {
        signedIn: boolean;
        showHeader: boolean;
        showLeft: boolean;
        showRight: boolean;
        showFooter: boolean;
        confirmation: Confirmation;
    } {
        return {
            signedIn: false,
            showHeader: true,
            showLeft: false,
            showRight: false,
            showFooter: false,
            confirmation: {} as Confirmation,
        };
    },
    provide(): {
        toggleRightPanel: Function;
        ConfirmAction: Function;
    } {
        return {
            toggleRightPanel: this.toggleRightPanel,
            ConfirmAction: this.confirmAction,
        };
    },
    computed: {
        ...mapGetters('roles', {
            validPermissions: 'getArePermissionsValid',
        }),
        $confirmationBoxRef(): { close: Function; isOpen: boolean } {
            return (this as any).$refs.confirmationBox;
        },
    },
    async created(): Promise<void> {
        try {
            await this.loadAuth();
            await this.loadMetaData();
            await this.$store.dispatch('roles/SPLIT_ASSETS');
            await this.$store.dispatch('roles/VALIDATE_PERMISSIONS');
            await this.authenticateRoute();
            await this.$store.dispatch('unitOfMeasurement/FETCH_UOMS');

            if (process.env.VUE_APP_CLICKTELEMETRY_CONNECTION_STRING) {
                const clickTelemetry = new ClickTelemetry(process.env.VUE_APP_CLICKTELEMETRY_CONNECTION_STRING);
                clickTelemetry.start();
            }
        } catch (error) {
            (this as any).$pui.toast({
                type: 'error',
                title: this.$t('error.loadingResource'),
                copy: this.$t((error as any).message),
            });
        }
    },
    methods: {
        async loadMetaData(): Promise<void> {
            const statusCode = await this.$store.dispatch('metaData/GET_META_DATA');
            if (statusCode !== HttpStatusCodes.Ok) {
                (this as any).$pui.toast({
                    type: 'error',
                    title: this.$t('error.loadingMetaData.title'),
                    copy: this.$t('error.loadingMetaData.text'),
                });
            }
        },

        async loadAuth(): Promise<void> {
            try {
                this.$store.commit('loading');
                const sdk = getSdk();
                await sdk?.$authenticator?.loadAuthModule();
                await sdk?.$authenticator?.attemptSsoSilent('loginRedirect');
                await sdk?.$http?.$generic.request.api.setBaseUrl(process.env.VUE_APP_API_BASE_URL);
                await sdk?.$http?.$generic.request.api.setTokenCallback(
                    async () => await sdk?.$authenticator?.getToken(camaScope),
                );

                const signedIn = (await sdk?.$authenticator?.isAuthenticated()) as boolean;
                if (signedIn && sdk) {
                    const useCaseId = Number(process.env.VUE_APP_USE_CASE_ID);
                    await sdk?.$permissions?.setPermissions(useCaseId);
                    await getTranslationManager().fetchInitialData(sdk);
                    this.signedIn = signedIn;
                    const profile = sdk?.$authenticator?.getAccount();
                    if (profile) {
                        this.$store.commit('user/SET_PROFILE', profile);
                    }
                }
            } catch (err) {
                throw new Error('Error occurred during auth process.');
            } finally {
                this.$store.commit('loading');
            }
        },
        async authenticateRoute(): Promise<void> {
            if (!this.validPermissions) return;
            const route = this.$route.path;
            if (route === RouteNames.REPORTING) return;
            if (route === RouteNames.DATA_INPUT && functionalPermissions.userCanEnterEmissionData()) return;
            if (route === RouteNames.APPROVAL && functionalPermissions.userCanApproveEmissionData()) return;
            if (route === RouteNames.FREE_ALLOCATION && functionalPermissions.userCanAllocateFreeCO2()) return;
            if (route === RouteNames.DISTRIBUTION_TYPE && functionalPermissions.userCanDefineCO2Risk()) return;
            if (route === RouteNames.STANDARD_EMISSION && functionalPermissions.userCanViewStandardEF()) return;
            await this.$router.replace('/');
        },
        toggleRightPanel(state: boolean | undefined): void {
            if (state === undefined) {
                this.showRight = !this.showRight;
            } else {
                this.showRight = state;
            }
        },
        confirmAction({ title, cancel, confirm, content }: ConfirmationOptions): Promise<boolean> {
            return new Promise((res, rej) => {
                const reject = (): void => {
                    this.$confirmationBoxRef.close();
                    return rej(false);
                };
                const resolve = (): void => {
                    this.$confirmationBoxRef.close();
                    return res(true);
                };
                this.confirmation = {
                    resolve,
                    reject,
                    title,
                    cancel,
                    confirm,
                    content,
                };
                this.$confirmationBoxRef.isOpen = true;
            });
        },
    },
});

export default App;
