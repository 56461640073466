
import Vue from 'vue';
import clickOutside from '@/directives/click-outside';
import { getSdk } from '@/utils/fe-sdk/sdk-helper';
import DevTools from '../dev-tools/dev-tools.vue';
import CopyText from '@/components/ui/text/copy-text.vue';
import LogoutIcon from '@/components/ui/icons/logout.vue';
import { functionalPermissions } from '@/utils';
import { USER_FEEDBACK_URL, REPORT_INCIDENT_URL, USER_MANUAL_URL } from '@/constants/meta';

const UserProfile = Vue.extend({
    name: 'UserProfile',
    directives: {
        clickOutside,
    },
    components: {
        DevTools,
        CopyText,
        LogoutIcon,
    },
    props: {
        userName: {
            type: String,
            required: true,
        },
        emailAddress: {
            type: String,
            required: true,
        },
    },
    data(): {
        detailsToggled: boolean;
    } {
        return {
            detailsToggled: false,
        };
    },
    computed: {
        hasAccessToDevTools(): boolean {
            return functionalPermissions.userCanAccessDevTools();
        },
        environmentColour(): string {
            const choices = {
                development: '#009688', // local
                dev: '#B3254C',
                uat: '#8A3898',
                prd: '#0078DC',
            } as Record<string, string>;
            const environment = process.env.VUE_APP_ENVIRONMENT as string;
            return choices[environment];
        },
    },
    methods: {
        logOut(): void {
            getSdk()?.$authenticator?.logout();
        },

        offFocus(): void {
            if (this.detailsToggled) {
                this.detailsToggled = false;
            }
        },

        openUserManual(): void {
            window.open(USER_MANUAL_URL);
        },

        sendFeedback(): void {
            window.open(USER_FEEDBACK_URL);
        },

        reportIncident(): void {
            window.open(REPORT_INCIDENT_URL);
        },
    },
});

export default UserProfile;
