import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ApplicationState } from '@/store';
import { UserAccessService } from '@/services/user-access-service';
import { Profile } from '@/models';
import { Permissions } from '@/enums/permissions';

const userAccessService = new UserAccessService();

export interface UserState {
    profile: Profile;
    accessData: any;
}

const state: UserState = {
    profile: {
        name: '',
        email: '',
        family_name: '',
        given_name: '',
        permissions: [],
        sub: '',
    },
    accessData: null,
};

const getters: GetterTree<UserState, ApplicationState> = {
    IS_PROFILE(state) {
        return !!state.profile.email;
    },
    HAS_PERMISSION(state) {
        return (permission: Permissions): boolean | undefined => state.profile.permissions?.includes(permission);
    },
    IS_CREATOR(state) {
        return state.profile.permissions?.includes(Permissions.Creator);
    },
    IS_ADMIN(state) {
        return state.profile.permissions?.includes(Permissions.Admin);
    },
    CAN_ASSIGN_FREE_ALLOCATIONS(state) {
        return state.profile.permissions?.includes(Permissions.FreeAllocation);
    },
};

const mutations: MutationTree<UserState> = {
    SET_PROFILE(state: UserState, profile: Profile) {
        Object.assign(state.profile, profile);
    },
    SET_USER_ACCESS_DATA(state: UserState, payload: any) {
        state.accessData = payload;
    },
};

const actions: ActionTree<UserState, ApplicationState> = {
    async GET_USER_ACCESS_DATA({ commit }: ActionContext<UserState, ApplicationState>) {
        const { result } = await userAccessService.getUserAccessData();
        commit('SET_USER_ACCESS_DATA', result);
    },
};

const namespaced = true;

export const user: Module<UserState, ApplicationState> = {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};
