
import Vue from 'vue';
import { DateDisplayOptions, formatDate, getInitialStartDate } from '@/utils/helpers/formatters';
import GranularityDropdown from './granularity-dropdown/granularity-dropdown.vue';
import clickOutside from '@/directives/click-outside';
import { mapGetters, mapState } from 'vuex';
import { Granularity } from '@/service-proxies/service-proxies.g';

export const GranularityDate = Vue.extend({
    name: 'GranularityDate',
    components: {
        GranularityDropdown,
    },
    directives: {
        clickOutside,
    },
    data(): {
        showCalendar: boolean;
        year: number;
        month: number;
        day: number;
    } {
        return {
            showCalendar: false,
            year: 0,
            month: 0,
            day: 0,
        };
    },
    computed: {
        ...mapState('location', {
            startDate: 'start',
            granularity: 'granularity',
        }),
        ...mapState('scope', {
            scopeId: 'selectedScopeId',
        }),
        ...mapGetters('scope', {
            getScope: 'GET_SCOPE',
        }),
        ...mapGetters('fleet', {
            fleetIsLoading: 'loadFleet',
        }),

        dateDisplay(): DateDisplayOptions {
            if (this.granularity === Granularity.Yearly) {
                return DateDisplayOptions.YearOnly;
            } else if (this.granularity === Granularity.Quarterly) {
                return DateDisplayOptions.QuarterYear;
            }

            return DateDisplayOptions.MonthOnly;
        },

        disableBackButton(): boolean {
            if (this.fleetIsLoading) return true;
            if (this.granularity === Granularity.Yearly) {
                return this.year === 2022;
            }
            return this.year === 2022 && this.month === 1;
        },

        disableNextButton(): boolean {
            return this.fleetIsLoading;
        },
    },
    watch: {
        scopeId(): void {
            this.$store.dispatch('location/SET_START', getInitialStartDate());
        },

        startDate(): void {
            this.refreshDate();
        },
    },
    mounted() {
        this.refreshDate();
    },
    methods: {
        openCalendar(): void {
            if (this.fleetIsLoading) return;
            this.showCalendar = true;
        },

        formatDate(dateString: string, locale: string, display: DateDisplayOptions): string {
            return formatDate(dateString, locale, display);
        },

        formatToTwoDigits(value: number): string {
            return value.toPrecision(2);
        },

        refreshDate(): void {
            const fragments = this.startDate.split('-');
            this.year = Number(fragments[0]);
            this.month = Number(fragments[1]);
            this.day = Number(fragments[2]);
        },

        selectStartPeriod(newPeriod: string): void {
            if (newPeriod !== this.startDate) {
                this.$store.dispatch('location/SET_START', newPeriod);
            }
            this.closeCalendar();
        },

        updateStartPeriod(): void {
            const year = this.year.toString();
            const month = this.month < 10 ? `0${this.month}` : this.month.toString();
            const day = this.day < 10 ? `0${this.day}` : this.day.toString();
            const newDate = `${year}-${month}-${day}`;
            this.selectStartPeriod(newDate);
        },

        closeCalendar(event?: { target: { dataset: { close: string } } }): void {
            if (this.showCalendar && (!event || !event.target.dataset.close)) {
                this.showCalendar = false;
            }
        },

        decrement(): void {
            if (this.disableBackButton) return;
            switch (this.granularity) {
                case Granularity.Monthly:
                    this.decrementMonth();
                    break;
                case Granularity.Quarterly:
                    this.decrementQuarter();
                    break;
                case Granularity.Yearly:
                    this.year = this.year - 1;
                    break;
                default:
                    break;
            }
            this.updateStartPeriod();
        },

        decrementMonth(): void {
            const newMonth = this.month - 1;
            if (newMonth < 1) {
                this.year = this.year - 1;
                this.month = 12;
            } else {
                this.month = newMonth;
            }
        },

        decrementQuarter(): void {
            const newMonth = this.month - 3;
            if (newMonth < 1) {
                this.year = this.year - 1;
                this.month = 10;
            } else {
                this.month = newMonth;
            }
        },

        increment(): void {
            if (this.disableNextButton) return;
            switch (this.granularity) {
                case Granularity.Monthly:
                    this.incrementMonth();
                    break;
                case Granularity.Quarterly:
                    this.incrementQuarter();
                    break;
                case Granularity.Yearly:
                    this.year = this.year + 1;
                    break;
                default:
                    break;
            }
            this.updateStartPeriod();
        },

        incrementMonth(): void {
            const newMonth = this.month + 1;
            if (newMonth > 12) {
                this.year = this.year + 1;
                this.month = 1;
            } else {
                this.month = newMonth;
            }
        },

        incrementQuarter(): void {
            const newMonth = this.month + 3;
            if (newMonth > 12) {
                this.year = this.year + 1;
                this.month = 1;
            } else {
                this.month = newMonth;
            }
        },
    },
});

export default GranularityDate;
