interface Dates {
    createDate: string;
    startDate: string;
    endDate: string;
}

//Useful for development, won't be needed when emission drafts get given dates in the backend
export function createDates(date: string): Dates {
    const timePeriods = date.split('-');
    const current = new Date();

    const createDate = current.toISOString();

    const month = Number(timePeriods[1]);
    const year = Number(timePeriods[0]);

    // Date constructor month parameter is index, whilst month is number
    const startDate = new Date(Date.UTC(year, month - 1, 1)).toISOString();
    let endDate;
    if (month === 12) {
        endDate = new Date(Date.UTC(year + 1, 0, 0)).toISOString();
    } else {
        endDate = new Date(Date.UTC(year, month, 0)).toISOString();
    }

    return {
        createDate,
        startDate,
        endDate,
    };
}
