
import Vue, { PropType } from 'vue';
import Tab from './tab.vue';

interface NavItems {
    displayName: string;
    href: string;
    isActive: boolean;
    visible: boolean;
}

interface Option {
    label: string;
    value: string;
}

interface StructureSelect {
    options: { text: string; id: string }[];
    selectedId: string;
}

export default Vue.extend({
    name: 'Navbar',
    components: {
        Tab,
    },
    props: {
        navigationItems: {
            type: Array as PropType<NavItems[]>,
            default: () => [],
        },
        structureSelect: {
            type: Object as PropType<StructureSelect>,
            default: undefined,
        },
    },
    computed: {
        languageOptions(): Option[] {
            return this.structureSelect.options.map((object: any) => {
                return { label: object.text, value: object.id };
            });
        },
    },
    methods: {
        handleClick(value: any) {
            this.$emit('click', value);
        },
        onSelectIdChanged(id: any) {
            this.$emit('changed', id);
        },
    },
});
