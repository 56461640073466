export function equivalentHBEFUOM(energyId: number) {
    switch (energyId) {
        case 12:
            return '';
        case 13:
            return 'kg / kJ';
        case 14:
            return 'kg / MJ';
        case 15:
            return 'kg / GJ';
        default:
            return '';
    }
}

export function equivalentCalorificUOM(energyId: number, fuelId: number) {
    if (fuelId !== 3) return '';
    switch (energyId) {
        case 12:
            return '';
        case 13:
            return 'kJ / kg';
        case 14:
            return 'MJ / kg';
        case 15:
            return 'GJ / kg';
        default:
            return '';
    }
}
