import { Permissions } from '@/enums/permissions';
import { LocalStorageHelper } from '@coode/fe-sdk';
import { UserPermissions } from '@coode/fe-sdk/dist/types';
import { Permission } from '@coode/fe-sdk/dist/types/uam';

export function getUserPermissions(): UserPermissions {
    return LocalStorageHelper.getPermissionsLocalStorage();
}

function getFunctionalPermissions(): Permission[] {
    return getUserPermissions().permissions;
}

function findPermission(permission: string): boolean {
    return !!getFunctionalPermissions().find((p) => p.name === permission);
}

//TODO remove old unused permissions
export const functionalPermissions = {
    findPermission,
    userCanAccessDevTools: (): boolean => findPermission(Permissions.DevTools),
    userCanAllocateFreeCO2: (): boolean => findPermission(Permissions.FreeAllocation),
    userCanEnterEmissionData: (): boolean => findPermission(Permissions.EmissionDataEntry),
    userCanDefineCO2Risk: (): boolean => findPermission(Permissions.Co2RiskAllocation),
    userCanApproveEmissionData: (): boolean => findPermission(Permissions.ApproveEmissionData),
    userCanWithdrawApproval: (): boolean => findPermission(Permissions.WithdrawApproval),
    userCanEditFuelAdjustment: (): boolean => findPermission(Permissions.EditFuelAdjustment),
    userCanViewFuelAdjustment: (): boolean => findPermission(Permissions.ViewFuelAdjustment),
    userCanViewRelatedEmissions: (): boolean => findPermission(Permissions.ViewRelatedEmissions),
    userCanEditStandardEF: (): boolean => findPermission(Permissions.EditStandardEF),
    userCanViewStandardEF: (): boolean => findPermission(Permissions.ViewStandardEF),
};
