import { ActivityDataService } from '@/services/activity-data-service';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ApplicationState } from '..';
import { ActivityDataDto } from '@/service-proxies/service-proxies.g';
import axios, { AxiosError } from 'axios';
import { HttpStatusCodes } from '@/enums/http-status-codes';
import { ActivityDataType } from '@/enums/activity-data-type';

const activityDataService: ActivityDataService = new ActivityDataService();

export interface ActivityDataState {
    activityData: ActivityDataDto[];
}

const state: ActivityDataState = {
    activityData: [],
};

const getters: GetterTree<ActivityDataState, ApplicationState> = {
    GET_ACTIVITY_DATA: (state): ActivityDataDto[] => {
        return state.activityData;
    },
    GET_ACTIVITY_DATA_TYPE: (state): ActivityDataType => {
        if (!state.activityData) {
            return ActivityDataType.Unknown;
        }
        return (state.activityData[0]?.type as ActivityDataType) ?? ActivityDataType.Unknown;
    },
};

const mutations: MutationTree<ActivityDataState> = {
    SET_ACTIVITY_DATA(state: ActivityDataState, data: ActivityDataDto[]) {
        state.activityData = data;
    },
};

const actions: ActionTree<ActivityDataState, ApplicationState> = {
    async GET_ACTIVITY_DATA(
        { commit }: ActionContext<ActivityDataState, ApplicationState>,
        scopeId: number | undefined,
    ): Promise<HttpStatusCodes> {
        const filter = {
            scopeId: scopeId,
        };
        try {
            const result = await activityDataService.getActivities(filter);
            commit('SET_ACTIVITY_DATA', result.result);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return (e as AxiosError).response?.status ?? HttpStatusCodes.InternalServerError;
            } else {
                console.error(`An unknown error occurred while fetching the activityData data.`);
                console.error(e);
                return HttpStatusCodes.InternalServerError;
            }
        }
        return HttpStatusCodes.Ok;
    },
};

const namespaced = true;

export const activityData: Module<ActivityDataState, ApplicationState> = {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};
