
import Vue, { inject } from 'vue';
import UserProfile from './user-profile/user-profile.vue';
import Navbar from './navbar/navbar.vue';
import { MenuEntry } from 'adam.ui-core/dist/src/lib/components/AppDrawer/AppDrawer';
import { AppService } from '@/services';
import { Application, ConfirmAction } from '@/models';
import clickOutside from '@/directives/click-outside';
import { mapState, mapGetters, mapActions } from 'vuex';
import { getTranslationManager } from '@/utils/translation-plugin/translation-manager';
import { functionalPermissions } from '@/utils';
import { RouteNames } from '@/constants/routes';

const HeaderWrapper = Vue.extend({
    name: 'HeaderWrapper',
    directives: {
        clickOutside,
    },
    components: {
        userProfile: UserProfile,
        Navbar,
    },
    data(): {
        $pui: { toast: Function };
        appService: AppService;
        applications: MenuEntry[];
        isOpenUserProfile: boolean;
        showToolbar: boolean;
    } {
        return {
            $pui: { toast: Function },
            appService: new AppService(),
            applications: [],
            isOpenUserProfile: false,
            showToolbar: true,
        };
    },
    setup() {
        return {
            confirmAction: inject<ConfirmAction>('ConfirmAction'),
        };
    },
    computed: {
        ...mapState('user', { profile: 'profile' }),
        ...mapGetters('roles', { validPermissions: 'getArePermissionsValid' }),
        ...mapGetters({ unSavedChanges: 'unSavedChanges' }),

        // added custom visible property to filter out tabs that the user doe not have permission to
        navItems(): {
            displayName: string;
            href: string;
            isActive: boolean;
            visible: boolean;
        }[] {
            return [
                {
                    displayName: this.$t('nav.dataProvisioning').toString(),
                    href: RouteNames.DATA_INPUT,
                    isActive: this.$route.path === RouteNames.DATA_INPUT,
                    visible: functionalPermissions.userCanEnterEmissionData(),
                },
                {
                    displayName: this.$t('nav.approvalsDashboard').toString(),
                    href: RouteNames.APPROVAL,
                    isActive: this.$route.path === RouteNames.APPROVAL,
                    visible: functionalPermissions.userCanApproveEmissionData(),
                },
                {
                    displayName: this.$t('nav.freeAllocation').toString(),
                    href: RouteNames.FREE_ALLOCATION,
                    isActive: this.$route.path === RouteNames.FREE_ALLOCATION,
                    visible: functionalPermissions.userCanAllocateFreeCO2(),
                },
                {
                    displayName: this.$t('nav.riskClassification').toString(),
                    href: RouteNames.DISTRIBUTION_TYPE,
                    isActive: this.$route.path === RouteNames.DISTRIBUTION_TYPE,
                    visible: functionalPermissions.userCanDefineCO2Risk(),
                },
                {
                    displayName: this.$t('nav.standardEmissionFactor').toString(),
                    href: RouteNames.STANDARD_EMISSION,
                    isActive: this.$route.path === RouteNames.STANDARD_EMISSION,
                    visible: functionalPermissions.userCanViewStandardEF(),
                },
                {
                    displayName: this.$t('nav.reporting').toString(),
                    href: RouteNames.REPORTING,
                    isActive: this.$route.path === RouteNames.REPORTING,
                    visible: true,
                },
            ].filter(({ visible }) => visible);
        },

        languageOptions(): { text: string; id: string }[] {
            return this.$i18n.availableLocales.map((lang) => {
                return {
                    text: this.$t(`languages.${lang}`) as string,
                    id: lang,
                };
            });
        },

        getAppDrawerLabels(): any {
            return {
                applications: this.$i18n.t('appsWithAccess'),
                miscellaneous: this.$i18n.t('appsWithoutAccess'),
            };
        },

        environmentColour(): string {
            const choices = {
                development: '#009688', // local
                dev: '#B3254C',
                test: '#8A3898',
                uat: '#8A3898',
                production: '#0078DC',
                prd: '#0078DC',
            } as Record<string, string>;
            const environment = process.env.VUE_APP_ENVIRONMENT as string;
            return choices[environment];
        },
    },
    async mounted(): Promise<void> {
        await this.init();
    },
    methods: {
        ...mapActions({ resetUnsavedChanges: 'resetUnsavedChanges' }),
        async handleClick(newRoute: { displayName: string; href: string; isActive: boolean }): Promise<void> {
            if (newRoute.isActive) return;
            const checkUnsavedChanges = Object.values(this.unSavedChanges).some((value) => value);
            if (checkUnsavedChanges) {
                this.showAllChangesWillBeLostModal(() => this.changePage(newRoute.href));
            } else {
                this.changePage(newRoute.href);
            }
        },

        changePage(href: string) {
            this.$router.push(href);
            this.resetUnsavedChanges();
        },

        changeLanguage(localeCode: string): void {
            getTranslationManager().changeCurrentLanguage(localeCode);
            window.location.reload();
        },

        async init(): Promise<void> {
            this.createLogoSpanAndAddToDOM();
            await this.loadApps();
        },

        async loadApps(): Promise<void> {
            try {
                const apps = (await this.appService.getApps()).result.items;
                this.applications = this.processApps(apps);
            } catch (error) {
                this.$pui.toast({
                    type: 'error',
                    title: this.$t('error.loadingApplications'),
                    copy: (error as Error).message,
                });
                throw error;
            }
        },

        processApps(apps: Application[]): MenuEntry[] {
            return apps
                .map(
                    (app: Application): MenuEntry => ({
                        id: app.useCaseId,
                        type: app.hasAccess ? 'APPLICATION' : '',
                        label: app.name,
                        iconId: this.getLogo(app.logo),
                        url: app.url ? app.url : undefined,
                    }),
                )
                .sort((a: MenuEntry, b: MenuEntry) => {
                    const nameA = a?.label?.toLowerCase();
                    const nameB = b?.label?.toLowerCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });
        },

        clickDrawer(): void {
            (this.$refs.appDrawer as any).open = true;
        },

        toggleUserProfile(): void {
            this.isOpenUserProfile = !this.isOpenUserProfile;
        },

        closeUserProfile(event: any): void {
            if (event && this.isOpenUserProfile && !event.target.className.includes('pui-avatar__image')) {
                this.isOpenUserProfile = false;
            }
        },

        handleAppClick(app: Application): void {
            if (app.url) {
                window.open(app.url);
            }
        },

        getLogo(img: string): string {
            try {
                require(`adam.ui-core/assets/icons/${img}.svg`);
                return img;
            } catch (e) {
                return 'app-generic';
            }
        },

        /**
         * Unfortunately pui-global-header does not provide slots to add content to the header bar.
         * Therefore, we add the Coode-Logo by hand here. This is obviously not reactive at all but
         * works because the header bar never changes after the initial render.
         */
        createLogoSpanAndAddToDOM(): void {
            // create span with img
            const span = document.createElement('span');
            span.setAttribute('id', 'coode-logo-span');
            span.setAttribute('style', 'height: 24px; padding-left: 1.6rem');
            const img = document.createElement('img');
            img.setAttribute('src', '/img/icons/logo-coode-white.svg');
            img.setAttribute('style', 'height: 100%');
            span.appendChild(img);

            // get header
            const header = document.getElementsByClassName('pui-global-header__app')[0];

            // add span to header
            if (header) {
                header.insertBefore(span, header.children[1]);
            }
        },

        showAllChangesWillBeLostModal(approveCallback?: () => void, cancelCallback?: () => void): void {
            if (this.confirmAction) {
                this.confirmAction({
                    title: this.$t('actions.changesWillBeLost.title'),
                    cancel: this.$t('actions.cancel'),
                    confirm: this.$t('actions.confirm'),
                    content: this.$t('alert.unsavedChanges.body'),
                })
                    .then(() => {
                        if (approveCallback) {
                            approveCallback();
                        }
                    })
                    .catch(() => {
                        if (cancelCallback) {
                            cancelCallback();
                        }
                    });
            }
        },
    },
});

export default HeaderWrapper;
