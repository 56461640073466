
import { LocationTableData } from '@/models/table';
import { EmissionWithParkedEmissionDto } from '@/service-proxies/service-proxies.g';
import { EventBus, checkDecimalPlaces } from '@/utils';
import Vue, { PropType } from 'vue';
import { MaxDecimalPlaces, MaxValue } from '@/constants/input-precision';

const EmissionsActions = Vue.extend({
    name: 'EmissionActions',
    props: {
        activity: {
            type: Object as PropType<LocationTableData>,
            required: true,
        },
        unit: {
            type: Object as PropType<LocationTableData>,
            required: true,
        },
        powerPlant: {
            type: Object as PropType<LocationTableData>,
            required: true,
        },
        resetRow: {
            type: Function,
            required: true,
        },
    },
    computed: {
        labels(): Record<string, string> {
            return {
                save: this.$t('actions.save') as string,
                cancel: this.$t('actions.cancel') as string,
                viewDetail: this.$t('emissionTable.action.openDetailed') as string,
                delete: this.$t('actions.delete') as string,
                withdraw: this.$t('detailedView.withdraw') as string,
            };
        },
        canSaveChanges(): boolean {
            const emission = this.activity as LocationTableData;
            const importedEmission = emission.meta.object as EmissionWithParkedEmissionDto;
            const correctDecimals = checkDecimalPlaces(
                emission.activityDataAmount ?? 0,
                MaxDecimalPlaces.FUEL_CONSUMPTION,
            );
            const fuelUnderMax = (emission.activityDataAmount ?? 0) < MaxValue.FUEL_CONSUMPTION;
            const ghgUnderMax = (emission.ghgAmount ?? 0) < MaxValue.GHG;
            return (
                emission.activityDataAmount !== importedEmission.activityDataAmount &&
                correctDecimals &&
                fuelUnderMax &&
                ghgUnderMax
            );
        },
        groupIsComplete(): boolean {
            return !!this.unit?.meta?.isComplete || !!this.powerPlant?.meta?.isComplete;
        },
        canDelete(): boolean {
            return this.activity.status === 'Preliminary' && !this.groupIsComplete;
        },
        iconName(): string {
            return this.canDelete ? 'edit-notes' : 'report';
        },
    },
    methods: {
        openModal(): void {
            EventBus.$emit(EventBus.VIEWS.MAIN.OPEN_DETAILED_EMISSIONS_MODAL, {
                activity: this.activity,
                unit: this.unit,
                powerPlant: this.powerPlant,
            });
        },
        discardChanges(): void {
            this.resetRow();
        },
        saveChanges(): void {
            const oldEmission = this.activity.meta.object as EmissionWithParkedEmissionDto;
            const saveObject = {
                ...oldEmission,
                activityDataAmount: this.activity.activityDataAmount,
                ghgAmount: this.activity.ghgAmount,
            };
            EventBus.$emit(EventBus.VIEWS.MAIN.SAVE_TABLE_CHANGES, saveObject);
        },
        deleteEmission(): void {
            EventBus.$emit(
                EventBus.VIEWS.MAIN.DELETE_EMISSION,
                this.activity.meta.object as EmissionWithParkedEmissionDto,
            );
        },
    },
});

export default EmissionsActions;
