import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { PowerPlantDto, FleetDto } from '@/service-proxies/service-proxies.g';
import { ApplicationState } from '@/store';
import { setTracker } from '@/components/tables/emission-table/helpers/row-tracker';
import { LocationTableDataMapper } from '@/components/tables/emission-table/helpers/location-table-data-mapper';
import { TableType } from '@/utils/tables/table-config';

export interface TablesState {
    inputExpansionTracker: Record<any, any>;
    standardEFExpansionTracker: Record<any, any>;
    fleetNames: Record<number, string>;
}

const state: TablesState = {
    inputExpansionTracker: {},
    standardEFExpansionTracker: {},
    fleetNames: {},
};

const getters: GetterTree<TablesState, ApplicationState> = {
    inputExpansionTracker: (state) => state.inputExpansionTracker,
    standardEFExpansionTracker: (state) => state.standardEFExpansionTracker,
    fleetNames: (state) => state.fleetNames,
};

const mutations: MutationTree<TablesState> = {
    setInputExpansionTracker(state: TablesState, tracker: Record<any, any>) {
        state.inputExpansionTracker = tracker;
    },
    setStandardEFExpansionTracker(state: TablesState, tracker: Record<any, any>) {
        state.standardEFExpansionTracker = tracker;
    },
    setFleetNames(state: TablesState, input: Record<number, string>) {
        state.fleetNames = input;
    },
};

const actions: ActionTree<TablesState, ApplicationState> = {
    GET_FLEET_NAMES({ commit }: ActionContext<TablesState, ApplicationState>, input: FleetDto[]): void {
        let idToNames = {} as Record<number, string>;
        for (const fleet of input) {
            idToNames[fleet.sid!] = fleet.name ?? '';
        }
        commit('setFleetNames', idToNames);
    },
    SET_TRACKERS(
        { state, commit }: ActionContext<TablesState, ApplicationState>,
        input: { powerPlants: PowerPlantDto[]; scopeId: number; countryId: number },
    ): void {
        const { powerPlants, scopeId, countryId } = input;
        const oldTracker = state.inputExpansionTracker;
        if (oldTracker && oldTracker.scopeId === scopeId && oldTracker.countryId === countryId) return;

        const locationData = new LocationTableDataMapper().mapLocationsToTableData(powerPlants, TableType.DATA_ENTRY);
        let tracker = setTracker(locationData, countryId) as Record<any, any>;
        tracker = { ...tracker, ...oldTracker, scopeId, countryId };
        commit('setInputExpansionTracker', tracker);
    },
    UPDATE_TRACKER(
        { state, commit }: ActionContext<TablesState, ApplicationState>,
        keyValue: Record<any, boolean>,
    ): void {
        const updatedTracker = { ...state.inputExpansionTracker, ...keyValue };
        commit('setInputExpansionTracker', updatedTracker);
    },
    SET_STANDARDEF_TRACKERS(
        { state, commit }: ActionContext<TablesState, ApplicationState>,
        input: { activities: string[]; scopeId: number },
    ): void {
        const { scopeId, activities } = input;
        const oldTracker = state.standardEFExpansionTracker;
        if (oldTracker && oldTracker.scopeId === scopeId) return;
        const tracker = activities.reduce((prev, curr) => {
            return { ...prev, [curr]: false };
        }, {});
        commit('setStandardEFExpansionTracker', { ...tracker, ...oldTracker, scopeId });
    },
    UPDATE_STANDARDEF_TRACKER(
        { state, commit }: ActionContext<TablesState, ApplicationState>,
        keyValue: Record<any, boolean>,
    ): void {
        const updatedTracker = { ...state.standardEFExpansionTracker, ...keyValue };
        commit('setStandardEFExpansionTracker', updatedTracker);
    },
};

const namespaced = true;

export const tables: Module<TablesState, ApplicationState> = {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};
