import Vue from 'vue';

export const Suspense = Vue.extend({
    name: 'Suspense',
    data: () => ({
        resolved: false,
    }),
    async created() {
        const setupMethod = (this.$parent as any).setup;
        if (!setupMethod) {
            throw new Error(
                '[Suspense] No setup method found - make sure Suspense is at root level, just inside <template>',
            );
        }

        await setupMethod();
        this.resolved = true;
    },
    render(createElement) {
        return createElement('div', this.resolved ? this.$slots.default : this.$slots.fallback);
    },
});
