import {
    MasterDataLevel,
    MasterDataLevelEndpointResponse,
    MasterDataLevelEndpointResponseOkResponse,
    MasterDataLevelServiceProxy,
} from '@/service-proxies/service-proxies.g';

/**
 * @name ScopeService
 * @description This class is used to query the backend server for master data level
 */
export class MasterDataLevelService {
    private masterDataLevelServiceproxy: MasterDataLevelServiceProxy;

    constructor() {
        this.masterDataLevelServiceproxy = new MasterDataLevelServiceProxy();
    }

    /**
     * Get master data level.
     */
    public getMasterDataLevel = async (
        scopeId: number,
        countryId: number,
    ): Promise<MasterDataLevelEndpointResponseOkResponse> => {
        const response = await this.masterDataLevelServiceproxy.getMasterDataLevel(scopeId, countryId);
        this.replaceEnums(response.result);
        return response;
    };

    /**
     * Helper function which mutates the given masterDataLevelDto and replaces all enums with their integer values.
     */
    private replaceEnums = (masterDataLevelDto: MasterDataLevelEndpointResponse | undefined): any => {
        if (typeof masterDataLevelDto?.masterDataLevel === 'string') {
            masterDataLevelDto.masterDataLevel = masterDataLevelDto.masterDataLevel
                ? (MasterDataLevel[masterDataLevelDto.masterDataLevel] as unknown as MasterDataLevel)
                : masterDataLevelDto.masterDataLevel;
        }
    };
}
