import Vue from 'vue';

export const EventBus = new Vue({
    data(): any {
        return {
            GLOBAL: {
                SHOW_SNACKBAR: 'global.show-snackbar',
            },
            VIEWS: {
                MAIN: {
                    OPEN_ADD_ACTIVITY_DATA_MODAL: 'view.main.openAddActivityDataModal',
                    OPEN_DETAILED_EMISSIONS_MODAL: 'view.main.openDetailedEmissionsModal',
                    SAVE_TABLE_CHANGES: 'view.main.saveTableChanges',
                    DELETE_EMISSION: 'view.main.deleteEmission',
                    OPEN_WITHDRAW_APPROVAL: 'view.main.openWithdrawApproval',
                    OPEN_OVERWRITE_APPROVAL: 'view.main.openOverwriteApproval',
                    OPEN_WITHDRAW_COMPLETENESS: 'view.main.openWithdrawCompleteness',
                },
                APPROVAL: {
                    APPROVE: 'view.approval.approve',
                    BULK_APPROVE: 'view.approval.bulkApprove',
                    COMPLETE: 'view.approval.complete',
                    OPEN_DETAILED_EMISSIONS_MODAL: 'view.approval.openDetailedEmissionsModal',
                },
                STANDARD_EMISSION: {
                    OPEN_ADD_ACTIVITY_MODAL: 'view.standardEmission.addActivity',
                    OPEN_ADD_UOM_MODAL: 'view.standardEmission.addUoM',
                    EDIT: 'view.standardEmission.edit',
                    SAVE_CHANGE: 'view.standardEmission.saveChange',
                    BULK_EDIT: 'view.standardEmission.bulkEdit',
                    CHANGE_BULK_ASSIGNMENT: 'view.standardEmission.changeBulkAssignment',
                    OPEN_BLOCKED_ERROR_MODAL: 'view.standardEmission.blockedErrorModal',
                    OPEN_BULK_RESPONSE_MODAL: 'view.standardEmission.bulkResponseModal',
                    OPEN_BULK_ERROR_MODAL: 'view.standardEmission.bulkErrorModal',
                },
            },
        };
    },
});
