import { render, staticRenderFns } from "./environment.vue?vue&type=template&id=52e45391&scoped=true&class=environment"
import script from "./environment.vue?vue&type=script&lang=ts"
export * from "./environment.vue?vue&type=script&lang=ts"
import style0 from "./environment.vue?vue&type=style&index=0&id=52e45391&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52e45391",
  null
  
)

export default component.exports