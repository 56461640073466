import { ActivityDataDto, EmissionWithParkedEmissionDto } from '@/service-proxies/service-proxies.g';

const numeric = 'numeric';
export enum DateDisplayOptions {
    'YearOnly',
    'MonthOnly',
    'Default',
    'DateHourMonth',
    'QuarterYear',
}

export const formatUrlSearchParams = (query: {
    AssetGroupIds: string[];
    Granularity: string;
    StartDate: string;
}): URLSearchParams => {
    const qs = new URLSearchParams();
    Object.entries(query).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((val) => qs.append(key, val));
        } else {
            qs.append(key, value);
        }
    });
    return qs;
};

export const formatDate = (
    dateString: string,
    locale: string,
    displayOption: DateDisplayOptions = DateDisplayOptions.Default,
): string => {
    if (!dateString) {
        return '';
    }
    const date = new Date(dateString);

    if (displayOption === DateDisplayOptions.YearOnly) {
        return date.getFullYear().toString();
    }
    if (displayOption === DateDisplayOptions.MonthOnly) {
        const month = date.toLocaleDateString(locale, { month: 'long' }).toString();
        const year = date.getFullYear().toString();
        return `${month} ${year}`;
    }

    if (displayOption === DateDisplayOptions.DateHourMonth) {
        return date.toLocaleString(locale);
    }

    if (displayOption === DateDisplayOptions.QuarterYear) {
        const year = date.getFullYear().toString();
        const month = date.getMonth();
        const quarter = Math.floor(month / 3) + 1;
        return `Q${quarter} ${year}`;
    }

    if (date) {
        const { format } = new Intl.DateTimeFormat('en-GB', {
            year: numeric,
            month: numeric,
            day: numeric,
        });
        return format(date);
    } else {
        return 'n/a';
    }
};

// 'fr-CA' returns date separator as '-' ('YYYY-MM-DD')
export const { format: formatToDashedYYYYMMDD } = new Intl.DateTimeFormat('fr-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
});

export const getMonths = (locale = 'en-US'): string[] =>
    Array.from({ length: 12 }, (_, index) => {
        return new Date(0, index).toLocaleDateString(locale, { month: 'long' });
    });

export const getInitialStartDate = (): string => {
    const today = new Date();
    const initialPeriodDate =
        today.getMonth() === 0
            ? new Date(today.getFullYear() - 1, 11, 1)
            : new Date(today.getFullYear(), today.getMonth() - 1, 1);
    return formatToDashedYYYYMMDD(initialPeriodDate);
};

export const getNextMonthDate = (date?: Date): string => {
    date = date ?? new Date();
    var nextMonth = new Date(Date.UTC(date.getFullYear(), date.getMonth() + 1, 1));
    return formatToDashedYYYYMMDD(nextMonth);
};

export const formatActivityDataDisplayName = (activity: ActivityDataDto | undefined): string => {
    if (!activity || !activity.name) return '';
    return activity.quality ? `${activity.name} - ${activity.quality}` : `${activity.name}`;
};

export const formatLocationName = (powerPlant: string | undefined, unit: string | undefined) =>
    [powerPlant, unit].filter(Boolean).join(' - ');

export const formatLastUpdated = (
    emission: EmissionWithParkedEmissionDto | null,
    lastUpdated: string,
    draft: string,
) => {
    if (!emission) return { lastUpdated: '', KID: '' };
    const date = emission.id !== 0 ? new Date(emission.createDate ?? '').toLocaleString() : '';
    //CreateDate is the updateDate, since each update will get a new entry
    //and its the creation of this entry that is saved in CreateDate.
    const kid = emission.updateUserKid != null ? emission.updateUserKid : emission.createUserKid;
    return {
        lastUpdated: date ? `${lastUpdated}: ${date}` : `${draft.toUpperCase()}`,
        KID: emission.id !== 0 ? `KID ${kid}` : '',
    };
};
