
import Vue from 'vue';
import TokenGenerator from './auth/token-generator.vue';
import Environment from './misc/environment.vue';
import Urls from './misc/urls.vue';
import Version from './misc/version.vue';

const DevTools = Vue.extend({
    components: {
        TokenGenerator,
        Urls,
        Environment,
        Version,
    },
});

export default DevTools;
