
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
    name: 'ReportingPage',
    computed: {
        ...mapGetters('metaData', { powerBiUrl: 'powerBiUrl' }),

        prompt(): string {
            const anchor = `<a href="${this.powerBiUrl}" target="_blank">Power BI</a>`;
            let message = this.$t('reporting.prompt') as string;
            message = message.replace('{{ link }}', anchor);
            // Make sure that the link is available even if translation doesn't load
            return message.includes(anchor) ? message : anchor;
        },
    },
});
