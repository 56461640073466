
import { FactorActionType } from '@/enums/factor-action-type';
import { EmissionDto, EmissionFactorDto, Granularity } from '@/service-proxies/service-proxies.g';
import { BlockedErrorBody } from '@/store/modules/standard-emission';
import { DateDisplayOptions, formatDate, formatLocationName } from '@/utils/helpers/formatters';
import Vue from 'vue';

interface ModalActions {
    open: () => void;
    close: () => void;
}

interface Data {
    actionType: FactorActionType | null;
    blockingEmissions: EmissionDto[];
}

const BlockedErrorModal = Vue.extend({
    name: 'BlockedErrorModal',
    data(): Data {
        return {
            actionType: null,
            blockingEmissions: [],
        };
    },
    computed: {
        modalRef(): ModalActions {
            return this.$refs.blockedErrorModal as unknown as ModalActions;
        },
        infoMessage(): string {
            return this.$t(`standardEF.blocked.message.${this.actionType}`) as string;
        },
        suggestionMessage(): string {
            return this.$t(`standardEF.blocked.message.suggestion`) as string;
        },
    },
    methods: {
        close(): void {
            this.blockingEmissions = [];
            this.modalRef.close();
        },
        async open(data: BlockedErrorBody): Promise<void> {
            this.actionType = data.actionType;
            this.blockingEmissions = data.blockingEmissions;
            (this as any).$refs.blockedErrorModal.open();
        },
        blockingEmissionsInfo(emission: EmissionDto): string {
            var infoString = '';
            const startDate = formatDate(emission.startDate!, this.$i18n.locale, DateDisplayOptions.MonthOnly);
            const endDate = formatDate(emission.endDate!, this.$i18n.locale, DateDisplayOptions.MonthOnly);
            const locationName = formatLocationName(emission.powerPlant?.name, emission.unit?.name);
            infoString += `[${emission.activityData?.name} - \
                ${emission.activityData?.quality}, \
                ${locationName}, \
                ${startDate} - \
                ${endDate}]\n`;
            return infoString;
        },
    },
});

export default BlockedErrorModal;
