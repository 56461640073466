import { LocationTableData } from '@/models/table';
import { CountryIds } from '@/constants/countrys';

/**
 * Extracts the Ids from all the rows that can be expanded and collapsed,
 * and creates a key value pair of the key to a boolean (dependant on country), all collected in a single object.
 *
 * @param locationTableData - the data arrays used to create the emission tables.
 * @returns an object of row id keys, with a boolean as the value.
 */
export function setTracker(locationTableData: LocationTableData[], countryId: number): Record<number, boolean> {
    const environment = process.env.VUE_APP_ENVIRONMENT as string;
    // User wants this feature, makes devlopment difficult though, so ignore it in development environment
    const startingState = environment === 'development' ? true : countryId !== CountryIds.GERMANY;

    const levelZero = locationTableData.reduce((accumulator: Record<any, boolean>, current: LocationTableData) => {
        return {
            ...accumulator,
            [current.meta.id]: startingState,
            [`${countryId}-${current.meta.fleet!.sid!}`]: startingState,
        };
    }, {} as Record<any, boolean>);

    const children = locationTableData.map((location) => location._children);

    if (children.length === 0) {
        return levelZero;
    }

    const nextDatSet = children.reduce((accumulator: LocationTableData[], current) => {
        if (!current) return accumulator;
        return [...accumulator!, ...current];
    }, [] as LocationTableData[]);

    const levelOne = nextDatSet.reduce((accumulator: Record<any, boolean>, current: LocationTableData) => {
        if (!current) return accumulator;
        return { ...accumulator, [current.meta.id]: startingState };
    }, {} as Record<any, boolean>);

    return { ...levelZero, ...levelOne };
}
