
import Vue from 'vue';
import { EmissionFactorDto } from '@/service-proxies/service-proxies.g';
import UnitOfMeasurement from '@/components/unit-of-measurement/unit-of-measurement.vue';
import FloatInput from '../inputs/float-input.vue';
import ModalInputWrapper from '../inputs/modal-input-wrapper.vue';
import { mapGetters } from 'vuex';

interface Input {
    value: string;
    valid?: boolean;
    validator?: Function[];
}

const EmissionFactorDetails = Vue.extend({
    name: 'EmissionFactorDetails',
    components: {
        UnitOfMeasurement,
        FloatInput,
        ModalInputWrapper,
    },
    props: {
        fuelConsumptionUOM: {
            type: Object,
            required: true,
        },
    },
    data(): {
        inputFields: { [key: string]: Input };
    } {
        return {
            inputFields: {
                factor: {
                    value: '',
                    valid: true,
                },
                calorificValue: {
                    value: '',
                    valid: true,
                },
                heatBasedEmissionFactor: {
                    value: '',
                    valid: true,
                },
                carbonContent: {
                    value: '',
                    valid: true,
                },
                conversionFactor: {
                    value: '',
                    valid: true,
                },
                oxidationFactor: {
                    value: '',
                    valid: true,
                },
                biogen: {
                    value: '',
                    valid: true,
                },
            },
        };
    },
    computed: {
        ...mapGetters('detailedView', {
            emission: 'GET_EMISSION',
        }),
        factorUnits(): string {
            const numerator = this.emission.ghgUnitOfMeasurement?.shortName ?? '';
            const denominator = this.emission.activityDataUnitOfMeasurement?.shortName ?? '';
            return `${numerator} / ${denominator}`;
        },
        calorificValueUnits(): string {
            const numerator = this.emission.emissionFactor.heatValueNumeratorUnitOfMeasurement?.shortName ?? '';
            const denominator = this.emission.emissionFactor.heatValueDenominatorUnitOfMeasurement?.shortName ?? '';
            return `${numerator} / ${denominator}`;
        },
        heatBasedEFUnits(): string {
            const numerator =
                this.emission.emissionFactor.heatBasedEmissionFactorNumeratorUnitOfMeasurement?.shortName ?? '';
            const denominator =
                this.emission.emissionFactor.heatBasedEmissionFactorDenominatorUnitOfMeasurement?.shortName ?? '';
            return `${numerator} / ${denominator}`;
        },
    },
    mounted(): void {
        const params = this.emission.emissionFactor as EmissionFactorDto;
        const {
            factor,
            calorificValue,
            heatBasedEmissionFactor,
            carbonContent,
            conversionFactor,
            oxidationFactor,
            biogen,
        } = this.inputFields;

        factor.value = params.factor?.toString() ?? '';
        calorificValue.value = params.heatValue?.toString() ?? '';
        heatBasedEmissionFactor.value = params.heatBasedEmissionFactor?.toString() ?? '';
        carbonContent.value = params.carbonContent?.toString() ?? '';
        conversionFactor.value = params.conversionFactor?.toString() ?? '1';
        oxidationFactor.value = params.oxidationFactor?.toString() ?? '1';
        biogen.value = params.biogen?.toString() ?? '0';
    },
});

export default EmissionFactorDetails;
