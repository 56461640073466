import {
    EmissionDto,
    Granularity,
    PowerPlantGetEndpointResponseOkResponse,
    PowerplantServiceProxy,
} from '@/service-proxies/service-proxies.g';
import { PowerPlantListEndpointRequest } from '@/models/power-plant';
/**
 * @name LocationService
 * @description This class is used to query API for Location (plants / units) data,
 */

export class LocationService {
    private powerplantServiceProxy: PowerplantServiceProxy;

    constructor() {
        this.powerplantServiceProxy = new PowerplantServiceProxy();
    }

    /**
     * Get filtered list of power-plants
     * @param filter filter of endpoint
     */
    public async getPowerPlants(
        filter: PowerPlantListEndpointRequest | undefined = undefined,
    ): Promise<PowerPlantGetEndpointResponseOkResponse> {
        const response = await this.powerplantServiceProxy.getPowerplant(
            filter?.countryId,
            filter?.fleetId,
            filter?.assetGroupId,
            filter?.granularity,
            filter?.startDate,
            filter?.endDate,
            filter?.scopeId,
            filter?.locationTypeIds ?? undefined,
        );
        for (const plant of response.result ?? []) {
            for (const emission of plant.emissions ?? []) {
                this.replaceEnums(emission);
            }
            for (const unit of plant.units ?? []) {
                for (const emission of unit.emissions ?? []) {
                    this.replaceEnums(emission);
                }
            }
        }
        return response;
    }

    public async getPowerPlantsForDistributonType(
        filter: PowerPlantListEndpointRequest | undefined = undefined,
    ): Promise<PowerPlantGetEndpointResponseOkResponse> {
        const response = await this.powerplantServiceProxy.getPowerplantForDistributionType(
            filter?.countryId,
            filter?.fleetId,
            filter?.assetGroupId,
            filter?.granularity,
            filter?.startDate,
            filter?.endDate,
            filter?.scopeId,
            filter?.locationTypeIds ?? undefined,
        );
        for (const plant of response.result ?? []) {
            for (const emission of plant.emissions ?? []) {
                this.replaceEnums(emission);
            }
            for (const unit of plant.units ?? []) {
                for (const emission of unit.emissions ?? []) {
                    this.replaceEnums(emission);
                }
            }
        }
        return response;
    }

    /**
     * Helper function which mutates the given EmissionDto and replaces all enums with their integer values.
     */
    private replaceEnums = (emissionDto: EmissionDto | undefined): void => {
        if (typeof emissionDto?.granularity === 'string') {
            emissionDto.granularity = emissionDto.granularity
                ? (Granularity[emissionDto.granularity] as unknown as Granularity)
                : emissionDto.granularity;
        }
    };
}
