
import Vue from 'vue';
import { UOM_MAPPING, FRAC_START_LINE, SYMBOL_LINE } from './uom-mapping';

export default Vue.extend({
    name: 'UnitOfMeasurement',
    props: {
        uom: {
            type: String,
            required: true,
        },
        quantityName: {
            type: String,
            default: '',
        },
        isChemicalFormula: {
            type: Boolean,
            default: false,
        },
        noPadding: {
            type: Boolean,
            default: false,
        },
    },
    data(): { uomMapping: { [key: string]: string | undefined } } {
        return { uomMapping: UOM_MAPPING };
    },
    computed: {
        uomHtml(): string {
            if (this.uomMapping[this.uom]) {
                return this.uomMapping[this.uom] ?? '';
            } else if (this.uom.includes('/')) {
                const fracList = this.uom.split('/');
                return this.toFraction(fracList[0], fracList[1]);
            }

            return `<p>${this.uom}</p>`;
        },
        quantityNameHtml(): string {
            if (this.quantityName !== '') {
                if (!this.isChemicalFormula) {
                    return `<p>${this.quantityName}</p>`;
                } else {
                    return `${this.chemicalFormulaToHtml(this.quantityName)}`;
                }
            }
            return '';
        },
    },
    methods: {
        toFraction(numerator: string, denominator: string): string {
            const numeratorMapped =
                this.uomMapping[numerator]?.replaceAll('<p>', '').replaceAll('</p>', '') ?? numerator;
            const denomMapped =
                this.uomMapping[denominator]?.replaceAll('<p>', '').replaceAll('</p>', '') ?? denominator;

            return (
                FRAC_START_LINE +
                `<span>${numeratorMapped}</span>` +
                SYMBOL_LINE +
                `<span class="bottom">${denomMapped}</span>` +
                '</p>'
            );
        },
        chemicalFormulaToHtml(input: string): string {
            const regex = /\d+/;
            const numbers = input.match(regex)?.map((x) => String(x)) ?? [];
            const characters = input.split(regex);
            const maxLength = Math.max(numbers.length, characters.length);
            let result = `<p>&nbsp${this.$t('of')} `;
            for (let i = 0; i < maxLength; i++) {
                result += `${characters[i] ?? ''}`;
                if (numbers[i]) {
                    result += `<sub>${numbers[i]}</sub>`;
                }
            }
            result += '</p>';
            return result;
        },
    },
});
