/**
 * Validates if a number is rounded to a certain number of decimal places.
 *
 * @param value - The number to validate.
 * @param decimalPlaces - The maximum number of digits that should be after the decimal point.
 * @returns True if number has less or equal decimal places than defined, false if more.
 */
export function checkDecimalPlaces(value: number, decimalPlaces: number): boolean {
    const splitDecimal = value.toLocaleString('de-DE', { maximumFractionDigits: 20 }).split(',');
    if (splitDecimal.length === 1) {
        return true;
    }
    return splitDecimal[1].length <= decimalPlaces;
}

/**
 * Rounds a number to a certain number of decimal places,
 * fixing the floating point.
 *
 * @param value - The number to be rounded.
 * @param decimalPlaces - How many digits after the decimal point should remain.
 * @returns A number with the designated number of decimal places.
 */
export function roundToDecimalPlaces(value: number, decimalPlaces: number): number {
    return Math.round(value * 10 ** decimalPlaces) / 10 ** decimalPlaces;
}
