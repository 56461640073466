import { render, staticRenderFns } from "./delete-emission-modal.vue?vue&type=template&id=e204d0e4&scoped=true"
import script from "./delete-emission-modal.vue?vue&type=script&lang=ts"
export * from "./delete-emission-modal.vue?vue&type=script&lang=ts"
import style0 from "./delete-emission-modal.vue?vue&type=style&index=0&id=e204d0e4&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e204d0e4",
  null
  
)

export default component.exports