import { render, staticRenderFns } from "./approvals-modal.vue?vue&type=template&id=6068b768&scoped=true&class=edit-emission-factor-modal"
import script from "./approvals-modal.vue?vue&type=script&lang=ts"
export * from "./approvals-modal.vue?vue&type=script&lang=ts"
import style0 from "./approvals-modal.vue?vue&type=style&index=0&id=6068b768&prod&scoped=true&lang=less"
import style1 from "./approvals-modal.vue?vue&type=style&index=1&id=6068b768&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6068b768",
  null
  
)

export default component.exports