
import Vue from 'vue';
import { DateDisplayOptions, formatDate } from '@/utils/helpers/formatters';
import GranularityDropdown from './granularity-dropdown/granularity-dropdown.vue';
import clickOutside from '@/directives/click-outside';

export const DatePicker = Vue.extend({
    name: 'DatePicker',
    components: {
        GranularityDropdown,
    },
    directives: {
        clickOutside,
    },
    props: {
        date: {
            type: String,
            default: '',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        granularity: {
            type: Number,
            default: 0,
        },
        endOfPeriod: {
            type: Boolean,
            default: false,
        },
        isValid: {
            type: Boolean,
            default: true,
        },
    },
    data(): {
        showCalendar: boolean;
    } {
        return {
            showCalendar: false,
        };
    },
    computed: {
        dateDisplay(): string {
            return formatDate(this.date, this.$i18n.locale, DateDisplayOptions.MonthOnly);
        },
    },
    methods: {
        openCalendar(): void {
            if (this.loading) return;
            this.showCalendar = true;
        },

        formatDate(dateString: string, locale: string, display: DateDisplayOptions): string {
            return formatDate(dateString, locale, display);
        },

        formatToTwoDigits(value: number): string {
            return value.toPrecision(2);
        },

        selectPeriod(newPeriod: string): void {
            this.$emit('change', newPeriod);
            this.closeCalendar();
        },

        closeCalendar(event?: { target: { dataset: { close: string } } }): void {
            if (this.showCalendar && (!event || !event.target.dataset.close)) {
                this.showCalendar = false;
            }
        },
    },
});

export default DatePicker;
