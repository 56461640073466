
import Vue from 'vue';
import StandardEmissionTable from '@/components/tables/standard-emission/standard-emission-table.vue';
import HeaderWithScopeSelection from '../page-headers/header-emission-factor.vue';
import FleetSkeleton from '../ui/loading/skeletons/fleet-skeleton.vue';
import EditEmissionModal from './modals/edit-emission-factor.vue';
import AddActivityModal from './modals/add-activity.vue';
import AddUomModal from './modals/add-uom.vue';
import { EventBus } from '@/utils';
import { mapActions, mapGetters } from 'vuex';
import { ActivityDataDto, EmissionFactorDto } from '@/service-proxies/service-proxies.g';
import BlockedErrorModal from './modals/blocked-error-modal.vue';
import BulkResponseInfoModal from './modals/bulk-response-info-modal.vue';
import BulkErrorModal from './modals/bulk-error-modal.vue';

export default Vue.extend({
    name: 'StandardEmissionPage',
    components: {
        HeaderWithScopeSelection,
        StandardEmissionTable,
        FleetSkeleton,
        EditEmissionModal,
        AddActivityModal,
        AddUomModal,
        BlockedErrorModal,
        BulkResponseInfoModal,
        BulkErrorModal,
    },
    computed: {
        ...mapGetters('standardEmissionFactor', {
            fetchTableData: 'tableData',
            factors: 'factors',
            loading: 'loading',
        }),
        ...mapActions('standardEmissionFactor', { editEmissionFactor: 'EDIT_STANDARD_EMISSION_FACTOR' }),
    },
    async created() {
        await this.fetchActivities();
        if (this.factors.length === 0) {
            await this.initaliseEmissionFactors();
        }
    },
    async mounted() {
        EventBus.$on(EventBus.VIEWS.STANDARD_EMISSION.EDIT, (data: EmissionFactorDto): void => {
            (this as any).$refs['editFactorModal'].open(data);
        });
        EventBus.$on(EventBus.VIEWS.STANDARD_EMISSION.OPEN_ADD_ACTIVITY_MODAL, (): void => {
            (this as any).$refs['addActivityModal'].open();
        });
        EventBus.$on(EventBus.VIEWS.STANDARD_EMISSION.OPEN_ADD_UOM_MODAL, (data: Record<string, any>): void => {
            (this as any).$refs['addUomModal'].open(data);
        });
        EventBus.$on(EventBus.VIEWS.STANDARD_EMISSION.OPEN_BLOCKED_ERROR_MODAL, (data: Record<string, any>): void => {
            (this as any).$refs['blockedErrorModal'].open(data);
        });
        EventBus.$on(EventBus.VIEWS.STANDARD_EMISSION.OPEN_BULK_RESPONSE_MODAL, (data: Record<string, any>): void => {
            (this as any).$refs['bulkResponseInfoModal'].open(data);
        });
        EventBus.$on(EventBus.VIEWS.STANDARD_EMISSION.OPEN_BULK_ERROR_MODAL, (data: Record<string, any>): void => {
            (this as any).$refs['bulkResponseErrorModal'].open(data);
        });
        EventBus.$on(EventBus.VIEWS.STANDARD_EMISSION.SAVE_CHANGE, (factor: EmissionFactorDto): void => {
            this.$store.dispatch('standardEmissionFactor/EDIT_STANDARD_EMISSION_FACTOR', factor);
        });
    },

    beforeDestroy(): void {
        EventBus.$off(EventBus.VIEWS.STANDARD_EMISSION.EDIT);
        EventBus.$off(EventBus.VIEWS.STANDARD_EMISSION.OPEN_ADD_ACTIVITY_MODAL);
        EventBus.$off(EventBus.VIEWS.STANDARD_EMISSION.OPEN_ADD_UOM_MODAL);
        EventBus.$off(EventBus.VIEWS.STANDARD_EMISSION.OPEN_BLOCKED_ERROR_MODAL);
        EventBus.$off(EventBus.VIEWS.STANDARD_EMISSION.OPEN_BULK_RESPONSE_MODAL);
        EventBus.$off(EventBus.VIEWS.STANDARD_EMISSION.SAVE_CHANGE);
    },

    methods: {
        ...mapActions('standardEmissionFactor', {
            initaliseEmissionFactors: 'INITIALISE_STANDARD_EMISSION_FACTORS',
        }),
        ...mapActions('activityData', { fetchActivities: 'GET_ACTIVITY_DATA' }),
    },
});
