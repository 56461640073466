
import StatusCell from '@/components/tables/emission-table/custom-cells/status-cell.vue';
import Vue, { PropType } from 'vue';
import { LocationTableData } from '@/models/table';
import { EventBus } from '@/utils';

export default Vue.extend({
    name: 'ApprovalCompleteness',

    components: {
        StatusCell,
    },

    props: {
        parentData: {
            type: Object as PropType<LocationTableData>,
            required: false,
            default: null,
        },
        rowData: {
            type: Object as PropType<LocationTableData>,
            required: true,
        },
    },

    computed: {
        isComplete(): boolean {
            return (this.rowData as LocationTableData).meta.isComplete || false;
        },

        canBeCompleted(): boolean {
            const emissions = (this.rowData as LocationTableData)._children;
            if (!emissions) return false;

            return emissions.every(({ status }) => status === 'Approved');
        },
    },

    methods: {
        setComplete() {
            if (!this.canBeCompleted) return;
            EventBus.$emit(EventBus.VIEWS.APPROVAL.COMPLETE, {
                parentData: this.parentData,
                rowData: this.rowData,
            });
        },
    },
});
