import {
    EmissionDeleteEndpointResponseOkResponse,
    EmissionDto,
    EmissionGetDefaultEndpointResponseOkResponse,
    EmissionPostEndpointRequest,
    EmissionPostEndpointResponseOkResponse,
    EmissionsAcceptParkedEndpointResponseOkResponse,
    EmissionsApproveEndpointRequest,
    EmissionsApproveEndpointResponseOkResponse,
    EmissionApproveEndpointRequest,
    EmissionApproveEndpointResponseOkResponse,
    EmissionServiceProxy,
    EmissionWithdrawApprovalEndpointResponseOkResponse,
    Granularity,
} from '@/service-proxies/service-proxies.g';
import { EmissionDefaultEndpointRequest } from '@/models/emission';
import { getInitialStartDate } from '@/utils/helpers/formatters';

/**
 * @name EmissionService
 * @description This class is used to query API for Emission (per fuel) data,
 */
export class EmissionService {
    private emissionServiceProxy: EmissionServiceProxy;

    constructor() {
        this.emissionServiceProxy = new EmissionServiceProxy();
    }

    public async getEmissionDrafts(
        filter: EmissionDefaultEndpointRequest,
    ): Promise<EmissionGetDefaultEndpointResponseOkResponse> {
        const response = await this.emissionServiceProxy.getEmissionDefault(
            filter.activityDataInformation ?? undefined,
            filter.locationId || 0,
            filter.startDate ?? getInitialStartDate(),
            filter.scopeId,
        );
        for (const el of response.result ?? []) {
            this.replaceEnums(el);
        }
        return response;
    }

    public async postEmission(emission: EmissionPostEndpointRequest): Promise<EmissionPostEndpointResponseOkResponse> {
        const response = await this.emissionServiceProxy.postEmission(emission);
        this.replaceEnums(response.result);
        return response;
    }

    public async deleteEmission(id: number): Promise<EmissionDeleteEndpointResponseOkResponse> {
        const response = await this.emissionServiceProxy.deleteEmission(id);
        this.replaceEnums(response.result);
        return response;
    }

    public async approveBulkEmissions(ids: number[]): Promise<EmissionsApproveEndpointResponseOkResponse> {
        const response = await this.emissionServiceProxy.approveEmissions({
            emissionIds: ids,
        } as EmissionsApproveEndpointRequest);
        this.replaceEnums(response.result);
        return response;
    }

    public async approveAndCommentEmission(
        emissionId: number,
        comment: string,
    ): Promise<EmissionApproveEndpointResponseOkResponse> {
        const response = await this.emissionServiceProxy.approveEmission({
            emissionId: emissionId,
            comment: comment,
        } as EmissionApproveEndpointRequest);
        this.replaceEnums(response.result);
        return response;
    }

    public async withdrawApproval(id: number): Promise<EmissionWithdrawApprovalEndpointResponseOkResponse> {
        const response = await this.emissionServiceProxy.withdrawApproval(id);
        this.replaceEnums(response.result);
        return response;
    }

    public async acceptParked(id: number): Promise<EmissionsAcceptParkedEndpointResponseOkResponse> {
        const response = await this.emissionServiceProxy.acceptParkedEmissions(id);
        this.replaceEnums(response.result);
        return response;
    }

    /**
     * Helper function which mutates the given EmissionDto and replaces all enums with their integer values.
     */
    private replaceEnums = (emissionDto: EmissionDto | undefined): void => {
        if (typeof emissionDto?.granularity === 'string') {
            emissionDto.granularity = emissionDto.granularity
                ? (Granularity[emissionDto.granularity] as unknown as Granularity)
                : emissionDto.granularity;
        }
    };
}
