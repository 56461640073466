import { Api } from '@coode/fe-sdk/dist/networking';
import { getSdk } from './fe-sdk/sdk-helper';

export enum CoreServiceType {
    MetaData,
}

export default class BaseService {
    protected sdkApi!: Api;
    protected endpoint = '';
    protected coreServiceType: CoreServiceType | undefined;

    constructor(endpoint: string, coreServiceType?: CoreServiceType) {
        this.endpoint = endpoint;
        this.coreServiceType = coreServiceType;
    }

    protected get api(): Api {
        if (!this.sdkApi) {
            this.initializeSdkApi();
        }

        return this.sdkApi;
    }

    private initializeSdkApi(): void {
        const sdk = getSdk();
        if (!sdk) {
            throw new Error('Can not create BaseService because sdk does not exists.');
        }

        let api: Api | undefined;
        if (this.coreServiceType === CoreServiceType.MetaData) {
            api = sdk.$http?.$core.metaData.request.api;
        } else {
            api = sdk.$http?.$generic.request.api;
        }

        if (!api) {
            throw new Error('Can not create BaseService because sdk API does not exists.');
        }

        this.sdkApi = api;
    }
}
