
import {
    EmissionWithParkedEmissionDto,
    UnitDto,
    PowerPlantDto,
    BadRequestResponse,
} from '@/service-proxies/service-proxies.g';
import Vue, { nextTick } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import {
    formatDate,
    DateDisplayOptions,
    formatActivityDataDisplayName,
    formatLocationName,
} from '@/utils/helpers/formatters';

interface Data {
    activity: EmissionWithParkedEmissionDto | null;
    unit: UnitDto | null;
    powerPlant: PowerPlantDto | null;
    bodyMessage: string;
}

export default Vue.extend({
    name: 'WithdrawApprovalModal',

    data(): Data {
        return {
            activity: null,
            unit: null,
            powerPlant: null,
            bodyMessage: '',
        };
    },

    computed: {
        ...mapGetters({ loading: 'isLoading' }),
        ...mapGetters('country', {
            getCurrentCountry: 'GET_SELECTED_COUNTRY',
        }),
        ...mapGetters('location', {
            getStartDate: 'start',
        }),
        location(): string {
            return formatLocationName(this.powerPlant?.name, this.unit?.name);
        },
        date(): string {
            return formatDate(this.getStartDate, this.$i18n.locale, DateDisplayOptions.MonthOnly);
        },
        name(): string {
            return formatActivityDataDisplayName(this.activity?.activityData);
        },
    },

    methods: {
        ...mapActions('location', {
            withdrawApproval: 'WITHDRAW_APPROVAL',
        }),
        open(data: any) {
            this.activity = data.activity;
            this.unit = data.unit;
            this.powerPlant = data.powerPlant;

            const placeholders = {
                location: formatLocationName(this.powerPlant?.name, this.unit?.name),
                date: formatDate(this.getStartDate, this.$i18n.locale, DateDisplayOptions.MonthOnly),
            };
            this.bodyMessage = this.$t('withdrawApproval.prompt') as string;
            // Placeholder aren't working so manually replace them
            Object.entries(placeholders).forEach(([key, value]) => {
                this.bodyMessage = this.bodyMessage.replace(`{{ ${key} }}`, value);
            });
            (this as any).$refs.withdrawApprovalModal.open();
        },

        async onConfirmCallback() {
            if (this.loading || this.activity === null) return;
            this.$store.commit('loading');

            try {
                await this.withdrawApproval(this.activity.id);
                (this as any).$pui.toast({
                    type: 'success',
                    title: this.$t('notification.approvals.withdrawn'),
                    copy: '',
                });
            } catch (e) {
                const message = (e as BadRequestResponse).message ?? '';
                (this as any).$pui.toast({
                    type: 'error',
                    title: this.$t('toast.error'),
                    copy: message,
                });
                console.log(e);
                throw e;
            } finally {
                this.$store.commit('loading');
                await nextTick();
                this.onCloseCallback();
            }
        },

        onCloseCallback() {
            if (this.loading) return;
            this.activity = null;
            this.unit = null;
            this.powerPlant = null;
            (this as any).$refs.withdrawApprovalModal.close();
        },
    },
});
