
import 'reflect-metadata';
import Vue from 'vue';

export default Vue.extend({
    name: 'BoxSkeleton',
    props: {
        maxWidth: {
            type: Number,
            default: 100,
        },
        minWidth: {
            type: Number,
            default: 80,
        },
        width: {
            type: String,
            default: undefined,
        },
        height: {
            type: String,
            default: '1em',
        },
    },
    computed: {
        computedWidth(): string {
            return this.width || `${Math.floor(Math.random() * (this.maxWidth - this.minWidth) + this.minWidth)}%`;
        },
    },
});
