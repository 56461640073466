
import CheckIcon from '@/components/ui/icons/check.vue';
import DoubleCheckIcon from '@/components/ui/icons/double-check.vue';
import { LocationTableData } from '@/models/table';
import { ApprovalStatus } from '@/service-proxies/service-proxies.g';
import { EventBus } from '@/utils';
import Vue, { PropType } from 'vue';

export default Vue.extend({
    name: 'ApprovalActions',

    components: {
        CheckIcon,
        DoubleCheckIcon,
    },

    props: {
        powerPlant: {
            type: Object as PropType<LocationTableData>,
            required: true,
        },
        unit: {
            type: Object as PropType<LocationTableData>,
            required: false,
        },
        activity: {
            type: Object as PropType<LocationTableData>,
            required: true,
        },
        resetRow: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            bulkApprove: false,
        };
    },

    computed: {
        showApprove(): boolean {
            // Shouldn't be able to see 'draft' activities but just in case
            if (this.activity.meta.id === 0 || this.unit?.meta.isComplete || this.powerPlant.meta.isComplete)
                return false;

            // @ts-ignore - Status comes in as a string rather than an enum int so doing this hacky thing to convert it
            return ApprovalStatus[this.activity.status!] === ApprovalStatus.Preliminary;
        },
        iconName(): string {
            return this.showApprove ? 'edit-notes' : 'report';
        },
    },

    methods: {
        emitOnEventBus(event: string, payload: object = {}): void {
            const { powerPlant, unit, activity } = this;
            EventBus.$emit(event, { powerPlant, unit, activity, ...payload });
        },

        openModal(): void {
            this.emitOnEventBus(EventBus.VIEWS.APPROVAL.OPEN_DETAILED_EMISSIONS_MODAL, { checked: this.bulkApprove });
        },

        approve(): void {
            this.emitOnEventBus(EventBus.VIEWS.APPROVAL.APPROVE);
        },

        onBulkApprovalCheck(checked: boolean): void {
            this.bulkApprove = checked;
            this.emitOnEventBus(EventBus.VIEWS.APPROVAL.BULK_APPROVE, { checked });
        },
    },
});
