
import { MultiselectOption } from '@/models';
import Vue, { nextTick } from 'vue';
import { mapGetters, mapState, mapActions } from 'vuex';
import { ActivityDataDto, BadRequestResponse, EmissionFactorGroupDto } from '@/service-proxies/service-proxies.g';
import { ActivityDataType } from '@/enums/activity-data-type';
import { formatActivityDataDisplayName } from '@/utils/helpers/formatters';
import { StyleValue } from 'vue/types/jsx';

interface ModalActions {
    open: () => void;
    close: () => void;
}

const MULTI_SELECT_MINIMUM_HEIGHT = 100; //in pixels

const AddActivityModal = Vue.extend({
    name: 'AddActivityModal',
    data(): {
        selectedActivitiesIds: number[];
        selectBoxHeight: number;
    } {
        return {
            selectedActivitiesIds: [],
            selectBoxHeight: MULTI_SELECT_MINIMUM_HEIGHT,
        };
    },
    computed: {
        ...mapState({ loading: 'isLoading' }),
        ...mapState('activityData', { activityData: 'activityData' }),
        ...mapState('standardEmissionFactor', { tableData: 'tableData' }),

        headerLabel(): string {
            return this.$t('standardEF.actions.addActivity').toString();
        },

        modalRef(): ModalActions {
            return this.$refs.lightbox as unknown as ModalActions;
        },

        lightboxHeight(): StyleValue {
            const overlayHeight = 190; //The max height of the multiselect dropdown list
            return { height: `${overlayHeight + this.selectBoxHeight}px` };
        },

        disableSelect(): boolean {
            return this.selectBoxHeight > 400; //Past this height the footer of lightbox gets pushed off
        },

        activityOptions(): MultiselectOption[] {
            return this.activityData.map((activity: ActivityDataDto) => {
                return {
                    value: activity.id ?? 0,
                    label: formatActivityDataDisplayName(activity),
                    disabled: this.isActivityAlreadyShownInTable(activity.id ?? 0) || this.disableSelect,
                };
            });
        },
    },
    watch: {
        async selectedActivitiesIds(): Promise<void> {
            await nextTick();
            const box = document.querySelector('#activitySelectBox');
            this.selectBoxHeight = box?.clientHeight ?? MULTI_SELECT_MINIMUM_HEIGHT;
        },
    },
    methods: {
        ...mapActions('activityData', { fetchActivities: 'GET_ACTIVITY_DATA' }),
        ...mapActions('tables', { updateTracker: 'UPDATE_TRACKER' }),

        isActivityAlreadyShownInTable(activityId: number): boolean {
            return !!this.tableData.find((rowGroup: any) => rowGroup.meta.activity?.id === activityId);
        },

        async confirmActivitySelection(): Promise<void> {
            this.$store.commit('loading');
            try {
                const selectedActivities = this.activityData.filter((activity: ActivityDataDto) =>
                    this.selectedActivitiesIds.includes(activity.id ?? 0),
                );
                await this.$store.dispatch('standardEmissionFactor/ADD_ACTIVITY', {
                    selectedActivities: selectedActivities,
                });
            } catch (e) {
                const message = (e as BadRequestResponse).message ?? '';
                (this as any).$pui.toast({
                    type: 'error',
                    title: this.$t('toast.error'),
                    copy: message,
                });
                console.log(e);
                throw new Error();
            } finally {
                this.$store.commit('loading');
                this.modalRef.close();
            }
        },

        beforeCloseActivitySelectionModal(): void {
            this.selectedActivitiesIds = [];
        },

        open(): void {
            this.modalRef.open();
        },
    },
});

export default AddActivityModal;
