import { RiskClassificationEndpointRequest } from '@/models/risk-classification';
import {
    ClassificatorDto,
    ClassificatorGetEndpointResponseOkResponse,
    ClassificatorPostEndpointRequest,
    ClassificatorPostEndpointResponseOkResponse,
    ClassificatorPutEndpointRequest,
    ClassificatorPutEndpointResponseOkResponse,
    ClassificatorRiskLevel,
    ClassificatorServiceProxy,
} from '@/service-proxies/service-proxies.g';

export class RiskClassificationService {
    private classificatorServiceProxy: ClassificatorServiceProxy;

    constructor() {
        this.classificatorServiceProxy = new ClassificatorServiceProxy();
    }

    public async getClassificators(
        request: RiskClassificationEndpointRequest,
    ): Promise<ClassificatorGetEndpointResponseOkResponse> {
        const response = await this.classificatorServiceProxy.getClassificators(
            request?.id ?? undefined,
            request?.countrySid ?? undefined,
            request?.plantSid ?? undefined,
            request?.machineSid ?? undefined,
            request?.periodStart ?? undefined,
            request?.periodEnd ?? undefined,
            request?.sortDirection,
            request?.sortProperty,
            request?.page,
            request?.size,
            request?.term ?? undefined,
            request?.termToLower ?? undefined,
            request?.cancelToken,
        );
        for (const classificator of response.result?.items ?? []) {
            this.replaceEnums(classificator);
        }
        return response;
    }

    public async addClassificator(
        classificator: ClassificatorDto,
    ): Promise<ClassificatorPostEndpointResponseOkResponse> {
        const response = await this.classificatorServiceProxy.addClassificator(
            new ClassificatorPostEndpointRequest(classificator),
        );
        this.replaceEnums(response.result);
        return response;
    }

    public async putClassificator(
        classificator: ClassificatorDto,
    ): Promise<ClassificatorPutEndpointResponseOkResponse> {
        const response = await this.classificatorServiceProxy.putClassificator(
            new ClassificatorPutEndpointRequest(classificator),
        );
        this.replaceEnums(response.result!.item);
        return response;
    }

    private replaceEnums = (classificatorDto: ClassificatorDto | undefined): void => {
        if (!classificatorDto) {
            return;
        }
        classificatorDto.riskAllocations = classificatorDto?.riskAllocations?.map((riskAllocation) => {
            if (typeof riskAllocation.value === 'string') {
                riskAllocation.value = riskAllocation.value
                    ? (ClassificatorRiskLevel[riskAllocation.value] as unknown as ClassificatorRiskLevel)
                    : riskAllocation.value;
            }
            return riskAllocation;
        });
    };
}
