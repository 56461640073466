
import {
    EmissionWithParkedEmissionDto,
    UnitDto,
    PowerPlantDto,
    BadRequestResponse,
} from '@/service-proxies/service-proxies.g';
import Vue, { nextTick } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import {
    formatDate,
    DateDisplayOptions,
    formatActivityDataDisplayName,
    formatLocationName,
} from '@/utils/helpers/formatters';

interface Data {
    activity: EmissionWithParkedEmissionDto | null;
    unit: UnitDto | null;
    powerPlant: PowerPlantDto | null;
    bodyMessage: string;
}

export default Vue.extend({
    name: 'OverwriteApprovalModal',

    data(): Data {
        return {
            activity: null,
            unit: null,
            powerPlant: null,
            bodyMessage: '',
        };
    },

    computed: {
        ...mapGetters({ loading: 'isLoading' }),
        ...mapGetters('detailedView', { emission: 'GET_EDITED_EMISSION' }),
        ...mapGetters('country', {
            getCurrentCountry: 'GET_SELECTED_COUNTRY',
        }),
        ...mapGetters('location', {
            getStartDate: 'start',
        }),
    },

    methods: {
        ...mapActions('location', {
            acceptParked: 'OVERRIDE_APPROVAL',
        }),
        open(data: any) {
            this.activity = data.activity;
            this.unit = data.unit;
            this.powerPlant = data.powerPlant;

            const placeholders = {
                fuel: formatActivityDataDisplayName(this.activity?.activityData),
                location: formatLocationName(this.powerPlant?.name, this.unit?.name),
                date: formatDate(this.getStartDate, this.$i18n.locale, DateDisplayOptions.MonthOnly),
            };

            this.bodyMessage = this.$t('overwriteApproval.prompt') as string;
            // Placeholder aren't working so manually replace them
            Object.entries(placeholders).forEach(([key, value]) => {
                this.bodyMessage = this.bodyMessage.replace(`{{ ${key} }}`, value);
            });

            (this as any).$refs.overwriteApprovalModal.open();
        },

        async onConfirmCallback() {
            if (this.loading || this.activity === null) return;
            this.$store.commit('loading');

            try {
                await this.acceptParked(this.activity.id);
                (this as any).$pui.toast({
                    type: 'success',
                    title: this.$t('notification.emissions.save.title'),
                    copy: '',
                });
            } catch (e) {
                const message = (e as BadRequestResponse).message ?? '';
                (this as any).$pui.toast({
                    type: 'error',
                    title: this.$t('error.emissions.save.title'),
                    copy: message,
                });
                console.log(e);
                throw new Error();
            } finally {
                this.resetValues();
                await nextTick();
                (this as any).$refs.overwriteApprovalModal.close();
                this.$store.commit('loading');
            }
        },

        resetValues() {
            this.activity = null;
            this.unit = null;
            this.powerPlant = null;
        },

        onCloseCallback() {
            if (this.loading) return;
            this.resetValues();
            (this as any).$refs.overwriteApprovalModal.close();
        },
    },
});
