
import Vue from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Plant from './plant.vue';
import BoxSkeleton from '@/components/ui/loading/skeletons/box-skeleton.vue';
import PageHeader from '@/components/page-headers/header-with-country-and-allocation-period.vue';
import clickOutside from '@/directives/click-outside';

import type { EmissionQuotaProduct } from '@/store/modules/emission-quota';
import type { EQPlant, EQSum, EQUnit } from './emissionQuotaTypes';

interface Data {
    loadingEmissionQuotas: boolean;
    plants: EQPlant[];
    selectedCountry: number;
}

export default Vue.extend({
    name: 'FreeAllocationCO2Overview',

    components: {
        BoxSkeleton,
        PageHeader,
        Plant,
    },

    directives: {
        clickOutside,
    },

    data(): Data {
        return {
            loadingEmissionQuotas: false,
            plants: [],
            selectedCountry: -1 as number,
        };
    },

    computed: {
        ...mapGetters('country', {
            getCurrentCountry: 'GET_SELECTED_COUNTRY',
        }),
        ...mapGetters('emissionQuota', {
            getEQProducts: 'GET_EQ_PRODUCTS',
            getPowerPlants: 'GET_POWER_PLANTS',
            getTradingPeriodStartDate: 'GET_TRADING_PERIOD_START_DATE',
            getTradingPeriodStartYear: 'GET_TRADING_PERIOD_START_YEAR',
        }),

        loaderError(): { title: string; message: string } {
            let message = '';
            if (this.getCurrentCountry === null) {
                message = this.$t('emissionQuota.noData.selectCountry') as string;
            } else if (this.sortedPlants.length === 0) {
                message = this.$t('emissionQuota.noData.noEmissionQuota') as string;
            }

            return {
                title: this.$t('emissionQuota.noData.title') as string,
                message,
            };
        },

        sortedPlants(): EQPlant[] {
            return [...this.plants].sort((a, b) =>
                a.plantName.toLocaleLowerCase().localeCompare(b.plantName.toLocaleLowerCase()),
            );
        },

        tradingPeriod(): number {
            return parseInt(this.getTradingPeriodStartDate.split('-')[0]);
        },
    },

    watch: {
        getEQProducts(): void {
            this.generateStructure();
        },

        getCurrentCountry: {
            handler(): void {
                this.fetchEQs();
            },
            immediate: true,
        },
    },

    methods: {
        ...mapActions('emissionQuota', {
            fetchPowerPlants: 'FETCH_POWER_PLANTS',
            fetchEQProducts: 'FETCH_EQ_PRODUCTS',
        }),

        ...mapMutations('emissionQuota', {
            setTradingPeriodStartDate: 'SET_TRADING_PERIOD_START_DATE',
        }),

        async fetchEQs(shouldFetchPowerPlants = true): Promise<void> {
            if (this.getCurrentCountry === null || this.getCurrentCountry === undefined) return;

            this.loadingEmissionQuotas = true;

            const promises = [
                this.fetchEQProducts({
                    startYear: this.getTradingPeriodStartYear,
                    endYear: this.getTradingPeriodStartYear + 4,
                    countryId: this.getCurrentCountry.id,
                }),

                shouldFetchPowerPlants &&
                    this.fetchPowerPlants({
                        countryId: this.getCurrentCountry.id,
                    }),
            ];
            await Promise.all(promises);

            this.generateStructure();
            this.loadingEmissionQuotas = false;
        },

        generateStructure(): void {
            const plants = [];

            for (const powerplant of this.getPowerPlants) {
                // Ignore power plants without units
                if (powerplant.units.length === 0) continue;

                const plant: EQPlant = {
                    plantName: powerplant.name,
                    plantLocation: powerplant.locationType,
                    plantSid: powerplant.sid,
                    plantSum: {},
                    units: [] as EQUnit[],
                };

                const plantSum = {};

                for (const unit of powerplant.units) {
                    const eqProducts = this.getEQProducts.filter(
                        (eqp: EmissionQuotaProduct) => eqp.plantSid === powerplant.sid && eqp.machineSid === unit.sid,
                    );

                    const unitSum = this.calculateUnitSums(eqProducts);
                    this.addUnitSumToPlantSum(plantSum, unitSum);

                    plant.units.push({
                        unitName: unit.name,
                        unitSid: unit.sid,
                        unitSum: this.calculateUnitSums(eqProducts),
                        products: eqProducts,
                    });
                }

                plant.plantSum = plantSum;
                plants.push(plant);
            }

            this.plants = plants;
        },

        calculateUnitSums(eqProducts: EmissionQuotaProduct[]): EQSum {
            const sum: EQSum = {};
            for (const product of eqProducts) {
                for (const eq of product.emissionQuotas) {
                    if (!sum[eq.year]) sum[eq.year] = 0;
                    sum[eq.year] += eq.emissionQuotaAmount;
                }
            }
            return sum;
        },

        addUnitSumToPlantSum(plantSum: EQSum, unitSum: EQSum): void {
            for (const year in unitSum) {
                if (!plantSum[year]) plantSum[year] = 0;
                plantSum[year] += unitSum[year];
            }
        },

        updateStartDate(newDate: string): void {
            this.setTradingPeriodStartDate(newDate);

            this.fetchEQs(false);
        },
    },
});
