import { StateWithModules } from '@/store';
import { Module } from 'vuex';
import axios, { AxiosError } from 'axios';
import { HttpStatusCodes } from '@/enums/http-status-codes';
import { MasterDataLevel, MasterDataLevelEndpointResponse } from '@/service-proxies/service-proxies.g';
import { MasterDataLevelService } from '@/services/master-data-level-service';

export interface MasterDataLevelState {
    masterDataLevelApi: MasterDataLevelService;
    masterDataLevel: MasterDataLevel;
}

export const masterDataLevel: Module<MasterDataLevelState, StateWithModules> = {
    namespaced: true,
    state: (): MasterDataLevelState => ({
        masterDataLevelApi: new MasterDataLevelService(),
        masterDataLevel: MasterDataLevel.UnitLevel,
    }),
    getters: {
        masterDataLevel: (state: MasterDataLevelState): MasterDataLevel => state.masterDataLevel,
    },
    actions: {
        GET_MASTER_DATA_LEVEL: async (
            { commit, state }: { commit: any; state: MasterDataLevelState },
            request: { scopeId: number; countryId: number },
        ): Promise<number> => {
            try {
                const metaData = await state.masterDataLevelApi.getMasterDataLevel(request.scopeId, request.countryId);
                commit('SET_MASTER_DATA_LEVEL', metaData.result);
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    return (e as AxiosError).response?.status ?? HttpStatusCodes.InternalServerError;
                } else {
                    console.error(`An unknown error occurred while fetching the master data level data.`);
                    console.error(e);
                    return HttpStatusCodes.InternalServerError;
                }
            }
            return HttpStatusCodes.Ok;
        },
    },
    mutations: {
        SET_MASTER_DATA_LEVEL: (
            state: MasterDataLevelState,
            masterDataLevelDto: MasterDataLevelEndpointResponse,
        ): any => {
            if (masterDataLevelDto) {
                state.masterDataLevel = masterDataLevelDto.masterDataLevel ?? MasterDataLevel.UnitLevel;
            }
        },
    },
};
