import { FleetService } from '@/services/fleet-service';
import { StateWithModules } from '@/store';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { FleetDto } from '@/service-proxies/service-proxies.g';

const fleetService: FleetService = new FleetService();

export interface FleetState {
    fleets: FleetDto[];
    fleetIsLoading: boolean;
}

const state: FleetState = {
    fleets: [],
    fleetIsLoading: false,
};

const getters: GetterTree<FleetState, StateWithModules> = {
    fleets: (state): FleetDto[] => state.fleets,
    loadFleet: (state): boolean => state.fleetIsLoading,
};

const mutations: MutationTree<FleetState> = {
    setFleets(state: FleetState, payload: typeof state.fleets) {
        state.fleets = payload;
    },
    setLoadFleet(state: FleetState, payload: typeof state.fleetIsLoading) {
        state.fleetIsLoading = payload;
    },
};

const actions: ActionTree<FleetState, StateWithModules> = {
    async LOAD_FLEETS(
        { commit, dispatch }: ActionContext<FleetState, StateWithModules>,
        { scopeId, countryId }: { scopeId: number; countryId?: number },
    ): Promise<void> {
        const response = await fleetService.getAll({ countryId, scopeId });
        const fleets = response.result;

        dispatch('tables/GET_FLEET_NAMES', fleets, { root: true });
        commit('setFleets', fleets);
    },
    RESET_FLEETS({ commit }: ActionContext<FleetState, StateWithModules>) {
        commit('setFleets', []);
    },
};

const namespaced = true;

export const fleet: Module<FleetState, StateWithModules> = {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};
