
import { ActivitySelectModalConfirmHandler } from './modalsActionsTypes';
import { MultiselectOption } from '@/models';
import Vue, { nextTick } from 'vue';
import { mapGetters, mapState, mapActions } from 'vuex';
import { ActivityDataDto, EmissionDto, BadRequestResponse } from '@/service-proxies/service-proxies.g';
import { ActivityDataType } from '@/enums/activity-data-type';
import { LocationTableData } from '@/models/table';
import { formatActivityDataDisplayName } from '@/utils/helpers/formatters';
import { StyleValue } from 'vue/types/jsx';

interface ModalActions {
    open: () => void;
    close: () => void;
}

const MULTI_SELECT_MINIMUM_HEIGHT = 100; //in pixels

const ActivitySelectModal = Vue.extend({
    name: 'ActivitySelectModal',
    data(): {
        currentLocation: LocationTableData | undefined;
        selectedActivitiesIds: number[];
        confirmHandler: (activityIds: number[]) => void;
        selectBoxHeight: number;
    } {
        return {
            currentLocation: undefined,
            selectedActivitiesIds: [],
            confirmHandler: (activityIds: number[]) => {
                return;
            },
            selectBoxHeight: MULTI_SELECT_MINIMUM_HEIGHT,
        };
    },
    computed: {
        ...mapState({ loading: 'isLoading' }),
        ...mapState('activityData', { activityData: 'activityData' }),
        ...mapState('scope', { selectedScopeId: 'selectedScopeId' }),
        ...mapGetters('emissionDraft', { getDraft: 'GET_DRAFT' }),

        activityType(): ActivityDataType {
            if (!this.activityData) {
                return ActivityDataType.Unknown;
            }
            return (this.activityData[0]?.type as ActivityDataType) ?? ActivityDataType.Unknown;
        },

        headerLabel(): string {
            return this.$t('actions.add').toString();
        },

        modalRef(): ModalActions {
            return this.$refs.lightbox as unknown as ModalActions;
        },

        lightboxHeight(): StyleValue {
            const overlayHeight = 190; //The max height of the multiselect dropdown list
            return { height: `${overlayHeight + this.selectBoxHeight}px` };
        },

        disableSelect(): boolean {
            return this.selectBoxHeight > 400; //Past this height the footer of lightbox gets pushed off
        },

        activityOptions(): MultiselectOption[] {
            return this.activityData.map((activity: ActivityDataDto) => {
                return {
                    value: activity.id ?? 0,
                    label: formatActivityDataDisplayName(activity),
                    disabled: this.isActivityAlreadyUsedInUnit(activity.id ?? 0) || this.disableSelect,
                };
            });
        },
    },
    watch: {
        selectedScopeId: {
            async handler(): Promise<void> {
                if (this.$store.getters['scope/GET_SCOPE']) {
                    await this.$store.dispatch('activityData/GET_ACTIVITY_DATA', this.selectedScopeId);
                }
            },
            immediate: true,
        },
        async selectedActivitiesIds(): Promise<void> {
            await nextTick();
            const box = document.querySelector('#activitySelectBox');
            this.selectBoxHeight = box?.clientHeight ?? MULTI_SELECT_MINIMUM_HEIGHT;
        },
    },
    methods: {
        ...mapActions('activityData', { fetchActivities: 'GET_ACTIVITY_DATA' }),
        ...mapActions('tables', { updateTracker: 'UPDATE_TRACKER' }),

        isActivityAlreadyUsedInUnit(activityId: number): boolean {
            if (!this.currentLocation) return false;

            return (
                !!this.currentLocation._children?.find(
                    (emission) => (emission.meta.object as EmissionDto).activityData?.id === activityId,
                ) || !!this.getDraft(this.currentLocation?.meta.id, activityId)
            );
        },

        async confirmActivitySelection(): Promise<void> {
            this.confirmHandler(this.selectedActivitiesIds);
            this.$store.commit('loading');
            try {
                this.updateTracker({ [this.currentLocation?.meta.id as number]: true });
                await this.$store.dispatch('location/ADD_DRAFT_EMISSIONS_TO_LOCATION', {
                    selectedActivityDataIds: this.selectedActivitiesIds,
                    assetId: this.currentLocation?.meta.id,
                    scopeId: this.selectedScopeId,
                });
            } catch (e) {
                const message = (e as BadRequestResponse).message ?? '';
                (this as any).$pui.toast({
                    type: 'error',
                    title: this.$t('toast.error'),
                    copy: message,
                });
                console.log(e);
                throw new Error();
            } finally {
                this.$store.commit('loading');
                this.modalRef.close();
            }
        },

        beforeCloseActivitySelectionModal(): void {
            this.selectedActivitiesIds = [];
        },

        async open(location: LocationTableData, confirmHandler: ActivitySelectModalConfirmHandler): Promise<void> {
            this.confirmHandler = (activityIds: number[]): void => confirmHandler(activityIds, location);
            this.currentLocation = location;
            this.modalRef.open();
            if ((this.activityData as ActivityDataDto[]).length === 0) {
                this.$store.commit('loading');
                const response = await this.fetchActivities(this.selectedScopeId);
                if (response !== 200) {
                    (this as any).$pui.toast({
                        type: 'error',
                        title: this.$t('error.riskClassification.load.title'),
                        copy: '',
                    });
                    this.$store.commit('loading');
                    throw new Error();
                }
                this.$store.commit('loading');
            }
        },
    },
});

export default ActivitySelectModal;
