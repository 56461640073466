import { EmissionFactorType } from '@/enums/emission-factor-type';
import { HttpStatusCodes } from '@/enums/http-status-codes';
import {
    EmissionFactorDto,
    EmissionWithParkedEmissionDto,
    UnitOfMeasurementEmissionFactorDto,
    AttachmentDto,
} from '@/service-proxies/service-proxies.g';
import { UOMService } from '@/services/uom-service';
import axios, { AxiosError } from 'axios';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ApplicationState } from '..';

const service = new UOMService();

export interface DetailedViewState {
    emission: EmissionWithParkedEmissionDto;
    editedEmission: EmissionWithParkedEmissionDto;
    currentEmissionFactor: EmissionFactorDto | undefined;
    importedIndividualEmissionFactor: EmissionFactorDto | undefined;
    editedIndividualEmissionFactor: EmissionFactorDto | undefined;
    individualEmissionFactorHasBeenChanged: boolean;
    standardEmissionFactor: EmissionFactorDto | undefined;
    typeOFEmissionFactor: EmissionFactorType | null;
    consumptionUOMs: UnitOfMeasurementEmissionFactorDto[];
}

const state: DetailedViewState = {
    emission: {} as EmissionWithParkedEmissionDto,
    editedEmission: {} as EmissionWithParkedEmissionDto,
    currentEmissionFactor: {} as EmissionFactorDto,
    importedIndividualEmissionFactor: {} as EmissionFactorDto,
    editedIndividualEmissionFactor: {} as EmissionFactorDto,
    individualEmissionFactorHasBeenChanged: false,
    standardEmissionFactor: {} as EmissionFactorDto,
    typeOFEmissionFactor: null,
    consumptionUOMs: [],
};

const getters: GetterTree<DetailedViewState, ApplicationState> = {
    GET_EMISSION: (state) => state.emission,
    GET_EDITED_EMISSION: (state) => state.editedEmission,
    GET_CURRENT_EMISSION_FACTOR: (state) => state.currentEmissionFactor,
    GET_IMPORTED_INDIVIDUAL_EMISSION_FACTOR: (state) => state.importedIndividualEmissionFactor,
    GET_EDITED_INDIVIDUAL_EMISSION_FACTOR: (state) => state.editedIndividualEmissionFactor,
    GET_INDIVIDUAL_HAS_BEEN_CHANGED: (state) => state.individualEmissionFactorHasBeenChanged,
    GET_STANDARD_EMISSION_FACTOR: (state) => state.standardEmissionFactor,
    GET_TYPE_OF_EMISSION_FACTOR: (state) => state.typeOFEmissionFactor,
    GET_CONSUMPTION_UOMS: (state) => state.consumptionUOMs,
};

const mutations: MutationTree<DetailedViewState> = {
    SET_EMISSION(state: DetailedViewState, payload: typeof state.emission) {
        state.emission = payload;
    },
    SET_EDITED_EMISSION(state: DetailedViewState, payload: typeof state.editedEmission) {
        state.editedEmission = payload;
    },
    SET_CURRENT_EMISSION_FACTOR(state: DetailedViewState, payload: typeof state.currentEmissionFactor) {
        state.currentEmissionFactor = payload;
    },
    SET_IMPORTED_INDIVIDUAL_EMISSION_FACTOR(
        state: DetailedViewState,
        payload: typeof state.importedIndividualEmissionFactor,
    ) {
        state.importedIndividualEmissionFactor = payload;
    },
    SET_EDITED_INDIVIDUAL_EMISSION_FACTOR(
        state: DetailedViewState,
        payload: typeof state.editedIndividualEmissionFactor,
    ) {
        state.editedIndividualEmissionFactor = payload;
    },
    SET_INDIVIDUAL_HAS_BEEN_CHANGED(
        state: DetailedViewState,
        payload: typeof state.individualEmissionFactorHasBeenChanged,
    ) {
        state.individualEmissionFactorHasBeenChanged = payload;
    },
    SET_STANDARD_EMISSION_FACTOR(state: DetailedViewState, payload: typeof state.standardEmissionFactor) {
        state.standardEmissionFactor = payload;
    },
    SET_TYPE_OF_EMISSION_FACTOR(state: DetailedViewState, payload: typeof state.typeOFEmissionFactor) {
        state.typeOFEmissionFactor = payload;
    },
    SET_CONSUMPTION_UOMS(state: DetailedViewState, payload: typeof state.consumptionUOMs) {
        state.consumptionUOMs = payload;
    },
};

const actions: ActionTree<DetailedViewState, ApplicationState> = {
    SET_EMISSION_FACTORS(
        { rootGetters, commit }: ActionContext<DetailedViewState, ApplicationState>,
        emission: EmissionWithParkedEmissionDto,
    ): void {
        const tonUnitOfMeasurement = {
            id: 3,
            shortName: 't',
            name: 'Tons',
        };
        commit('SET_EMISSION', { ...emission });
        commit('SET_EDITED_EMISSION', { ...emission });

        if (emission.emissionFactor === undefined) {
            return;
        }
        const currentEmissionFactor = { ...emission.emissionFactor };

        commit('SET_CURRENT_EMISSION_FACTOR', currentEmissionFactor);

        if (currentEmissionFactor.type === EmissionFactorType.Individual) {
            commit('SET_IMPORTED_INDIVIDUAL_EMISSION_FACTOR', {
                ...currentEmissionFactor,
            });
            commit('SET_EDITED_INDIVIDUAL_EMISSION_FACTOR', {
                ...currentEmissionFactor,
            });
            commit('SET_STANDARD_EMISSION_FACTOR', {
                ...emission.emissionFactorOptions?.globalEmissionFactorOption,
            });
        } else {
            commit('SET_IMPORTED_INDIVIDUAL_EMISSION_FACTOR', {
                ...emission.emissionFactorOptions?.individualEmissionFactorOption,
            });
            commit('SET_EDITED_INDIVIDUAL_EMISSION_FACTOR', {
                ...emission.emissionFactorOptions?.individualEmissionFactorOption,
            });
            commit('SET_STANDARD_EMISSION_FACTOR', {
                ...currentEmissionFactor,
            });
        }
        commit('SET_INDIVIDUAL_HAS_BEEN_CHANGED', false);
    },
    async FETCH_UOMS({ commit, state }: ActionContext<DetailedViewState, ApplicationState>): Promise<HttpStatusCodes> {
        try {
            const activityId = state.emission.activityData?.id;
            const locationId = state.emission.locationId;
            const scopeId = state.emission.scope?.id;
            const startDate = state.emission.startDate;
            if (!activityId || !locationId || !scopeId || !startDate) {
                throw new Error();
            }
            const result = await service.getUOMEFs(activityId, locationId, scopeId, startDate);
            commit('SET_CONSUMPTION_UOMS', result.result);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return (e as AxiosError).response?.status ?? HttpStatusCodes.InternalServerError;
            } else {
                console.error(`An unknown error occurred while fetching the country data.`);
                console.error(e);
                return HttpStatusCodes.InternalServerError;
            }
        }
        return HttpStatusCodes.Ok;
    },
    DELETE_ATTACHMENT(
        { commit, state }: ActionContext<DetailedViewState, ApplicationState>,
        deletedAttachment: AttachmentDto,
    ): void {
        const attachments = state.editedEmission.attachments;
        if (!attachments) return;
        const deletedAttachmentIndex = attachments?.findIndex((attachment) => attachment.id === deletedAttachment.id);
        if (deletedAttachmentIndex === -1) return;
        const newAttachments = [
            ...attachments.slice(0, deletedAttachmentIndex),
            ...attachments.slice(deletedAttachmentIndex + 1),
        ];
        commit('SET_EDITED_EMISSION', { ...state.editedEmission, attachments: newAttachments });
    },
};

export const detailedView: Module<DetailedViewState, ApplicationState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
