import { ArchiveData, EmissionDto, EmissionFactorDto, UnitOfMeasurementDto } from '@/service-proxies/service-proxies.g';
import { EmissionFactorType } from '@/enums/emission-factor-type';

const fillMissingArchiveData = (emission: EmissionDto): ArchiveData => {
    return new ArchiveData({
        createComment: '',
        createDate: emission.startDate ?? '',
        createUserKid: '',
        validFrom: emission.startDate ?? '',
        validTo: emission.endDate ?? '',
        updateComment: '',
        updateDate: emission.startDate ?? '',
        updateUserKid: '',
    });
};

const fillMissingEF = (emission: EmissionDto): EmissionFactorDto => {
    return new EmissionFactorDto({
        archiveData: fillMissingArchiveData(emission),
        emissionsFactor: undefined,
        id: 0,
        source: 'Manually Entered',
        type: EmissionFactorType.Individual,
    });
};

const fillMissingGhgUOM = (emission: EmissionDto): UnitOfMeasurementDto => {
    return new UnitOfMeasurementDto({
        archiveData: fillMissingArchiveData(emission),
        id: 1,
        name: 'Tonnes',
        shortName: 't',
    });
};

export const fillMissingEmissionValues = (emission: EmissionDto): EmissionDto => {
    const newEmission = { ...emission } as EmissionDto;
    if (!emission.emissionFactor) {
        newEmission.emissionFactor = fillMissingEF(emission);
    }
    if (!emission.ghgUnitOfMeasurement) {
        newEmission.ghgUnitOfMeasurement = fillMissingGhgUOM(emission);
    }
    return newEmission;
};
