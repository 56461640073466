import Vue from 'vue';
import { getMonths } from '@/utils/helpers/formatters';
import { MultiselectOption } from '@/models';
import { isDateStringValid } from '@/utils/helpers/validators';
import { Granularity } from '@/service-proxies/service-proxies.g';

const today = new Date();

const GranularityDropdown = Vue.extend({
    name: 'GranularityDropdown',
    props: {
        value: {
            type: String,
            default: '',
            validator(dateString: string): boolean {
                if (!dateString) {
                    return true;
                }

                return isDateStringValid(dateString);
            },
        },
        granularity: {
            type: Number,
            default: Granularity.Monthly,
            validator(value: number): boolean {
                return Object.values(Granularity).includes(value as Granularity);
            },
        },
        endOfPeriod: {
            type: Boolean,
            default: false,
        },
    },
    data(): {
        year: string;
        month: string;
        selectingYear: boolean;
    } {
        return {
            year: '',
            month: '',
            selectingYear: false,
        };
    },
    computed: {
        localValue(): string {
            if (this.endOfPeriod) {
                if (this.granularity === Granularity.Yearly) return `${this.year}-12-31`;
                const dateObject = new Date(Number(this.year), Number(this.month), 0);
                return `${dateObject.getFullYear()}-${dateObject.getMonth() + 1}-${dateObject.getDate()}`;
            }
            return this.granularity === Granularity.Yearly ? `${this.year}-01-01` : `${this.year}-${this.month}-01`;
        },

        months(): MultiselectOption[] {
            return getMonths(this.$i18n.locale).map((month, index) => ({
                value: String(index + 1).padStart(2, '0'),
                label: month,
            }));
        },

        quarters(): MultiselectOption[] {
            return Array.from({ length: 4 }, (_, index) => {
                const quarter = index + 1;
                const quarterFirstMonth = String(quarter * 3 - 2).padStart(2, '0');
                return { value: quarterFirstMonth, label: `Q${quarter}` };
            });
        },

        years(): MultiselectOption[] {
            return Array.from({ length: 40 }, (_, index) => {
                const value = String(2022 + index);
                return { value, label: value };
            });
        },

        options(): MultiselectOption[] {
            return this.selectingYear
                ? this.years
                : this.granularity === Granularity.Quarterly
                ? this.quarters
                : this.months;
        },

        dynamicValue(): string {
            return this.selectingYear ? this.year : this.month;
        },
    },
    mounted() {
        this.year = this.value ? this.value.slice(0, 4) : today.getFullYear().toString();
        this.month = this.getMonth();
        this.selectingYear = this.granularity === Granularity.Yearly;
    },
    methods: {
        getMonth(): string {
            const currentMonth = this.value ? Number(this.value.slice(5, 7)) : today.getMonth() + 1;
            if (this.granularity === Granularity.Quarterly) {
                const quarter = Math.ceil(currentMonth / 3);
                return String(quarter * 3 - 2).padStart(2, '0');
            } else {
                return String(currentMonth).padStart(2, '0');
            }
        },
        update(value: number | string): void {
            if (this.selectingYear) {
                this.year = value.toString();
            } else {
                this.month = value.toString();
            }
            if (this.granularity !== Granularity.Yearly) {
                this.selectingYear = false;
            }
        },
    },
});

export default GranularityDropdown;
