
import Vue from 'vue';
import ApproveConfirmModal from './modals/approve-confirm-modal.vue';
import CompleteConfirmModal from './modals/complete-confirm-modal.vue';
import EmissionTable from '@/components/tables/emission-table/emission-table.vue';
import ApprovalsModal from '@/components/approvals/modals/approvals-modal.vue';
import FleetSkeleton from '../ui/loading/skeletons/fleet-skeleton.vue';
import PageHeader from '@/components/page-headers/header-with-country-and-monthly-granularity.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { Granularity } from '@/service-proxies/service-proxies.g';
import { EventBus } from '@/utils';
import { TableType } from '@/utils/tables/table-config';
import { LocationTableData } from '@/models/table';

interface Data {
    tableType: TableType;
    bulkApprovals: Record<string, any>;
}

export default Vue.extend({
    name: 'ApprovalsOverview',

    components: {
        ApprovalsModal,
        ApproveConfirmModal,
        CompleteConfirmModal,
        EmissionTable,
        FleetSkeleton,
        PageHeader,
    },

    data(): Data {
        return {
            tableType: TableType.APPROVAL,
            bulkApprovals: {},
        };
    },

    computed: {
        ...mapGetters('country', {
            getCurrentCountry: 'GET_SELECTED_COUNTRY',
        }),
        ...mapGetters('fleet', {
            getFleets: 'fleets',
            isFleetLoading: 'loadFleet',
        }),
        ...mapGetters('scope', {
            getSelectedScope: 'GET_SELECTED_SCOPE',
        }),
        ...mapGetters('location', {
            startDate: 'start',
        }),
    },

    watch: {
        getCurrentCountry() {
            this.fetchData();
        },
        startDate() {
            this.bulkApprovals = {};
        },
    },

    mounted() {
        this.setupGlobalEventListeners();

        this.fetchData();
    },

    beforeDestroy(): void {
        EventBus.$off(EventBus.VIEWS.APPROVAL.APPROVE);
        EventBus.$off(EventBus.VIEWS.APPROVAL.BULK_APPROVE);
        EventBus.$off(EventBus.VIEWS.APPROVAL.COMPLETE);
        EventBus.$off(EventBus.VIEWS.APPROVAL.OPEN_DETAILED_EMISSIONS_MODAL);
    },

    methods: {
        ...mapActions('fleet', {
            fetchFleets: 'LOAD_FLEETS',
        }),
        ...mapActions('location', {
            fetchLocations: 'LOAD_LOCATIONS',
        }),
        ...mapActions('masterDataLevel', {
            fetchMasterDataLevel: 'GET_MASTER_DATA_LEVEL',
        }),

        ...mapMutations('fleet', {
            setLoadFleet: 'setLoadFleet',
        }),

        async fetchData(): Promise<void> {
            if (!this.getCurrentCountry) return;
            if (!this.getSelectedScope) return;

            this.setLoadFleet(true);
            this.bulkApprovals = {};

            try {
                const countryId = this.getCurrentCountry.id;
                const scopeId = this.getSelectedScope.id;

                await Promise.all([
                    this.fetchFleets({ scopeId, countryId }),
                    this.fetchLocations({ scopeId, countryId, granularity: Granularity.Monthly }),
                    this.fetchMasterDataLevel({ scopeId, countryId }),
                ]);
            } catch (e) {
                console.log(e);
            }

            this.setLoadFleet(false);
        },

        resetBulkApproval(): void {
            this.bulkApprovals = {};
        },

        setupGlobalEventListeners() {
            EventBus.$on(EventBus.VIEWS.APPROVAL.APPROVE, (data: Record<string, any>): void => {
                const isPartOfBulk = !!this.bulkApprovals[(data.activity as LocationTableData).meta.id];
                (this as any).$refs['approveConfirmModal'].open(
                    {
                        ...data,
                        ...(isPartOfBulk && { bulkApprovals: this.bulkApprovals }),
                    },
                    this.resetBulkApproval,
                );
            });
            EventBus.$on(EventBus.VIEWS.APPROVAL.BULK_APPROVE, (data: Record<string, any>): void => {
                this.setBulkApprove(data);
            });
            EventBus.$on(EventBus.VIEWS.APPROVAL.COMPLETE, (data: Record<string, any>): void => {
                (this as any).$refs['completeConfirmModal'].open(data);
            });
            EventBus.$on(EventBus.VIEWS.APPROVAL.OPEN_DETAILED_EMISSIONS_MODAL, (data: Record<string, any>): void => {
                (this as any).$refs['detailedApprovalsModal'].open(data);
            });
        },

        setBulkApprove(data: Record<string, any>): void {
            const { activity, checked } = data as { activity: LocationTableData; checked: boolean };

            if (checked) {
                this.bulkApprovals[activity.meta.id] = data;
            } else {
                delete this.bulkApprovals[activity.meta.id];
            }
        },
    },
});
