
import Vue from 'vue';

export default Vue.extend({
    name: 'Logout',

    props: {
        height: {
            type: [String, Number],
            required: false,
            default: '24',
        },
    },
});
