import { tabulatorVueComponentInjector } from '@/utils/tables/table-config';
import EmissionFactor from '../custom-cells/emission-factor.vue';
import AddUoMCell from '../custom-cells/add-uom.vue';
import type { CellComponent, FormatterParams } from 'tabulator-tables';
import { EmissionFactorTableLevel } from '@/enums/TableLevel';
import { resetEmissionRow } from '@/utils/helpers/table/table-helper';
import SelectBox from '@/components/tables/standard-emission/custom-cells/select-box.vue';
import { UnitOfMeasurementDto } from '@/service-proxies/service-proxies.g';

export const columnDefinition = [
    {
        title: 'Fuel / Unit Of Measurement',
        field: 'activity',
    },
    {
        title: 'Emission Factor CO2',
        field: 'emissionFactorCO2',
        minWidth: 250,
        formatter: (cell: CellComponent, _formatterParams: FormatterParams, _onRendered: any): any => {
            const value = cell.getValue();
            const data = cell.getData() as any;
            return data.meta.level === EmissionFactorTableLevel.UnitOfMeasurement
                ? tabulatorVueComponentInjector(EmissionFactor, {
                      value: value,
                      emissionFactor: data.meta.dataCO2,
                  })
                : '';
        },
    },
    {
        title: 'Emission Factor CH4',
        field: 'emissionFactorCH4',
        minWidth: 250,
        formatter: (cell: CellComponent, _formatterParams: FormatterParams, _onRendered: any): any => {
            const value = cell.getValue();
            const data = cell.getData() as any;
            return data.meta.level === EmissionFactorTableLevel.UnitOfMeasurement
                ? tabulatorVueComponentInjector(EmissionFactor, {
                      value: value,
                      emissionFactor: data.meta.dataCH4,
                  })
                : '';
        },
    },
    {
        title: 'Emission Factor N2O',
        field: 'emissionFactorN2O',
        minWidth: 250,
        formatter: (cell: CellComponent, _formatterParams: FormatterParams, _onRendered: any): any => {
            const value = cell.getValue();
            const data = cell.getData() as any;
            return data.meta.level === EmissionFactorTableLevel.UnitOfMeasurement
                ? tabulatorVueComponentInjector(EmissionFactor, {
                      value: value,
                      emissionFactor: data.meta.dataN2O,
                  })
                : '';
        },
    },
    {
        field: 'actions',
        minWidth: 100,
        formatter: (cell: CellComponent, _formatterParams: FormatterParams, _onRendered: any): any => {
            const data = cell.getData() as any;
            if (
                data.meta.level == EmissionFactorTableLevel.Activity &&
                data.meta.activity.unitsOfMeasurement.filter(
                    (uom: UnitOfMeasurementDto) =>
                        !data._children.some((row: any) => row.meta.activityUOM.id == uom.id),
                ).length !== 0
            ) {
                return tabulatorVueComponentInjector(AddUoMCell, {
                    activity: data.meta.activity,
                    children: data._children,
                });
            } else if (data.meta.level !== EmissionFactorTableLevel.Activity) {
                return tabulatorVueComponentInjector(SelectBox, {
                    activity: cell.getRow().getData(),
                    resetRow: () => resetEmissionRow(cell.getRow()),
                });
            }
        },
    },
];
