
import Vue from 'vue';

const UnitPeriodCommentField = Vue.extend({
    name: 'UnitPeriodCommentField',
    props: {
        text: {
            type: String,
            default: '',
        },
        lastChanged: {
            type: String,
            required: true,
        },
        lastChangedBy: {
            type: String,
            required: true,
        },
        deletionPending: {
            type: Boolean,
            required: true,
        },
        hasSavedComment: {
            type: Boolean,
            default: true,
        },
    },
    data(): {
        editing: boolean;
        unsavedComment: string;
        internalCommentState: string;
        fieldKey: number;
    } {
        return {
            editing: false,
            unsavedComment: '',
            internalCommentState: '',
            fieldKey: 0,
        };
    },
    computed: {
        showField(): boolean {
            return !!this.text || this.editing || this.deletionPending;
        },
    },
    watch: {
        text(): void {
            // reload textarea if the text is changed programmatically since automatic resizing doesn't trigger
            if (this.text !== this.internalCommentState) {
                this.fieldKey++;
            }
        },
    },
    methods: {
        showDelete(): boolean {
            return (this.hasSavedComment && !!this.text) || this.deletionPending;
        },

        edit(): void {
            this.editing = true;
        },

        stopEditing(): void {
            if (!this.editing) {
                return;
            }
            this.editing = false;
            if (this.hasSavedComment) {
                this.internalCommentState = this.text;
                return;
            }
            if (!!this.internalCommentState) {
                this.internalCommentState = '';
                this.$emit('change', undefined);
            }
        },

        deleteComment(): void {
            this.unsavedComment = this.text!;
            this.editing = false;
            this.$emit('change', null);
        },

        editComment(input: string): void {
            if (!this.hasSavedComment && !input) return;
            if (input === null) {
                input = '';
            }
            this.internalCommentState = input;
            this.$emit('change', input);
        },
    },
});

export default UnitPeriodCommentField;
