
import Vue, { PropType } from 'vue';

interface NavItems {
    displayName: string;
    href: string;
    isActive: boolean;
    visible: boolean;
}

export default Vue.extend({
    name: 'Tab',
    props: {
        linkItem: {
            type: Object as PropType<NavItems>,
            required: true,
        },
    },
    methods: {
        handleClick(e: Event) {
            e.preventDefault();
            this.$emit('click', this.linkItem);
        },
    },
});
