import { render, staticRenderFns } from "./bulk-edit-values-list.vue?vue&type=template&id=223149d8&scoped=true"
import script from "./bulk-edit-values-list.vue?vue&type=script&lang=ts"
export * from "./bulk-edit-values-list.vue?vue&type=script&lang=ts"
import style0 from "./bulk-edit-values-list.vue?vue&type=style&index=0&id=223149d8&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "223149d8",
  null
  
)

export default component.exports