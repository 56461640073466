export enum TableLevel {
    Plant,
    Unit,
    Emission,
}

export enum EmissionFactorTableLevel {
    Activity,
    UnitOfMeasurement,
}
