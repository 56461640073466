import { CompletenessServiceProxy } from '@/service-proxies/service-proxies.g';

import type {
    CompletenessGetEndpointResponseOkResponse,
    CompletenessPostEndpointRequest,
    CompletenessPostEndpointResponseOkResponse,
} from '@/service-proxies/service-proxies.g';

export class CompletenessService {
    private completenessServiceProxy: CompletenessServiceProxy;

    constructor() {
        this.completenessServiceProxy = new CompletenessServiceProxy();
    }

    public async getCompleteness(locationId: number): Promise<CompletenessGetEndpointResponseOkResponse> {
        return await this.completenessServiceProxy.getCompleteness(locationId, 3);
    }

    public async addCompleteness(
        body: CompletenessPostEndpointRequest,
    ): Promise<CompletenessPostEndpointResponseOkResponse> {
        return await this.completenessServiceProxy.addCompleteness(body);
    }
}
