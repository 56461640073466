export enum Permissions {
    Admin = 'Admin',
    Creator = 'Write',
    User = 'Read',

    DevTools = 'HasAccessToDevTools',
    EmissionDataEntry = 'HasAccessToDataInput',
    FreeAllocation = 'HasAccessToFreeAllocation',
    Co2RiskAllocation = 'HasAccessToTypeOfDistribution',
    ApproveEmissionData = 'HasAccessToApprovalsDashboard',
    WithdrawApproval = 'HasAccessToWithdrawAprrovalsAndCompletenessCheck',
    ViewFuelAdjustment = 'HasAccessToViewFuelAdjustment',
    EditFuelAdjustment = 'HasAccessToEditFuelAdjustment',
    ViewRelatedEmissions = 'HasAccessToViewRelatedEmissions',
    ViewStandardEF = 'HasAccessToViewStandardEFManagement',
    EditStandardEF = 'HasAccessToEditStandardEFManagement',
}
