
import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import { EmissionFactorDto } from '@/service-proxies/service-proxies.g';
import UnitOfMeasurement from '@/components/unit-of-measurement/unit-of-measurement.vue';
import StatusCell from '@/components/tables/emission-table/custom-cells/status-cell.vue';
import FloatInput from '@/components/inputs/float-input.vue';
import ModalInputWrapper from '@/components/inputs/modal-input-wrapper.vue';
import { EmissionFactorType } from '@/enums/emission-factor-type';
import { checkDecimalPlaces, roundToDecimalPlaces } from '@/utils';
import { MaxDecimalPlaces, MaxValue, MaxWholeNumbers, JAVASCRIPT_MAX_DIGITS } from '@/constants/input-precision';
import { EmissionInputs } from '@/constants/emission-input';
import { EFKey, EmissionFactorInput } from '@/models/emission-factor-input';
import { DEFAULT_SCOPE_ID } from '@/constants/default';

const EmissionSummary = Vue.extend({
    name: 'EmissionSummary',
    components: {
        UnitOfMeasurement,
        StatusCell,
        FloatInput,
        ModalInputWrapper,
    },
    props: {
        unit: {
            type: Object,
            required: true,
        },
        powerPlant: {
            type: Object,
            required: false,
            default: null,
        },
        canEditFuel: {
            type: Boolean,
            default: false,
        },
        isApprovalView: {
            type: Boolean,
            default: true,
        },
    },
    data(): {
        formValues: any;
        valuesChanged: boolean;
        MaxDecimalPlaces: EmissionFactorInput;
    } {
        return {
            formValues: {
                activityDataAmount: {
                    value: '',
                    isValid: true,
                },
                ghgAmount: {
                    value: '',
                    isValid: true,
                },
                ghgAmountBiogenicShare: {
                    value: '',
                    isValid: true,
                },
                emissionFactor: {
                    value: '',
                    isValid: true,
                },
            },
            valuesChanged: false,
            MaxDecimalPlaces,
        };
    },
    computed: {
        ...mapGetters({ scope: 'scope/GET_SELECTED_SCOPE' }),
        ...mapGetters('detailedView', {
            importedEmission: 'GET_EMISSION',
            emission: 'GET_EDITED_EMISSION',
            getTypeOfEmissionFactor: 'GET_TYPE_OF_EMISSION_FACTOR',
            getEditedIndividualEmissionFactor: 'GET_EDITED_INDIVIDUAL_EMISSION_FACTOR',
            getStandardEmissionFactor: 'GET_STANDARD_EMISSION_FACTOR',
        }),
        scopeName(): string {
            return this.$t(`scope${this.scope?.subScope?.replace('.', '_') ?? ''}`).toString();
        },
        tooLargeEmissionFactor(): boolean {
            return (this.emissionFactor.emissionsFactor ?? 0) >= MaxValue.EMISSION_FACTOR;
        },
        emissionFactor(): EmissionFactorDto {
            if (this.isApprovalView || this.importedEmission.approvalStatus === 'Approved') {
                return this.importedEmission.emissionFactor;
            }
            if (this.getTypeOfEmissionFactor === EmissionFactorType.Individual) {
                return this.getEditedIndividualEmissionFactor;
            } else {
                return this.getStandardEmissionFactor;
            }
        },
        factorUnits(): string {
            let numerator =
                this.emissionFactor.ghgUnitOfMeasurement?.shortName ?? this.emission.ghgUnitOfMeasurement?.shortName;
            if (this.scope.id === DEFAULT_SCOPE_ID) {
                numerator += ' CO2';
            }
            const denominator =
                this.emissionFactor.activityDataUnitOfMeasurement?.shortName ??
                this.emission.activityDataUnitOfMeasurement?.shortName;
            return `${numerator ?? ''} / ${denominator ?? ''}`;
        },
        emptyRequiredField(): boolean {
            return (
                this.formValues.activityDataAmount.value === undefined ||
                this.formValues.activityDataAmount.value === ''
            );
        },
        showCaution(): boolean {
            return this.emptyRequiredField || this.formValues.activityDataAmount.value.length > JAVASCRIPT_MAX_DIGITS;
        },
        validFuelInput(): boolean {
            const value = Number(this.formValues.activityDataAmount.value);
            return checkDecimalPlaces(value, MaxDecimalPlaces.FUEL_CONSUMPTION) && value < MaxValue.FUEL_CONSUMPTION;
        },
        validGhgAmount(): boolean {
            const value = Number(this.formValues.ghgAmount.value);
            return checkDecimalPlaces(value, MaxDecimalPlaces.GHG) && value < MaxValue.GHG;
        },
        validBiogenicGhgAmount(): boolean {
            const value = Number(this.formValues.ghgAmountBiogenicShare.value);
            return checkDecimalPlaces(value, MaxDecimalPlaces.GHG_BIOGENIC) && value < MaxValue.GHG_BIOGENIC;
        },
        approvalStatus(): string {
            if (!this.emission.approvalStatus) return '';
            return this.$t(`emissionTable.status.${(this.emission.approvalStatus as string).toLowerCase()}`) as string;
        },
        greenHouseGasUOM(): string {
            let uom = this.emission.ghgUnitOfMeasurement.shortName;
            if (this.scope.id === DEFAULT_SCOPE_ID) {
                uom += ' CO2';
            }
            return uom;
        },
        metaInfo(): Record<string, string> {
            const source = this.$t('meta.source');
            const type = this.$t('meta.type');

            const translatedEFType =
                this.emissionFactor.type === EmissionFactorType.Individual
                    ? this.$t('emission.type.individual')
                    : this.$t('emission.type.global');

            const fuelSource = `${source}: ${this.emission.source}`;
            const emissionType = `${type}: ${translatedEFType}`;
            const emissionSource = `${source}: ${this.emissionFactor?.source}`;

            return {
                fuelSource,
                emissionType,
                emissionSource,
            };
        },
        fuelConsumptionTooltip(): string {
            return this.toolTipMessage('FUEL_CONSUMPTION');
        },
        emissionFactorTooltip(): string {
            if (this.formValues.emissionFactor.value === '') return this.$t('detailedView.summaryTooltip') as string;
            if (this.tooLargeEmissionFactor) {
                const message = this.$t('input.invalid.tooLarge') as string;
                return message.replace(`{{ number }}`, MaxWholeNumbers['EMISSION_FACTOR'].toString());
            }
            return '';
        },
        ghgTooltip(): string {
            return this.toolTipMessage('GHG');
        },
        ghgTooltipBiogenic(): string {
            return this.toolTipMessage('GHG_BIOGENIC');
        },
        useFuelAdjustment(): boolean {
            return this.emission.activeActivityDataAmountAdjustment ?? false;
        },
        fuelAdjustedValue(): number {
            return roundToDecimalPlaces(
                this.emission.unadjustedActivityDataAmount * this.emission.activityDataAmountAdjustmentFactor,
                MaxDecimalPlaces.FUEL_CONSUMPTION,
            );
        },
    },
    watch: {
        emissionFactor(): void {
            this.formValues.emissionFactor.value = this.emissionFactor.emissionsFactor?.toString() ?? '';
            this.calculateGHGAmount();
            this.$emit('valid', !this.tooLargeEmissionFactor);
        },
        useFuelAdjustment(): void {
            this.onFuelAdjustment();
        },
        fuelAdjustedValue(): void {
            this.onFuelAdjustment();
        },
    },
    mounted() {
        this.formValues.activityDataAmount.value = this.emission.activityDataAmount.toString() ?? '';
        this.formValues.ghgAmount.value = this.emission.ghgAmount.toString() ?? '';
        this.formValues.ghgAmountBiogenicShare.value = this.emission.ghgAmountBiogenicShare.toString() ?? '';
        this.formValues.emissionFactor.value = this.emissionFactor.emissionsFactor?.toString() ?? '';

        // this is needed because otherwise otherwise on closing and opening the lightbox, the old values
        // will still be visible
        this.formValues = { ...this.formValues };
    },
    methods: {
        ...mapMutations('detailedView', {
            setEmission: 'SET_EDITED_EMISSION',
        }),

        calculateGHGAmount(): void {
            if (this.emissionFactor.emissionsFactor === null || this.emissionFactor.emissionsFactor === undefined) {
                this.formValues.ghgAmount.value = '';
                this.formValues.ghgAmountBiogenicShare.value = '';
                this.formValues = { ...this.formValues };
                this.updateValues();
                return;
            }
            const { activityDataAmount } = this.formValues;
            if (
                activityDataAmount.value === null ||
                activityDataAmount.value === undefined ||
                activityDataAmount.value === ''
            ) {
                this.formValues.ghgAmount.value = '';
                this.formValues.ghgAmountBiogenicShare.value = '';
                this.formValues = { ...this.formValues };
                this.updateValues();
                return;
            }
            const conversion =
                (this.emission.ghgUnitOfMeasurement?.magnitude ?? 1) /
                (this.emissionFactor.ghgUnitOfMeasurement?.magnitude ?? 1);

            const calculation =
                (Number(this.formValues.activityDataAmount.value) * this.emissionFactor.emissionsFactor) / conversion;

            const roundingCorrection = roundToDecimalPlaces(calculation, MaxDecimalPlaces.GHG);

            this.formValues.ghgAmount.value = roundingCorrection.toString();

            if (
                this.emissionFactor.biogenicEmissionFactor === null ||
                this.emissionFactor.biogenicEmissionFactor === undefined
            ) {
                this.formValues.ghgAmountBiogenicShare.value = '';
            } else {
                const biogenicCalculation =
                    (Number(this.formValues.activityDataAmount.value) * this.emissionFactor.biogenicEmissionFactor) /
                    conversion;

                const biogenicRoundingCorrection = roundToDecimalPlaces(biogenicCalculation, MaxDecimalPlaces.GHG);

                this.formValues.ghgAmountBiogenicShare.value = biogenicRoundingCorrection.toString();
            }

            this.formValues = { ...this.formValues };
            this.updateValues();
        },

        handleActivityDataInput(newValue: string): void {
            this.formValues.activityDataAmount.value = newValue;
            this.formValues = { ...this.formValues };
            this.calculateGHGAmount();
        },

        updateValues(): void {
            this.setEmission({
                ...this.emission,
                activityDataAmount: Number(this.formValues.activityDataAmount.value),
                ghgAmount: Number(this.formValues.ghgAmount.value),
                ghgAmountBiogenicShare: Number(this.formValues.ghgAmountBiogenicShare.value),
            });
            this.$emit('valid', this.validFuelInput && this.validGhgAmount && this.validBiogenicGhgAmount);
        },

        toolTipMessage(key: EFKey): string {
            const input = this.formValues[EmissionInputs[key]].value;
            const value = Number(input);
            if (!checkDecimalPlaces(value, MaxDecimalPlaces[key])) {
                const message = this.$t('input.invalid.decimal') as string;
                return message.replace(`{{ number }}`, MaxDecimalPlaces[key].toString());
            }
            if (value >= MaxValue[key]) {
                const message = this.$t('input.invalid.tooLarge') as string;
                return message.replace(`{{ number }}`, MaxWholeNumbers[key].toString());
            }
            if (input.length > JAVASCRIPT_MAX_DIGITS) {
                return this.$t('input.warning.jsPrecision') as string;
            }
            return '';
        },

        onFuelAdjustment(): void {
            const newValue = this.useFuelAdjustment
                ? this.fuelAdjustedValue
                : this.emission.unadjustedActivityDataAmount;
            this.handleActivityDataInput(newValue.toString());
        },
    },
});

export default EmissionSummary;
