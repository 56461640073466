import { CountriesServiceProxy, CountriesListEndpointResponseOkResponse } from '@/service-proxies/service-proxies.g';

/**
 * @name CountryService
 * @description This class is used to query the backend server for country data
 */
export class CountryService {
    private countryServiceProxy: CountriesServiceProxy;

    constructor() {
        this.countryServiceProxy = new CountriesServiceProxy();
    }

    /**
     * Get filtered list of countries.
     * @param scopeId scope id
     * @param countryIds country ids to filter for.
     */
    public getCountries = async (
        scopeId?: number,
        countryIds?: number[],
    ): Promise<CountriesListEndpointResponseOkResponse> => {
        return await this.countryServiceProxy.listCountries(scopeId, countryIds);
    };
}
