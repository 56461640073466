import { EmissionFactorTableLevel } from '@/enums/TableLevel';
import {
    ActivityDataDto,
    EmissionFactorDto,
    EmissionFactorGroupDto,
    GreenhouseGasDto,
    UnitOfMeasurementDto,
} from '@/service-proxies/service-proxies.g';
import { formatActivityDataDisplayName } from '@/utils/helpers/formatters';
import store from '@/store';

function sortByName(a: any, b: any): number {
    return a.activity.toLocaleLowerCase().localeCompare(b.activity.toLocaleLowerCase());
}
function sortByDateThenName(a: any, b: any): number {
    const startSort = a.startDate.localeCompare(b.startDate);
    if (startSort !== 0) return startSort;
    const endSort = a.endDate.localeCompare(b.endDate);
    if (endSort !== 0) return endSort;
    return a.activity.toLocaleLowerCase().localeCompare(b.activity.toLocaleLowerCase());
}

interface StandardEFData {
    activity?: string;
    emissionFactorCO2?: number;
    emissionFactorCH4?: number;
    emissionFactorN2O?: number;
    meta: Meta;
}

interface Meta {
    level: EmissionFactorTableLevel;
    activityData?: ActivityDataDto;
    activityUOM?: UnitOfMeasurementDto;
    dataCO2?: EmissionFactorDto;
    dataCH4?: EmissionFactorDto;
    dataN2O?: EmissionFactorDto;
}

export function mapStandardEmissionFactors(emissionFactors: EmissionFactorGroupDto[]): StandardEFData[] {
    return emissionFactors
        .map((emissionFactorGroup) => ({
            activity: formatActivityDataDisplayName(emissionFactorGroup.activity),
            emissionFactorCO2: emissionFactorGroup.emissionFactorCO2?.emissionsFactor ?? 0,
            emissionFactorCH4: emissionFactorGroup.emissionFactorCH4?.emissionsFactor ?? 0,
            emissionFactorN2O: emissionFactorGroup.emissionFactorN2O?.emissionsFactor ?? 0,
            meta: {
                level: EmissionFactorTableLevel.UnitOfMeasurement,
                activityData: emissionFactorGroup.activity,
                activityUOM: emissionFactorGroup.unitOfMeasurement,
                dataCO2: emissionFactorGroup.emissionFactorCO2 ?? createRawFactor('CO2', emissionFactorGroup),
                dataCH4: emissionFactorGroup.emissionFactorCH4 ?? createRawFactor('CH4', emissionFactorGroup),
                dataN2O: emissionFactorGroup.emissionFactorN2O ?? createRawFactor('N2O', emissionFactorGroup),
            },
        }))
        .sort(sortByName);
}

export function createTableData(mappedFactors: StandardEFData[]) {
    const names = new Set(mappedFactors.map((x) => x.activity));
    const arrayNames = Array.from(names);
    return arrayNames.map((name) => {
        const relevantFactors = mappedFactors
            .filter((factor) => factor.activity === name)
            .map((factor) => {
                return { ...factor, activity: factor.meta.activityUOM!.name };
            })
            .sort(sortByName);
        return {
            activity: name,
            meta: { level: EmissionFactorTableLevel.Activity, activity: relevantFactors[0].meta.activityData },
            _children: relevantFactors,
        };
    });
}

export function rebuildTableData(oldTable: any[], mappedFactors: StandardEFData[]) {
    return oldTable.map((topLevelRow) => {
        const relevantFactors = mappedFactors
            .filter((factor) => factor.activity === topLevelRow.activity)
            .map((factor) => {
                return { ...factor, activity: factor.meta.activityUOM!.name };
            })
            .sort(sortByName);
        return {
            activity: topLevelRow.activity,
            meta: topLevelRow.meta,
            _children: relevantFactors,
        };
    });
}

function createRawFactor(ghg: string, factorGroup: EmissionFactorGroupDto): EmissionFactorDto {
    var ghgId: number;
    var ghgUomId: number;
    var uoms = store.getters['unitOfMeasurement/getAllUOMs'] as UnitOfMeasurementDto[];
    switch (ghg) {
        case 'CO2':
            ghgId = 1;
            ghgUomId = 2;
            break;
        case 'CH4':
            ghgId = 3;
            ghgUomId = 20;
            break;
        case 'N2O':
            ghgId = 2;
            ghgUomId = 20;
            break;
        default:
            throw new Error('Unknown uom name');
    }
    const existingFactor =
        factorGroup.emissionFactorCO2 ?? factorGroup.emissionFactorCH4 ?? factorGroup.emissionFactorN2O;
    if (!existingFactor) {
        throw new Error('factorGroup does not contain factor');
    }
    return new EmissionFactorDto({
        id: 0,
        type: 'GLOBAL',
        emissionsFactor: 0,
        greenhouseGas: new GreenhouseGasDto({ id: ghgId, chemicalFormula: ghg }),
        ghgUnitOfMeasurement: uoms.find((uom) => uom.id === ghgUomId),
        activityData: factorGroup.activity,
        activityDataUnitOfMeasurement: factorGroup.unitOfMeasurement,
        source: existingFactor?.source,
        linkToPublication: existingFactor?.linkToPublication,
        additionalInformation: existingFactor?.additionalInformation,
        publicationDate: existingFactor?.publicationDate,
        startDate: existingFactor?.startDate,
        endDate: '2100-12-31',
    });
}
