import { CountryService } from '@/services/country-service';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ApplicationState } from '..';
import { CountryDto } from '@/service-proxies/service-proxies.g';
import axios, { AxiosError } from 'axios';
import { HttpStatusCodes } from '@/enums/http-status-codes';

const countryService: CountryService = new CountryService();

export interface CountryState {
    countries: CountryDto[];
    selectedCountryId: number | undefined;
}

const state: CountryState = {
    countries: [],
    selectedCountryId: undefined,
};

const getters: GetterTree<CountryState, ApplicationState> = {
    GET_COUNTRIES: (state): CountryDto[] => {
        return state.countries;
    },
    GET_SELECTED_COUNTRY: (state): CountryDto | undefined => {
        return state.countries.find((country) => country.id == state.selectedCountryId);
    },
};

const mutations: MutationTree<CountryState> = {
    SET_COUNTRIES(state: CountryState, data: CountryDto[]) {
        state.countries = data;
    },
    SET_SELECTED_COUNTRY_ID(state: CountryState, data: number) {
        state.selectedCountryId = data;
    },
};

const actions: ActionTree<CountryState, ApplicationState> = {
    async GET_COUNTRIES(
        { commit }: ActionContext<CountryState, ApplicationState>,
        scopeId?: number,
    ): Promise<HttpStatusCodes> {
        try {
            const result = await countryService.getCountries(scopeId);
            commit('SET_COUNTRIES', result.result);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return (e as AxiosError).response?.status ?? HttpStatusCodes.InternalServerError;
            } else {
                console.error(`An unknown error occurred while fetching the country data.`);
                console.error(e);
                return HttpStatusCodes.InternalServerError;
            }
        }
        return HttpStatusCodes.Ok;
    },
};

const namespaced = true;

export const country: Module<CountryState, ApplicationState> = {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};
