import { PowerPlant, Unit } from '@/models';
import { cloneDeep } from 'lodash';
import { EmissionDto, PowerPlantDto, UnitDto } from '@/service-proxies/service-proxies.g';
import { UnitRisks } from '@/models/unit';
import { PowerPlantRisks } from '@/models/power-plant';

export class PowerPlantNormalizer {
    private sortEmissionsByFuelNameDesc(emissions: EmissionDto[]): EmissionDto[] {
        return cloneDeep(emissions).sort((a, b) => {
            const nameA = a.activityData?.name?.toUpperCase() ?? '';
            const nameB = b.activityData?.name?.toUpperCase() ?? '';
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });
    }

    preparePowerPlant(powerPlantGroupId: number, powerPlant: PowerPlantDto): PowerPlant {
        return {
            powerPlantGroupId: powerPlantGroupId,
            id: powerPlant.sid ?? 0,
            name: powerPlant.name ?? '',
            unitsIds: powerPlant.units?.map((unit) => unit.sid ?? 0) ?? [],
            emissionIds: this.sortEmissionsByFuelNameDesc(powerPlant.emissions?.filter((e) => e.id !== 0) ?? []).map(
                (emission) => emission.id ?? 0,
            ),
        };
    }

    prepareUnit(unit: UnitDto): Unit {
        return {
            id: unit.sid ?? 0,
            name: unit.name ?? '',
            emissionIds: this.sortEmissionsByFuelNameDesc(unit.emissions?.filter((e) => e.id !== 0) ?? []).map(
                (emission) => emission.id ?? 0,
            ),
        };
    }

    prepareRawPowerPlant(powerPlant: PowerPlantDto): PowerPlantRisks {
        const sid = powerPlant.sid ?? 0;
        return {
            sid: sid,
            name: powerPlant.name ?? '',
            units:
                powerPlant.units
                    ?.map((unit) => this.prepareRawUnit(unit, sid))
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .filter((unit, index, array) => !index || unit.sid !== array[index - 1].sid) ?? [],
        };
    }

    prepareRawUnit(unit: UnitDto, plantSid: number): UnitRisks {
        return {
            sid: unit.sid ?? 0,
            plantSid: plantSid,
            name: unit.name ?? '',
        };
    }
}
