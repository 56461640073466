
import {
    ActivityDataDto,
    EmissionFactorDto,
    EmissionFactorGroupDto,
    GreenhouseGasDto,
    UnitOfMeasurementDto,
} from '@/service-proxies/service-proxies.g';
import Vue, { nextTick } from 'vue';
import { formatActivityDataDisplayName, formatToDashedYYYYMMDD, getNextMonthDate } from '@/utils/helpers/formatters';
import DatePicker from '@/components/date-picker/granularity-date/date-picker.vue';
import { mapActions } from 'vuex';

interface ModalActions {
    open: () => void;
    close: () => void;
}

const AddUomModal = Vue.extend({
    name: 'AddUomModal',
    components: {
        DatePicker,
    },
    data(): {
        activity: ActivityDataDto | null;
        existingChildren: any[];
        selectedUoM: number | null;
        source: string;
        linkToSource: string;
        startDate: string;
    } {
        return {
            activity: null,
            existingChildren: [],
            selectedUoM: null,
            source: '',
            linkToSource: '',
            startDate: '',
        };
    },
    computed: {
        modalRef(): ModalActions {
            return this.$refs.lightbox as unknown as ModalActions;
        },
        headerLabel(): string {
            return this.$t('standardEF.modal.AddUOM').toString();
        },
        activityName(): string {
            if (!this.activity) {
                return '';
            }
            return formatActivityDataDisplayName(this.activity);
        },
        uomOptions(): any[] {
            if (!this.activity?.unitsOfMeasurement) {
                return [];
            }
            return this.activity?.unitsOfMeasurement
                .filter((uom) => !this.existingChildren.some((row) => row.meta.activityUOM.id == uom.id))
                .map((entry: UnitOfMeasurementDto) => {
                    return {
                        label: entry.shortName,
                        value: entry.id,
                    };
                });
        },
        uomDto(): UnitOfMeasurementDto | undefined {
            return this.activity?.unitsOfMeasurement?.find((uom) => uom.id == this.selectedUoM);
        },
    },
    methods: {
        ...mapActions('tables', { updateTracker: 'UPDATE_STANDARDEF_TRACKER' }),
        ...mapActions('standardEmissionFactor', { addFactorGroup: 'ADD_FACTOR_GROUP' }),
        open(data: Record<string, any>): void {
            this.modalRef.open();
            this.activity = data.activity;
            this.existingChildren = data.children;
            this.startDate = getNextMonthDate();
            this.source = '';
            this.linkToSource = '';
        },
        selectUoM(id: number): void {
            this.selectedUoM = id;
        },
        selectStartDate(newDate: string): void {
            this.startDate = newDate;
        },
        changeSource(value: string): void {
            this.source = value;
        },
        changeLink(value: string): void {
            this.linkToSource = value;
        },
        close(): void {
            this.selectedUoM = null;
            this.modalRef.close();
        },
        confirm(): void {
            const newFactorGroup = new EmissionFactorGroupDto({
                activity: this.activity ?? undefined,
                unitOfMeasurement: this.uomDto,
                emissionFactorCO2: this.createRawFactor('CO2'),
                emissionFactorCH4: this.createRawFactor('CH4'),
                emissionFactorN2O: this.createRawFactor('N2O'),
            });
            this.addFactorGroup({ factorGroup: newFactorGroup });
            this.updateTracker({ [this.activityName]: true });
            this.close();
        },
        createRawFactor(ghg: string): EmissionFactorDto {
            var ghgId: number;
            var ghgUomId: number;
            var uoms = this.$store.getters['unitOfMeasurement/getAllUOMs'] as UnitOfMeasurementDto[];
            switch (ghg) {
                case 'CO2':
                    ghgId = 1;
                    ghgUomId = 2;
                    break;
                case 'CH4':
                    ghgId = 3;
                    ghgUomId = 20;
                    break;
                case 'N2O':
                    ghgId = 2;
                    ghgUomId = 20;
                    break;
                default:
                    throw new Error('Unknown uom name');
            }
            return new EmissionFactorDto({
                id: 0,
                type: 'GLOBAL',
                emissionsFactor: 0,
                greenhouseGas: new GreenhouseGasDto({ id: ghgId, chemicalFormula: ghg }),
                ghgUnitOfMeasurement: uoms.find((uom) => uom.id === ghgUomId),
                activityData: this.activity ?? undefined,
                activityDataUnitOfMeasurement: this.uomDto,
                source: this.source,
                linkToPublication: this.linkToSource,
                startDate: this.startDate,
                endDate: '2100-12-31',
            });
        },
    },
});

export default AddUomModal;
