import { render, staticRenderFns } from "./unit.vue?vue&type=template&id=3503a488&scoped=true"
import script from "./unit.vue?vue&type=script&lang=ts"
export * from "./unit.vue?vue&type=script&lang=ts"
import style0 from "./unit.vue?vue&type=style&index=0&id=3503a488&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3503a488",
  null
  
)

export default component.exports