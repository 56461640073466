
import CopyText from '@/components/ui/text/copy-text.vue';
import Vue from 'vue';

export default Vue.extend({
    name: 'Urls',
    components: {
        CopyText,
    },
    data(): { apiUrl: string | undefined; swaggerUrl: string | undefined } {
        return { apiUrl: process.env.VUE_APP_API_BASE_URL, swaggerUrl: process.env.VUE_APP_API_SWAGGER_URL };
    },
});
