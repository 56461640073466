
import Vue from 'vue';
import UnitOfMeasurement from '@/components/unit-of-measurement/unit-of-measurement.vue';
import FloatInput from '@/components/inputs/float-input.vue';
import { EmissionFactorType } from '@/enums/emission-factor-type';
import { mapGetters, mapMutations } from 'vuex';
import { DEFAULT_SCOPE_ID } from '@/constants/default';

const SelectEmissionFactor = Vue.extend({
    name: 'SelectEmissionFactor',
    components: {
        UnitOfMeasurement,
        FloatInput,
    },
    data(): {
        EmissionFactorType: Record<string, any>;
    } {
        return {
            EmissionFactorType,
        };
    },
    computed: {
        ...mapGetters('scope', {
            scope: 'GET_SELECTED_SCOPE',
        }),
        ...mapGetters('detailedView', {
            emission: 'GET_EDITED_EMISSION',
            getCurrentEmissionFactor: 'GET_CURRENT_EMISSION_FACTOR',
            getStandardEmissionFactor: 'GET_STANDARD_EMISSION_FACTOR',
            getImportedIndividualEmissionFactor: 'GET_IMPORTED_INDIVIDUAL_EMISSION_FACTOR',
            getEditedIndividualEmissionFactor: 'GET_EDITED_INDIVIDUAL_EMISSION_FACTOR',
            getIndividualHasBeenChanged: 'GET_INDIVIDUAL_HAS_BEEN_CHANGED',
            getTypeOfEmissionFactor: 'GET_TYPE_OF_EMISSION_FACTOR',
        }),
        disableStandardEmission(): boolean {
            return (
                this.getStandardEmissionFactor == undefined ||
                this.getStandardEmissionFactor.emissionsFactor == undefined ||
                this.stringStandardFactor === ''
            );
        },
        stringIndividualFactor(): string {
            if (!this.getEditedIndividualEmissionFactor) return '';
            const factor = this.getEditedIndividualEmissionFactor.emissionsFactor;
            if (factor === null || factor === undefined) {
                return '';
            }
            return factor.toString();
        },
        stringStandardFactor(): string {
            if (!this.getStandardEmissionFactor) return '';
            const factor = this.getStandardEmissionFactor.emissionsFactor;
            return factor ? factor.toString() : '';
        },
        individualUOM(): string {
            if (!this.getEditedIndividualEmissionFactor) return '';
            let numerator =
                this.getEditedIndividualEmissionFactor?.ghgUnitOfMeasurement?.shortName ??
                this.emission.ghgUnitOfMeasurement?.shortName;
            if (this.scope.id === DEFAULT_SCOPE_ID) {
                numerator += ' CO2';
            }
            const denominator =
                this.getEditedIndividualEmissionFactor?.activityDataUnitOfMeasurement?.shortName ??
                this.emission.activityDataUnitOfMeasurement?.shortName;
            return `${numerator ?? ''} / ${denominator ?? ''}`;
        },
        standardUOM(): string {
            if (!this.getStandardEmissionFactor || !this.getStandardEmissionFactor.emissionsFactor) return '';
            let numerator = this.getStandardEmissionFactor?.ghgUnitOfMeasurement?.shortName ?? '';
            if (this.scope.id === DEFAULT_SCOPE_ID) {
                numerator += ' CO2';
            }
            const denominator =
                this.getStandardEmissionFactor?.activityDataUnitOfMeasurement?.shortName ??
                this.emission.activityDataUnitOfMeasurement?.shortName;
            return `${numerator} / ${denominator ?? ''}`;
        },
        IEFMessage(): string {
            // If user alters individual emssion factor inputs, they are creating
            // a new factor, so no need to display when the old was created
            if (this.getIndividualHasBeenChanged === true) {
                return '';
            }
            //Don't show message if no individual emission factors are found
            if (this.getImportedIndividualEmissionFactor.id === 0) {
                return '';
            }
            if (!this.getImportedIndividualEmissionFactor.archiveData) {
                return '';
            }
            const date = new Date(this.getImportedIndividualEmissionFactor.archiveData.createDate);
            const dateCreated = date.toLocaleString();
            return `${this.$t('detailedView.firstCreated')}: ${dateCreated}`;
        },
        IEFSourceMessage(): string {
            if (this.getIndividualHasBeenChanged === true) {
                return `${this.$t('detailedView.newlyCreated')}`;
            }
            if (this.getImportedIndividualEmissionFactor.id === 0) {
                return 'N/A';
            }
            return this.getImportedIndividualEmissionFactor.source ?? 'N/A';
        },
    },
    watch: {
        getTypeOfEmissionFactor() {
            const checkedRadio = document.querySelector(
                `input[value=${this.getTypeOfEmissionFactor}]`,
            ) as HTMLInputElement;
            if (checkedRadio) {
                checkedRadio.checked = true;
            }
        },
    },
    mounted() {
        let typeOfFactor;
        if (this.getCurrentEmissionFactor.type) {
            typeOfFactor = this.getCurrentEmissionFactor.type;
        } else {
            // this allows the user to immediately start creating an individual factor
            typeOfFactor = EmissionFactorType.Individual;
        }
        this.setTypeOfEmissionFactor(typeOfFactor);
        const checkedRadio = document.querySelector(`input[value=${typeOfFactor}]`) as HTMLInputElement;
        if (checkedRadio) {
            checkedRadio.checked = true;
        }
    },
    methods: {
        ...mapMutations('detailedView', {
            setTypeOfEmissionFactor: 'SET_TYPE_OF_EMISSION_FACTOR',
        }),
        selectTypeOfEmission(newValue: EmissionFactorType | null): void {
            this.setTypeOfEmissionFactor(newValue);
        },
    },
});

export default SelectEmissionFactor;
