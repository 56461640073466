import { HttpStatusCodes } from '@/enums/http-status-codes';
import { UOMService } from '@/services/uom-service';
import axios, { AxiosError } from 'axios';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ApplicationState } from '..';
import { UnitOfMeasurementDto } from '@/service-proxies/service-proxies.g';

export interface UOMState {
    service: UOMService;
    allUOMs: UnitOfMeasurementDto[];
    energyUnits: UnitOfMeasurementDto[];
}

const state: UOMState = {
    service: new UOMService(),
    allUOMs: [],
    energyUnits: [],
};

const getters: GetterTree<UOMState, ApplicationState> = {
    getAllUOMs: (state) => state.allUOMs,
    getEnergyUnits: (state) => state.energyUnits,
};

const mutations: MutationTree<UOMState> = {
    setAllUOMs: (state, payload) => (state.allUOMs = payload),
    setEnergyUnits: (state) => {
        const energyUnits = state.allUOMs.filter((unit) => unit.baseUnitId === 13); //Unit for MegaJoule (MJ)
        // We currently only want Joule Units, we don't want Watt type units
        const removedWattsArray = energyUnits.filter((unit) => unit.shortName?.includes('J'));
        // We have no use for just Joules so filter that too
        const filteredUnits = removedWattsArray.filter((unit) => unit.shortName !== 'J');
        state.energyUnits = filteredUnits;
    },
};

const actions: ActionTree<UOMState, ApplicationState> = {
    async FETCH_UOMS({ commit, state }: ActionContext<UOMState, ApplicationState>): Promise<HttpStatusCodes> {
        try {
            const response = await state.service.getAllUOMs();
            const result = response.result;
            commit('setAllUOMs', result);
            commit('setEnergyUnits');
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return (e as AxiosError).response?.status ?? HttpStatusCodes.InternalServerError;
            } else {
                console.error(`An unknown error occurred while fetching the unit of measurements.`);
                console.error(e);
                return HttpStatusCodes.InternalServerError;
            }
        }
        return HttpStatusCodes.Ok;
    },
};

const namespaced = true;

export const unitOfMeasurement: Module<UOMState, ApplicationState> = {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};
