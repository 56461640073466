
import { LocationTableData } from '@/models/table';
import { EventBus } from '@/utils';
import { defineComponent, PropType } from 'vue';
import { functionalPermissions } from '@/utils';
import StatusCell from './status-cell.vue';
import UnlockIcon from '@/components/ui/icons/unlock.vue';
import LockIcon from '@/components/ui/icons/lock.vue';
import { PowerPlantDto, UnitDto } from '@/service-proxies/service-proxies.g';

interface Data {
    canOverwrite: boolean;
    unit: UnitDto | null;
    powerPlant: PowerPlantDto | null;
}

export default defineComponent({
    name: 'CompletenessCell',
    components: {
        StatusCell,
        LockIcon,
        UnlockIcon,
    },
    props: {
        location: {
            type: Object as PropType<LocationTableData>,
            required: true,
        },
        parentData: {
            type: Object as PropType<LocationTableData>,
            default: undefined,
        },
    },
    data(): Data {
        return {
            canOverwrite: functionalPermissions.userCanWithdrawApproval(),
            unit: null,
            powerPlant: null,
        };
    },
    computed: {
        title(): string {
            return this.$t('label.withdrawComplete') as string;
        },
    },
    mounted() {
        if (this.parentData) {
            this.powerPlant = this.parentData.meta?.object as PowerPlantDto;
            this.unit = this.location.meta?.object as UnitDto;
        } else {
            this.powerPlant = this.location.meta?.object as PowerPlantDto;
        }
    },
    methods: {
        overwriteCompleteness(): void {
            EventBus.$emit(EventBus.VIEWS.MAIN.OPEN_WITHDRAW_COMPLETENESS, {
                unit: this.unit,
                powerPlant: this.powerPlant,
            });
        },
    },
});
