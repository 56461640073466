
import Vue from 'vue';
import PageHeader from '@/components/page-headers/header-with-country-and-allocation-period.vue';
import { PowerPlantRisks } from '@/models/power-plant';
import RiskClassificationTable from './risk-classification/risk-classification-table.vue';
import BoxSkeleton from '@/components/ui/loading/skeletons/box-skeleton.vue';
import { HttpStatusCodes } from '@/enums/http-status-codes';
import { CountryDto } from '@/service-proxies/service-proxies.g';

interface Data {
    loadingClassificators: boolean;
}

const CertificateRisk = Vue.extend({
    name: 'CertificateRisk',
    components: {
        BoxSkeleton,
        PageHeader,
        RiskClassificationTable,
    },
    data(): Data {
        return {
            loadingClassificators: true,
        };
    },
    computed: {
        classificatorData(): PowerPlantRisks[] {
            return this.$store.getters['classificatorData/GET_CLASSIFICATOR_DATA'];
        },

        countryId(): number {
            if (this.$store.getters['country/GET_SELECTED_COUNTRY']) {
                return (this.$store.getters['country/GET_SELECTED_COUNTRY'] as CountryDto).id ?? -1;
            }
            return -1;
        },
    },
    watch: {
        async countryId(): Promise<void> {
            await this.loadClassificatorData();
        },
    },
    async created(): Promise<void> {
        const loadedCountry = this.$store.getters['classificatorData/GET_CLASSIFICATOR_STORE_COUNTRY'];
        if (this.countryId > -1 && this.countryId != loadedCountry) {
            await this.loadClassificatorData();
        } else if (this.countryId > -1) {
            this.loadingClassificators = false;
        }
    },
    methods: {
        async loadClassificatorData(): Promise<void> {
            this.loadingClassificators = true;
            const response = await this.$store.dispatch('classificatorData/GET_CLASSIFICATOR_DATA');
            if ((response as HttpStatusCodes) !== HttpStatusCodes.Ok) {
                (this as any).$pui.toast({
                    type: 'error',
                    title: this.$t('error.riskClassification.load.title'),
                    copy: this.$t('error.riskClassification.load.text'),
                });
            }
            this.loadingClassificators = false;
        },
        async setStartDate(newDate: string): Promise<void> {
            this.$store.commit('classificatorData/SET_START_DATE', newDate);
            await this.loadClassificatorData();
        },

        loaderError(): { title: string; message: string } {
            let message = '';
            if (this.countryId === -1) {
                message = this.$t('emissionQuota.noData.selectCountry') as string;
            } else if (this.classificatorData.length === 0) {
                message = this.$t('emissionQuota.noData.noEmissionQuota') as string;
            }

            return {
                title: this.$t('emissionQuota.noData.title') as string,
                message,
            };
        },
    },
});

export default CertificateRisk;
