import {
    UnitOfMeasurementEmissionFactorServiceProxy,
    UnitOfMeasurementsServiceProxy,
} from '@/service-proxies/service-proxies.g';

import type {
    UnitOfMeasurementEmissionFactorGetEndpointResponseOkResponse,
    UnitOfMeasurementsGetEndpointRequest,
    UnitOfMeasurementsGetEndpointResponseOkResponse,
} from '@/service-proxies/service-proxies.g';

/**
 * @name UOMService
 * @description This class is used to query the API for all UOMs with
 * corresponding emission factors for a given activity data ID
 */

export class UOMService {
    private uomServiceProxy: UnitOfMeasurementsServiceProxy;
    private uomEmissionFactorServiceProxy: UnitOfMeasurementEmissionFactorServiceProxy;

    constructor() {
        this.uomServiceProxy = new UnitOfMeasurementsServiceProxy();
        this.uomEmissionFactorServiceProxy = new UnitOfMeasurementEmissionFactorServiceProxy();
    }

    public async getAllUOMs(
        request?: UnitOfMeasurementsGetEndpointRequest,
    ): Promise<UnitOfMeasurementsGetEndpointResponseOkResponse> {
        return await this.uomServiceProxy.getUnitOfMeasurements(request);
    }

    public async getUOMEFs(
        activityDataId: number,
        locationId: number,
        scopeId: number,
        startDate: string,
    ): Promise<UnitOfMeasurementEmissionFactorGetEndpointResponseOkResponse> {
        //return (await this.api.get<ApiResponse<UOMEF[]>>(`${this.endpoint}?ActivityDataId=${id}`)).data;
        return await this.uomEmissionFactorServiceProxy.getUnitOfMeasurementEmissionFactor(
            activityDataId,
            locationId,
            scopeId,
            startDate,
        );
    }
}
