import BaseService, { CoreServiceType } from '@/utils/base-service';
import { ApiResponse, Application, ApiResponseList } from '@/models';

/**
 * @name AppService
 * @description This class is used to query the identity server for user related data,
 * which is not related to authentication.
 */
export class AppService extends BaseService {
    constructor() {
        super('use-cases', CoreServiceType.MetaData);
    }

    public async getApps(): Promise<ApiResponse<ApiResponseList<Application>>> {
        return (await this.api.get<ApiResponse<ApiResponseList<Application>>>(`${this.endpoint}`)).data;
    }
}
