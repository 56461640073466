
import { EventBus } from '@/utils';
import Vue from 'vue';

export default Vue.extend({
    name: 'SelectBox',

    props: {
        activity: {
            type: Object,
            required: true,
        },
        resetRow: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            bulkApprove: false,
        };
    },

    computed: {
        showApprove(): boolean {
            return (
                this.activity.meta.dataCH4.id !== 0 &&
                this.activity.meta.dataCO2.id !== 0 &&
                this.activity.meta.dataN2O.id !== 0
            );
        },
    },

    methods: {
        emitOnEventBus(event: string, payload: object = {}): void {
            const { activity } = this;
            EventBus.$emit(event, { activity, ...payload });
        },

        onBulkApprovalCheck(checked: boolean): void {
            this.bulkApprove = checked;
            this.emitOnEventBus(EventBus.VIEWS.STANDARD_EMISSION.CHANGE_BULK_ASSIGNMENT, { checked: this.bulkApprove });
        },
    },
});
