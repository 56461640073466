
import Vue from 'vue';

export default Vue.extend({
    name: 'LockIcon',

    props: {
        height: {
            type: [String, Number],
            required: false,
            default: '24',
        },
    },
});
