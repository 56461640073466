import { render, staticRenderFns } from "./urls.vue?vue&type=template&id=b39da344&scoped=true&class=urls"
import script from "./urls.vue?vue&type=script&lang=ts"
export * from "./urls.vue?vue&type=script&lang=ts"
import style0 from "./urls.vue?vue&type=style&index=0&id=b39da344&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b39da344",
  null
  
)

export default component.exports