
import Vue from 'vue';

const Loader = Vue.extend({
    computed: {
        isLoading(): boolean {
            return this.$store.getters.isLoading;
        },
    },
});

export default Loader;
