
import Vue from 'vue';
import ActivitySelectModal from './activity-select-modal.vue';
import EmissionModal from './emission-modal/index.vue';
import WithdrawCompleteModal from './withdraw-complete-modal.vue';
import WithdrawApprovalModal from './withdraw-approval-modal.vue';
import OverwriteApprovalModal from './overwrite-approval-modal.vue';
import DeleteEmissionModal from './delete-emission-modal.vue';

export default Vue.extend({
    name: 'DataInputModals',
    components: {
        ActivitySelectModal,
        EmissionModal,
        WithdrawCompleteModal,
        WithdrawApprovalModal,
        OverwriteApprovalModal,
        DeleteEmissionModal,
    },
});
