
import Vue from 'vue';
import GranularityDate from '@/components/granularity/granularity-date/granularity-date.vue';

const GranularitySection = Vue.extend({
    name: 'GranularitySection',
    components: { GranularityDate },
});

export default GranularitySection;
