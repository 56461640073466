import AddEmissionCell from '@/components/tables/emission-table/custom-cells/add-emission-cell.vue';
import ApprovalActions from '@/components/tables/emission-table/custom-cells/approval-actions.vue';
import ApprovalCompleteness from '@/components/tables/emission-table/custom-cells/approval-completeness.vue';
import EmissionsActions from '@/components/tables/emission-table/custom-cells/emission-actions.vue';
import InputCell from '@/components/tables/emission-table/custom-cells/input-cell.vue';
import StatusCell from '@/components/tables/emission-table/custom-cells/status-cell.vue';
import CompletenessCell from '@/components/tables/emission-table/custom-cells/complete-group.vue';
import { TableLevel } from '@/enums/TableLevel';
import { LocationTableData } from '@/models/table';
import { EmissionWithParkedEmissionDto } from '@/service-proxies/service-proxies.g';
import { CellComponent, FormatterParams, RowComponent } from 'tabulator-tables';
import Vue from 'vue';
import { checkDecimalPlaces } from '@/utils/decimal-places';
import { resetEmissionRow } from '@/utils/helpers/table/table-helper';
import { getTranslationManager } from '@/utils/translation-plugin/translation-manager';
import { MaxDecimalPlaces, MaxValue, MaxWholeNumbers } from '@/constants/input-precision';
import { floatInputEditor } from './table-float-editor';

const injectedComponents: any[] = [];

// Tabulator has no native way to render Vue-Components into the cells, therefore we compile the component
// ourselves and inject the native element into tabulator as part of a formatter since only plain HTML/JS is allowed.
export const tabulatorVueComponentInjector = (component: any, props: any): any => {
    const ComponentClass = Vue.extend(component);
    const instance = new ComponentClass({
        i18n: getTranslationManager().vueI18n,
        propsData: {
            ...props,
        },
    });

    injectedComponents.push(instance);
    instance.$mount();

    return instance.$el;
};

export const tabulatorTranslationInjector = (key: string): string => {
    const instance = new Vue({
        i18n: getTranslationManager().vueI18n,
    });

    injectedComponents.push(instance);

    return instance.$t(key) as string;
};

// TODO: find a better way to store and handle destroying of injected components.
// Possible to have the table component handle their own injected components?
export const destroyInjectedComponents = (): void => {
    for (let i = injectedComponents.length - 1; i >= 0; i--) {
        injectedComponents[i].$destroy();
        injectedComponents.splice(i, 1);
    }
};

interface UOMFormatter {
    activityDataUnitOfMeasurement: string | undefined;
    greenHouseGasUnitOfMeasurement: string | undefined;
}
export const globalRowFormatter = (row: RowComponent): void => {
    const location = row.getData() as LocationTableData;

    row.getElement().classList.add('emission-table-row');

    if (location.meta.level === TableLevel.Plant) {
        row.getElement().classList.add('emission-table-row__plant');
    } else if (location.meta.level === TableLevel.Unit) {
        row.getElement().classList.add('emission-table-row__unit');
    }
};

function createTooltip(inputs: string[]): HTMLDivElement {
    const el = document.createElement('div');
    //styling of pui-tooltip
    el.style.backgroundColor = 'rgba(0,66,108,0.88)';
    el.style.color = '#fff';
    el.style.padding = '1.2rem';
    el.style.borderRadius = '4px';
    el.style.boxShadow = '0 1px 8px rgba(0,0,0,0.16)';
    el.style.display = 'flex';
    el.style.flexDirection = 'column';
    el.style.gap = '0.25rem';

    for (const input of inputs) {
        const span = document.createElement('span');
        span.innerText = input;
        el.appendChild(span);
    }

    return el;
}

function getParentData(cell: CellComponent) {
    const firstParent = cell.getRow().getTreeParent();
    let response = { unitData: {} as LocationTableData, powerPlantData: {} as LocationTableData };
    if (firstParent) {
        const secondParent = firstParent.getTreeParent();
        const unitData = secondParent ? firstParent.getData() : {};
        const powerPlantData = secondParent ? secondParent.getData() : firstParent.getData();
        response = { unitData, powerPlantData };
    }
    return response;
}

export enum TableType {
    DATA_ENTRY,
    APPROVAL,
}

interface CommonTableColumnOptions {
    editableActivityData?: boolean;
    widths?: string[];
}

const commonTableColumns = (options: CommonTableColumnOptions = {}): Array<{ [key: string]: any }> => [
    {
        title: 'Location / Activity Data',
        field: 'location',
        width: options.widths?.[0] ?? '22%',
        minWidth: 250,
        formatter: (cell: CellComponent, _formatterParams: FormatterParams, _onRendered: any): any => {
            let value = cell.getValue();
            const data = cell.getData() as LocationTableData;
            value += data.meta.holdsEmissions ? ` (${data._children!.length})` : '';
            return value;
        },
    },
    {
        title: 'Approval Status',
        field: 'status',
        width: options.widths?.[1] ?? '10%',
        minWidth: 150,
        hozAlign: 'center',
        formatter: (cell: CellComponent, _formatterParams: FormatterParams, _onRendered: any): any => {
            const cellData = cell.getData() as LocationTableData;
            if (cellData.meta.level === TableLevel.Emission) {
                const emission = cellData.meta.object as EmissionWithParkedEmissionDto;
                return tabulatorVueComponentInjector(StatusCell, {
                    status: cellData.status?.toLowerCase(),
                    hasParked: !!emission.parkedEmission,
                });
            }
        },
    },
    {
        title: 'Fuel Consumption',
        field: 'activityDataAmount',
        width: options.widths?.[2] ?? '15%',
        minWidth: 150,
        tooltip: function (_e: Event, cell: CellComponent, _onRendered: any): any {
            const data = cell.getData() as LocationTableData;
            const emission = data.meta.object as EmissionWithParkedEmissionDto;
            if (!data.activityDataAmount) return;
            if (!checkDecimalPlaces(data.activityDataAmount, MaxDecimalPlaces.FUEL_CONSUMPTION)) {
                const phrase = tabulatorTranslationInjector('input.invalid.decimal');
                const message = phrase.replace(`{{ number }}`, MaxDecimalPlaces.FUEL_CONSUMPTION.toString());
                return createTooltip([message]);
            }
            if (data.activityDataAmount >= MaxValue.FUEL_CONSUMPTION) {
                const phrase = tabulatorTranslationInjector('input.invalid.tooLarge');
                const message = phrase.replace(`{{ number }}`, MaxWholeNumbers.FUEL_CONSUMPTION.toString());
                return createTooltip([message]);
            }

            const sourceSpan = `${tabulatorTranslationInjector('meta.source')}: ${emission.source}`;

            return createTooltip([sourceSpan]);
        },
        ...(options.editableActivityData && {
            editable: (cell: CellComponent): any => {
                const data = cell.getData() as LocationTableData;
                const { unitData, powerPlantData } = getParentData(cell);
                const groupIsComplete = !!unitData?.meta?.isComplete || !!powerPlantData?.meta?.isComplete;
                return data.meta.level === TableLevel.Emission && data.status === 'Preliminary' && !groupIsComplete;
            },
            editor: floatInputEditor,
        }),
        editorParams: {
            step: 'any',
            selectContents: true,
        },
        formatter: (cell: CellComponent, _formatterParams: FormatterParams, _onRendered: any): any => {
            const data = cell.getData() as LocationTableData;
            const value = cell.getValue();

            if (data.meta.level !== TableLevel.Emission) return value;

            const uoms = data.meta.formatter as UOMFormatter;
            const displayedUom = uoms.activityDataUnitOfMeasurement?.includes('kWh')
                ? 'kWh'
                : uoms.activityDataUnitOfMeasurement;
            const { unitData, powerPlantData } = getParentData(cell);
            const groupIsComplete = !!unitData?.meta?.isComplete || !!powerPlantData?.meta?.isComplete;
            const editable = data.status === 'Preliminary' && !groupIsComplete;
            return tabulatorVueComponentInjector(InputCell, {
                data: data.activityDataAmount,
                name: 'activityDataAmount',
                uom: displayedUom ?? '',
                readonly: !((options.editableActivityData && editable) ?? false),
            });
        },
    },
    {
        title: 'Emission Factor',
        field: 'emissionFactor',
        width: options.widths?.[3] ?? '19%',
        minWidth: 150,
        tooltip: function (_e: Event, cell: CellComponent, _onRendered: any): any {
            const data = cell.getData() as LocationTableData;
            const emission = data.meta.object as EmissionWithParkedEmissionDto;
            if (!data.emissionFactor) return;

            const sourceSpan = `${tabulatorTranslationInjector('meta.source')}: ${emission.emissionFactor?.source}`;
            const typeSpan = `${tabulatorTranslationInjector('meta.type')}: ${emission.emissionDataQuality?.efType}`;

            return createTooltip([sourceSpan, typeSpan]);
        },
        formatter: (cell: CellComponent, _formatterParams: FormatterParams, _onRendered: any): any => {
            const data = cell.getData() as LocationTableData;
            const value = cell.getValue();

            if (data.meta.level !== TableLevel.Emission) return value;

            const emission = data.meta.object as EmissionWithParkedEmissionDto;
            const numerator =
                emission.emissionFactor?.ghgUnitOfMeasurement?.shortName ?? emission.ghgUnitOfMeasurement?.shortName;
            const denominator =
                emission.emissionFactor?.activityDataUnitOfMeasurement?.shortName ??
                emission.activityDataUnitOfMeasurement?.shortName;
            const tooltip = emission.emissionFactor?.type;
            const displayedUom = `${numerator ?? ''} / ${denominator ?? ''}`;
            return tabulatorVueComponentInjector(InputCell, {
                data: data.emissionFactor,
                name: 'emissionFactor',
                uom: displayedUom,
                readonly: true,
                tooltip: tooltip,
            });
        },
    },
    {
        title: 'Amount GHG CO2',
        field: 'ghgAmount',
        width: options.widths?.[4] ?? '19%',
        minWidth: 150,
        tooltip: function (_e: Event, cell: CellComponent, _onRendered: any): any {
            const data = cell.getData() as LocationTableData;
            if (!data.ghgAmount) return;
            if (data.ghgAmount >= MaxValue.GHG) {
                const phrase = tabulatorTranslationInjector('input.invalid.tooLarge');
                const message = phrase.replace(`{{ number }}`, MaxWholeNumbers.GHG.toString());
                return createTooltip([message]);
            }
        },
        formatter: (cell: CellComponent, _formatterParams: FormatterParams, _onRendered: any): any => {
            const data = cell.getData() as LocationTableData;
            const value = cell.getValue();

            if (data.meta.level !== TableLevel.Emission) return value;

            const uoms = data.meta.formatter as UOMFormatter;

            const displayedUom = uoms.greenHouseGasUnitOfMeasurement;
            return tabulatorVueComponentInjector(InputCell, {
                data: data.ghgAmount,
                name: 'ghgAmount',
                uom: displayedUom,
                readonly: true,
            });
        },
    },
];

export const EMISSION_TABLE_COLUMN_DEF: Array<{ [key: string]: any }> = [
    ...commonTableColumns({ editableActivityData: true, widths: ['22%', '10%', '15%', '19%', '19%'] }),
    {
        title: 'Actions',
        field: 'actions',
        width: '15%',
        minWidth: 200,
        formatter: (cell: CellComponent, _formatterParams: FormatterParams, _onRendered: any): any => {
            const cellData = cell.getData() as LocationTableData;

            if (cellData.meta.holdsEmissions) {
                const actionCell = cellData.meta.isComplete ? CompletenessCell : AddEmissionCell;
                const parentRow = cell.getRow().getTreeParent();
                return tabulatorVueComponentInjector(actionCell, {
                    location: cell.getRow().getData() as LocationTableData,
                    parentData: parentRow ? (parentRow.getData() as LocationTableData) : undefined,
                });
            }

            if (cellData.meta.level === TableLevel.Emission) {
                //Need powerplant and unit name for modal title
                const { unitData, powerPlantData } = getParentData(cell);
                if (!powerPlantData.meta) return;
                return tabulatorVueComponentInjector(EmissionsActions, {
                    activity: cell.getRow().getData() as LocationTableData,
                    unit: unitData as LocationTableData,
                    powerPlant: powerPlantData as LocationTableData,
                    resetRow: () => resetEmissionRow(cell.getRow()),
                });
            }
        },
    },
];

const APPROVAL_COLUMN_WIDTHS = ['20%', '10%', '12%', '12%', '12%'];
export const APPROVAL_TABLE_COLUMN_DEF: Array<{ [key: string]: any }> = [
    ...commonTableColumns({ widths: APPROVAL_COLUMN_WIDTHS }).slice(0, 4),
    {
        title: 'Calorific value',
        field: 'heatValue',
        width: '12%',
        minWidth: 150,
        formatter: (cell: CellComponent, _formatterParams: FormatterParams, _onRendered: any): any => {
            const data = cell.getData() as LocationTableData;
            const value = cell.getValue();

            if (data.meta.level !== TableLevel.Emission) return value;

            const emission = data.meta.object as EmissionWithParkedEmissionDto;

            // Taken from IndividualEmissionFactor#energyUnits
            const numeratorMap = {
                12: 'kJ',
                13: 'MJ',
                14: 'GJ',
                15: 'TJ',
            } as Record<number, string>;
            const numerator = numeratorMap[emission.emissionFactor?.heatValueNumeratorUnitOfMeasurement?.id ?? 13];
            const denominator = emission.activityDataUnitOfMeasurement?.shortName || '';
            const displayedUom = `${numerator} / ${denominator}`;

            return tabulatorVueComponentInjector(InputCell, {
                data: data.heatValue,
                uom: displayedUom,
                readonly: true,
                name: 'heatValue',
            });
        },
    },
    {
        title: 'Heat based EF',
        field: 'heatBasedEmissionFactor',
        width: '12%',
        minWidth: 150,
        formatter: (cell: CellComponent, _formatterParams: FormatterParams, _onRendered: any): any => {
            const data = cell.getData() as LocationTableData;
            const value = cell.getValue();

            if (data.meta.level !== TableLevel.Emission) return value;

            const emission = data.meta.object as EmissionWithParkedEmissionDto;

            // Taken from IndividualEmissionFactor#energyUnits
            const denominatorMap = {
                12: 'kJ',
                13: 'MJ',
                14: 'GJ',
                15: 'TJ',
            } as Record<number, string>;
            const numerator = 't CO2';
            const denominator =
                denominatorMap[emission.emissionFactor?.heatBasedEmissionFactorDenominatorUnitOfMeasurement?.id ?? 13];
            const displayedUom = `${numerator} / ${denominator}`;

            return tabulatorVueComponentInjector(InputCell, {
                data: data.heatBasedEmissionFactor,
                uom: displayedUom ?? '',
                readonly: true,
                name: 'heatBasedEmissionFactor',
            });
        },
    },
    ...commonTableColumns({ widths: APPROVAL_COLUMN_WIDTHS }).slice(4),
    {
        title: 'Actions',
        field: 'actions',
        width: '10%',
        minWidth: 150,
        formatter: (cell: CellComponent, _formatterParams: FormatterParams, _onRendered: any): any => {
            const cellData = cell.getData() as LocationTableData;

            if (cellData.meta.holdsEmissions) {
                const parent = cell.getRow().getTreeParent();
                return tabulatorVueComponentInjector(ApprovalCompleteness, {
                    parentData: (parent && (parent.getData() as LocationTableData)) || null,
                    rowData: cellData,
                });
            }

            if (cellData.meta.level === TableLevel.Emission) {
                // Need powerplant and unit name for modal title
                const { unitData, powerPlantData } = getParentData(cell);
                if (Object.keys(unitData).length !== 0) {
                    return tabulatorVueComponentInjector(ApprovalActions, {
                        powerPlant: powerPlantData as LocationTableData,
                        unit: unitData as LocationTableData,
                        activity: cell.getRow().getData() as LocationTableData,
                        resetRow: () => resetEmissionRow(cell.getRow()),
                    });
                } else {
                    return tabulatorVueComponentInjector(ApprovalActions, {
                        powerPlant: powerPlantData as LocationTableData,
                        activity: cell.getRow().getData() as LocationTableData,
                        resetRow: () => resetEmissionRow(cell.getRow()),
                    });
                }
            }
        },
    },
];
