
import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import FloatInput from '@/components/inputs/float-input.vue';
import ModalInputWrapper from '@/components/inputs/modal-input-wrapper.vue';
import UnitOfMeasurement from '@/components/unit-of-measurement/unit-of-measurement.vue';

export default Vue.extend({
    name: 'RelatedEmissions',

    components: {
        FloatInput,
        UnitOfMeasurement,
        ModalInputWrapper,
    },

    props: {},

    data() {
        return {
            console,
        };
    },

    computed: {
        ...mapGetters('detailedView', {
            emission: 'GET_EDITED_EMISSION',
        }),

        n2oFactorUnits(): string {
            let numerator = this.emission.n2OEmissionFactor?.ghgUnitOfMeasurement?.shortName;
            const denominator = this.emission.n2OEmissionFactor?.activityDataUnitOfMeasurement?.shortName;
            return `${numerator ?? ''} / ${denominator ?? ''}`;
        },

        ch4FactorUnits(): string {
            let numerator = this.emission.cH4EmissionFactor?.ghgUnitOfMeasurement?.shortName;
            const denominator = this.emission.cH4EmissionFactor?.activityDataUnitOfMeasurement?.shortName;
            return `${numerator ?? ''} / ${denominator ?? ''}`;
        },
    },
});
