
import CopyText from '@/components/ui/text/copy-text.vue';
import Vue from 'vue';

export default Vue.extend({
    name: 'Environment',
    components: {
        CopyText,
    },
    data(): { environment: string | undefined } {
        return { environment: process.env.VUE_APP_ENVIRONMENT };
    },
});
