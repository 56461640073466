import { EmissionWithParkedEmissionDto } from '@/service-proxies/service-proxies.g';
import { EmissionFactorType } from '@/enums/emission-factor-type';

/**
 * The draft endpoint currently does not pu ghg unit of measuremnt in emission factor but it does in the options
 *
 * @param input - the emission recievd from the draft endpoint.
 * @returns the emission draft filled with missing data.
 */
export function fixDraft(input: EmissionWithParkedEmissionDto): EmissionWithParkedEmissionDto {
    let emissionDraft = input;
    if (!emissionDraft.emissionFactor) {
        emissionDraft.emissionFactor = emissionDraft.emissionFactorOptions?.individualEmissionFactorOption;
        return emissionDraft;
    }
    if (!emissionDraft.emissionFactor?.ghgUnitOfMeasurement) {
        if (emissionDraft.emissionFactor.type === EmissionFactorType.Global) {
            emissionDraft.emissionFactor = emissionDraft.emissionFactorOptions?.globalEmissionFactorOption;
        } else {
            emissionDraft.emissionFactor = emissionDraft.emissionFactorOptions?.individualEmissionFactorOption;
        }
    }
    return emissionDraft;
}
