import Vue from 'vue';
import Vuex from 'vuex';
import { user, UserState } from '@/store/modules/user';
import { fleet, FleetState } from '@/store/modules/fleet';
import { emissionDraft, EmissionDraftState } from './modules/emission-draft';
import { scope, ScopeState } from '@/store/modules/scope';
import { country, CountryState } from '@/store/modules/country';
import { activityData, ActivityDataState } from '@/store/modules/activity-data';
import { waitingModal } from '@/store/modules/waiting-modal';
import { metaData, MetaDataState } from '@/store/modules/meta-data';
import { masterDataLevel, MasterDataLevelState } from '@/store/modules/master-data-level';
import { classificatorData, ClassificatorDataState } from '@/store/modules/risk-classification';
import { emissionQuota, EmissionQuotaState } from '@/store/modules/emission-quota';
import { location, LocationState } from '@/store/modules/location';
import { detailedView, DetailedViewState } from '@/store/modules/detailed-view';
import { tables, TablesState } from '@/store/modules/tables';
import { roles, RolesState } from '@/store/modules/roles';
import { unitOfMeasurement, UOMState } from './modules/unit-of-measurement';
import { standardEmissionFactor, StandardEmissionFactorState } from './modules/standard-emission';

Vue.use(Vuex);

export interface ApplicationState {
    isLoading: boolean;
    unSavedChanges: Record<string, boolean>;
}

export const applicationState: StateWithModules = {
    isLoading: false,
    unSavedChanges: {},
    user: null as unknown as UserState,
    fleet: null as unknown as FleetState,
    emissionDraft: null as unknown as EmissionDraftState,
    scope: null as unknown as ScopeState,
    country: null as unknown as CountryState,
    activityData: null as unknown as ActivityDataState,
    metaData: null as unknown as MetaDataState,
    masterDataLevel: null as unknown as MasterDataLevelState,
    classificatorData: null as unknown as ClassificatorDataState,
    emissionQuota: null as unknown as EmissionQuotaState,
    location: null as unknown as LocationState,
    detailedView: null as unknown as DetailedViewState,
    tables: null as unknown as TablesState,
    roles: null as unknown as RolesState,
    unitOfMeasurement: null as unknown as UOMState,
    standardEmissionFactor: null as unknown as StandardEmissionFactorState,
};

export interface StateWithModules extends ApplicationState {
    user: UserState;
    fleet: FleetState;
    emissionDraft: EmissionDraftState;
    scope: ScopeState;
    country: CountryState;
    activityData: ActivityDataState;
    metaData: MetaDataState;
    masterDataLevel: MasterDataLevelState;
    classificatorData: ClassificatorDataState;
    emissionQuota: EmissionQuotaState;
    location: LocationState;
    detailedView: DetailedViewState;
    tables: TablesState;
    roles: RolesState;
    unitOfMeasurement: UOMState;
    standardEmissionFactor: StandardEmissionFactorState;
}

export default new Vuex.Store({
    modules: {
        fleet,
        user,
        emissionDraft,
        scope,
        country,
        activityData,
        waitingModal,
        metaData,
        masterDataLevel,
        classificatorData,
        emissionQuota,
        location,
        detailedView,
        tables,
        roles,
        unitOfMeasurement,
        standardEmissionFactor,
    },
    state: applicationState,
    getters: {
        isLoading(state): boolean {
            return state.isLoading;
        },
        unSavedChanges(state): Record<string, boolean> {
            return state.unSavedChanges;
        },
    },
    mutations: {
        loading(state): void {
            state.isLoading = !state.isLoading;
        },
        setUnsavedChanges(state, payload): void {
            state.unSavedChanges = payload;
        },
    },
    actions: {
        resetUnsavedChanges({ commit }): void {
            commit('setUnsavedChanges', {});
        },
        updateUnsavedChanges({ state, commit }, payload): void {
            commit('setUnsavedChanges', { ...state.unSavedChanges, ...payload });
        },
    },
});
