
import { BadRequestResponse, UnitDto, PowerPlantDto } from '@/service-proxies/service-proxies.g';
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { DateDisplayOptions, formatDate, formatLocationName } from '@/utils/helpers/formatters';

interface Data {
    powerPlant?: PowerPlantDto;
    unit?: UnitDto;
    comment: string;
    bodyMessage: string;
}

export default Vue.extend({
    name: 'WithdrawCompleteModal',

    data(): Data {
        return {
            powerPlant: undefined,
            unit: undefined,
            comment: '',
            bodyMessage: '',
        };
    },

    computed: {
        ...mapGetters({ loading: 'isLoading' }),
        ...mapGetters('country', {
            getCurrentCountry: 'GET_SELECTED_COUNTRY',
        }),
        ...mapGetters('location', {
            getStartDate: 'start',
        }),
        ...mapGetters('scope', {
            getSelectedScope: 'GET_SELECTED_SCOPE',
        }),
    },

    methods: {
        ...mapActions('location', {
            setCompleteness: 'SET_COMPLETENESS',
        }),

        open(data: Record<string, any>) {
            this.unit = data.unit;
            this.powerPlant = data.powerPlant;
            this.comment = '';

            const placeholders = {
                location: formatLocationName(this.powerPlant?.name, this.unit?.name),
                date: formatDate(this.getStartDate, this.$i18n.locale, DateDisplayOptions.MonthOnly),
            };

            this.bodyMessage = this.$t('withdrawModal.prompt') as string;
            // Placeholder aren't working so manually replace them
            Object.entries(placeholders).forEach(([key, value]) => {
                this.bodyMessage = this.bodyMessage.replace(`{{ ${key} }}`, value);
            });

            (this as any).$refs.withdrawCompleteModal.open();
        },

        async onConfirmCallback() {
            if (this.loading) return;
            await this.withdrawCompleteness();
            (this as any).$refs.withdrawCompleteModal.close();
        },

        onCloseCallback() {
            if (this.loading) return;
            this.powerPlant = undefined;
            this.unit = undefined;
            (this as any).$refs.withdrawCompleteModal.close();
        },

        async withdrawCompleteness() {
            this.$store.commit('loading');

            try {
                const [year, month, _day] = this.getStartDate.split('-').map(Number);
                // Set the end date to the last day of the month
                // Note: month is 0-indexed so no need to add 1
                const endDate = new Date(Date.UTC(year, month, 0)).toISOString().split('T')[0];

                const unitOrPlant: PowerPlantDto | undefined = this.unit?.sid ? this.unit : this.powerPlant;
                const locationType = unitOrPlant?.locationType ?? null;

                await this.setCompleteness({
                    locationId: this.unit?.sid ?? this.powerPlant!.sid,
                    startDate: this.getStartDate,
                    endDate,
                    isComplete: false,
                    scope: this.getSelectedScope,
                    comment: this.comment,
                    locationType: locationType,
                });
                (this as any).$pui.toast({
                    type: 'success',
                    title: this.$t('notification.complete.withdrawn'),
                    copy: '',
                });
            } catch (error) {
                const message = (error as BadRequestResponse).message ?? '';
                (this as any).$pui.toast({
                    type: 'error',
                    title: this.$t('toast.error'),
                    copy: message,
                });
                console.log(error);
                (this as any).$refs.withdrawCompleteModal.close();
                throw error;
            } finally {
                this.$store.commit('loading');
            }
        },
    },
});
