
import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import FloatInput from '@/components/inputs/float-input.vue';
import ModalInputWrapper from '@/components/inputs/modal-input-wrapper.vue';
import UnitOfMeasurement from '@/components/unit-of-measurement/unit-of-measurement.vue';
import { checkDecimalPlaces } from '@/utils';
import { MaxDecimalPlaces, JAVASCRIPT_MAX_DIGITS, MaxWholeNumbers, MaxValue } from '@/constants/input-precision';
import { functionalPermissions } from '@/utils';
import { EFKey } from '@/models/emission-factor-input';

export default Vue.extend({
    name: 'FuelCorrection',

    components: {
        FloatInput,
        UnitOfMeasurement,
        ModalInputWrapper,
    },

    props: {
        canEditFuel: {
            type: Boolean,
            required: true,
        },
        showAdjustedFuel: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            console,
            emptyFuelInput: false,
            emptyFactorInput: false,
            MaxDecimalPlaces,
        };
    },

    computed: {
        ...mapGetters('detailedView', {
            importedEmission: 'GET_EMISSION',
            emission: 'GET_EDITED_EMISSION',
        }),

        disableCheckbox(): boolean {
            return !functionalPermissions.userCanEditFuelAdjustment() || !this.canEditFuel;
        },
        useFuelAdjustment(): boolean {
            return this.emission.activeActivityDataAmountAdjustment ?? false;
        },

        nonAdjustedFuelInput(): string {
            return this.emission.unadjustedActivityDataAmount.toString();
        },

        adjustmentFactorInput(): string {
            return this.emission.activityDataAmountAdjustmentFactor.toString();
        },

        isFuelInputEmpty(): boolean {
            return this.emission.unadjustedActivityDataAmount === 0;
            // return this.emission.unadjustedActivityDataAmount === null;
        },

        isFactorInputEmpty(): boolean {
            return this.emission.activityDataAmountAdjustmentFactor === 0;
            // return this.emission.activityDataAmountAdjustmentFactor === null;
        },

        isFuelInputValid(): boolean {
            return checkDecimalPlaces(this.emission.unadjustedActivityDataAmount, MaxDecimalPlaces.FUEL_CONSUMPTION);
        },

        isFactorInputValid(): boolean {
            return checkDecimalPlaces(
                this.emission.activityDataAmountAdjustmentFactor,
                MaxDecimalPlaces.ADJUSTMENT_FACTOR,
            );
        },

        fuelConsumptionTooltip(): string {
            return this.toolTipMessage('FUEL_CONSUMPTION');
        },
        adjustmentFactorTooltip(): string {
            if (this.isFactorInputValid) {
                return (
                    this.$t('detailedView.fuelCorrection.info') +
                    '\n' +
                    this.$t('detailedView.fuelCorrection.calculation')
                );
            }
            return this.toolTipMessage('ADJUSTMENT_FACTOR');
        },
    },

    methods: {
        ...mapMutations('detailedView', {
            setEditedEmission: 'SET_EDITED_EMISSION',
        }),

        handleToggle(newState: boolean) {
            this.setEditedEmission({
                ...this.emission,
                activeActivityDataAmountAdjustment: newState,

                // On toggle on, if the activity data is different from the unadjusted value, set the unadjusted value
                // to the activity data
                ...(newState &&
                    this.emission.unadjustedActivityDataAmount != this.emission.activityDataAmount && {
                        unadjustedActivityDataAmount: this.emission.activityDataAmount,
                    }),

                // On toggle on, if the adjustment factor is not set, set it to 1
                ...(newState &&
                    !this.emission.activityDataAmountAdjustmentFactor && {
                        activityDataAmountAdjustmentFactor: 1,
                    }),

                // On toggle off, revert the values to the currently saved values
                ...(!newState && {
                    activityDataAmountAdjustmentFactor: this.importedEmission.activityDataAmountAdjustmentFactor,
                    unadjustedActivityDataAmount: this.importedEmission.unadjustedActivityDataAmount,
                }),
            });
            this.$emit('valid', true);
        },

        handleFuelInput(newValue: number) {
            this.setEditedEmission({
                ...this.emission,
                unadjustedActivityDataAmount: newValue,
            });
            this.$emit('valid', this.isFuelInputValid && this.isFactorInputValid);
        },

        handleFactorInput(newValue: number) {
            this.setEditedEmission({
                ...this.emission,
                activityDataAmountAdjustmentFactor: newValue,
            });
            this.$emit('valid', this.isFuelInputValid && this.isFactorInputValid);
        },
        toolTipMessage(key: EFKey): string {
            const input = key === 'ADJUSTMENT_FACTOR' ? this.adjustmentFactorInput : this.nonAdjustedFuelInput;
            const value = Number(input);
            if (!checkDecimalPlaces(value, MaxDecimalPlaces[key])) {
                const message = this.$t('input.invalid.decimal') as string;
                return message.replace(`{{ number }}`, MaxDecimalPlaces[key].toString());
            }
            if (value >= MaxValue[key]) {
                const message = this.$t('input.invalid.tooLarge') as string;
                return message.replace(`{{ number }}`, MaxWholeNumbers[key].toString());
            }
            if (input.length > JAVASCRIPT_MAX_DIGITS) {
                return this.$t('input.warning.jsPrecision') as string;
            }
            return '';
        },
    },
});
