import { FleetGetEndpointResponseOkResponse, FleetServiceProxy } from '@/service-proxies/service-proxies.g';
import { FleetListEndpointRequest } from '@/models/fleet';

/**
 * @name FleetService
 * @description This class is used to query API for data related to App needs,
 */
export class FleetService {
    private readonly fleetServiceProxy: FleetServiceProxy;

    constructor() {
        this.fleetServiceProxy = new FleetServiceProxy();
    }

    public async getAll(filter: FleetListEndpointRequest): Promise<FleetGetEndpointResponseOkResponse> {
        return await this.fleetServiceProxy.getFleet(filter?.scopeId, filter?.countryId ?? undefined);
    }
}
