import Vue from 'vue';
import { mapGetters } from 'vuex';
const WaitingModal = Vue.extend({
    name: 'WaitingModal',
    components: {},
    props: {},
    data(): {} {
        return {};
    },
    computed: {
        ...mapGetters({
            isOpen: 'waitingModal/isWaiting',
            waitingModalTitle: 'waitingModal/header',
            waitingModalContent: 'waitingModal/content',
        }),
    },
    watch: {
        isOpen: {
            handler(val: boolean): void {
                if (val) {
                    (this.$refs['globalWaitingModalRef'] as any).open();
                } else {
                    (this.$refs['globalWaitingModalRef'] as any).close();
                }
            },
        },
    },
});
export default WaitingModal;
