import { EmissionQuotaProductServiceProxy } from '@/service-proxies/service-proxies.g';

import type {
    EmissionQuotaProductDeleteEndpointResponseOkResponse,
    EmissionQuotaProductDto,
    EmissionQuotaProductGetEndpointResponseOkResponse,
    EmissionQuotaProductPostEndpointResponseOkResponse,
    EmissionQuotaProductPutEndpointResponseOkResponse,
} from '@/service-proxies/service-proxies.g';

export class EmissionQuotaProductService {
    private emissionQuotaProductServiceProxy: EmissionQuotaProductServiceProxy;

    constructor() {
        this.emissionQuotaProductServiceProxy = new EmissionQuotaProductServiceProxy();
    }

    public async getEmissionQuotaProducts(
        startYear: number,
        endYear: number,
        countryId?: number,
    ): Promise<EmissionQuotaProductGetEndpointResponseOkResponse> {
        return await this.emissionQuotaProductServiceProxy.getEmissionQuotaProducts(
            undefined, // id
            countryId, // countrySid
            undefined, // plantSid
            undefined, // machineSid
            startYear, // periodStart
            endYear, // periodEnd
            undefined, // sortDirection
            undefined, // sortProperty
            1, // page
            1000, // size - large value to ensure everything is loaded
            undefined, // term
            undefined, // termToLower
        );
    }

    public async addEmissionQuotaProduct(
        emissionQuotaProduct: EmissionQuotaProductDto,
    ): Promise<EmissionQuotaProductPostEndpointResponseOkResponse> {
        return await this.emissionQuotaProductServiceProxy.addEmissionQuotaProduct(emissionQuotaProduct);
    }

    public async updateEmissionQuotaProduct(
        emissionQuotaProduct: EmissionQuotaProductDto,
    ): Promise<EmissionQuotaProductPutEndpointResponseOkResponse> {
        return await this.emissionQuotaProductServiceProxy.putEmissionQuotaProduct(emissionQuotaProduct);
    }

    public async deleteEmissionQuotaProduct(
        emissionQuotaProductId: number,
    ): Promise<EmissionQuotaProductDeleteEndpointResponseOkResponse> {
        return await this.emissionQuotaProductServiceProxy.deleteEmissionQuotaProduct(emissionQuotaProductId);
    }
}
