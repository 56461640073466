import { EmissionFactorInput } from '@/models/emission-factor-input';

//IMPORTANT: Javascript number precision is 16/17,
//so in edge cases where user tries to put in large numbers with high decimal precision,
//the decimal points will be rounded
export const JAVASCRIPT_MAX_DIGITS = 17; //includes the decimal point
export const JAVASCRIPT_PRECISION = 16; //includes the decimal point

// The maximum number of decimal places
export const MaxDecimalPlaces: EmissionFactorInput = {
    FUEL_CONSUMPTION: 3,
    EMISSION_FACTOR: 10,
    GHG: 3,
    GHG_BIOGENIC: 3,
    FACTOR: 10,
    CALORIFIC_VALUE: 6,
    HBEF: 6,
    CARBON_CONTENT: 6,
    CONVERSION_FACTOR: 6,
    OXIDATION_FACTOR: 6,
    BIOGENIC_SHARE: 6,
    ADJUSTMENT_FACTOR: 6,
};

// The upper limits of inputs (non-inclusive unless specified otherwise)
export const MaxValue: EmissionFactorInput = {
    FUEL_CONSUMPTION: 10 ** (JAVASCRIPT_PRECISION - MaxDecimalPlaces.FUEL_CONSUMPTION),
    EMISSION_FACTOR: 10 ** (JAVASCRIPT_PRECISION - MaxDecimalPlaces.EMISSION_FACTOR),
    GHG: 10 ** (JAVASCRIPT_PRECISION - MaxDecimalPlaces.GHG),
    GHG_BIOGENIC: 10 ** (JAVASCRIPT_PRECISION - MaxDecimalPlaces.GHG_BIOGENIC),
    FACTOR: 10 ** (JAVASCRIPT_PRECISION - MaxDecimalPlaces.FACTOR),
    CALORIFIC_VALUE: 10 ** (JAVASCRIPT_PRECISION - MaxDecimalPlaces.CALORIFIC_VALUE),
    HBEF: 10 ** (JAVASCRIPT_PRECISION - MaxDecimalPlaces.HBEF),
    CARBON_CONTENT: 100, // Percentage Value (inclusive)
    CONVERSION_FACTOR: 10 ** (JAVASCRIPT_PRECISION - MaxDecimalPlaces.CONVERSION_FACTOR),
    OXIDATION_FACTOR: 10 ** (JAVASCRIPT_PRECISION - MaxDecimalPlaces.OXIDATION_FACTOR),
    BIOGENIC_SHARE: 100, // Percentage Value (inclusive)
    ADJUSTMENT_FACTOR: 10 ** (JAVASCRIPT_PRECISION - MaxDecimalPlaces.ADJUSTMENT_FACTOR),
};

// The max amount of digits before the decimal point allowed, useful for tooltip/error messages
export const MaxWholeNumbers: EmissionFactorInput = {
    FUEL_CONSUMPTION: JAVASCRIPT_PRECISION - MaxDecimalPlaces.FUEL_CONSUMPTION,
    EMISSION_FACTOR: JAVASCRIPT_PRECISION - MaxDecimalPlaces.EMISSION_FACTOR,
    GHG: JAVASCRIPT_PRECISION - MaxDecimalPlaces.GHG,
    GHG_BIOGENIC: JAVASCRIPT_PRECISION - MaxDecimalPlaces.GHG_BIOGENIC,
    FACTOR: JAVASCRIPT_PRECISION - MaxDecimalPlaces.FACTOR,
    CALORIFIC_VALUE: JAVASCRIPT_PRECISION - MaxDecimalPlaces.CALORIFIC_VALUE,
    HBEF: JAVASCRIPT_PRECISION - MaxDecimalPlaces.HBEF,
    CARBON_CONTENT: 3,
    CONVERSION_FACTOR: JAVASCRIPT_PRECISION - MaxDecimalPlaces.CONVERSION_FACTOR,
    OXIDATION_FACTOR: JAVASCRIPT_PRECISION - MaxDecimalPlaces.OXIDATION_FACTOR,
    BIOGENIC_SHARE: 3,
    ADJUSTMENT_FACTOR: JAVASCRIPT_PRECISION - MaxDecimalPlaces.ADJUSTMENT_FACTOR,
};
