import { CellComponent, EditorParams } from 'tabulator-tables';

// https://tabulator.info/docs/5.5/edit#edit-custom
export function floatInputEditor(
    cell: CellComponent,
    onRendered: Function,
    success: Function,
    cancel: Function,
    editorParams: EditorParams,
): HTMLInputElement {
    const editor = document.createElement('input');

    editor.setAttribute('type', 'text');
    editor.style.width = '75%';
    editor.style.height = '50%';
    editor.style.textAlign = 'right';
    editor.style.margin = '1rem';

    const numberValue = cell.getValue();
    const localeOptions = {
        maximumFractionDigits: 20,
        minimumFractionDigits: 0,
        maximumSignificantDigits: 16,
    };

    const storedLanguage = localStorage.getItem('translation-plugin-currentLanguage'); // this should be the same as i18n.locale
    const locale = new Intl.NumberFormat(storedLanguage ?? [...navigator.languages], localeOptions);
    const numberWithGroupAndDecimalSeparator = 10000.1;
    const seperatedNumber = locale!.formatToParts(numberWithGroupAndDecimalSeparator);
    const decimalSeparator = seperatedNumber.find((part) => part.type === 'decimal')?.value ?? '.';
    const thousandSeparator = seperatedNumber.find((part) => part.type === 'group')?.value ?? ',';

    let savedValue = numberValue.toString();

    function onInput(payload: Event): void {
        const target = payload.target as HTMLInputElement;
        let newValue = target.value;
        newValue = cleanupThousandSeperators(newValue, thousandSeparator);
        newValue = cleanupZeros(newValue, decimalSeparator);
        const numberValue = newValue.replace(decimalSeparator, '.');
        if (catchNegative(newValue)) {
            savedValue = newValue;
            editor.value = savedValue;
            return;
        }
        const invalidInput = isNotANumber(numberValue) || containsLetter(newValue);
        if (invalidInput) {
            payload.stopImmediatePropagation();
            editor.value = savedValue;
            return;
        }
        savedValue = newValue;
        editor.value = savedValue;
    }

    function onBlur(): void {
        const numberValue = editor.value.replace(decimalSeparator, '.');
        if (isNotANumber(numberValue)) {
            console.error(numberValue);
            cancel();
            return;
        }
        success(Number(numberValue));
    }

    if (numberValue === undefined || numberValue === null) {
        editor.value = '';
    } else {
        let formattedValue = cleanupThousandSeperators(locale.format(numberValue), thousandSeparator);
        editor.value = formattedValue;
    }

    onRendered(() => {
        editor.focus();
        editor.select();
    });

    editor.addEventListener('input', onInput);
    editor.addEventListener('blur', onBlur);

    return editor;
}

function cleanupZeros(value: string, decimalSeparator: string): string {
    let newValue = value;
    while (newValue.length > 1) {
        if (newValue.startsWith(decimalSeparator)) {
            return '0' + newValue;
        }
        if (newValue.startsWith(`0${decimalSeparator}`)) return newValue;
        if (!newValue.startsWith('0')) return newValue;
        newValue = newValue.slice(1);
    }
    return newValue;
}

function cleanupThousandSeperators(value: string, thousandSeparator: string): string {
    return value.split(thousandSeparator).join('');
}

function isNotANumber(stringValue: string): boolean {
    return Number.isNaN(Number(stringValue));
}

function containsLetter(stringValue: string): boolean {
    const expression = /[a-zA-Z]/;
    return expression.test(stringValue);
}

function catchNegative(value: string): boolean {
    return value.startsWith('-') && value.length < 2;
}
