import { StateWithModules } from '@/store';
import { Module } from 'vuex';
import { MetaDataService } from '@/services/meta-data-service';
import axios, { AxiosError } from 'axios';
import { HttpStatusCodes } from '@/enums/http-status-codes';
import { MetaDataEndpointResponse } from '@/service-proxies/service-proxies.g';

export interface MetaDataState {
    metaDataApi: MetaDataService;
    powerBiUrl: string;
}

export const metaData: Module<MetaDataState, StateWithModules> = {
    namespaced: true,
    state: (): MetaDataState => ({
        metaDataApi: new MetaDataService(),
        powerBiUrl: '',
    }),
    getters: {
        powerBiUrl: (state: MetaDataState): string => state.powerBiUrl,
    },
    actions: {
        GET_META_DATA: async ({ commit, state }: { commit: any; state: MetaDataState }): Promise<number> => {
            try {
                const metaData = await state.metaDataApi.getMetaData();
                commit('SET_META_DATA', metaData.result);
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    return (e as AxiosError).response?.status ?? HttpStatusCodes.InternalServerError;
                } else {
                    console.error(`An unknown error occurred while fetching the meta data.`);
                    console.error(e);
                    return HttpStatusCodes.InternalServerError;
                }
            }
            return HttpStatusCodes.Ok;
        },
    },
    mutations: {
        SET_META_DATA: (state: MetaDataState, metaDataDto: MetaDataEndpointResponse): any => {
            if (metaDataDto) {
                state.powerBiUrl = metaDataDto.powerBiUrl ?? '';
            }
        },
    },
};
