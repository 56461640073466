
import { ActivityDataDto } from '@/service-proxies/service-proxies.g';
import { EventBus } from '@/utils';
import { defineComponent, PropType } from 'vue';

interface Data {
    icon: {
        name: string;
        size: string;
    };
}

export default defineComponent({
    name: 'AddUoMCell',
    data(): Data {
        return {
            icon: {
                name: 'add',
                size: '16px',
            },
        };
    },
    props: {
        activity: {
            type: Object as PropType<ActivityDataDto>,
            required: true,
        },
        children: {
            type: Array as PropType<any[]>,
            required: false,
        },
    },
    computed: {
        label(): string {
            return this.$t('standardEF.actions.addUoM') as string;
        },
    },
    methods: {
        openAddUomModal(): void {
            EventBus.$emit(EventBus.VIEWS.STANDARD_EMISSION.OPEN_ADD_UOM_MODAL, {
                activity: this.activity,
                children: this.children,
            });
        },
    },
});
