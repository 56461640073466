import { HttpStatusCodes } from '@/enums/http-status-codes';
import { ScopeDto, ScopeSortColumn, SortType } from '@/service-proxies/service-proxies.g';
import { ScopeService } from '@/services/scope-service';
import axios, { AxiosError } from 'axios';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ApplicationState } from '..';

const scopeService: ScopeService = new ScopeService();

export interface ScopeState {
    scopes: ScopeDto[];
    selectedScopeId: number | undefined;
}

const state: ScopeState = {
    scopes: [],
    selectedScopeId: undefined,
};

const getters: GetterTree<ScopeState, ApplicationState> = {
    GET_SCOPES: (state): ScopeDto[] => {
        return state.scopes;
    },
    GET_SCOPE:
        (state) =>
        (scopeId: number): ScopeDto | undefined => {
            return state.scopes?.find((scope) => scope.id === scopeId);
        },
    GET_SELECTED_SCOPE: (state): ScopeDto | undefined => {
        return state.scopes?.find((scope) => scope.id == state.selectedScopeId);
    },
};

const mutations: MutationTree<ScopeState> = {
    SET_SCOPES(state: ScopeState, data: ScopeDto[]) {
        state.scopes = data;
    },
    SET_SELECTED_SCOPE_ID(state: ScopeState, data: number) {
        state.selectedScopeId = data;
    },
};

const actions: ActionTree<ScopeState, ApplicationState> = {
    async GET_SCOPES({ commit }: ActionContext<ScopeState, ApplicationState>): Promise<HttpStatusCodes> {
        const filter = {
            ids: undefined,
            subScopes: undefined,
            sortDirection: SortType.Ascending,
            sortProperty: ScopeSortColumn.Id,
            page: 1,
            size: 1000,
            Term: undefined,
            TermToLower: undefined,
        };
        try {
            const result = await scopeService.getScopes(filter);

            // Unfortunately we have a special case for scope 1.1 (id = 2)
            // For this scope we actually want scope 1.1.1 but the label/name of scope 1.1
            // Therefore we set the id of this scope to the id of scope 1.1.1 (id = 3)
            const scopes = [...(result.result?.items ?? [])];
            const idx = scopes.findIndex((x) => x.id === 2);
            if (idx >= 0) {
                scopes[idx].id = 3;
            }

            commit('SET_SCOPES', result.result?.items);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return (e as AxiosError).response?.status ?? HttpStatusCodes.InternalServerError;
            } else {
                console.error(`An unknown error occurred while fetching the scope data.`);
                console.error(e);
                return HttpStatusCodes.InternalServerError;
            }
        }
        return HttpStatusCodes.Ok;
    },
    SET_SCOPE_ID({ commit }: ActionContext<ScopeState, ApplicationState>, scope: ScopeDto): void {
        commit('SET_SELECTED_SCOPE_ID', scope.id);
    },
};

const namespaced = true;

export const scope: Module<ScopeState, ApplicationState> = {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};
