import { MetaDataEndpointResponseOkResponse, MetaServiceProxy } from '@/service-proxies/service-proxies.g';

/**
 * @name ScopeService
 * @description This class is used to query the backend server for metadata
 */
export class MetaDataService {
    private metaDataServiceproxy: MetaServiceProxy;

    constructor() {
        this.metaDataServiceproxy = new MetaServiceProxy();
    }

    /**
     * Get meta data.
     */
    public getMetaData = (): Promise<MetaDataEndpointResponseOkResponse> => {
        return this.metaDataServiceproxy.getMetaDataDto(undefined);
    };
}
