
import { BadRequestResponse, EmissionFactorDto } from '@/service-proxies/service-proxies.g';
import Vue, { nextTick } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import DatePicker from '@/components/date-picker/granularity-date/date-picker.vue';
import ModalInputWrapper from '@/components/inputs/modal-input-wrapper.vue';
import { formatToDashedYYYYMMDD } from '@/utils/helpers/formatters';

export default Vue.extend({
    name: 'BulkEditConfirmModal',
    components: { ModalInputWrapper, DatePicker },

    data(): {
        bodyMessage: string;
        emissionFactorIds: number[];
        source: string;
        linkToSource: string;
        additionalInformation: string;
        publicationDate: string;
        startDate: string;
        validFields: {
            startDate: boolean;
        };
    } {
        return {
            bodyMessage: '',
            emissionFactorIds: [],
            source: '',
            linkToSource: '',
            additionalInformation: '',
            publicationDate: '',
            startDate: '',
            validFields: {
                startDate: false,
            },
        };
    },

    computed: {
        ...mapGetters({
            isLoading: 'isLoading',
        }),
        disableSave(): boolean {
            return Object.values(this.validFields).includes(false);
        },
    },

    methods: {
        ...mapActions('standardEmissionFactor', {
            approveBulkEmissions: 'BULK_EDIT',
        }),

        open(data: number[]) {
            this.emissionFactorIds = data;
            const now = new Date();
            this.selectStartDate(
                formatToDashedYYYYMMDD(new Date(new Date(now.setMonth(now.getMonth() + 1)).setDate(1))),
            );

            (this as any).$refs['bulk-edit-confirm-modal'].open();
        },

        async onConfirmCallback() {
            if (this.isLoading) return;
            await this.bulkEdit();

            (this as any).$refs['bulk-edit-confirm-modal'].close();
        },

        onCloseCallback() {
            this.emissionFactorIds = [];
            this.source = '';
            this.linkToSource = '';
            this.additionalInformation = '';
            this.publicationDate = '';
            this.startDate = '';
        },

        selectStartDate(newDate: string): void {
            this.startDate = newDate;
            if (Date.parse(this.startDate) < Date.now()) {
                this.validFields.startDate = false;
            } else {
                this.validFields.startDate = true;
            }
        },
        changeSource(value: string): void {
            this.source = value;
        },
        changeLink(value: string): void {
            this.linkToSource = value;
        },
        changePublicationDate(value: string): void {
            this.publicationDate = value;
        },
        changeAdditionalInformation(value: string): void {
            this.additionalInformation = value;
        },

        async bulkEdit() {
            this.$store.commit('loading');

            try {
                await this.approveBulkEmissions({
                    emissionFactorIds: this.emissionFactorIds,
                    startDate: this.startDate,
                    source: this.source,
                    linkToSource: this.linkToSource,
                    additionalInformation: this.additionalInformation,
                    publicationDate: this.publicationDate,
                });
                await nextTick();
                this.emissionFactorIds = [];
            } catch (e) {
                console.log(e);
                throw e;
            } finally {
                await nextTick();
                this.$store.commit('loading');
                this.onCloseCallback();
            }
        },
    },
});
