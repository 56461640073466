
import Vue from 'vue';
import { mapGetters } from 'vuex';
import Product from './product.vue';
import { EQGenerationProduct } from '@/store/modules/emission-quota';

import type { EmissionQuotaProduct } from '@/store/modules/emission-quota';
import { EQSum } from './emissionQuotaTypes';

export default Vue.extend({
    name: 'Unit',

    components: {
        Product,
    },

    props: {
        plantSid: {
            type: Number,
            required: true,
        },
        plantLocation: {
            type: String,
            required: true,
        },
        unitSid: {
            type: Number,
            required: true,
        },
        unitName: {
            type: String,
            required: true,
        },
        unitSum: {
            type: Object as () => EQSum,
            required: true,
        },
        products: {
            type: Array as () => EmissionQuotaProduct[],
            required: true,
        },
    },

    data() {
        return {
            EQGenerationProduct,
            isExpanded: false,
        };
    },

    computed: {
        ...mapGetters('emissionQuota', {
            getTradingPeriodStartYear: 'GET_TRADING_PERIOD_START_YEAR',
        }),
    },

    methods: {
        getProduct(generationProduct: EQGenerationProduct): EmissionQuotaProduct | null {
            return this.products.find((product) => product.generationProduct === generationProduct) ?? null;
        },

        getValueForIndex(index: number): string {
            const year = this.getTradingPeriodStartYear + index;
            return this.unitSum[year]?.toString() ?? '0';
        },
    },
});
