import { formatToDashedYYYYMMDD } from '@/utils/helpers/formatters';

export const isDateStringValid = (dateString: string): boolean => {
    const formatRegEx = /^\d{4}-\d{2}-\d{2}$/;
    const boundariesRegEx = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
    const isValidFormat = dateString.match(formatRegEx) && dateString.match(boundariesRegEx);
    if (!isValidFormat) {
        return false;
    }
    const date = new Date(dateString);
    const dateNum = date.getTime();
    const isValidDate = !!dateNum || dateNum !== 0;
    if (!isValidDate) {
        return false;
    }
    return formatToDashedYYYYMMDD(date) === dateString;
};
