
import Vue from 'vue';

export default Vue.extend({
    name: 'CopyText',
    props: {
        text: {
            type: String,
            required: true,
        },
    },
    methods: {
        async copyToClipboard(value: string): Promise<void> {
            try {
                await navigator.clipboard.writeText(value);
                this.showCopySuccessToast();
            } catch (error) {
                this.showCopyFailedToast();
            }
        },
        showCopySuccessToast(): void {
            (this as any).$pui.toast({
                type: 'info',
                title: this.$t('copiedToClipboard'),
                copy: `${this.text} was copied.`,
            });
        },
        showCopyFailedToast(): void {
            (this as any).$pui.toast({
                type: 'error',
                title: this.$t('copyToClipboardFailed'),
                copy: `Failed to copy ${this.text}.`,
            });
        },
    },
});
