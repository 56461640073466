
import Vue from 'vue';
import { EmissionFactorDto, BadRequestResponse } from '@/service-proxies/service-proxies.g';
import ModalInputWrapper from '@/components/inputs/modal-input-wrapper.vue';
import FloatInput from '@/components/inputs/float-input.vue';
import DatePicker from '@/components/date-picker/granularity-date/date-picker.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { checkDecimalPlaces } from '@/utils';
import { formatToDashedYYYYMMDD, formatActivityDataDisplayName, getNextMonthDate } from '@/utils/helpers/formatters';
import { createDates } from '@/utils/helpers/emission/new-emission-dates';
import { MaxDecimalPlaces, MaxValue, MaxWholeNumbers } from '@/constants/input-precision';

const ApprovalModal = Vue.extend({
    name: 'EditEmissionModal',
    components: {
        ModalInputWrapper,
        FloatInput,
        DatePicker,
    },
    data(): {
        emissionFactor: EmissionFactorDto | null | undefined;
        detailedViewEmissionFactorHistory: EmissionFactorDto[];
        isSaving: boolean;
        formFields: Record<string, string>;
        validFields: Record<string, boolean>;
        valueAdded: boolean;
        valueDeleted: boolean;
        emissionIdInTable: number | undefined;
        lastValueDeleted: boolean;
    } {
        return {
            emissionFactor: null,
            detailedViewEmissionFactorHistory: [],
            isSaving: false,
            formFields: {
                activityUOM: '',
                additionalInformation: '',
                greenHouseGas: '',
                greenHouseGasUOM: '',
                emissionFactor: '',
                publicationDate: '',
                startDate: '',
                endDate: '',
                source: '',
                link: '',
            },
            validFields: {
                activityUOM: true,
                additionalInformation: true,
                greenHouseGas: true,
                greenHouseGasUOM: true,
                emissionFactor: true,
                publicationDate: true,
                startDate: true,
                endDate: true,
                source: true,
                link: true,
            },
            valueAdded: false,
            valueDeleted: false,
            emissionIdInTable: 0,
            lastValueDeleted: false,
        };
    },
    computed: {
        ...mapGetters('scope', { currentScope: 'GET_SELECTED_SCOPE' }),
        ...mapGetters('standardEmissionFactor', {
            fetchHistory: 'detailedViewEmissionFactorHistory',
        }),
        emissionFactorUOM(): string {
            const numerator = this.emissionFactor?.ghgUnitOfMeasurement?.shortName ?? '';
            const denominator = this.emissionFactor?.activityDataUnitOfMeasurement?.shortName ?? '';
            return `${numerator} / ${denominator}`;
        },
        fuel(): string {
            return formatActivityDataDisplayName(this.emissionFactor?.activityData);
        },
        disableSave(): boolean {
            return Object.values(this.validFields).includes(false);
        },
        tooltipMessages(): Record<string, string> {
            return {
                endDate: !this.validFields.endDate ? (this.$t('input.invalid.date') as string) : '',
                emissionFactor: this.generateToolTipMessage(),
            };
        },
        deleteButtonDisabled(): boolean {
            return this.isSaving || this.valueDeleted || this.valueAdded || !this.emissionFactor?.id;
        },
    },
    watch: {
        fetchHistory() {
            this.detailedViewEmissionFactorHistory = this.fetchHistory;
        },
    },
    methods: {
        ...mapActions('standardEmissionFactor', {
            editEmissionFactor: 'EDIT_STANDARD_EMISSION_FACTOR',
            getEFHistory: 'GET_STANDARD_EMISSION_FACTORS_HISTORY',
            addEmissionFactor: 'ADD_STANDARD_EMISSION_FACTOR',
            deleteAndUpdateEF: 'DELETE_AND_UPDATE',
            deleteLastEF: 'DELETE',
        }),

        async open(data: EmissionFactorDto): Promise<void> {
            this.valueAdded = false;
            this.valueDeleted = false;
            this.lastValueDeleted = false;
            this.validFields.startDate = true;
            this.emissionFactor = data;

            await this.getEFHistory(this.emissionFactor);
            this.detailedViewEmissionFactorHistory = this.fetchHistory;
            this.emissionIdInTable = this.emissionFactor.id;

            this.setFormFieldsToEF();

            (this as any).$refs.editEFModal.open();
        },

        setFormFieldsToEF() {
            this.formFields.activityUOM = this.emissionFactor?.activityDataUnitOfMeasurement?.name ?? '';
            this.formFields.greenHouseGas = this.emissionFactor?.greenhouseGas?.chemicalFormula ?? '';
            this.formFields.greenHouseGasUOM = this.emissionFactor?.ghgUnitOfMeasurement?.name ?? '';
            this.formFields.emissionFactor = this.emissionFactor?.emissionsFactor?.toString() ?? '';
            this.formFields.startDate = this.emissionFactor?.startDate
                ? formatToDashedYYYYMMDD(new Date(this.emissionFactor?.startDate))
                : '';
            this.formFields.endDate = this.emissionFactor?.endDate
                ? formatToDashedYYYYMMDD(new Date(this.emissionFactor?.endDate))
                : '';
            this.formFields.publicationDate = this.emissionFactor?.publicationDate
                ? formatToDashedYYYYMMDD(new Date(this.emissionFactor?.publicationDate))
                : '';
            this.formFields.source = this.emissionFactor?.source ?? '';
            this.formFields.link = this.emissionFactor?.linkToPublication ?? '';
            this.formFields.additionalInformation = this.emissionFactor?.additionalInformation ?? '';

            this.validateEmissionFactor();
        },

        addValue(): void {
            this.valueAdded = true;

            var newStartDate = getNextMonthDate(new Date(this.formFields.startDate));

            if (newStartDate < formatToDashedYYYYMMDD(Date.now())) {
                newStartDate = formatToDashedYYYYMMDD(Date.now());
            }

            if (this.emissionFactor) {
                this.detailedViewEmissionFactorHistory.unshift(this.emissionFactor);
            }
            this.formFields.startDate = newStartDate;
            this.formFields.endDate = formatToDashedYYYYMMDD(new Date(2100, 11, 1));
            this.detailedViewEmissionFactorHistory[0].endDate = this.formFields.startDate;
            this.validateStartDate();
        },

        deleteValue(): void {
            this.valueDeleted = true;
            if (this.detailedViewEmissionFactorHistory.length === 0) {
                this.lastValueDeleted = true;
            } else {
                this.emissionFactor = this.detailedViewEmissionFactorHistory.shift();
                if (!!this.emissionFactor) {
                    this.emissionFactor.endDate = formatToDashedYYYYMMDD(new Date(2100, 11, 1));
                }
                if (!!this.detailedViewEmissionFactorHistory[0] && !!this.emissionFactor) {
                    this.emissionFactor.startDate = this.detailedViewEmissionFactorHistory[0].endDate;
                }
                this.setFormFieldsToEF();
            }
        },

        close(): void {
            this.emissionFactor = null;
            (this as any).$refs.editEFModal.close();
        },

        changeEmissionFactor(value: string): void {
            this.formFields.emissionFactor = value;
            this.validateEmissionFactor();
        },
        changeSource(value: string): void {
            this.formFields.source = value;
        },
        changeLink(value: string): void {
            this.formFields.link = value;
        },
        changeAdditionalInformation(value: string): void {
            this.formFields.additionalInformation = value;
        },
        changePublicationDate(newPeriod: string): void {
            this.formFields.publicationDate = newPeriod;
        },
        changeStartPeriod(newPeriod: string): void {
            this.formFields.startDate = newPeriod;
            this.validateStartDate();
            if (this.valueAdded) {
                this.detailedViewEmissionFactorHistory[0].endDate = newPeriod;
            }
        },

        validateStartDate(): void {
            if (this.valueAdded && !!this.detailedViewEmissionFactorHistory[0]) {
                const newStartDate = createDates(this.formFields.startDate).startDate;
                const previousEmissionStartDate = createDates(
                    !!this.detailedViewEmissionFactorHistory[0].startDate
                        ? this.detailedViewEmissionFactorHistory[0].startDate
                        : '',
                ).startDate;
                if (newStartDate <= previousEmissionStartDate) {
                    this.validFields.startDate = false;
                } else {
                    this.validFields.startDate = true;
                }
            }
        },
        validateEmissionFactor(): void {
            const value = Number(this.formFields.emissionFactor);
            this.validFields.emissionFactor =
                checkDecimalPlaces(value, MaxDecimalPlaces.EMISSION_FACTOR) && value < MaxValue.EMISSION_FACTOR;
        },

        async save(): Promise<void> {
            if (this.disableSave || this.isSaving) return;
            const { startDate } = createDates(this.formFields.startDate);
            const { endDate } = createDates(this.formFields.endDate);
            const publicationDate = this.formFields.publicationDate ? this.formFields.publicationDate : undefined;
            const body = {
                ...this.emissionFactor,
                id: this.emissionFactor?.id,
                startDate,
                endDate,
                emissionsFactor: Number(this.formFields.emissionFactor),
                source: this.formFields.source,
                linkToPublication: this.formFields.link,
                additionalInformation: this.formFields.additionalInformation,
                publicationDate: publicationDate,
                scope: this.currentScope,
            };
            try {
                if (this.lastValueDeleted) {
                    const payload = {
                        deleteId: this.emissionIdInTable,
                    };
                    await this.deleteLastEF(payload);
                } else if (this.valueDeleted) {
                    const payload = {
                        updateFactor: body,
                        deleteId: this.emissionIdInTable,
                    };
                    await this.deleteAndUpdateEF(payload);
                } else if (this.valueAdded) {
                    body.id = undefined;
                    const payload = {
                        body: body,
                        previousFactorId: this.emissionIdInTable,
                    };
                    await this.addEmissionFactor(payload);
                } else {
                    await this.editEmissionFactor(body);
                }
            } catch (e) {
                console.log(e);
                throw new Error();
            } finally {
                this.close();
            }
        },

        reset(): void {
            if (!this.emissionFactor) return;
            this.formFields.emissionFactor = this.emissionFactor?.emissionsFactor?.toString() ?? '';
            this.formFields.startDate = this.emissionFactor.startDate
                ? formatToDashedYYYYMMDD(new Date(this.emissionFactor.startDate))
                : '';
            this.formFields.endDate = this.emissionFactor.endDate
                ? formatToDashedYYYYMMDD(new Date(this.emissionFactor.endDate))
                : '';
            this.formFields.source = this.emissionFactor?.source ?? '';
            this.formFields.link = this.emissionFactor?.linkToPublication ?? '';
            this.formFields.additionalInformation = this.emissionFactor?.additionalInformation ?? '';
            this.formFields.publicationDate = this.emissionFactor.publicationDate
                ? formatToDashedYYYYMMDD(new Date(this.emissionFactor.publicationDate))
                : '';
            this.validateEmissionFactor();
        },

        generateToolTipMessage(): string {
            const input = this.formFields.emissionFactor;
            const value = Number(input);
            if (!checkDecimalPlaces(value, MaxDecimalPlaces['EMISSION_FACTOR'])) {
                const message = this.$t('input.invalid.decimal') as string;
                return message.replace(`{{ number }}`, MaxDecimalPlaces['EMISSION_FACTOR'].toString());
            }
            if (value >= MaxValue['EMISSION_FACTOR']) {
                const message = this.$t('input.invalid.tooLarge') as string;
                return message.replace(`{{ number }}`, MaxWholeNumbers['EMISSION_FACTOR'].toString());
            }
            return '';
        },
    },
});

export default ApprovalModal;
