
import Vue from 'vue';

export default Vue.extend({
    name: 'HeaderEmissionFactor',

    props: {
        title: {
            type: String,
            default: '',
        },
    },
});
