
import { LocationTableData } from '@/models/table';
import { EmissionWithParkedEmissionDto, BadRequestResponse } from '@/service-proxies/service-proxies.g';
import { DateDisplayOptions, formatDate, formatLocationName } from '@/utils/helpers/formatters';
import { translateWithPlaceholders } from '@/utils/helpers/i18n';
import Vue, { nextTick } from 'vue';
import { mapActions, mapGetters } from 'vuex';

interface Data {
    bodyMessage: string;
    emissions: EmissionWithParkedEmissionDto[];
    reset: Function;
}

export default Vue.extend({
    name: 'ApproveConfirmModal',

    data(): Data {
        return {
            bodyMessage: '',
            emissions: [],
            reset: () => {},
        };
    },

    computed: {
        ...mapGetters({
            isLoading: 'isLoading',
        }),
        ...mapGetters('country', {
            getCurrentCountry: 'GET_SELECTED_COUNTRY',
        }),
        ...mapGetters('location', {
            getStartDate: 'start',
        }),
        ...mapGetters('detailedView', {
            emission: 'GET_EDITED_EMISSION',
        }),
    },

    methods: {
        ...mapActions('location', {
            approveBulkEmissions: 'APPROVE_BULK_EMISSIONS',
            approveSingularEmission: 'APPROVE_SINGULAR_EMISSION',
        }),

        open(data: Record<string, LocationTableData>, reset: Function) {
            const { powerPlant, unit, activity, bulkApprovals } = data;
            this.reset = reset;
            this.emissions = bulkApprovals
                ? Object.values(bulkApprovals).map((ba) => ba.activity.meta.object)
                : [activity.meta.object];

            const placeholders = {
                location: [powerPlant.location, unit?.location].filter(Boolean).join(' - '),
                fuel: activity.location,
                date: formatDate(this.getStartDate, this.$i18n.locale, DateDisplayOptions.MonthOnly),
                emissions: bulkApprovals
                    ? Object.values(bulkApprovals || {})
                          .map((ba) => {
                              const { powerPlant, unit, activity } = ba;
                              const location = formatLocationName(powerPlant.location, unit?.location);
                              return translateWithPlaceholders('approvals.approveModal.bulk.emission', {
                                  location,
                                  fuel: activity.location,
                              });
                          })
                          .sort((a, b) => a.toLocaleUpperCase().localeCompare(b.toLocaleUpperCase()))
                          .join('<br />')
                    : translateWithPlaceholders('approvals.approveModal.bulk.emission', {
                          location: formatLocationName(powerPlant.location, unit?.location),
                          fuel: activity.location,
                      }),
            };

            const translationKey = 'approvals.approveModal.bulk.body';
            this.bodyMessage = translateWithPlaceholders(translationKey, placeholders) as string;

            (this as any).$refs['approve-warning-modal'].open();
        },

        async onConfirmCallback() {
            if (this.isLoading) return;
            const emissionIds = this.emissions.map((emission) => emission.id!).filter((id) => id !== undefined);
            await this.approveEmission(emissionIds);

            (this as any).$refs['approve-warning-modal'].close();
        },

        onCloseCallback() {
            this.bodyMessage = '';
            this.emissions = [];
        },

        async approveEmission(emissions: number[]) {
            this.$store.commit('loading');

            try {
                if (emissions.length > 1) {
                    await this.approveBulkEmissions(emissions);
                } else {
                    await this.approveSingularEmission({
                        emissionId: emissions[0],
                        comment: (this.emission as EmissionWithParkedEmissionDto).comment,
                    });
                }
                await nextTick();
                (this as any).$pui.toast({
                    type: 'success',
                    title: this.$t('notification.approvals.savedChanges'),
                    copy: '',
                });
                this.emissions = [];
                this.reset();
            } catch (e) {
                const message = (e as BadRequestResponse).message ?? '';
                (this as any).$pui.toast({
                    type: 'error',
                    title: this.$t('toast.error'),
                    copy: message,
                });
                console.log(e);
                throw e;
            } finally {
                await nextTick();
                this.$store.commit('loading');
                this.onCloseCallback();
            }
        },
    },
});
