/* eslint-disable */
import { PowerPlantDto, AttachmentDto } from '@/service-proxies/service-proxies.g';

/**
 * Loops through all of the power plants and units (if applicable)
 * and loops through the emissions and extracts any attachments associated
 * with the emission.
 *
 * @param locations - A list of powerplants of a particular country and scope.
 * @returns an array of all the attached documents in the emissions.
 */
export function getAttachments(locations: PowerPlantDto[]): AttachmentDto[] {
    let attachments: AttachmentDto[] = [];

    for (const powerPlant of locations) {
        if (powerPlant.units) {
            for (const unit of powerPlant.units) {
                const emissions = unit.emissions;
                if (emissions) {
                    for (const emission of emissions) {
                        if (emission.attachments) {
                            attachments = [...attachments, ...emission.attachments];
                        }
                    }
                }
            }
        } else {
            const emissions = powerPlant.emissions;
            if (emissions) {
                for (const emission of emissions) {
                    if (emission.attachments) {
                        attachments = [...attachments, ...emission.attachments];
                    }
                }
            }
        }
    }

    return attachments;
}
