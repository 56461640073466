
import Vue from 'vue';
import { PropType } from 'vue';
import { BulkEditValues } from '@/store/modules/standard-emission';
import { DateDisplayOptions, formatDate } from '@/utils/helpers/formatters';

export default Vue.extend({
    name: 'BulkEditValuesList',
    props: {
        bulkEditValues: {
            type: Object as PropType<BulkEditValues>,
            required: true,
        },
    },
    computed: {
        formatPublicationDate(): string {
            if (this.bulkEditValues?.publicationDate) {
                return formatDate(
                    this.bulkEditValues?.publicationDate,
                    this.$i18n.locale,
                    DateDisplayOptions.MonthOnly,
                );
            }
            return '';
        },
    },
});
