import { render, staticRenderFns } from "./dev-tools.vue?vue&type=template&id=4124d750&scoped=true&class=dev-tools"
import script from "./dev-tools.vue?vue&type=script&lang=ts"
export * from "./dev-tools.vue?vue&type=script&lang=ts"
import style0 from "./dev-tools.vue?vue&type=style&index=0&id=4124d750&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4124d750",
  null
  
)

export default component.exports