
import Vue from 'vue';
import GranularitySection from '@/components/granularity/granularity-section.vue';
import InputBoxSkeleton from '@/components/ui/loading/skeletons/input-box-skeleton.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import type { MultiselectOption } from '@/models';
import type { CountryDto } from '@/service-proxies/service-proxies.g';

export default Vue.extend({
    name: 'HeaderWithCountryAndMonthlyGranularity',

    components: {
        GranularitySection,
        InputBoxSkeleton,
    },

    props: {
        title: {
            type: String,
            default: '',
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            selectedCountry: '',
            loadingData: false,
        };
    },

    computed: {
        ...mapGetters('country', {
            getCountries: 'GET_COUNTRIES',
            getCurrentCountry: 'GET_SELECTED_COUNTRY',
        }),

        countryOptions(): MultiselectOption[] {
            return this.getCountries.map((country: CountryDto) => ({
                label: country.name ?? '',
                value: country.id ?? 0,
                secondaryLabel: country.code,
            }));
        },
    },

    watch: {
        selectedCountry(countryId): void {
            this.setCurrentCountryId(countryId);
        },
    },

    mounted() {
        if (this.getCurrentCountry) {
            this.selectedCountry = this.getCurrentCountry.id;
        } else {
            this.fetchData();
        }
    },

    methods: {
        ...mapActions('country', {
            fetchCountries: 'GET_COUNTRIES',
        }),
        ...mapActions('scope', {
            fetchScopes: 'GET_SCOPES',
        }),

        ...mapMutations('country', {
            setCurrentCountryId: 'SET_SELECTED_COUNTRY_ID',
        }),
        ...mapMutations('scope', {
            setSelectedScope: 'SET_SELECTED_SCOPE_ID',
        }),

        async fetchData(): Promise<void> {
            this.loadingData = true;
            await Promise.all([this.fetchCountries(3), this.fetchScopes()]);
            this.selectedCountry = this.getCountries[0].id ?? '';
            this.setSelectedScope(3); // Scope 1.1
            this.loadingData = false;
        },
    },
});
