
import { PowerPlantRisks } from '@/models/power-plant';
import Vue from 'vue';
import RiskClassificationUnit from './risk-classification-unit.vue';

const RiskClassificationTable = Vue.extend({
    name: 'RiskClassificationTable',
    components: {
        RiskClassificationUnit,
    },
    props: {
        classificatorData: {
            type: Array,
            required: true,
        },
    },
    data(): {
        data: PowerPlantRisks[];
    } {
        return {
            data: [],
        };
    },
    mounted() {
        this.data = this.classificatorData as PowerPlantRisks[];
    },
});

export default RiskClassificationTable;
