import { HttpStatusCodes } from '@/enums/http-status-codes';
import { EmissionQuotaProductDto, LocationType, PowerPlantDto } from '@/service-proxies/service-proxies.g';
import { EmissionQuotaProductService } from '@/services/emission-quota-product-service';
import { LocationService } from '@/services/location-service';
import axios, { AxiosError } from 'axios';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ApplicationState } from '..';

import type { PowerPlantListEndpointRequest } from '@/models/power-plant';

const emissionQuotaProductService: EmissionQuotaProductService = new EmissionQuotaProductService();
const locationService: LocationService = new LocationService();

export interface PowerPlant {
    sid: number;
    locationType: LocationType;
    name: string;
    units: Unit[];
}

export interface Unit {
    sid: number;
    name: string;
}

export enum EQGenerationProduct {
    DistrictHeat = 'DistrictHeat',
    Fuel = 'Fuel',
    Steam = 'Steam',
}

export interface EmissionQuotaProduct {
    id: number | null;
    countrySid: number;
    plantSid: number;
    machineSid: number;
    periodStart: number;
    periodEnd: number;
    emissionQuotas: EmissionQuota[];
    generationProduct: EQGenerationProduct;

    comment?: string;
    createDate?: string;
    createUserKid?: string;
    createComment?: string;
    validFrom?: string;
    validTo?: string;
    updateDate?: string;
    updateUserKid?: string;
    updateComment?: string;
}

export interface EmissionQuota {
    id?: number;
    year: number;
    locationId: number;
    locationType?: LocationType;
    generationProduct: EQGenerationProduct;
    emissionQuotaAmount: number;
    originalQuotaAmount: number;
}

export interface EmissionQuotaState {
    eqProducts: EmissionQuotaProduct[];
    powerPlants: PowerPlant[];
    tradingPeriodStartDate: string;
}

const state: EmissionQuotaState = {
    eqProducts: [],
    powerPlants: [],
    tradingPeriodStartDate: '2021-01-01',
};

const actions: ActionTree<EmissionQuotaState, ApplicationState> = {
    async FETCH_POWER_PLANTS(
        { commit }: ActionContext<EmissionQuotaState, ApplicationState>,
        payload: { countryId?: number; scopeId?: number },
    ): Promise<HttpStatusCodes> {
        try {
            const res = await locationService.getPowerPlants({
                countryId: payload.countryId,
                scopeId: payload.scopeId ?? 3,
            } as PowerPlantListEndpointRequest);

            commit('SET_POWER_PLANTS', res.result);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return (e as AxiosError).response?.status ?? HttpStatusCodes.InternalServerError;
            } else {
                console.error(`An unknown error occurred while fetching the power plant data.`);
                console.error(e);
                return HttpStatusCodes.InternalServerError;
            }
        }
        return HttpStatusCodes.Ok;
    },

    async FETCH_EQ_PRODUCTS(
        { commit }: ActionContext<EmissionQuotaState, ApplicationState>,
        payload: { startYear: number; endYear: number; countryId: number },
    ): Promise<HttpStatusCodes> {
        try {
            const { startYear, endYear, countryId } = payload;
            const res = await emissionQuotaProductService.getEmissionQuotaProducts(startYear, endYear, countryId);
            commit('SET_EQ_PRODUCTS_AND_FORMAT', res.result?.items ?? []);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return (e as AxiosError).response?.status ?? HttpStatusCodes.InternalServerError;
            } else {
                console.error(`An unknown error occurred while fetching the emission quota data.`);
                console.error(e);
                return HttpStatusCodes.InternalServerError;
            }
        }
        return HttpStatusCodes.Ok;
    },

    async POST_EQ_PRODUCT(
        { commit }: ActionContext<EmissionQuotaState, ApplicationState>,
        eqProduct: EmissionQuotaProduct,
    ): Promise<HttpStatusCodes> {
        try {
            const res = await emissionQuotaProductService.addEmissionQuotaProduct(
                eqProduct as unknown as EmissionQuotaProductDto,
            );
            commit('REMOVE_EQ_PRODUCT', eqProduct); // Delete the temporary local version with no `id`
            commit('ADD_EQ_PRODUCT_AND_FORMAT', res.result);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return (e as AxiosError).response?.status ?? HttpStatusCodes.InternalServerError;
            } else {
                console.error(`An unknown error occurred while fetching the emission quota data.`);
                console.error(e);
                return HttpStatusCodes.InternalServerError;
            }
        }
        return HttpStatusCodes.Ok;
    },

    async PUT_EQ_PRODUCT(
        { commit }: ActionContext<EmissionQuotaState, ApplicationState>,
        eqProduct: EmissionQuotaProduct,
    ): Promise<HttpStatusCodes> {
        try {
            const res = await emissionQuotaProductService.updateEmissionQuotaProduct(
                eqProduct as unknown as EmissionQuotaProductDto,
            );
            commit('REMOVE_EQ_PRODUCT', eqProduct);
            commit('ADD_EQ_PRODUCT_AND_FORMAT', res.result?.item);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return (e as AxiosError).response?.status ?? HttpStatusCodes.InternalServerError;
            } else {
                console.error(`An unknown error occurred while fetching the emission quota data.`);
                console.error(e);
                return HttpStatusCodes.InternalServerError;
            }
        }
        return HttpStatusCodes.Ok;
    },

    async DELETE_EQ_PRODUCT(_, id: number): Promise<HttpStatusCodes> {
        try {
            const res = await emissionQuotaProductService.deleteEmissionQuotaProduct(id);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return (e as AxiosError).response?.status ?? HttpStatusCodes.InternalServerError;
            } else {
                console.error(`An unknown error occurred while fetching the emission quota data.`);
                console.error(e);
                return HttpStatusCodes.InternalServerError;
            }
        }
        return HttpStatusCodes.Ok;
    },
};

const mutations: MutationTree<EmissionQuotaState> = {
    SET_POWER_PLANTS(state: EmissionQuotaState, data: PowerPlantDto[]) {
        state.powerPlants = data
            .map(
                (pp) =>
                    ({
                        sid: pp.sid,
                        name: pp.name,
                        locationType: pp.locationType,
                        units: (pp.units ?? [])
                            .map(
                                (u) =>
                                    ({
                                        sid: u.sid,
                                        name: u.name,
                                    } as Unit),
                            )
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .filter((unit, index, array) => !index || unit.sid !== array[index - 1].sid),
                    } as PowerPlant),
            )
            .sort((a, b) => a.name.localeCompare(b.name));
    },

    SET_EQ_PRODUCTS_AND_FORMAT(state: EmissionQuotaState, data: EmissionQuotaProductDto[]) {
        const eqProducts: EmissionQuotaProduct[] = [];
        for (const eqp of data) {
            eqProducts.push({
                ...eqp,

                emissionQuotas: (eqp.emissionQuotas ?? []).map((eq) => ({
                    ...eq,
                    originalQuotaAmount: eq.emissionQuotaAmount,
                })),
            } as unknown as EmissionQuotaProduct);
        }
        state.eqProducts = eqProducts;
    },

    ADD_EQ_PRODUCT_AND_FORMAT(state: EmissionQuotaState, data: EmissionQuotaProductDto) {
        const newEqProduct: EmissionQuotaProduct = {
            ...data,
            emissionQuotas:
                data.emissionQuotas?.map((eq) => ({
                    ...eq,
                    originalQuotaAmount: eq.emissionQuotaAmount,
                })) ?? [],
        } as unknown as EmissionQuotaProduct;

        state.eqProducts = [...state.eqProducts, newEqProduct];
    },

    CREATE_FORMATTED_EQ_PRODUCT(state: EmissionQuotaState, data: EmissionQuotaProduct) {
        state.eqProducts = [...state.eqProducts, data];
    },

    FILL_MISSING_QUOTAS(state: EmissionQuotaState, dataId: number) {
        const product = state.eqProducts.find((product) => product.id === dataId);
        if (!product) {
            console.error('Could not find EQ Product', dataId);
            throw new Error();
        }
        const productIndex = state.eqProducts.findIndex((product) => product.id === dataId);
        const newProduct = {
            ...product,
            emissionQuotas: Array(5)
                .fill(0)
                .map((_, i) => ({
                    locationId: product.machineSid,
                    generationProduct: product.generationProduct,
                    year: product.periodStart + i,
                    emissionQuotaAmount: 0,
                    originalQuotaAmount: 0,
                })),
        } as EmissionQuotaProduct;
        state.eqProducts[productIndex] = newProduct;
    },

    UPDATE_EQ_PRODUCT_EMISSION_QUOTA_AMOUNT(
        state: EmissionQuotaState,
        data: {
            countrySid: number;
            plantSid: number;
            unitSid: number;
            generationProduct: EQGenerationProduct;
            periodStart: number;
            year: number;
            amount: number;
        },
    ) {
        const eqProduct = state.eqProducts.find(
            (eqp) =>
                eqp.countrySid === data.countrySid &&
                eqp.plantSid === data.plantSid &&
                eqp.machineSid === data.unitSid &&
                eqp.generationProduct === data.generationProduct &&
                eqp.periodStart === data.periodStart,
        );
        if (!eqProduct) {
            console.error('Could not find EQ product:', data);
            return;
        }

        const emissionQuota = eqProduct.emissionQuotas.find((eq) => eq.year === data.year);
        if (!emissionQuota) {
            console.error('Could not find emission quota:', data);
            return;
        }

        emissionQuota.emissionQuotaAmount = data.amount;
        state.eqProducts = [...state.eqProducts];
    },

    REMOVE_EQ_PRODUCT(
        state: EmissionQuotaState,
        data: {
            countrySid: number;
            plantSid: number;
            machineSid: number;
            generationProduct: EQGenerationProduct;
            periodStart: number;
        },
    ) {
        state.eqProducts = state.eqProducts.filter(
            (eqp) =>
                !(
                    eqp.countrySid === data.countrySid &&
                    eqp.plantSid === data.plantSid &&
                    eqp.machineSid === data.machineSid &&
                    eqp.generationProduct === data.generationProduct &&
                    eqp.periodStart === data.periodStart
                ),
        );
    },

    SET_TRADING_PERIOD_START_DATE(state: EmissionQuotaState, data: string) {
        state.tradingPeriodStartDate = data;
    },
};

const getters: GetterTree<EmissionQuotaState, ApplicationState> = {
    GET_POWER_PLANTS: (state): PowerPlant[] => state.powerPlants,
    GET_EQ_PRODUCTS: (state): EmissionQuotaProduct[] => state.eqProducts,

    GET_TRADING_PERIOD_START_DATE: (state): string => state.tradingPeriodStartDate,
    GET_TRADING_PERIOD_START_YEAR: (state): number => parseInt(state.tradingPeriodStartDate.split('-')[0]),
};

const namespaced = true;

export const emissionQuota: Module<EmissionQuotaState, ApplicationState> = {
    namespaced,
    state,
    actions,
    mutations,
    getters,
};
