import { EmissionFactorType } from '@/enums/emission-factor-type';
import { EmissionDto, EmissionFactorDto } from '@/service-proxies/service-proxies.g';
import Vue from 'vue';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ApplicationState } from '..';
import { EmissionService } from '@/services/emission-service';

export interface EmissionDrafts {
    [unitId: number]: EmissionDto[];
}

export interface EmissionDraftState {
    emissionDrafts: EmissionDrafts;
    emissionService: EmissionService;
}

const state: EmissionDraftState = {
    emissionDrafts: {},
    emissionService: new EmissionService(),
};

const getters: GetterTree<EmissionDraftState, ApplicationState> = {
    GET_DRAFT:
        (state) =>
        (unitId: number, activityDataId: number): EmissionDto | undefined => {
            const draftCandidate = state.emissionDrafts[unitId]?.find(
                (draft) => draft?.activityData?.id === activityDataId,
            );
            if (draftCandidate && !draftCandidate.emissionFactor) {
                draftCandidate.emissionFactor = {
                    type: EmissionFactorType.Individual,
                    id: 0,
                    source: 'Manually Entered',
                    emissionsFactor: undefined,
                    toJSON: EmissionFactorDto.prototype.toJSON,
                } as EmissionFactorDto;
            }
            return draftCandidate;
        },
};

const mutations: MutationTree<EmissionDraftState> = {
    SET_DRAFT(state: EmissionDraftState, draft: EmissionDto) {
        const unitDrafts = state.emissionDrafts[draft!.locationId!];
        const existingDraft = unitDrafts?.find((d) => d.activityData?.id === draft.activityData!.id);

        if (existingDraft) {
            const existingIndex = unitDrafts.findIndex((d) => d === existingDraft);
            unitDrafts.splice(existingIndex, 1, draft);
        } else {
            unitDrafts.push(draft);
        }
    },

    SET_DRAFTS_FOR_ASSET(state: EmissionDraftState, { assetId, drafts }: { assetId: number; drafts: EmissionDto[] }) {
        const newDrafts = state.emissionDrafts[assetId] ? state.emissionDrafts[assetId].concat(drafts) : drafts;

        Vue.set(state.emissionDrafts, assetId, newDrafts);
    },

    DELETE_DRAFT(state: EmissionDraftState, { unitId, activityDataId }: { unitId: number; activityDataId: number }) {
        const emissionsForUnit = state.emissionDrafts[unitId];
        const index = emissionsForUnit.findIndex((e) => e.activityData!.id === activityDataId);
        emissionsForUnit.splice(index, 1);
    },

    RESET_DRAFTS(state: EmissionDraftState): void {
        Vue.set(state, 'emissionDrafts', {});
    },
};

const actions: ActionTree<EmissionDraftState, ApplicationState> = {
    async GET_EMISSION_DRAFTS(
        { commit }: ActionContext<EmissionDraftState, ApplicationState>,
        {
            selectedActivityDataIds,
            assetId,
            scopeId,
        }: {
            selectedActivityDataIds: number[];
            assetId: number;
            scopeId: number;
        },
    ): Promise<{ assetId: number; drafts: EmissionDto[] | undefined }> {
        const filter = {
            activityDataInformation: selectedActivityDataIds,
            locationId: assetId,
            scopeId: scopeId,
        };
        const response = await state.emissionService.getEmissionDrafts(filter);
        const result = response.result;
        result?.forEach((emissionDraft) => (emissionDraft.locationId = assetId));
        const draftAsset = { assetId: assetId, drafts: result };
        commit('SET_DRAFTS_FOR_ASSET', draftAsset);
        return draftAsset;
    },
};

export const emissionDraft: Module<EmissionDraftState, ApplicationState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
