import Vue from 'vue';
import { TranslationsManager } from '@translations/translation-plugin';

export const getTranslationManager = (): TranslationsManager => {
    const translationManager = Vue.prototype.$translationManager;
    if (!translationManager) {
        throw new Error('Can not get translationManager, please ensure that you initialized plugin correctly.');
    }

    return translationManager;
};
