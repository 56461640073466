import { TableLevel } from '@/enums/TableLevel';
import { LocationTableData } from '@/models/table';
import {
    EmissionWithParkedEmissionDto,
    FleetDto,
    MasterDataLevel,
    PowerPlantDto,
    UnitDto,
} from '@/service-proxies/service-proxies.g';
import store from '@/store';
import { formatActivityDataDisplayName } from '@/utils/helpers/formatters';
import { TableType } from '@/utils/tables/table-config';

export class LocationTableDataMapper {
    private static sortByLocation(a: LocationTableData, b: LocationTableData): number {
        return a.location.toLocaleLowerCase().localeCompare(b.location.toLocaleLowerCase());
    }

    public mapLocationsToTableData(locations: PowerPlantDto[], tableType: TableType): LocationTableData[] {
        const masterDataLevel = store.getters['masterDataLevel/masterDataLevel'];
        const isUnitLevel = masterDataLevel === MasterDataLevel.UnitLevel;
        const fleets = store.getters['fleet/fleets'] as FleetDto[];
        return (
            locations
                ?.map((powerPlant) => ({
                    location: powerPlant.name!,
                    fleetId: powerPlant.fleetId,
                    meta: {
                        id: powerPlant.sid!,
                        level: TableLevel.Plant,
                        expanded: true,
                        fleet: fleets.find((f) => f.sid === powerPlant.fleetId),
                        object: powerPlant,
                        isComplete: (powerPlant as any).isComplete || false,
                        holdsEmissions: masterDataLevel === MasterDataLevel.PowerPlantLevel,
                    },
                    _children: isUnitLevel
                        ? this.mapUnits(powerPlant.units, tableType, {
                              holdsEmissions: masterDataLevel === MasterDataLevel.UnitLevel,
                          })
                        : this.mapEmissions(powerPlant.emissions, tableType),
                }))
                .sort(LocationTableDataMapper.sortByLocation) || []
        );
    }

    private mapUnits(
        units: UnitDto[] | undefined,
        tableType: TableType,
        additionalMetaAttributes?: object,
    ): LocationTableData[] {
        return (
            units
                ?.map((unit) => ({
                    location: unit.name!,
                    meta: {
                        id: unit.sid!,
                        level: TableLevel.Unit,
                        expanded: true,
                        object: unit,
                        holdsEmissions: false, // additionalMetaAttributes overwrites this but needed for type safety
                        isComplete: unit.isComplete,
                        ...additionalMetaAttributes,
                    },
                    _children: this.mapEmissions(unit.emissions, tableType),
                }))
                .sort(LocationTableDataMapper.sortByLocation)
                .filter((unit, index, array) => !index || unit.meta.id !== array[index - 1].meta.id) || []
        );
    }

    private mapEmissions(
        emissions: EmissionWithParkedEmissionDto[] | undefined,
        tableType: TableType,
        additionalMetaAttributes?: object,
    ): LocationTableData[] {
        const mappedEmissions =
            emissions
                ?.map((emission) => {
                    return {
                        location: formatActivityDataDisplayName(emission.activityData),
                        status: emission.approvalStatus?.toString(),
                        activityDataAmount: emission.activityDataAmount,
                        emissionFactor: emission.emissionFactor?.emissionsFactor,
                        ghgAmount: emission.ghgAmount,
                        heatValue: emission.emissionFactor?.heatValue,
                        heatBasedEmissionFactor: emission.emissionFactor?.heatBasedEmissionFactor,
                        meta: {
                            id: emission.id || 0,
                            level: TableLevel.Emission,
                            expanded: true,
                            object: emission,
                            formatter: {
                                greenHouseGasUnitOfMeasurement: emission.ghgUnitOfMeasurement?.shortName,
                                activityDataUnitOfMeasurement: emission.activityDataUnitOfMeasurement?.shortName,
                            },
                            holdsEmissions: false,
                            ...additionalMetaAttributes,
                        } as any,
                    };
                })
                .sort(LocationTableDataMapper.sortByLocation) || [];
        return tableType === TableType.APPROVAL
            ? mappedEmissions.filter((emission) => emission.meta.id)
            : mappedEmissions;
    }
}
