import {
    AttachmentServiceProxy,
    AttachmentGetEndpointResponseOkResponse,
    AttachmentDeleteEndpointResponseOkResponse,
    AttachmentPostEndpointResponseOkResponse,
    FileParameter,
} from '@/service-proxies/service-proxies.g';

/**
 * @name AttachmentService
 * @description This class is used to query the backend server for attachment data
 */
export class AttachmentService {
    private attachmentServiceProxy: AttachmentServiceProxy;

    constructor() {
        this.attachmentServiceProxy = new AttachmentServiceProxy();
    }

    /**
     * Get attachment by id.
     * @param id
     */
    public getAttachment = async (id: number): Promise<AttachmentGetEndpointResponseOkResponse> => {
        return await this.attachmentServiceProxy.getAttachment(id);
    };

    /**
     * Delete attachment by id.
     * @param id
     */
    public deleteAttachment = async (id: number): Promise<AttachmentDeleteEndpointResponseOkResponse> => {
        return await this.attachmentServiceProxy.deleteAttachment(id);
    };

    /**
     * Post a new attachment for a given emission.
     * @param emissionId Id of the emission
     * @param file File to upload
     */
    public postAttachment = async ({
        emissionId,
        file,
    }: {
        emissionId: number;
        file: FileParameter;
    }): Promise<AttachmentPostEndpointResponseOkResponse> => {
        return await this.attachmentServiceProxy.postAttachment(emissionId, file);
    };
}
