
import Vue from 'vue';
import { HttpStatusCodes } from '@/enums/http-status-codes';
import { CountryDto, ScopeDto } from '@/service-proxies/service-proxies.g';
import { MultiselectOption } from '@/models';
import { Suspense } from '../ui/loading/suspense';
import { mapGetters, mapMutations } from 'vuex';
import InputBoxSkeleton from '../ui/loading/skeletons/input-box-skeleton.vue';
import { DEFAULT_SCOPE_ID } from '@/constants/default';

export default Vue.extend({
    name: 'HeaderWithCountryScopeAndMonthlyGranularity',
    components: {
        Suspense,
        InputBoxSkeleton,
    },
    data(): {
        selectedScope: number;
        selectedCountry: number;
        isLoadingData: boolean;
    } {
        return {
            selectedScope: 0,
            selectedCountry: 0,
            isLoadingData: false,
        };
    },
    computed: {
        ...mapGetters('country', {
            getCountries: 'GET_COUNTRIES',
            getSelectedCountry: 'GET_SELECTED_COUNTRY',
        }),
        ...mapGetters('fleet', {
            isFleetLoading: 'loadFleet',
        }),
        ...mapGetters('scope', {
            getScopes: 'GET_SCOPES',
            getSelectedScope: 'GET_SELECTED_SCOPE',
        }),
        countryOptions(): MultiselectOption[] {
            return (this.getCountries as CountryDto[]).map((country: CountryDto) => ({
                label: country.name ?? '',
                value: country.id ?? 0,
                secondaryLabel: country.code,
            }));
        },
        scopeOptions(): MultiselectOption[] {
            return (this.getScopes as ScopeDto[])
                .filter((x) => x.dataInputAllowed)
                .filter((x) => x.id === DEFAULT_SCOPE_ID) //TODO - remove/change once other scopes are ready to be used in the app
                .map((x) => ({
                    label: x.subScope ?? '',
                    value: x.id ?? 0,
                    secondaryLabel: this.$t(`scope${x?.subScope?.replace('.', '_') ?? ''}`).toString(),
                }));
        },
    },
    methods: {
        ...mapMutations('country', { setSelectedCountryId: 'SET_SELECTED_COUNTRY_ID' }),
        ...mapMutations('scope', { setSelectedScopeId: 'SET_SELECTED_SCOPE_ID' }),
        async setup(): Promise<void> {
            this.isLoadingData = true;
            await Promise.all([this.fetchCountries(), this.fetchScopes()]);
            this.isLoadingData = false;
        },
        async fetchCountries(): Promise<void> {
            if (this.getSelectedCountry) {
                this.selectedCountry = this.getSelectedCountry.id!;
                return;
            }

            const statusCountries = (await this.$store.dispatch(
                'country/GET_COUNTRIES',
                this.selectedScope ? this.selectedScope : DEFAULT_SCOPE_ID,
            )) as HttpStatusCodes;
            if (statusCountries != HttpStatusCodes.Ok) {
                console.error('Could not load countries from backend.');
            }

            this.countrySelected(this.countryOptions[0].value as number);
        },
        countrySelected(countryId: number): void {
            this.selectedCountry = countryId;
            this.setSelectedCountryId(countryId);
        },
        async fetchScopes(): Promise<void> {
            if (this.getSelectedScope) {
                this.selectedScope = this.getSelectedScope.id!;
                return;
            }

            const statusScopes = (await this.$store.dispatch('scope/GET_SCOPES')) as HttpStatusCodes;
            if (statusScopes != HttpStatusCodes.Ok) {
                console.error('Could not load scopes from backend.');
            }

            this.scopeSelected(this.scopeOptions[0].value as number);
        },
        async scopeSelected(scopeId: number): Promise<void> {
            this.selectedScope = scopeId;
            this.setSelectedScopeId(scopeId);
        },
    },
});
