import { HttpStatusCodes } from '@/enums/http-status-codes';
import { BasicResponse } from '@/models/api-response';
import { ToastArgument } from '@/models/pebble-ui';
import { ApiException } from '@/service-proxies/service-proxies.g';
import { WAITING_MODAL_CLOSE_STRING, WAITING_MODAL_OPEN_STRING } from '@/store/modules/waiting-modal';
import { sleep } from '@/utils/javascript-utils';
import { Store } from 'vuex';

type GetFirstArgumentOfAnyFunction<T> = T extends (first: infer FirstArgument, ...args: any[]) => any
    ? FirstArgument
    : never;

export const apiCallWithErrorHandling = async <T extends (arg: any) => Promise<any>>(
    apiFunction: T,
    argument: GetFirstArgumentOfAnyFunction<T>,
    toastFunction: (arg: ToastArgument) => void,
    strings: {
        successTitle?: string;
        successText?: string;
        errorTitle: string;
        errorText: string;
        waitingTitle?: string;
        waitingText?: string;
        forbiddenErrorText?: string;
        conflictErrorText?: string;
    },
    store: Store<any>,
    additionalWaitInSeconds = 0,
    showWaitingModal = true,
    // eslint-disable-next-line sonarjs/cognitive-complexity
): Promise<ReturnType<T>> => {
    if (showWaitingModal && strings.waitingText && strings.waitingTitle) {
        store.commit(WAITING_MODAL_OPEN_STRING, {
            title: strings.waitingTitle,
            content: strings.waitingText,
        });
    }

    let response: BasicResponse | undefined = undefined;
    let errorShown = false;
    try {
        response = await apiFunction(argument);
    } catch (error) {
        if ((error as any).isApiException) {
            const apiException = error as ApiException;
            let errorText: string | undefined = undefined;
            if (apiException.status === HttpStatusCodes.Conflict) {
                errorText = strings.conflictErrorText ?? strings.errorText;
            } else if (apiException.status === HttpStatusCodes.Forbidden) {
                errorText = strings.forbiddenErrorText ?? strings.errorText;
            }
            if (errorText) {
                toastFunction({
                    type: 'error',
                    title: strings.errorTitle,
                    copy: errorText,
                });
                errorShown = true;
            }

            // Fill response with error code and message
            response = {
                statusCode: apiException.status,
                message: apiException.message,
            };
        }

        // Raised by UserFriendlyException? Then "error" looks different
        response = {
            statusCode: (error as any).statusCode,
            message: (error as any).message,
        };
    } finally {
        if (response?.statusCode === 200) {
            if (strings.successTitle && strings.successText) {
                toastFunction({
                    type: 'success',
                    title: strings.successTitle,
                    copy: strings.successText,
                });
            }
        } else {
            if (!errorShown) {
                toastFunction({
                    type: 'error',
                    title: strings.errorTitle,
                    copy: strings.errorText,
                });
            }
        }

        if (strings.waitingText && strings.waitingTitle) {
            if (additionalWaitInSeconds > 0) {
                await sleep(additionalWaitInSeconds * 1000);
            }
            if (showWaitingModal) {
                store.commit(WAITING_MODAL_CLOSE_STRING);
            }
        }
    }

    return response as ReturnType<T>;
};
