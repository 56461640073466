
import Vue from 'vue';
import UnitOfMeasurement from '../unit-of-measurement/unit-of-measurement.vue';

export default Vue.extend({
    name: 'ModalInputWrapper',
    components: {
        UnitOfMeasurement,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        notitle: {
            type: Boolean,
            default: false,
        },
        tooltip: {
            type: String,
            default: '',
        },
        uom: {
            type: String,
            default: '',
        },
        rightmost: {
            type: Boolean,
            default: false,
        },
        leftmost: {
            type: Boolean,
            default: false,
        },
    },
});
