import { render, staticRenderFns } from "./bulk-error-modal.vue?vue&type=template&id=667874c4&scoped=true"
import script from "./bulk-error-modal.vue?vue&type=script&lang=ts"
export * from "./bulk-error-modal.vue?vue&type=script&lang=ts"
import style0 from "./bulk-error-modal.vue?vue&type=style&index=0&id=667874c4&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "667874c4",
  null
  
)

export default component.exports