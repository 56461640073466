
import Vue, { PropType } from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import CommentField from '../comments/unit-period-comment-field.vue';
import FloatInput from '../inputs/float-input.vue';
import { HttpStatusCodes } from '@/enums/http-status-codes';
import { EQGenerationProduct } from '@/store/modules/emission-quota';
import { DateDisplayOptions } from '@/utils/helpers/formatters';

import type { EmissionQuotaProduct } from '@/store/modules/emission-quota';
import { formatDate } from '@/utils/helpers/formatters';

export default Vue.extend({
    name: 'Product',

    components: {
        CommentField,
        FloatInput,
    },

    props: {
        plantSid: {
            type: Number,
            required: true,
        },
        unitSid: {
            type: Number,
            required: true,
        },
        productType: {
            type: String as PropType<EQGenerationProduct>,
            required: true,
        },
        product: {
            type: Object as () => EmissionQuotaProduct | null,
            required: false,
            default: null,
        },
    },

    data() {
        return {
            commentText: undefined as string | undefined,
            deletionPending: false,
            isSaving: false,
        };
    },

    computed: {
        ...mapGetters('country', {
            getCurrentCountry: 'GET_SELECTED_COUNTRY',
        }),
        ...mapGetters('emissionQuota', {
            getTradingPeriodStartYear: 'GET_TRADING_PERIOD_START_YEAR',
        }),
        ...mapGetters('scope', {
            getScope: 'GET_SCOPE',
        }),

        name(): string {
            return this.$t(`generationProduct.${this.productType}`) as string;
        },

        commentLastChanged(): string | undefined {
            const date = this.product?.updateDate || this.product?.createDate || '';
            return formatDate(date, this.$i18n.locale, DateDisplayOptions.DateHourMonth);
        },

        commentLastChangedBy(): string | undefined {
            return this.product?.updateUserKid || this.product?.createUserKid || '';
        },

        hasSavedComment(): boolean {
            return !!this.product?.comment;
        },

        hasChanges(): boolean {
            if (!this.product) return false;
            if (this.hasErrors()) return false;

            const inputChanged = this.product.emissionQuotas.some(
                (eq) => eq.emissionQuotaAmount !== eq.originalQuotaAmount,
            );

            const commentChanged = this.product.comment !== this.commentText;

            return inputChanged || commentChanged || this.deletionPending;
        },

        saveButtonVariant(): string {
            return this.hasChanges && !this.hasErrors() ? 'primary' : 'secondary';
        },
    },

    watch: {
        product: {
            handler(): void {
                this.commentText = this.product?.comment;
                this.deletionPending = false;
            },
            immediate: true,
        },
        hasChanges() {
            this.updateUnsavedChanges({ [`${this.name[0]}-${this.unitSid}`]: this.hasChanges });
        },
    },

    methods: {
        ...mapActions('emissionQuota', {
            postEQProduct: 'POST_EQ_PRODUCT',
            putEQProduct: 'PUT_EQ_PRODUCT',
        }),
        ...mapMutations('emissionQuota', {
            createEQProduct: 'CREATE_FORMATTED_EQ_PRODUCT',
            updateEQProduct: 'UPDATE_EQ_PRODUCT_EMISSION_QUOTA_AMOUNT',
            fillMissingEQ: 'FILL_MISSING_QUOTAS',
        }),
        ...mapActions({ updateUnsavedChanges: 'updateUnsavedChanges' }),

        getValueForIndex(index: number): number {
            if (!this.product) return 0;

            const year = this.getTradingPeriodStartYear + index;
            const emissionQuota = this.product.emissionQuotas.find((eq) => eq.year === year);
            if (!emissionQuota) return 0;

            return emissionQuota.emissionQuotaAmount;
        },

        isIndexValid(index: number): boolean {
            const value = this.getValueForIndex(index);
            return !isNaN(value) && value >= 0 && Number.isSafeInteger(value);
        },

        hasErrors(): boolean {
            return Array(5)
                .fill(0)
                .some((_, i) => !this.isIndexValid(i));
        },

        onInput(newValue: number, index: number): void {
            const amount = newValue || 0;
            const year = this.getTradingPeriodStartYear + index;
            if (!this.product) this.createEmptyEQProduct();
            if (this.product?.emissionQuotas.length === 0) this.fillMissingEQ(this.product.id);

            this.updateEQProduct({
                countrySid: this.getCurrentCountry.id as number,
                plantSid: this.plantSid,
                unitSid: this.unitSid,
                generationProduct: this.productType,
                periodStart: this.getTradingPeriodStartYear,

                year,
                amount,
            });
        },

        onCommentChange(text: string | undefined | null): void {
            if (!this.product) this.createEmptyEQProduct();

            if (text === null) this.deletionPending = true;
            else this.commentText = text ?? undefined;
        },

        createEmptyEQProduct(): void {
            const product: EmissionQuotaProduct = {
                id: null,
                countrySid: this.getCurrentCountry.id as number,
                plantSid: this.plantSid,
                machineSid: this.unitSid,
                generationProduct: this.productType,
                periodStart: this.getTradingPeriodStartYear,
                periodEnd: this.getTradingPeriodStartYear + 4,
                comment: this.commentText,

                emissionQuotas: Array(5)
                    .fill(0)
                    .map((_, i) => ({
                        locationId: this.unitSid,
                        generationProduct: this.productType,
                        year: this.getTradingPeriodStartYear + i,
                        emissionQuotaAmount: 0,
                        originalQuotaAmount: 0,
                    })),
            };

            this.createEQProduct(product);
        },

        async onSave(): Promise<void> {
            if (!this.product) return;
            if (!this.hasChanges) return;
            if (this.hasErrors()) return;
            if (this.isSaving) return;

            this.isSaving = true;

            const { id, emissionQuotas, ...eqProduct } = this.product;
            const sanitisedEQProduct = {
                ...eqProduct,
                comment: this.deletionPending ? undefined : this.commentText,

                // Strip out `id` and `originalQuotaAmount`
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                emissionQuotas: emissionQuotas.map(({ id, originalQuotaAmount, ...eq }) => ({
                    ...eq,
                    scope: this.getScope(3),
                })),
            };

            let res: HttpStatusCodes;
            if (this.product.id === null) {
                // Create new entry in the backend
                res = await this.postEQProduct(sanitisedEQProduct);
            } else {
                // Update existing entry in the backend
                res = await this.putEQProduct({ ...sanitisedEQProduct, id });
            }

            if (res !== HttpStatusCodes.Ok) {
                (this as any).$pui.toast({
                    type: 'error',
                    title: this.$t('notification.emissionQuota.save.error.title'),
                    copy: this.$t('notification.emissionQuota.save.error.body'),
                });
                return;
            }

            (this as any).$pui.toast({
                type: 'success',
                title: '',
                copy: this.$t('notification.emissionQuota.save.title'),
            });

            this.isSaving = false;
        },

        onReset(): void {
            if (!this.product) return;
            if (this.isSaving) return;

            for (const eq of this.product.emissionQuotas) {
                this.updateEQProduct({
                    countrySid: this.getCurrentCountry.id as number,
                    plantSid: this.plantSid,
                    unitSid: this.unitSid,
                    generationProduct: this.productType,
                    periodStart: this.getTradingPeriodStartYear,

                    year: eq.year,
                    amount: eq.originalQuotaAmount,
                });
            }

            this.deletionPending = false;
            this.commentText = this.product.comment;
            const commentField = this.$refs.commentfield as any;
            if (commentField) commentField.stopEditing();
        },
    },
});
