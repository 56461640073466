
import { checkDecimalPlaces } from '@/utils';
import Vue from 'vue';
import UnitOfMeasurement from '@/components/unit-of-measurement/unit-of-measurement.vue';
import FloatInput from '@/components/inputs/float-input.vue';
import { MaxDecimalPlaces, MaxValue } from '@/constants/input-precision';

const InputCell = Vue.extend({
    name: 'FuelInputCell',
    components: {
        UnitOfMeasurement,
        FloatInput,
    },
    props: {
        data: {
            type: Number,
            required: false,
            default: undefined,
        },
        name: {
            type: String,
            default: '',
        },
        uom: {
            type: String,
            required: true,
        },
        readonly: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        emptyValue(): boolean {
            return this.data === 0 || this.data === undefined;
        },
        invalid(): boolean {
            if (this.data === undefined) return false;

            if (this.name === 'activityDataAmount') {
                return (
                    !checkDecimalPlaces(this.data, MaxDecimalPlaces.FUEL_CONSUMPTION) ||
                    this.data >= MaxValue.FUEL_CONSUMPTION
                );
            }
            if (this.name === 'ghgAmount') {
                return this.data >= MaxValue.GHG;
            }
            return false;
        },
        displayedValue(): string {
            if (this.data === undefined) return '';

            return this.data.toString();
        },
        minimumDecimals(): number {
            const object = {
                activityDataAmount: MaxDecimalPlaces.FUEL_CONSUMPTION,
                emissionFactor: 0, // No purpose in showing consistent decimal places for this parameter
                ghgAmount: MaxDecimalPlaces.GHG,
                heatValue: 3,
                heatBasedEmissionFactor: 3,
            } as Record<string, number>;

            return object[this.name] ?? 3;
        },
    },
});

export default InputCell;
