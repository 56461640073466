import { LocationTableData } from '@/models/table';
import { EmissionWithEmissionFactorOptionsDto } from '@/service-proxies/service-proxies.g';
import { RowComponent } from 'tabulator-tables';

export const resetEmissionRow = (row: RowComponent): void => {
    const data = row.getData() as LocationTableData;
    const oldEmission = data.meta.object as EmissionWithEmissionFactorOptionsDto;
    const oldGhgAmount = oldEmission.ghgAmount;
    const oldActivity = oldEmission.activityDataAmount;

    const ghgCell = row.getCell('ghgAmount');
    const activityCell = row.getCell('activityDataAmount');

    activityCell.setValue(oldActivity);
    ghgCell.setValue(oldGhgAmount);
};
