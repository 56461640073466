import {
    StandardEmissionFactorServiceProxy,
    StandardEmissionFactorGetEndpointResponseOkResponse,
    GlobalEmissionFactorDto,
    StandardEmissionFactorPutEndpointRequest,
    StandardEmissionFactorPutEndpointResponseOkResponse,
} from '@/service-proxies/service-proxies.g';

interface Request {
    startDate?: string;
    endDate?: string;
    scopeId: number | null;
}

/**
 * @name StandardEmissionFactorService
 * @description This class is used to query the API for all
 * Standard Emission Factors in the database.
 */
export class StandardEmissionFactorService extends StandardEmissionFactorServiceProxy {
    private standardEmissionFactorService: StandardEmissionFactorServiceProxy;

    constructor() {
        super();
        this.standardEmissionFactorService = new StandardEmissionFactorServiceProxy();
    }

    public async getStandardEmissionFactors(): Promise<StandardEmissionFactorGetEndpointResponseOkResponse> {
        return await this.standardEmissionFactorService.getStandardEmissionFactor();
    }

    public async editStandardEmissionFactor(
        request: StandardEmissionFactorPutEndpointRequest,
    ): Promise<StandardEmissionFactorPutEndpointResponseOkResponse> {
        return await this.standardEmissionFactorService.putStandardEmissionFactor(request);
    }
}
