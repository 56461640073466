
import Vue from 'vue';
import { ClassificatorDto, ClassificatorRiskLevel } from '@/service-proxies/service-proxies.g';
import { MultiselectOption } from '@/models';
import { HttpStatusCodes } from '@/enums/http-status-codes';
import _ from 'lodash';
import UnitPeriodCommentField from '@/components/comments/unit-period-comment-field.vue';
import { DateDisplayOptions, formatDate } from '@/utils/helpers/formatters';
import { mapActions } from 'vuex';

const RiskClassificationUnit = Vue.extend({
    name: 'RiskClassificationUnit',
    components: {
        UnitPeriodCommentField,
    },
    props: {
        unit: {
            type: Object,
            required: true,
        },
    },
    data(): {
        hasChanges: boolean;
        isSaving: boolean;
        originalRisk: ClassificatorDto;
        updatedRisk: ClassificatorDto;
        commentDeletionPending: boolean;
        hasSavedComment: boolean;
    } {
        return {
            hasChanges: false,
            isSaving: false,
            originalRisk: {} as ClassificatorDto,
            updatedRisk: {} as ClassificatorDto,
            commentDeletionPending: false,
            hasSavedComment: false,
        };
    },
    watch: {
        hasChanges() {
            this.updateUnsavedChanges({ [this.unit.sid]: this.hasChanges });
        },
    },
    beforeMount(): void {
        this.setOriginalRisk();
        this.hasSavedComment = !!this.unit.riskClassification!.comment;
    },
    methods: {
        ...mapActions({ updateUnsavedChanges: 'updateUnsavedChanges' }),
        toNum(input: number | undefined): number {
            if (typeof input == 'number') {
                return input;
            }
            return -1;
        },

        updateDateString(): string {
            const date = this.originalRisk!.updateDate || this.originalRisk!.createDate || '';
            return formatDate(date, this.$i18n.locale, DateDisplayOptions.DateHourMonth);
        },

        updateKid(): string {
            return this.originalRisk!.updateUserKid || this.originalRisk!.createUserKid || '';
        },

        checkForChanges(): void {
            let change = false;
            for (let i = 0; i < 5; i++) {
                if (this.originalRisk!.riskAllocations![i]?.value != this.updatedRisk.riskAllocations![i]?.value) {
                    change = true;
                    break;
                }
            }
            change = change || (this.updatedRisk.comment ?? '') != (this.originalRisk.comment ?? '');
            this.hasChanges = change;
        },

        setOriginalRisk(): void {
            this.originalRisk = _.cloneDeep(this.unit.riskClassification!);
            this.updatedRisk = _.cloneDeep(this.unit.riskClassification!);
        },

        getYear(index: number): string {
            return (this.unit.riskClassification!.periodStart! + index).toString();
        },

        riskLevelOptions(): MultiselectOption[] {
            return Object.keys(ClassificatorRiskLevel)
                .filter((v) => isNaN(Number(v)))
                .map((desc, index) => {
                    let label: string;
                    if (desc === 'Unset') {
                        label = '';
                    } else {
                        label = this.$t(`riskClassification.levels.${desc}`).toString();
                    }
                    return {
                        label: label,
                        value: index + 1 /*Object.values(ClassificatorRiskLevel)[val]*/,
                    };
                });
        },

        async setKid(newRisk: ClassificatorDto): Promise<void> {
            const kid = await (this as any).$sdk.$authenticator.getAccount().username.split('@')[0];
            if (newRisk.id == 0) {
                newRisk.createUserKid = kid;
            } else {
                newRisk.updateUserKid = kid;
            }
        },

        async updateRisk(key: number, event: number): Promise<void> {
            this.updatedRisk.riskAllocations![key].value = event;
            this.updatedRisk.riskAllocations!.forEach((risk) => (risk.id = 0));
            this.checkForChanges();
        },

        async updateComment(text: string): Promise<void> {
            this.updatedRisk.comment = text;
            this.updatedRisk.riskAllocations!.forEach((risk) => (risk.id = 0));
            this.checkForChanges();
            if (text === null) {
                this.commentDeletionPending = true;
            }
        },

        async submit(): Promise<void> {
            if (this.isSaving) {
                return;
            }
            this.isSaving = true;
            await this.setKid(this.updatedRisk);
            const response = await this.$store.dispatch('classificatorData/PUBLISH_CLASSIFICATOR', this.updatedRisk);
            if ((response as HttpStatusCodes) === HttpStatusCodes.Ok) {
                this.setOriginalRisk();
                this.commentDeletionPending = false;
                this.hasSavedComment = !!this.updatedRisk!.comment;
                (this as any).$pui.toast({
                    type: 'success',
                    title: this.$t('notification.riskClassification.save.title'),
                    copy: '',
                });
            } else {
                (this as any).$pui.toast({
                    type: 'error',
                    title: this.$t('error.riskClassification.save.title'),
                    copy: this.$t('error.riskClassification.save.text'),
                });
            }
            this.isSaving = false;
            this.checkForChanges();
            this.$forceUpdate();
        },

        cancel(): void {
            this.commentDeletionPending = false;
            this.setOriginalRisk();
            const commentField = this.$refs.commentField as any;
            if (commentField) commentField.stopEditing();
            this.checkForChanges();
        },
    },
});

export default RiskClassificationUnit;
