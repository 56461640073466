
import Vue from 'vue';
import { mapGetters } from 'vuex';
import Unit from './unit.vue';

import type { EQSum, EQUnit } from './emissionQuotaTypes';

export default Vue.extend({
    name: 'Plant',

    components: {
        Unit,
    },

    props: {
        plantSid: {
            type: Number,
            required: true,
        },
        plantName: {
            type: String,
            required: true,
        },
        plantLocation: {
            type: String,
            required: true,
        },
        plantSum: {
            type: Object as () => EQSum,
            required: true,
        },
        units: {
            type: Array as () => EQUnit[],
            required: true,
        },
    },

    data() {
        return {
            isExpanded: false,
        };
    },

    computed: {
        ...mapGetters('emissionQuota', {
            getTradingPeriodStartYear: 'GET_TRADING_PERIOD_START_YEAR',
        }),

        sortedUnits(): EQUnit[] {
            return [...this.units].sort((a, b) =>
                (a.unitName as string).toLocaleLowerCase().localeCompare((b.unitName as string).toLocaleLowerCase()),
            );
        },
    },

    methods: {
        getValueForIndex(index: number): string {
            const year = this.getTradingPeriodStartYear + index;
            return this.plantSum[year]?.toString() ?? '0';
        },
    },
});
