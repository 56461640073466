
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'StatusCell',

    props: {
        status: {
            type: String,
            required: false,
            default: '',
        },
        hasParked: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    computed: {
        label(): string {
            if (!this.status) return '';
            return this.$t(`emissionTable.status.${this.status}`) as string;
        },

        cssVariables(): any {
            const map: Record<string, string> = {
                preliminary: 'rgb(var(--pbl-primary))',
                approved: 'rgb(var(--pbl-success))',
                parked: 'rgb(var(--pbl-warning))',
                complete: 'rgb(var(--pbl-success))',
            };

            return {
                '--border-color': map[this.status] || '#0000',
            };
        },
    },
});
