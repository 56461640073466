import { LocationState } from '@/store/modules/location';
import { EmissionDto, PowerPlantDto } from '@/service-proxies/service-proxies.g';

export const findLocation = (state: LocationState, emission: EmissionDto): PowerPlantDto | undefined => {
    // If we find the emission on the powerplant we update it there,
    let location = state.locations.find((loc) => loc.sid === emission.locationId);

    // otherwise we look for the units of the plants
    if (!location) {
        for (const plant of state.locations) {
            location = plant.units?.find((unit) => unit.sid === emission.locationId);
            if (location) break;
        }
    }

    if (!location) {
        location = state.locations.find(
            (plant) =>
                !!plant.units?.find((unitCandidate) => !!unitCandidate.emissions?.find((e) => e.id === emission.id)),
        );
    }
    return location;
};
