
import Vue from 'vue';
import { camaScope, sdkPluginConfig } from '@/utils/fe-sdk/sdk-plugin-config';
import { getSdk } from '@/utils/fe-sdk/sdk-helper';
import { Supervisor } from '@/utils';

export default Vue.extend({
    name: 'TokenGenerator',
    data(): {
        camaScope: string;
        uamScope: string;
        metaDataScope: string;
        masterDataScope: string;
        translationScope: string;
    } {
        return {
            camaScope: camaScope,
            uamScope: sdkPluginConfig.urls!['userAccessManagement'][sdkPluginConfig.environment].scope,
            metaDataScope: sdkPluginConfig.urls!['metaData'][sdkPluginConfig.environment].scope,
            masterDataScope: sdkPluginConfig.urls!['masterData'][sdkPluginConfig.environment].scope,
            translationScope: sdkPluginConfig.urls!['translation'][sdkPluginConfig.environment].scope,
        };
    },
    methods: {
        async copyToken(scope: string): Promise<void> {
            const token = await getSdk()?.$authenticator?.getToken(scope);
            if (token) {
                this.copyToClipboard(`Bearer ${token}`);
            } else {
                console.error('Can not copy user token.');
                this.showCopyFailedToast();
            }
        },
        async copyToClipboard(value: string): Promise<void> {
            try {
                await navigator.clipboard.writeText(value);
                this.showCopySuccessToast();
            } catch (error) {
                this.showCopyFailedToast();
            }
        },
        showCopySuccessToast(): void {
            (this as any).$pui.toast({
                type: 'info',
                title: this.$t('copiedToClipboard'),
                copy: 'Copied the token.',
            });
        },
        showCopyFailedToast(): void {
            (this as any).$pui.toast({
                type: 'error',
                title: this.$t('copyToClipboardFailed'),
                copy: 'Failed to copy the token.',
            });
        },
        printAllPermissions(): void {
            console.log(Supervisor.GetUserPermissions());
        },
        printMiConfig(): void {
            console.log(sdkPluginConfig);
        },
        printGlobalVm(): void {
            console.log((this as any).$vm);
        },
    },
});
