import BaseService from '@/utils/base-service';
import { ApiResponse, UserAccessData } from '@/models';

/**
 * @name UserAccessService
 * @description This class is used to query API for data related to App needs,
 */
export class UserAccessService extends BaseService {
    constructor() {
        //  TODO: update endpoint once settled
        super('access-data');
    }

    public async getUserAccessData(): Promise<ApiResponse<UserAccessData>> {
        return {
            statusCode: 200,
            result: {
                scope: '1',
                subScope: '1.1',
                items: [
                    {
                        sid: 1,
                        name: 'Steam',
                        items: [
                            {
                                sid: 11,
                                name: 'Heyden',
                                items: [
                                    {
                                        sid: 111,
                                        name: 'Audorf',
                                        items: [
                                            {
                                                sid: 1111,
                                                name: 'Fuel oil (light)',
                                            },
                                            {
                                                sid: 1112,
                                                name: 'Satamin',
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            message: '',
        };
        // return (await this.api.get(this.endpoint)).data;
    }
}
