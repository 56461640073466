
import { LocationTableData } from '@/models/table';
import {
    ApprovalStatus,
    EmissionWithParkedEmissionDto,
    Granularity,
    PowerPlantDto,
    UnitDto,
} from '@/service-proxies/service-proxies.g';
import {
    DateDisplayOptions,
    formatActivityDataDisplayName,
    formatDate,
    formatLastUpdated,
} from '@/utils/helpers/formatters';
import Vue from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import EmissionSummary from '@/components/detailed-view/emission-summary.vue';
import FactorDetails from '@/components/detailed-view/emission-factor-details.vue';
import FuelCorrection from '@/components/detailed-view/fuel-correction.vue';
import AdditionalInformation from '@/components/detailed-view/additional-info.vue';
import ParkedEmission from '@/components/detailed-view/parked-emission.vue';
import { EventBus } from '@/utils';
import { EmissionFactorType } from '@/enums/emission-factor-type';
import { functionalPermissions } from '@/utils';

const ApprovalModal = Vue.extend({
    name: 'ApprovalsModal',
    components: {
        AdditionalInformation,
        EmissionSummary,
        FactorDetails,
        FuelCorrection,
        ParkedEmission,
    },
    data(): {
        data: object | null;
        unit: UnitDto | null;
        powerPlant: PowerPlantDto | null;
        emission: EmissionWithParkedEmissionDto | null;
        isSaving: boolean;
        partOfBulk: boolean;
    } {
        return {
            data: null,
            powerPlant: null,
            unit: null,
            emission: null,
            isSaving: false,
            partOfBulk: false,
        };
    },
    computed: {
        ...mapGetters({ scope: 'scope/GET_SELECTED_SCOPE' }),
        ...mapGetters({ country: 'country/GET_SELECTED_COUNTRY' }),
        ...mapGetters('detailedView', {
            getEditedEmission: 'GET_EDITED_EMISSION',
        }),
        ...mapGetters('location', {
            startPeriod: 'start',
            granularity: 'granularity',
        }),
        isPreliminary(): boolean {
            return this.emission?.approvalStatus === ('Preliminary' as unknown as ApprovalStatus) && !this.partOfBulk;
        },
        canViewFuelAdjustment(): boolean {
            return (
                (this.emission?.activeActivityDataAmountAdjustment ?? false) &&
                functionalPermissions.userCanViewFuelAdjustment()
            );
        },
        scopeName(): string {
            return this.$t(`scope${this.scope?.subScope?.replace('.', '_') ?? ''}`).toString();
        },
        activityName(): string {
            return formatActivityDataDisplayName(this.emission?.activityData);
        },
        factorUOM(): string {
            const emissionUom = this.getEditedEmission?.ghgUnitOfMeasurement?.shortName ?? '';
            if (!this.getEditedEmission.activityDataUnitOfMeasurement) return '??';
            const consumptionShortName = this.getEditedEmission?.activityDataUnitOfMeasurement?.shortName ?? '';
            const consumptionLabel =
                consumptionShortName.length > 5 ? consumptionShortName.slice(0, 3) : consumptionShortName;
            return `${emissionUom}/${consumptionLabel}`;
        },
        plantUnitName(): string {
            return [this.powerPlant?.name, this.unit?.name].filter(Boolean).join(' - ');
        },
        showFactorDetails(): boolean {
            return this.emission?.emissionFactor?.type === EmissionFactorType.Individual;
        },
        canBeApproved(): boolean {
            const activity = (this.data as any).activity as LocationTableData;

            // @ts-ignore - Status comes in as a string rather than an enum int so doing this hacky thing to convert it
            return ApprovalStatus[activity.status!] === ApprovalStatus.Preliminary && !this.partOfBulk;
        },
        lastUpdated(): Record<string, string> {
            return formatLastUpdated(
                this.emission,
                this.$t('comment.lastUpdated') as string,
                this.$t('draft') as string,
            );
        },
    },
    methods: {
        ...mapActions('detailedView', {
            setEmissionFactor: 'SET_EMISSION_FACTORS',
            fetchUOMs: 'FETCH_UOMS',
        }),
        ...mapMutations('detailedView', {
            setEditedEmission: 'SET_EDITED_EMISSION',
            setEditedIndividualEmissionFactor: 'SET_EDITED_INDIVIDUAL_EMISSION_FACTOR',
            setStandardEmissionFactor: 'SET_STANDARD_EMISSION_FACTOR',
            setIndividualHasBeenChanged: 'SET_INDIVIDUAL_HAS_BEEN_CHANGED',
            setUOMs: 'SET_CONSUMPTION_UOMS',
        }),
        async open(data: Record<string, LocationTableData>): Promise<void> {
            this.data = data;
            const { powerPlant, unit, activity, checked } = data;
            // Power plant may not exist if the master data level is on the unit
            this.powerPlant = (powerPlant?.meta?.object as PowerPlantDto) || null;
            this.unit = (unit?.meta?.object as UnitDto) || null;

            this.emission = activity.meta.object as EmissionWithParkedEmissionDto;

            this.partOfBulk = checked as unknown as boolean;

            if (this.emission) {
                this.setEmissionFactor(this.emission);
            }

            // open modal
            (this as any).$refs.detailsBox.open();
        },

        close(): void {
            this.data = null;
            this.powerPlant = null;
            this.unit = null;
            this.emission = null;
            this.partOfBulk = false;
            (this as any).$refs.detailsBox.close();
        },

        approve(): void {
            if (this.partOfBulk) return;
            EventBus.$emit(EventBus.VIEWS.APPROVAL.APPROVE, this.data);
            this.close();
        },

        formatDate(dateString: string, locale: string, granularity: Granularity): string {
            return formatDate(dateString, locale, this.mapGranularityToDateDisplayOptions(granularity));
        },

        mapGranularityToDateDisplayOptions(granularity: Granularity): DateDisplayOptions {
            switch (granularity) {
                case Granularity.Yearly:
                    return DateDisplayOptions.YearOnly;
                case Granularity.Monthly:
                    return DateDisplayOptions.MonthOnly;
                default:
                    return DateDisplayOptions.Default;
            }
        },
    },
});

export default ApprovalModal;
