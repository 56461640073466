
import Vue, { nextTick } from 'vue';
import {
    EmissionFactorDto,
    EmissionFactorCalculation,
    EmissionWithParkedEmissionDto,
    UnitOfMeasurementDto,
} from '@/service-proxies/service-proxies.g';
import UnitOfMeasurement from '@/components/unit-of-measurement/unit-of-measurement.vue';
import FloatInput from '@/components/inputs/float-input.vue';
import ModalInputWrapper from '@/components/inputs/modal-input-wrapper.vue';
import { mapGetters, mapMutations } from 'vuex';
import { checkDecimalPlaces, roundToDecimalPlaces } from '@/utils';
import { MaxDecimalPlaces, MaxValue, MaxWholeNumbers, JAVASCRIPT_MAX_DIGITS } from '@/constants/input-precision';
import { EmissionInputs } from '@/constants/emission-input';
import { EFKey } from '@/models/emission-factor-input';
import { equivalentCalorificUOM, equivalentHBEFUOM } from '@/utils/helpers/equivalent-uom';
import { DEFAULT_ENERGY_UNIT_ID, DEFAULT_SCOPE_ID } from '@/constants/default';

interface Input {
    value: string;
    valid?: boolean;
    validator?: Function[];
}

const IndividualEmissionFactor = Vue.extend({
    name: 'IndividualEmissionFactor',
    components: {
        UnitOfMeasurement,
        FloatInput,
        ModalInputWrapper,
    },
    props: {
        fuelConsumptionUOM: {
            type: Object,
            required: true,
        },
    },
    data(): {
        calculationMethod: EmissionFactorCalculation | null;
        EmissionFactorCalculation: Record<string, any>;
        inputFields: { [key: string]: Input };
        selectedCVUnits: number;
        selectedHBEFUnits: number;
        renderSelect: boolean;
        maxPrecision: number;
        loadingValues: boolean;
    } {
        return {
            calculationMethod: null,
            EmissionFactorCalculation,
            inputFields: {
                factor: {
                    value: '',
                    valid: true,
                },
                calorificValue: {
                    value: '',
                    valid: true,
                },
                heatBasedEmissionFactor: {
                    value: '',
                    valid: true,
                },
                carbonContent: {
                    value: '',
                    valid: true,
                },
                conversionFactor: {
                    value: '',
                    valid: true,
                },
                oxidationFactor: {
                    value: '',
                    valid: true,
                },
                biogen: {
                    value: '',
                    valid: true,
                },
            },
            selectedCVUnits: DEFAULT_ENERGY_UNIT_ID,
            selectedHBEFUnits: DEFAULT_ENERGY_UNIT_ID,
            renderSelect: true,
            maxPrecision: JAVASCRIPT_MAX_DIGITS,
            loadingValues: true,
        };
    },

    computed: {
        ...mapGetters('scope', {
            scope: 'GET_SELECTED_SCOPE',
        }),
        ...mapGetters('detailedView', {
            editedEmission: 'GET_EDITED_EMISSION',
            editedIndividualEmissionFactor: 'GET_EDITED_INDIVIDUAL_EMISSION_FACTOR',
            individualHasBeenChanged: 'GET_INDIVIDUAL_HAS_BEEN_CHANGED',
        }),
        energyUnits(): UnitOfMeasurementDto[] {
            return this.$store.getters['unitOfMeasurement/getEnergyUnits'];
        },
        factorUnits(): string {
            if (!this.editedIndividualEmissionFactor) return '';
            let numerator =
                this.editedIndividualEmissionFactor?.ghgUnitOfMeasurement?.shortName ??
                this.editedEmission.ghgUnitOfMeasurement?.shortName;
            if (this.scope.id === DEFAULT_SCOPE_ID) {
                numerator += ' CO2';
            }
            const denominator =
                this.editedIndividualEmissionFactor?.activityDataUnitOfMeasurement?.shortName ??
                this.editedEmission.activityDataUnitOfMeasurement?.shortName;
            return `${numerator ?? ''} / ${denominator ?? ''}`;
        },
        CFOFBSProduct(): number | null {
            const { conversionFactor, oxidationFactor, biogen } = this.inputFields;
            if (conversionFactor.value === '') return null;
            if (oxidationFactor.value === '') return null;
            if (biogen.value === '') return null;
            return Number(conversionFactor.value) * Number(oxidationFactor.value) * (1 - Number(biogen.value) / 100);
        },
        biogenicCFOFBSProduct(): number | null {
            const { conversionFactor, oxidationFactor, biogen } = this.inputFields;
            if (conversionFactor.value === '') return null;
            if (oxidationFactor.value === '') return null;
            if (biogen.value === '') return null;
            return Number(conversionFactor.value) * Number(oxidationFactor.value) * (Number(biogen.value) / 100);
        },
        factorCalculationRaw(): number | null {
            const { factor } = this.inputFields;
            if (factor.value === '') return null;
            return Number(factor.value);
        },
        factorCalculation(): string {
            if (this.CFOFBSProduct === null || this.factorCalculationRaw === null) return '';
            const calculation = this.CFOFBSProduct * this.factorCalculationRaw;
            return roundToDecimalPlaces(calculation, MaxDecimalPlaces.EMISSION_FACTOR).toString();
        },
        validFactorCalculation(): boolean {
            const validFactor = Boolean(this.inputFields.factor?.valid);
            const validConversion = Boolean(this.inputFields.conversionFactor?.valid);
            const validOxidation = Boolean(this.inputFields.oxidationFactor?.valid);
            const validBiogen = Boolean(this.inputFields.biogen?.valid);
            return validFactor && validConversion && validOxidation && validBiogen;
        },
        calorificCalculationRaw(): number | null {
            const { calorificValue, heatBasedEmissionFactor } = this.inputFields;
            if (calorificValue.value === '' || heatBasedEmissionFactor.value === '') return null;
            const calorificValueMagnitude =
                this.energyUnits.find((unit) => unit.id === this.selectedCVUnits)?.magnitude ?? 1;
            const HBEFMagnitude = this.energyUnits.find((unit) => unit.id === this.selectedHBEFUnits)?.magnitude ?? 1;
            const jouleConversion = calorificValueMagnitude / HBEFMagnitude;
            return Number(calorificValue.value) * Number(heatBasedEmissionFactor.value) * jouleConversion;
        },
        calorificCalculation(): string {
            if (this.CFOFBSProduct === null || this.calorificCalculationRaw === null) return '';
            const calculation = this.CFOFBSProduct * this.calorificCalculationRaw;
            return roundToDecimalPlaces(calculation, MaxDecimalPlaces.EMISSION_FACTOR).toString();
        },
        validCalorificCalculation(): boolean {
            const validCalorific = Boolean(this.inputFields.calorificValue?.valid);
            const validHeatBased = Boolean(this.inputFields.heatBasedEmissionFactor?.valid);
            const validConversion = Boolean(this.inputFields.conversionFactor?.valid);
            const validOxidation = Boolean(this.inputFields.oxidationFactor?.valid);
            const validBiogen = Boolean(this.inputFields.biogen?.valid);
            return validCalorific && validHeatBased && validConversion && validOxidation && validBiogen;
        },
        carbonCalculationRaw(): number | null {
            if (this.CFOFBSProduct === null) return null;
            const { carbonContent } = this.inputFields;
            if (carbonContent.value === '') return null;
            return (Number(carbonContent.value) / 100) * 3.664;
        },
        carbonCalculation(): string {
            if (this.CFOFBSProduct === null || this.carbonCalculationRaw === null) return '';
            const calculation = this.CFOFBSProduct * this.carbonCalculationRaw;
            return roundToDecimalPlaces(calculation, MaxDecimalPlaces.EMISSION_FACTOR).toString();
        },
        validCarbonCalculation(): boolean {
            const validCarbon = Boolean(this.inputFields.carbonContent?.valid);
            const validConversion = Boolean(this.inputFields.conversionFactor?.valid);
            const validOxidation = Boolean(this.inputFields.oxidationFactor?.valid);
            const validBiogen = Boolean(this.inputFields.biogen?.valid);
            return validCarbon && validConversion && validOxidation && validBiogen;
        },
        emissionFactorRaw(): number | null {
            if (this.calculationMethod === null) {
                return null;
            }
            const method = Number(this.calculationMethod);
            switch (method) {
                case EmissionFactorCalculation.TypeInFactor:
                    return this.factorCalculationRaw;
                case EmissionFactorCalculation.CarbonContent:
                    return this.carbonCalculationRaw;
                case EmissionFactorCalculation.CalorificValueAndHeatBasedEmissionFactor:
                    return this.calorificCalculationRaw;
                default:
                    return null;
            }
        },
        individualEmissionFactor(): string {
            if (this.CFOFBSProduct === null) return '';
            if (this.emissionFactorRaw === null) return '';
            const rawValue = Number(this.emissionFactorRaw);
            const calculation = this.CFOFBSProduct * rawValue;
            return roundToDecimalPlaces(calculation, MaxDecimalPlaces.EMISSION_FACTOR).toString();
        },
        // used to calculate biogenic share of emissions
        biogenicEmissionFactor(): string {
            if (this.inputFields.biogen.value === null) return '0';
            if (this.biogenicCFOFBSProduct === null) return '';
            if (this.emissionFactorRaw === null) return '';
            const rawValue = Number(this.emissionFactorRaw);
            const calculation = this.biogenicCFOFBSProduct * rawValue;
            return roundToDecimalPlaces(calculation, MaxDecimalPlaces.EMISSION_FACTOR).toString();
        },
        calorificValueOptions(): any[] {
            return this.energyUnits.map(({ shortName, id }) => {
                const fuelId = this.fuelConsumptionUOM.id;
                const fuelName = this.fuelConsumptionUOM.shortName;
                return {
                    label: `${shortName} / ${fuelName}`,
                    value: id,
                    secondaryLabel: equivalentCalorificUOM(id ?? 0, fuelId),
                };
            });
        },
        heatBasedOptions(): any[] {
            const emissionFactor = this.editedIndividualEmissionFactor as EmissionFactorDto;
            const emission = this.editedEmission as EmissionWithParkedEmissionDto;
            const numerator = emissionFactor?.ghgUnitOfMeasurement?.id
                ? emissionFactor?.ghgUnitOfMeasurement?.shortName
                : emission.ghgUnitOfMeasurement?.shortName;
            return this.energyUnits.map(({ shortName, id }) => {
                return {
                    label: `${numerator} / ${shortName}`,
                    value: id,
                    secondaryLabel: equivalentHBEFUOM(id ?? 0),
                };
            });
        },
        emptyNeededValue(): Record<string, boolean> {
            return {
                factor:
                    this.inputFields.factor.value === '' &&
                    this.calculationMethod === EmissionFactorCalculation.TypeInFactor,
                calorificValue:
                    this.inputFields.calorificValue.value === '' &&
                    this.calculationMethod === EmissionFactorCalculation.CalorificValueAndHeatBasedEmissionFactor,
                heatBasedEmissionFactor:
                    this.inputFields.heatBasedEmissionFactor.value === '' &&
                    this.calculationMethod === EmissionFactorCalculation.CalorificValueAndHeatBasedEmissionFactor,
                carbonContent:
                    this.inputFields.carbonContent.value === '' &&
                    this.calculationMethod === EmissionFactorCalculation.CarbonContent,
                conversionFactor: this.inputFields.conversionFactor.value === '',
                oxidationFactor: this.inputFields.oxidationFactor.value === '',
                biogen: this.inputFields.biogen.value === '',
            };
        },
        showCaution(): Record<string, boolean> {
            const {
                factor,
                calorificValue,
                heatBasedEmissionFactor,
                carbonContent,
                conversionFactor,
                oxidationFactor,
                biogen,
            } = this.inputFields;
            return {
                factor: this.emptyNeededValue.factor || factor.value.length > JAVASCRIPT_MAX_DIGITS,
                calorificValue:
                    this.emptyNeededValue.calorificValue || calorificValue.value.length > JAVASCRIPT_MAX_DIGITS,
                heatBasedEmissionFactor:
                    this.emptyNeededValue.heatBasedEmissionFactor ||
                    heatBasedEmissionFactor.value.length > JAVASCRIPT_MAX_DIGITS,
                carbonContent:
                    this.emptyNeededValue.carbonContent || carbonContent.value.length > JAVASCRIPT_MAX_DIGITS,
                converionFactor:
                    this.emptyNeededValue.conversionFactor || conversionFactor.value.length > JAVASCRIPT_MAX_DIGITS,
                oxidationFactor:
                    this.emptyNeededValue.oxidationFactor || oxidationFactor.value.length > JAVASCRIPT_MAX_DIGITS,
                biogen: this.emptyNeededValue.biogen || biogen.value.length > JAVASCRIPT_MAX_DIGITS,
            };
        },
        tooltip(): Record<string, string> {
            return {
                factor: this.toolTipMessage('FACTOR'),
                calorificValue: this.toolTipMessage('CALORIFIC_VALUE'),
                heatBasedEmissionFactor: this.toolTipMessage('HBEF'),
                carbonContent: this.toolTipMessage('CARBON_CONTENT'),
                conversionFactor: this.toolTipMessage('CONVERSION_FACTOR'),
                oxidationFactor: this.toolTipMessage('OXIDATION_FACTOR'),
                biogen: this.toolTipMessage('BIOGENIC_SHARE'),
            };
        },
    },
    watch: {
        async fuelConsumptionUOM(): Promise<void> {
            this.inputFields.calorificValue.value = '';
            this.inputFields.heatBasedEmissionFactor.value = '';
            this.inputFields.factor.value = '';

            // this is to update the currently selected label for CV units
            this.renderSelect = false;
            await nextTick();
            this.renderSelect = true;

            this.mapToForm();
            this.saveAllValues();
        },
        calculationMethod(): void {
            if (this.calculationMethod === null) {
                const radioButtons = document.querySelectorAll('input[name=calculation]');
                const radioArray = Array.from(radioButtons as NodeListOf<HTMLInputElement>);
                const checkedButton = radioArray.find((button) => button.checked);
                if (checkedButton) checkedButton.checked = false;
            }
        },
        validFactorCalculation(): void {
            if (this.validFactorCalculation === true) return;
            if (this.calculationMethod !== EmissionFactorCalculation.TypeInFactor) return;

            this.calculationMethod = null;
            this.saveAllValues();
        },
        validCalorificCalculation(): void {
            if (this.validCalorificCalculation === true) return;
            if (this.calculationMethod !== EmissionFactorCalculation.CalorificValueAndHeatBasedEmissionFactor) return;

            this.calculationMethod = null;
            this.saveAllValues();
        },
        validCarbonCalculation(): void {
            if (this.validCarbonCalculation === true) return;
            if (this.calculationMethod !== EmissionFactorCalculation.CarbonContent) return;

            this.calculationMethod = null;
            this.saveAllValues();
        },
    },
    async mounted(): Promise<void> {
        this.mapToForm();
        this.saveAllValues();
        await nextTick();
        this.loadingValues = false;
    },
    methods: {
        ...mapMutations('detailedView', {
            setEditIndividualEmissionFactor: 'SET_EDITED_INDIVIDUAL_EMISSION_FACTOR',
            setIndividualHasBeenChanged: 'SET_INDIVIDUAL_HAS_BEEN_CHANGED',
        }),
        handleFactorInput(newValue: string): void {
            this.inputFields.factor.value = newValue;
            const numberValue = Number(newValue);
            this.inputFields.factor.valid =
                numberValue >= 0 &&
                numberValue < MaxValue.FACTOR &&
                checkDecimalPlaces(numberValue, MaxDecimalPlaces.FACTOR);
            this.changedValue('factor', newValue);
        },
        handleCVInput(newValue: string): void {
            this.inputFields.calorificValue.value = newValue;
            const numberValue = Number(newValue);
            this.inputFields.calorificValue.valid =
                numberValue >= 0 &&
                numberValue < MaxValue.CALORIFIC_VALUE &&
                checkDecimalPlaces(numberValue, MaxDecimalPlaces.CALORIFIC_VALUE);
            this.changedValue('heatValue', newValue);
            if (!this.editedIndividualEmissionFactor.heatValueNumeratorUnitOfMeasurement) {
                this.selectCVUnits(this.selectedCVUnits);
            }
        },
        handleHBEFInput(newValue: string): void {
            this.inputFields.heatBasedEmissionFactor.value = newValue;
            const numberValue = Number(newValue);
            this.inputFields.heatBasedEmissionFactor.valid =
                numberValue >= 0 &&
                numberValue < MaxValue.HBEF &&
                checkDecimalPlaces(numberValue, MaxDecimalPlaces.HBEF);
            this.changedValue('heatBasedEmissionFactor', newValue);
            if (!this.editedIndividualEmissionFactor.heatBasedEmissionFactorDenominatorUnitOfMeasurement) {
                this.selectHBEFUnits(this.selectedHBEFUnits);
            }
        },
        handleCCInput(newValue: string): void {
            this.inputFields.carbonContent.value = newValue;
            const numberValue = Number(newValue);
            this.inputFields.carbonContent.valid =
                numberValue >= 0 &&
                numberValue <= MaxValue.CARBON_CONTENT &&
                checkDecimalPlaces(numberValue, MaxDecimalPlaces.CARBON_CONTENT);
            this.changedValue('carbonContent', newValue);
        },
        handleConversionFactorInput(newValue: string): void {
            this.inputFields.conversionFactor.value = newValue;
            const numberValue = Number(newValue);
            this.inputFields.conversionFactor.valid =
                numberValue >= 0 &&
                numberValue < MaxValue.CONVERSION_FACTOR &&
                checkDecimalPlaces(numberValue, MaxDecimalPlaces.CONVERSION_FACTOR);
            this.changedValue('conversionFactor', newValue);
        },
        handleOxidationFactorInput(newValue: string): void {
            this.inputFields.oxidationFactor.value = newValue;
            const numberValue = Number(newValue);
            this.inputFields.oxidationFactor.valid =
                numberValue >= 0 &&
                numberValue < MaxValue.OXIDATION_FACTOR &&
                checkDecimalPlaces(numberValue, MaxDecimalPlaces.OXIDATION_FACTOR);
            this.changedValue('oxidationFactor', newValue);
        },
        handleBiogenicShareInput(newValue: string): void {
            this.inputFields.biogen.value = newValue;
            const numberValue = Number(newValue);
            this.inputFields.biogen.valid =
                numberValue >= 0 &&
                numberValue <= MaxValue.BIOGENIC_SHARE &&
                checkDecimalPlaces(numberValue, MaxDecimalPlaces.BIOGENIC_SHARE);
            this.changedValue('biogen', newValue);
        },
        selectCVUnits(newValue: number): void {
            this.selectedCVUnits = newValue;
            this.changedValue('heatValueNumeratorUnitOfMeasurement', {
                id: newValue,
            });
            this.changedValue('heatValueDenominatorUnitOfMeasurement', this.fuelConsumptionUOM);
        },
        selectHBEFUnits(newValue: number): void {
            this.selectedHBEFUnits = newValue;
            const emissionFactor = this.editedIndividualEmissionFactor as EmissionFactorDto;
            const emission = this.editedEmission as EmissionWithParkedEmissionDto;
            const numerator = emissionFactor?.ghgUnitOfMeasurement?.id
                ? emissionFactor?.ghgUnitOfMeasurement?.id
                : emission.ghgUnitOfMeasurement?.id;
            this.changedValue('heatBasedEmissionFactorNumeratorUnitOfMeasurement', { id: numerator });
            this.changedValue('heatBasedEmissionFactorDenominatorUnitOfMeasurement', { id: newValue });
        },
        selectCalculation(newValue: EmissionFactorCalculation): void {
            this.calculationMethod = Number(newValue);
            this.changedValue('emissionFactorCalculation', newValue);
        },
        changedValue(param: string, newValue: any): void {
            const emissionsFactor = this.individualEmissionFactor ? Number(this.individualEmissionFactor) : null;
            const biogenicEmissionFactor = this.biogenicEmissionFactor ? Number(this.biogenicEmissionFactor) : null;
            const input = typeof newValue === 'object' ? newValue : Number(newValue);
            this.setEditIndividualEmissionFactor({
                ...this.editedIndividualEmissionFactor,
                [param]: input,
                emissionsFactor: emissionsFactor,
                biogenicEmissionFactor: biogenicEmissionFactor,
            });
            if (!this.individualHasBeenChanged && !this.loadingValues) {
                this.setIndividualHasBeenChanged(true);
                this.setEditIndividualEmissionFactor({
                    ...this.editedIndividualEmissionFactor,
                    source: 'Web App',
                });
            }
            const {
                factor,
                calorificValue,
                heatBasedEmissionFactor,
                carbonContent,
                conversionFactor,
                oxidationFactor,
                biogen,
            } = this.inputFields;
            this.$emit(
                'valid',
                factor.valid &&
                    calorificValue.valid &&
                    heatBasedEmissionFactor.valid &&
                    carbonContent.valid &&
                    conversionFactor.valid &&
                    oxidationFactor.valid &&
                    biogen.valid,
            );
        },
        saveAllValues(): void {
            const {
                factor,
                calorificValue,
                heatBasedEmissionFactor,
                carbonContent,
                conversionFactor,
                oxidationFactor,
                biogen,
            } = this.inputFields;
            const emissionsFactor = this.individualEmissionFactor ? Number(this.individualEmissionFactor) : null;
            const biogenicEmissionsFactor = this.biogenicEmissionFactor ? Number(this.biogenicEmissionFactor) : null;

            const body = {
                ...this.editedIndividualEmissionFactor,
                factor: factor.value !== '' ? Number(factor.value) : undefined,
                heatValue: calorificValue.value !== '' ? Number(calorificValue.value) : undefined,
                heatBasedEmissionFactor:
                    heatBasedEmissionFactor.value !== '' ? Number(heatBasedEmissionFactor.value) : undefined,
                carbonContent: carbonContent.value !== '' ? Number(carbonContent.value) : undefined,
                conversionFactor: Number(conversionFactor.value),
                oxidationFactor: Number(oxidationFactor.value),
                biogen: Number(biogen.value),
                emissionFactorCalculation: this.calculationMethod,
                emissionsFactor: emissionsFactor,
                biogenicEmissionFactor: biogenicEmissionsFactor,
            };
            this.setEditIndividualEmissionFactor(body);
        },
        mapToForm(): void {
            const params = this.editedIndividualEmissionFactor as EmissionFactorDto;
            const {
                factor,
                calorificValue,
                heatBasedEmissionFactor,
                carbonContent,
                conversionFactor,
                oxidationFactor,
                biogen,
            } = this.inputFields;
            factor.value = params.factor?.toString() ?? '';
            calorificValue.value = params.heatValue?.toString() ?? '';
            this.selectedCVUnits = params.heatValueNumeratorUnitOfMeasurement?.id ?? 13;
            heatBasedEmissionFactor.value = params.heatBasedEmissionFactor?.toString() ?? '';
            this.selectedHBEFUnits = params.heatBasedEmissionFactorDenominatorUnitOfMeasurement?.id ?? 13;
            carbonContent.value = params.carbonContent?.toString() ?? '';
            conversionFactor.value = params.conversionFactor?.toString() ?? '1';
            oxidationFactor.value = params.oxidationFactor?.toString() ?? '1';
            biogen.value = params.biogen?.toString() ?? '0';
            let calculationMethod: any = params.emissionFactorCalculation;
            // 0 is a valid value so truthy comparison won't work
            if (calculationMethod !== undefined) {
                //front end defaults to use index values, but backend saves it as string value
                if (isNaN(Number(calculationMethod))) {
                    this.calculationMethod = Number(EmissionFactorCalculation[calculationMethod]);
                } else {
                    this.calculationMethod = calculationMethod;
                    calculationMethod = EmissionFactorCalculation[calculationMethod];
                }
                const radioButton = document.querySelector(`#calculation-${calculationMethod}`) as HTMLInputElement;
                radioButton.checked = true;
            }
        },
        toolTipMessage(key: EFKey): string {
            const input = this.inputFields[EmissionInputs[key]].value;
            const value = Number(input);
            if (!checkDecimalPlaces(value, MaxDecimalPlaces[key])) {
                const message = this.$t('input.invalid.decimal') as string;
                return message.replace(`{{ number }}`, MaxDecimalPlaces[key].toString());
            }
            if (key === 'CARBON_CONTENT' || key === 'BIOGENIC_SHARE') {
                if (value > MaxValue[key]) {
                    return this.$t('input.invalid.percentage') as string;
                }
            } else {
                if (value >= MaxValue[key]) {
                    const message = this.$t('input.invalid.tooLarge') as string;
                    return message.replace(`{{ number }}`, MaxWholeNumbers[key].toString());
                }
            }
            if (input.length > JAVASCRIPT_MAX_DIGITS) {
                return this.$t('input.warning.jsPrecision') as string;
            }
            if (this.emptyNeededValue[EmissionInputs[key]]) {
                return this.$t('input.warning.expectedValue') as string;
            }

            return '';
        },
    },
});

export default IndividualEmissionFactor;
