import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ApplicationState } from '..';
import {
    mapStandardEmissionFactors,
    createTableData,
    rebuildTableData,
} from '@/components/tables/standard-emission/helpers/emission-mapper';
import { StandardEmissionFactorService } from '@/services/standard-emission-service';
import {
    ActivityDataDto,
    BadRequestResponse,
    EmissionDto,
    EmissionFactorDto,
    EmissionFactorGroupDto,
    GlobalEmissionFactorDto,
    GlobalEmissionFactorWithBlockingEmissionsDto,
    StandardEmissionFactorBulkEndpointRequest,
} from '@/service-proxies/service-proxies.g';
import { formatToDashedYYYYMMDD, formatActivityDataDisplayName } from '@/utils/helpers/formatters';
import { EmissionFactorTableLevel } from '@/enums/TableLevel';
import { EventBus } from '@/utils';
import { FactorActionType } from '@/enums/factor-action-type';
import Vue from 'vue';
import { getTranslationManager } from '@/utils/translation-plugin/translation-manager';

export interface StandardEmissionFactorState {
    factors: EmissionFactorGroupDto[];
    tableData: any[];
    detailedViewEmissionFactorHistory: EmissionFactorDto[];
    api: StandardEmissionFactorService;
    startDate: string; // todo delete
    endDate: string; // todo delete
    loading: boolean;
}

export interface BlockedErrorBody {
    actionType: FactorActionType;
    blockingEmissions: EmissionDto[];
}

export interface BulkBlockedErrorBody {
    bulkEditValues: BulkEditValues;
    blockedEmissionFactors: GlobalEmissionFactorWithBlockingEmissionsDto[] | undefined;
}

export interface BulkEditResponse {
    bulkEditValues: BulkEditValues;
    updatedEmissionFactors?: EmissionFactorGroupDto[] | undefined;
    skippedEmissionFactors?: EmissionFactorGroupDto[] | undefined;
}

export interface BulkEditValues {
    startDate: string;
    source: string;
    linkToSource: string;
    additionalInformation: string;
    publicationDate: string;
}

const storeTranslationInjector = (key: string): string => {
    const instance = new Vue({
        i18n: getTranslationManager().vueI18n,
    });

    return instance.$t(key) as string;
};

const state: StandardEmissionFactorState = {
    factors: [],
    tableData: [],
    detailedViewEmissionFactorHistory: [],
    api: new StandardEmissionFactorService(),
    startDate: formatToDashedYYYYMMDD(new Date()), // todo delete
    endDate: formatToDashedYYYYMMDD(new Date()), // todo delete
    loading: true,
};

const successToastMessage: string = 'notification.emissionFactors.save.title';
const errorToastMessage: string = 'error.emissions.save.title';

function sendErrorAlert(payload: BlockedErrorBody) {
    EventBus.$emit(EventBus.VIEWS.STANDARD_EMISSION.OPEN_BLOCKED_ERROR_MODAL, payload);
}

function SendBulkEditResponseAlert(payload: BulkEditResponse) {
    EventBus.$emit(EventBus.VIEWS.STANDARD_EMISSION.OPEN_BULK_RESPONSE_MODAL, payload);
}

function SendBulkEditErrorAlert(payload: BulkBlockedErrorBody) {
    EventBus.$emit(EventBus.VIEWS.STANDARD_EMISSION.OPEN_BULK_ERROR_MODAL, payload);
}

function toastHttpSuccess() {
    Vue.prototype.$pui.toast({
        type: 'success',
        title: storeTranslationInjector(successToastMessage),
        copy: '',
    });
}

function toastHttpError(error: any) {
    const message = (error as BadRequestResponse).message ?? '';
    Vue.prototype.$pui.toast({
        type: 'error',
        title: storeTranslationInjector(errorToastMessage),
        copy: message,
    });
    console.log(error);
    throw new Error();
}

const getters: GetterTree<StandardEmissionFactorState, ApplicationState> = {
    factors: (state) => state.factors,
    detailedViewEmissionFactorHistory: (state) => state.detailedViewEmissionFactorHistory,
    tableData: (state) => state.tableData,
    startDate: (state) => state.startDate,
    endDate: (state) => state.endDate,
    loading: (state) => state.loading,
};

const mutations: MutationTree<StandardEmissionFactorState> = {
    setFactors(state, payload) {
        state.factors = payload.factors;
        state.factors.forEach((factor) => {
            factor.activity =
                (payload.activityData as ActivityDataDto[]).find((a) => a.id == factor.activity?.id) ?? undefined;
        });
    },
    setTableData(state) {
        state.tableData = createTableData(mapStandardEmissionFactors(state.factors));
    },
    updateTableData(state) {
        state.tableData = rebuildTableData(state.tableData, mapStandardEmissionFactors(state.factors));
    },
    addActivity(state, payload) {
        const activities = payload as ActivityDataDto[];
        activities.forEach((activity) =>
            state.tableData.unshift({
                activity: formatActivityDataDisplayName(activity),
                meta: {
                    level: EmissionFactorTableLevel.Activity,
                    activity: activity,
                },
                _children: [],
            }),
        );
    },
    setStartDate(state, payload) {
        state.startDate = payload;
    },
    setEndDate(state, payload) {
        state.endDate = payload;
    },
    setLoading(state, payload) {
        state.loading = payload;
    },
    deleteFactor(state, payload: { id: number }) {
        var oldFactorIndexCO2 = state.factors.findIndex((factor) => factor.emissionFactorCO2?.id === payload.id);
        if (oldFactorIndexCO2 !== -1) {
            state.factors[oldFactorIndexCO2].emissionFactorCO2 = undefined;
            if (
                !state.factors[oldFactorIndexCO2].emissionFactorN2O &&
                !state.factors[oldFactorIndexCO2].emissionFactorCH4
            ) {
                state.factors.splice(oldFactorIndexCO2, 1);
            }
        }

        var oldFactorIndexN2O = state.factors.findIndex((factor) => factor.emissionFactorN2O?.id === payload.id);
        if (oldFactorIndexN2O !== -1) {
            state.factors[oldFactorIndexN2O].emissionFactorN2O = undefined;
            if (
                !state.factors[oldFactorIndexN2O].emissionFactorCO2 &&
                !state.factors[oldFactorIndexN2O].emissionFactorCH4
            ) {
                state.factors.splice(oldFactorIndexN2O, 1);
            }
        }

        var oldFactorIndexCH4 = state.factors.findIndex((factor) => factor.emissionFactorCH4?.id === payload.id);
        if (oldFactorIndexCH4 !== -1) {
            state.factors[oldFactorIndexCH4].emissionFactorCH4 = undefined;
            if (
                !state.factors[oldFactorIndexCH4].emissionFactorN2O &&
                !state.factors[oldFactorIndexCH4].emissionFactorCO2
            ) {
                state.factors.splice(oldFactorIndexCH4, 1);
            }
        }
    },
    updateFactor(state, payload: EmissionFactorDto) {
        var oldFactorIndex = state.factors.findIndex(
            (factor) =>
                factor.activity?.id === payload.activityData?.id &&
                factor.unitOfMeasurement?.id === payload.activityDataUnitOfMeasurement?.id,
        );
        switch (payload.greenhouseGas?.chemicalFormula) {
            case 'CO2':
                state.factors[oldFactorIndex].emissionFactorCO2 = payload;
                break;
            case 'CH4':
                state.factors[oldFactorIndex].emissionFactorCH4 = payload;
                break;
            case 'N2O':
                state.factors[oldFactorIndex].emissionFactorN2O = payload;
                break;
        }
    },
    addFactorGroup(state, payload: EmissionFactorGroupDto) {
        state.factors.unshift(payload);
    },
    detailedViewEmissionFactorHistory(state, payload: EmissionFactorDto[]) {
        payload.sort((a, b) =>
            !!b.startDate && !!a.startDate ? Date.parse(b.startDate) - Date.parse(a.startDate) : 0,
        );
        state.detailedViewEmissionFactorHistory = payload;
    },
};

const actions: ActionTree<StandardEmissionFactorState, ApplicationState> = {
    async FETCH_STANDARD_EMISSION_FACTORS({
        state,
        commit,
        rootGetters,
    }: ActionContext<StandardEmissionFactorState, ApplicationState>): Promise<void> {
        commit('setLoading', true);
        const response = await state.api.getStandardEmissionFactors();
        commit('setFactors', { factors: response.result, activityData: rootGetters['activityData/GET_ACTIVITY_DATA'] });
        commit('setTableData');
        commit('setLoading', false);
    },
    async INITIALISE_STANDARD_EMISSION_FACTORS({
        state,
        commit,
        dispatch,
    }: ActionContext<StandardEmissionFactorState, ApplicationState>): Promise<void> {
        const currentYear = new Date().getFullYear();
        const endOfYear = new Date(currentYear + 1, 0, 0);
        commit('setStartDate', `${currentYear}-01-01`);
        commit('setEndDate', `${endOfYear.getFullYear()}-${endOfYear.getMonth() + 1}-${endOfYear.getDate()}`);
        dispatch('FETCH_STANDARD_EMISSION_FACTORS');
    },
    async EDIT_STANDARD_EMISSION_FACTOR(
        { state, commit, dispatch }: ActionContext<StandardEmissionFactorState, ApplicationState>,
        body: GlobalEmissionFactorDto,
    ): Promise<void> {
        try {
            commit('setLoading', true);
            const response =
                body.id != 0
                    ? await state.api.editStandardEmissionFactor({ emissionFactorDto: body } as any)
                    : await state.api.postStandardEmissionFactor({ emissionFactorDto: body } as any);
            const result = response.result;
            if (result?.blocked) {
                sendErrorAlert({
                    actionType: FactorActionType.Edit,
                    blockingEmissions: result?.blockingEmissions ?? [],
                });
                commit('setLoading', false);
                return;
            }
            const resultingFactor = result?.emissionFactor;
            if (body.id === 0 && !!resultingFactor) {
                dispatch('CREATE_RELATED_EMISSIONFACTORS', resultingFactor);
            }
            commit('updateFactor', resultingFactor);
            commit('updateTableData');
            commit('setLoading', false);
            toastHttpSuccess();
        } catch (e) {
            toastHttpError(e);
        }
    },
    async CREATE_RELATED_EMISSIONFACTORS(
        { state, commit }: ActionContext<StandardEmissionFactorState, ApplicationState>,
        body: EmissionFactorDto,
    ): Promise<void> {
        var oldFactorIndex = state.factors.findIndex(
            (factor) =>
                factor.activity?.id === body.activityData?.id &&
                factor.unitOfMeasurement?.id === body.activityDataUnitOfMeasurement?.id,
        );
        if (
            state.factors[oldFactorIndex].emissionFactorCO2?.id === 0 &&
            body.greenhouseGas?.chemicalFormula !== 'CO2'
        ) {
            const newCO2 = await state.api.postStandardEmissionFactor({
                emissionFactorDto: state.factors[oldFactorIndex].emissionFactorCO2,
            } as any);
            commit('updateFactor', newCO2.result?.emissionFactor);
        }
        if (
            state.factors[oldFactorIndex].emissionFactorCH4?.id === 0 &&
            body.greenhouseGas?.chemicalFormula !== 'CH4'
        ) {
            const newCH4 = await state.api.postStandardEmissionFactor({
                emissionFactorDto: state.factors[oldFactorIndex].emissionFactorCH4,
            } as any);
            commit('updateFactor', newCH4.result?.emissionFactor);
        }
        if (
            state.factors[oldFactorIndex].emissionFactorN2O?.id === 0 &&
            body.greenhouseGas?.chemicalFormula !== 'N2O'
        ) {
            const newN2O = await state.api.postStandardEmissionFactor({
                emissionFactorDto: state.factors[oldFactorIndex].emissionFactorN2O,
            } as any);
            commit('updateFactor', newN2O.result?.emissionFactor);
        }
        commit('updateTableData');
    },
    async ADD_ACTIVITY(
        { state, commit }: ActionContext<StandardEmissionFactorState, ApplicationState>,
        { selectedActivities }: { selectedActivities: ActivityDataDto[] },
    ): Promise<void> {
        commit('addActivity', selectedActivities);
    },
    async ADD_FACTOR_GROUP(
        { state, commit }: ActionContext<StandardEmissionFactorState, ApplicationState>,
        { factorGroup }: { factorGroup: EmissionFactorGroupDto },
    ): Promise<void> {
        commit('addFactorGroup', factorGroup);
        commit('updateTableData');
    },
    async ADD_STANDARD_EMISSION_FACTOR(
        { state, commit }: ActionContext<StandardEmissionFactorState, ApplicationState>,
        payload: {
            body: EmissionFactorDto;
            previousFactorId: number | undefined;
        },
    ): Promise<void> {
        try {
            commit('setLoading', true);
            const response = await state.api.postStandardEmissionFactor({ emissionFactorDto: payload.body } as any);
            const result = response.result;
            if (result?.blocked) {
                sendErrorAlert({
                    actionType: FactorActionType.Edit,
                    blockingEmissions: result?.blockingEmissions ?? [],
                });
                commit('setLoading', false);
                return;
            }
            commit('updateFactor', result?.emissionFactor);
            commit('setTableData');
            commit('setLoading', false);
            toastHttpSuccess();
        } catch (e) {
            toastHttpError(e);
        }
    },
    async DELETE_AND_UPDATE(
        { state, commit }: ActionContext<StandardEmissionFactorState, ApplicationState>,
        payload: {
            updateFactor: EmissionFactorDto;
            deleteId: number;
        },
    ): Promise<void> {
        try {
            commit('setLoading', true);
            const deleteResponse = await state.api.deleteStandardEmissionFactor(payload.deleteId);
            const deleteResult = deleteResponse.result;
            if (deleteResult?.blocked) {
                sendErrorAlert({
                    actionType: FactorActionType.Delete,
                    blockingEmissions: deleteResult?.blockingEmissions ?? [],
                });
                commit('setLoading', false);
                return;
            }
            const updateResponse = await state.api.putStandardEmissionFactor({
                emissionFactorDto: payload.updateFactor,
            } as any);
            const updateResult = updateResponse.result;
            commit('updateFactor', updateResult?.emissionFactor);
            commit('setTableData');
            commit('setLoading', false);
            toastHttpSuccess();
        } catch (e) {
            toastHttpError(e);
        }
    },
    async DELETE(
        { state, commit }: ActionContext<StandardEmissionFactorState, ApplicationState>,
        payload: {
            deleteId: number;
        },
    ): Promise<void> {
        try {
            commit('setLoading', true);
            const deleteResponse = await state.api.deleteStandardEmissionFactor(payload.deleteId);
            const deleteResult = deleteResponse.result;
            if (deleteResult?.blocked) {
                sendErrorAlert({
                    actionType: FactorActionType.Delete,
                    blockingEmissions: deleteResult?.blockingEmissions ?? [],
                });
                commit('setLoading', false);
                return;
            }
            commit('deleteFactor', { id: payload.deleteId });
            commit('setTableData');
            commit('setLoading', false);
            toastHttpSuccess();
        } catch (e) {
            toastHttpError(e);
        }
    },
    async GET_STANDARD_EMISSION_FACTORS_HISTORY(
        { state, commit }: ActionContext<StandardEmissionFactorState, ApplicationState>,
        body: EmissionFactorDto,
    ): Promise<void> {
        const response = await state.api.getStandardEmissionFactorHistory(
            body.activityData?.id,
            body.greenhouseGas?.id,
            body.activityDataUnitOfMeasurement?.id,
        );
        commit('detailedViewEmissionFactorHistory', response.result);
    },
    async RESET_STANDARD_EMISSION_FACTORS_HISTORY({
        state,
        commit,
    }: ActionContext<StandardEmissionFactorState, ApplicationState>): Promise<void> {
        commit('detailedViewEmissionFactorHistory', []);
    },
    async BULK_EDIT(
        { state, commit, dispatch }: ActionContext<StandardEmissionFactorState, ApplicationState>,
        payload: {
            emissionFactorIds: number[];
            startDate: string;
            source: string;
            linkToSource: string;
            additionalInformation: string;
            publicationDate: string;
        },
    ): Promise<void> {
        commit('setLoading', true);
        try {
            const values = {
                startDate: payload.startDate,
                source: payload.source,
                linkToSource: payload.linkToSource,
                publicationDate: payload.publicationDate,
                additionalInformation: payload.additionalInformation,
            } as BulkEditValues;
            var request = {
                emissionfactorIds: payload.emissionFactorIds,
                startDate: payload.startDate,
                source: payload.source,
                linkToPublication: payload.linkToSource,
                additionalInformation: payload.additionalInformation,
            } as StandardEmissionFactorBulkEndpointRequest;
            if (payload.publicationDate) {
                request.publicationDate = payload.publicationDate;
            }
            const response = await state.api.bulkStandardEmissionFactor(request);

            if (response.result?.blocked) {
                SendBulkEditErrorAlert({
                    bulkEditValues: values,
                    blockedEmissionFactors: response.result!.blockedEmissionFactors,
                });
            } else {
                SendBulkEditResponseAlert({
                    bulkEditValues: values,
                    updatedEmissionFactors: response.result?.updatedEmissionFactors,
                    skippedEmissionFactors: response.result?.skippedEmissionFactors,
                });
            }
        } catch (e) {
            toastHttpError(e);
        }

        dispatch('FETCH_STANDARD_EMISSION_FACTORS');
    },
};

const namespaced = true;

export const standardEmissionFactor: Module<StandardEmissionFactorState, ApplicationState> = {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};
