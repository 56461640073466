import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ApplicationState } from '@/store';
import { getUserPermissions } from '@/utils/permissions/functional';
import { Permission } from '@coode/fe-sdk/dist/types/uam';

export interface RolesState {
    roleIds: number[];
    pageRoles: Record<string, number[]>;
    assetIdsByRole: Record<number, number[]>;
    assetsWithinPermissions: Record<string, number[]>;
    arePermissionsValid: boolean;
}

const state: RolesState = {
    roleIds: [],
    pageRoles: {},
    assetIdsByRole: {},
    assetsWithinPermissions: {},
    arePermissionsValid: true,
};

const getters: GetterTree<RolesState, ApplicationState> = {
    getRolesIds: (state) => state.roleIds,
    getPageRoles: (state) => state.pageRoles,
    getAssetIdsByRole: (state) => state.assetIdsByRole,
    getAssetsWithinPermissions: (state) => state.assetsWithinPermissions,
    getArePermissionsValid: (state) => state.arePermissionsValid,
};

const mutations: MutationTree<RolesState> = {
    setRolesIds(state, payload) {
        state.roleIds = payload;
    },
    setPageRoles(state, payload) {
        state.pageRoles = payload;
    },
    setAssetIdsByRole(state, payload) {
        state.assetIdsByRole = payload;
    },
    setAssetsWithinPermissions(state, payload) {
        state.assetsWithinPermissions = payload;
    },
    setArePermissionsValid(state, payload) {
        state.arePermissionsValid = payload;
    },
};

interface UpdatedPermission extends Permission {
    userGroupId: number;
}

const actions: ActionTree<RolesState, ApplicationState> = {
    SPLIT_ASSETS({ commit }): void {
        const functionalPermissions = getUserPermissions().permissions as UpdatedPermission[];
        const assetPermissions = getUserPermissions().assetPermissions;

        //returns an array of all user group ids (no duplicates)
        const rolesIds = Array.from(new Set(functionalPermissions.map((perm) => perm.userGroupId)));
        commit('setRolesIds', rolesIds);

        // returns an object with the key/value pairs of Functional Permission Name to an array of userGroupIds
        const pageRoles: Record<string, number[]> = functionalPermissions.reduce(
            (prev: Record<string, number[]>, curr) => {
                if (!prev[curr.name]) {
                    return { ...prev, [curr.name]: [curr.userGroupId] };
                }
                return { ...prev, [curr.name]: [...prev[curr.name], curr.userGroupId] };
            },
            {} as Record<string, number[]>,
        );
        commit('setPageRoles', pageRoles);

        // returns an object with the key/value pairs of userGroupId to an array of assetIds
        const assetIdsByRole: Record<string, number[]> = assetPermissions.reduce(
            (prev: Record<string, number[]>, curr) => {
                if (!prev[curr.userGroupId]) {
                    return { ...prev, [curr.userGroupId]: [curr.assetSid] };
                }
                return { ...prev, [curr.userGroupId]: [...prev[curr.userGroupId], curr.assetSid] };
            },
            {} as Record<string, number[]>,
        );
        commit('setAssetIdsByRole', assetIdsByRole);

        // returns an object with the key/value pairs of Functional Permission Name to an array of assetIds
        const assetsWithinPermissions = Object.entries(pageRoles).reduce((prev, [page, roles]) => {
            const mappedAssetsToGroup = roles.reduce((prev, curr) => {
                return [...prev, ...assetIdsByRole[curr]];
            }, [] as number[]);

            const filteredDuplicates = new Set(mappedAssetsToGroup);

            return { ...prev, [page]: Array.from(filteredDuplicates) };
        }, {} as Record<string, number[]>);
        commit('setAssetsWithinPermissions', assetsWithinPermissions);
    },
    VALIDATE_PERMISSIONS({ state, commit }): void {
        if (state.roleIds.length < 1) commit('setArePermissionsValid', false);
        if (state.roleIds.length === 1) commit('setArePermissionsValid', true);

        const mappedAssetsToPermission = Object.entries(state.assetsWithinPermissions);

        function arrayEquals(a: any[], b: any[]) {
            return (
                Array.isArray(a) &&
                Array.isArray(b) &&
                a.length === b.length &&
                a.every((val, index) => val === b[index])
            );
        }

        const validation = mappedAssetsToPermission.reduce((prev, [permission, assets], index, array) => {
            if (!prev) return false;
            if (!array[index + 1]) return true;
            return arrayEquals(assets.sort(), array[index + 1][1].sort());
        }, true);

        commit('setArePermissionsValid', validation);
    },
};

const namespaced = true;

export const roles: Module<RolesState, ApplicationState> = {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};
