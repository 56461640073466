
import Vue from 'vue';
import BoxSkeleton from './box-skeleton.vue';

export default Vue.extend({
    name: 'AppSkeleton',
    components: {
        BoxSkeleton,
    },
});
