import { MaxDecimalPlaces } from '@/constants/input-precision';
import { LocationTableData } from '@/models/table';
import { EmissionWithParkedEmissionDto } from '@/service-proxies/service-proxies.g';
import { roundToDecimalPlaces } from '@/utils';
import {
    APPROVAL_TABLE_COLUMN_DEF,
    EMISSION_TABLE_COLUMN_DEF,
    TableType,
    destroyInjectedComponents,
    globalRowFormatter,
} from '@/utils/tables/table-config';
import { PebbleTable } from '@pebble/tables';
import { GroupComponent, RowComponent } from 'tabulator-tables';
import { PropType, defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

interface Data {
    table: PebbleTable | undefined;
    debounce: any;
    expansionTracker: Record<any, boolean>;
}

export default defineComponent({
    name: 'EmissionTable',
    props: {
        tableType: {
            type: Number as PropType<TableType>,
            required: true,
        },
    },
    data(): Data {
        return {
            table: undefined,
            debounce: null,
            expansionTracker: {},
        };
    },
    computed: {
        ...mapGetters({
            emissionTableData: 'location/emissionTableData',
            approvalTableData: 'location/approvalTableData',
            tracker: 'tables/inputExpansionTracker',
            fleetNames: 'tables/fleetNames',
            selectedCountry: 'country/GET_SELECTED_COUNTRY',
        }),
        columnDefinition(): any[] {
            const columnDefinitionsMap = {
                [TableType.DATA_ENTRY]: EMISSION_TABLE_COLUMN_DEF,
                [TableType.APPROVAL]: APPROVAL_TABLE_COLUMN_DEF,
            } as Record<string, Array<{ [key: string]: any }>>;
            const columnDefinition = columnDefinitionsMap[this.tableType];
            return (columnDefinition || []).map((col) => ({
                ...col,
                title: this.$t(`emissionTable.column.${col.field}`),
            }));
        },
        fleetGrouping(): number[] {
            const array = Object.entries(this.fleetNames as Record<number, string>);
            const sortedArray = array.sort((a, b) => a[1].toLocaleLowerCase().localeCompare(b[1].toLocaleLowerCase()));
            return sortedArray.map((entry) => Number(entry[0]));
        },
        countryId(): number {
            return this.selectedCountry.id;
        },
        tableData(): LocationTableData[] {
            return this.tableType === TableType.DATA_ENTRY ? this.emissionTableData : this.approvalTableData;
        },
    },
    watch: {
        // Emission and approval table data get changed at the same time, so we only need to watch one
        emissionTableData(): void {
            // Watcher is called multiple times on update, so we
            // debounce it to keep from rebuilding the table a bunch
            this.debounce && clearTimeout(this.debounce);

            this.debounce = setTimeout(() => {
                this.table?.replaceData(this.tableData || []);
            }, 500);
        },
    },
    mounted() {
        this.buildTable();
    },
    beforeDestroy() {
        if (!this.table) return;

        this.table.off('cellEdited');
        this.table.off('dataTreeRowExpanded');
        this.table.off('dataTreeRowCollapsed');
        this.table.off('groupVisibilityChanged');
        this.table.clearData();
        destroyInjectedComponents();
        this.table.destroy();
        this.table = undefined;
    },
    methods: {
        ...mapActions({ updateTracker: 'tables/UPDATE_TRACKER' }),
        buildTable(): void {
            this.table = new PebbleTable('#data-input-table', {
                data: this.tableData || [],
                height: 'calc(100vh - 270px)',
                pagination: false,
                groupBy: 'fleetId',
                groupHeader: (value: number) => {
                    return `<span style="color: #fff; font-weight: 300;">${this.fleetNames[
                        value
                    ].toUpperCase()}</span>`;
                },
                groupValues: [this.fleetGrouping],
                groupToggleElement: 'header',
                groupStartOpen: (value: number) => this.tracker[`${this.countryId}-${value}`],
                layout: 'fitDataStretch',
                dataTree: true,
                // This stops collapsed rows expanding whenever a change is made to the table.
                dataTreeStartExpanded: (row: RowComponent): boolean => this.startExpanded(row),
                dataTreeBranchElement: false,
                columns: this.columnDefinition,
                rowFormatter: globalRowFormatter,
                selectable: false,
                initialSort: [{ column: 'location', dir: 'asc' }],
                columnDefaults: {
                    vertAlign: 'middle',
                },
            });

            this.table.on('cellEdited', function (cell) {
                const row = cell.getRow();
                const data = row.getData() as LocationTableData;
                if (data.activityDataAmount === undefined || data.emissionFactor === undefined) return;
                if (cell.getValue() === '') cell.setValue(0);

                const emission = data.meta.object as EmissionWithParkedEmissionDto;
                const emissionFactorGHGUnitOfMeasurement = emission.emissionFactor?.ghgUnitOfMeasurement;
                const conversion =
                    (emission.ghgUnitOfMeasurement?.magnitude ?? 1) /
                    (emissionFactorGHGUnitOfMeasurement?.magnitude ?? 1);

                const calculation = (data.activityDataAmount * data.emissionFactor) / conversion;

                const roundingCorrection = roundToDecimalPlaces(calculation, MaxDecimalPlaces.GHG);

                data.ghgAmount = roundingCorrection;

                const ghgAmountCell = row.getCell('ghgAmount');
                ghgAmountCell.setValue(roundingCorrection);
            });

            this.table.on('dataTreeRowExpanded', (row: RowComponent) => this.onExpand(row));
            this.table.on('dataTreeRowCollapsed', (row: RowComponent) => this.onCollapse(row));
            this.table.on('groupVisibilityChanged', (group: GroupComponent, visible) =>
                this.onGroupToggle(group, visible),
            );
        },

        startExpanded(row: RowComponent): boolean {
            const data = row.getData() as LocationTableData;
            return this.tracker[data.meta.id];
        },

        onExpand(row: RowComponent): void {
            const data = row.getData() as LocationTableData;
            this.updateTracker({ [data.meta.id]: true });
        },

        onCollapse(row: RowComponent): void {
            const data = row.getData() as LocationTableData;
            this.updateTracker({ [data.meta.id]: false });
        },

        onGroupToggle(group: GroupComponent, visible: boolean): void {
            this.updateTracker({ [`${this.countryId}-${group.getKey()}`]: visible });
        },
    },
});
