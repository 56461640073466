
import Vue, { PropType } from 'vue';
import {
    EmissionFactorDto,
    EmissionWithParkedEmissionDto,
    EmissionWithEmissionFactorOptionsDto,
} from '@/service-proxies/service-proxies.g';
import UnitOfMeasurement from '@/components/unit-of-measurement/unit-of-measurement.vue';
import StatusCell from '../tables/emission-table/custom-cells/status-cell.vue';
import FloatInput from '../inputs/float-input.vue';
import ModalInputWrapper from '../inputs/modal-input-wrapper.vue';

const ParkedEmission = Vue.extend({
    name: 'ParkedEmission',
    components: {
        UnitOfMeasurement,
        StatusCell,
        FloatInput,
        ModalInputWrapper,
    },
    props: {
        emission: {
            type: Object as PropType<EmissionWithParkedEmissionDto>,
            required: true,
        },
    },
    data(): {
        parentEmission: EmissionWithParkedEmissionDto | null;
        parkedEmission: EmissionWithEmissionFactorOptionsDto | null;
        inputFields: { [key: string]: string };
    } {
        return {
            parentEmission: null,
            parkedEmission: null,
            inputFields: {
                activityDataAmount: '',
                emissionFactor: '',
                ghgAmount: '',
                factor: '',
                calorificValue: '',
                heatBasedEmissionFactor: '',
                carbonContent: '',
                conversionFactor: '',
                oxidationFactor: '',
                biogen: '',
            },
        };
    },
    computed: {
        activityDataUnits(): string {
            return this.emission.activityDataUnitOfMeasurement?.shortName ?? '';
        },
        factorUnits(): string {
            const numerator = this.emission.ghgUnitOfMeasurement?.shortName ?? '';
            const denominator = this.emission.activityDataUnitOfMeasurement?.shortName ?? '';
            return `${numerator} / ${denominator}`;
        },
        ghgUnits(): string {
            return this.emission.ghgUnitOfMeasurement?.shortName ?? '';
        },
        calorificValueUnits(): string {
            const numerator = this.emission.emissionFactor?.heatValueNumeratorUnitOfMeasurement?.shortName ?? '';
            const denominator = this.emission.emissionFactor?.heatValueDenominatorUnitOfMeasurement?.shortName ?? '';
            return `${numerator} / ${denominator}`;
        },
        heatBasedEFUnits(): string {
            const numerator =
                this.emission.emissionFactor?.heatBasedEmissionFactorNumeratorUnitOfMeasurement?.shortName ?? '';
            const denominator =
                this.emission.emissionFactor?.heatBasedEmissionFactorDenominatorUnitOfMeasurement?.shortName ?? '';
            return `${numerator} / ${denominator}`;
        },
        headline(): string {
            return (this.$t('detailedView.parked') as string).toUpperCase();
        },
        differentParameter(): Record<string, boolean> {
            const parentParams = this.parentEmission?.emissionFactor;
            const parkedParams = this.parkedEmission?.emissionFactor;
            return {
                activityDataAmount: this.parentEmission?.activityDataAmount !== this.parkedEmission?.activityDataAmount,
                emissionsFactor: parentParams?.emissionsFactor !== parkedParams?.emissionsFactor,
                ghgAmount: this.parentEmission?.activityDataAmount !== this.parkedEmission?.activityDataAmount,
                factor: parentParams?.factor !== parkedParams?.factor,
                heatValue: parentParams?.heatValue !== parkedParams?.heatValue,
                heatBasedEF: parentParams?.heatBasedEmissionFactor !== parkedParams?.heatBasedEmissionFactor,
                carbonContent: parentParams?.carbonContent !== parkedParams?.carbonContent,
                conversionFactor: parentParams?.conversionFactor !== parkedParams?.conversionFactor,
                oxidationFactor: parentParams?.oxidationFactor !== parkedParams?.oxidationFactor,
                biogen: parentParams?.biogen !== parkedParams?.biogen,
            };
        },
    },
    mounted(): void {
        this.parentEmission = this.emission;
        this.parkedEmission = this.emission.parkedEmission ?? null;
        if (!this.parkedEmission) return;
        const params = this.parkedEmission.emissionFactor as EmissionFactorDto;

        this.inputFields.activityDataAmount = this.parkedEmission.activityDataAmount?.toString() ?? '';
        this.inputFields.ghgAmount = this.parkedEmission.ghgAmount?.toString() ?? '';
        this.inputFields.emissionFactor = params.emissionsFactor?.toString() ?? '';
        this.inputFields.factor = params.factor?.toString() ?? '';
        this.inputFields.calorificValue = params.heatValue?.toString() ?? '';
        this.inputFields.heatBasedEmissionFactor = params.heatBasedEmissionFactor?.toString() ?? '';
        this.inputFields.carbonContent = params.carbonContent?.toString() ?? '';
        this.inputFields.conversionFactor = params.conversionFactor?.toString() ?? '';
        this.inputFields.oxidationFactor = params.oxidationFactor?.toString() ?? '';
        this.inputFields.biogen = params.biogen?.toString() ?? '';
    },
});

export default ParkedEmission;
