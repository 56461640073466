
import { LocationTableData } from '@/models/table';
import { EventBus } from '@/utils';
import { defineComponent, PropType } from 'vue';

interface Data {
    icon: {
        name: string;
        size: string;
    };
}

export default defineComponent({
    name: 'AddEmissionCell',
    props: {
        location: {
            type: Object as PropType<LocationTableData>,
            required: true,
        },
    },
    data(): Data {
        return {
            icon: {
                name: 'add',
                size: '16px',
            },
        };
    },
    computed: {
        label(): string {
            return this.$t('emissionTable.action.addActivity') as string;
        },
    },
    methods: {
        openAddActivityModal(): void {
            EventBus.$emit(EventBus.VIEWS.MAIN.OPEN_ADD_ACTIVITY_DATA_MODAL, this.location);
        },
    },
});
