import Approvals from '@/components/approvals/overview.vue';
import CertificateRisk from '@/components/distribution-type/distribution-type.vue';
import FreeAllocationCO2 from '@/components/free-allocation-co2/overview.vue';
import DataInput from '@/components/data-input/data-input.vue';
import StandardEmission from '@/components/standard-emissions/overview.vue';
import ReportingPage from '@/components/reporting/reporting.page.vue';
import { RouteNames } from '@/constants/routes';
import { functionalPermissions } from '@/utils';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

let KEY = 1;

const routes: RouteConfig[] = [
    {
        path: '/',
        redirect: () => {
            if (functionalPermissions.userCanEnterEmissionData()) {
                return RouteNames.DATA_INPUT;
            }
            if (functionalPermissions.userCanApproveEmissionData()) {
                return RouteNames.APPROVAL;
            }
            if (functionalPermissions.userCanAllocateFreeCO2()) {
                return RouteNames.FREE_ALLOCATION;
            }
            if (functionalPermissions.userCanDefineCO2Risk()) {
                return RouteNames.DISTRIBUTION_TYPE;
            }
            if (functionalPermissions.userCanViewStandardEF()) {
                return RouteNames.STANDARD_EMISSION;
            }
            return RouteNames.REPORTING;
        },
        meta: { key: KEY++ },
    },
    {
        path: RouteNames.DATA_INPUT,
        name: 'Data Input',
        component: DataInput,
        meta: { key: KEY++ },
        beforeEnter: (_to, _from, next): void => {
            if (!functionalPermissions.userCanEnterEmissionData()) {
                next({ path: '/', replace: true });
                return;
            }

            next();
        },
    },
    {
        path: RouteNames.APPROVAL,
        name: 'Approvals Dashboard',
        component: Approvals,
        meta: { key: KEY++ },
        beforeEnter: (_to, _from, next): void => {
            if (!functionalPermissions.userCanApproveEmissionData()) {
                next({ path: '/', replace: true });
                return;
            }

            next();
        },
    },
    {
        path: RouteNames.FREE_ALLOCATION,
        name: 'FreeAllocationCO2',
        component: FreeAllocationCO2,
        meta: { key: KEY++ },
        beforeEnter: (_to, _from, next): void => {
            if (!functionalPermissions.userCanAllocateFreeCO2()) {
                next({ path: '/', replace: true });
                return;
            }

            next();
        },
    },
    {
        path: RouteNames.DISTRIBUTION_TYPE,
        name: 'Certificate Risk',
        component: CertificateRisk,
        meta: { key: KEY++ },
        beforeEnter: (_to, _from, next): void => {
            if (!functionalPermissions.userCanDefineCO2Risk()) {
                next({ path: '/', replace: true });
                return;
            }

            next();
        },
    },
    {
        path: RouteNames.STANDARD_EMISSION,
        name: 'Standard Emission',
        component: StandardEmission,
        meta: { key: KEY++ },
        beforeEnter: (_to, _from, next): void => {
            if (!functionalPermissions.userCanViewStandardEF()) {
                next({ path: '/', replace: true });
                return;
            }

            next();
        },
    },
    {
        path: RouteNames.REPORTING,
        name: 'Reporting',
        component: ReportingPage,
        meta: { key: KEY++ },
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;
