import { render, staticRenderFns } from "./overview.vue?vue&type=template&id=216f6f90&scoped=true"
import script from "./overview.vue?vue&type=script&lang=ts"
export * from "./overview.vue?vue&type=script&lang=ts"
import style1 from "./overview.vue?vue&type=style&index=1&id=216f6f90&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "216f6f90",
  null
  
)

export default component.exports