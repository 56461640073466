export const SYMBOL_LINE = '<span class="symbol">/</span>';
export const FRAC_START_LINE = '<p class="frac">';

export const UOM_MAPPING = {
    m3: '<p>m<sup>3</sup></p>',

    't CO2e': '<p>t CO<sub>2</sub>-eq</p>',

    Nm3: '<p>Nm<sup>3</sup></p>',

    'kJ/kg': FRAC_START_LINE + '<span>kJ</span>' + SYMBOL_LINE + '<span class="bottom">kg</span>' + '</p>',

    'MJ/m3': FRAC_START_LINE + '<span>MJ</span>' + SYMBOL_LINE + '<span class="bottom">m<sup>3</sup></span>' + '</p>',

    't CO2/TJ':
        FRAC_START_LINE + '<span>t CO<sub>2</sub></span>' + SYMBOL_LINE + '<span class="bottom">TJ</span>' + '</p>',

    't CO2e/m3':
        FRAC_START_LINE +
        '<span>t CO<sub>2</sub>-eq</span>' +
        SYMBOL_LINE +
        '<span class="bottom">m<sup>3</sup></span>' +
        '</p>',

    't CO2e/t CO2e': '<p></p>',

    't CO2e/Nm3':
        FRAC_START_LINE +
        '<span>t CO<sub>2</sub>-eq</span>' +
        SYMBOL_LINE +
        '<span class="bottom">Nm<sup>3</sup></span>' +
        '</p>',

    't CO2e/kJ/kg':
        FRAC_START_LINE +
        '<span>t CO<sub>2</sub>-eq kg</span>' +
        SYMBOL_LINE +
        '<span class="bottom">kJ</span>' +
        '</p>',

    't CO2e/MJ/m3':
        FRAC_START_LINE +
        '<span>t CO<sub>2</sub>-eq m<sup>3</sup></span>' +
        SYMBOL_LINE +
        '<span class="bottom">MJ</span>' +
        '</p>',

    't CO2e/t CO2/TJ':
        FRAC_START_LINE +
        '<span>t CO<sub>2</sub>-eq TJ</span>' +
        SYMBOL_LINE +
        '<span class="bottom">t CO<sub>2</sub></span>' +
        '</p>',
};
