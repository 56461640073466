import { LocalStorageHelper } from '@coode/fe-sdk';
import { UserPermissions } from '@coode/fe-sdk/dist/types';
import { AssetPermission, ParameterPermission, Permission } from '@coode/fe-sdk/dist/types/uam';

export class Supervisor {
    static GetUserPermissions(): UserPermissions {
        return LocalStorageHelper.getPermissionsLocalStorage();
    }

    static GetAssetPermissions(): AssetPermission[] {
        return this.GetUserPermissions().assetPermissions;
    }

    static GetFunctionalPermissions(): Permission[] {
        return this.GetUserPermissions().permissions;
    }

    static GetParameterPermissions(): ParameterPermission[] {
        return this.GetUserPermissions().parameterPermissions;
    }

    static UserHasAccessToDevTools(): boolean {
        return !!this.GetFunctionalPermissions().find((p) => p.name === 'HasAccessToDevTools');
    }
}
