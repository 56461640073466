
import Vue, { inject, PropType } from 'vue';
import { AttachmentDto } from '@/service-proxies/service-proxies.g';
import { WAITING_MODAL_DEFAULT_TEXT, WAITING_MODAL_DEFAULT_TITLE } from '@/store/modules/waiting-modal';
import { AttachmentService } from '@/services/attachment-service';
import { apiCallWithErrorHandling } from '@/services/utils';
import { ConfirmAction } from '@/models';
import { base64PDFtoBlob } from '@/utils/javascript-utils';

const EmissionAttachment = Vue.extend({
    name: 'EmissionAttachment',
    props: {
        emissionId: {
            type: Number,
            required: true,
        },
        attachment: {
            type: Object as PropType<AttachmentDto>,
            required: true,
        },
        canDelete: {
            type: Boolean,
            default: true,
        },
    },
    setup() {
        return {
            confirmAction: inject<ConfirmAction>('ConfirmAction'),
        };
    },
    data(): {
        attachmentService: AttachmentService;
    } {
        return {
            attachmentService: new AttachmentService(),
        };
    },

    methods: {
        async downloadHandler(): Promise<void> {
            const pdfResponse = await apiCallWithErrorHandling(
                this.attachmentService.getAttachment,
                this.attachment.id ?? 0,
                (this as any).$pui.toast,
                {
                    successTitle: this.$t('toast.success').toString(),
                    successText: this.$t('attachment.download.successText').toString(),
                    errorTitle: this.$t('toast.error').toString(),
                    errorText: this.$t('attachment.download.errorText').toString(),
                    waitingTitle: this.$t(WAITING_MODAL_DEFAULT_TITLE).toString(),
                    waitingText: this.$t(WAITING_MODAL_DEFAULT_TEXT).toString(),
                },
                this.$store,
            );
            const blob = await base64PDFtoBlob(pdfResponse.result?.fileContent ?? '');
            const fileObjectUrl = URL.createObjectURL(blob);
            window.open(fileObjectUrl);
        },

        async deleteHandler(): Promise<void> {
            if (!this.confirmAction) return;
            this.confirmAction({
                title: this.$t('actions.deleteAttachment.title'),
                cancel: this.$t('actions.cancel'),
                confirm: this.$t('actions.confirm'),
                content: this.$t('actions.deleteAttachment.content'),
            }).then(() => {
                return this.deleteAttachment();
            });
        },

        async deleteAttachment(): Promise<void> {
            const response = await apiCallWithErrorHandling(
                this.attachmentService.deleteAttachment,
                this.attachment.id ?? 0,
                (this as any).$pui.toast,
                {
                    successTitle: this.$t('toast.success').toString(),
                    successText: this.$t('attachment.deletion.successText').toString(),
                    errorTitle: this.$t('toast.error').toString(),
                    errorText: this.$t('attachment.deletion.errorText').toString(),
                    waitingTitle: this.$t(WAITING_MODAL_DEFAULT_TITLE).toString(),
                    waitingText: this.$t(WAITING_MODAL_DEFAULT_TEXT).toString(),
                },
                this.$store,
            );
            // If we get here the request was a success. Now we remove the deleted attachment from our vuex store
            if (response && response?.result) {
                this.$store.dispatch('location/DELETE_ATTACHMENT', this.attachment);
                this.$store.dispatch('detailedView/DELETE_ATTACHMENT', this.attachment);
            }
        },
    },
});

export default EmissionAttachment;
