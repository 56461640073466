
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { EmissionFactorDto } from '@/service-proxies/service-proxies.g';
import UnitOfMeasurement from '@/components/unit-of-measurement/unit-of-measurement.vue';
import StatusCell from '../tables/emission-table/custom-cells/status-cell.vue';
import FloatInput from '../inputs/float-input.vue';
import ModalInputWrapper from '../inputs/modal-input-wrapper.vue';
import { MaxDecimalPlaces } from '@/constants/input-precision';
import { EmissionFactorType } from '@/enums/emission-factor-type';
import { EmissionFactorInput } from '@/models/emission-factor-input';
import { DEFAULT_SCOPE_ID } from '@/constants/default';

const EmissionSummary = Vue.extend({
    name: 'EmissionSummary',
    components: {
        UnitOfMeasurement,
        StatusCell,
        FloatInput,
        ModalInputWrapper,
    },
    props: {
        unit: {
            type: Object,
            required: true,
        },
        powerPlant: {
            type: Object,
            required: false,
            default: null,
        },
        isApprovalView: {
            type: Boolean,
            default: true,
        },
    },
    data(): {
        formValues: any;
        valuesChanged: boolean;
        MaxDecimalPlaces: EmissionFactorInput;
    } {
        return {
            formValues: {
                activityDataAmount: {
                    isValid: true,
                },
                ghgAmount: {
                    isValid: true,
                },
            },
            valuesChanged: false,
            MaxDecimalPlaces,
        };
    },
    computed: {
        ...mapGetters({ scope: 'scope/GET_SELECTED_SCOPE' }),
        ...mapGetters('detailedView', {
            importedEmission: 'GET_EMISSION',
            emission: 'GET_EDITED_EMISSION',
            getTypeOfEmissionFactor: 'GET_TYPE_OF_EMISSION_FACTOR',
            getEditedIndividualEmissionFactor: 'GET_EDITED_INDIVIDUAL_EMISSION_FACTOR',
            getStandardEmissionFactor: 'GET_STANDARD_EMISSION_FACTOR',
        }),
        scopeName(): string {
            return this.$t(`scope${this.scope?.subScope?.replace('.', '_') ?? ''}`).toString();
        },
        emissionFactorInput(): string {
            if (this.emissionFactor.emissionsFactor === null || this.emissionFactor.emissionsFactor === undefined) {
                return '';
            }
            return this.emissionFactor.emissionsFactor.toString();
        },
        emissionFactor(): EmissionFactorDto {
            if (this.isApprovalView || this.importedEmission.approvalStatus === 'Approved') {
                return this.importedEmission.emissionFactor;
            }
            if (this.getTypeOfEmissionFactor === EmissionFactorType.Individual) {
                return this.getEditedIndividualEmissionFactor;
            } else {
                return this.getStandardEmissionFactor;
            }
        },
        factorUnits(): string {
            let numerator =
                this.emissionFactor.ghgUnitOfMeasurement?.shortName ?? this.emission.ghgUnitOfMeasurement?.shortName;
            if (this.scope.id === DEFAULT_SCOPE_ID) {
                numerator += ' CO2';
            }
            const denominator =
                this.emissionFactor.activityDataUnitOfMeasurement?.shortName ??
                this.emission.activityDataUnitOfMeasurement?.shortName;
            return `${numerator ?? ''} / ${denominator ?? ''}`;
        },
        emptyRequiredField(): boolean {
            return (
                this.formValues.activityDataAmount.value === undefined ||
                this.formValues.activityDataAmount.value === ''
            );
        },
        greenHouseGasUOM(): string {
            let uom = this.emission.ghgUnitOfMeasurement.shortName;
            if (this.scope.id === DEFAULT_SCOPE_ID) {
                uom += ' CO2';
            }
            return uom;
        },
        metaInfo(): Record<string, string> {
            const source = this.$t('meta.source');
            const type = this.$t('meta.type');

            const translatedEFType =
                this.emissionFactor.type === EmissionFactorType.Individual
                    ? this.$t('emission.type.individual')
                    : this.$t('emission.type.global');

            const fuelSource = `${source}: ${this.emission.source}`;
            const emissionType = `${type}: ${translatedEFType}`;
            const emissionSource = `${source}: ${this.emissionFactor?.source}`;

            return {
                fuelSource,
                emissionType,
                emissionSource,
            };
        },
    },
    mounted() {
        this.formValues.activityDataAmount.value = this.emission?.activityDataAmount
            ? this.emission.activityDataAmount.toString()
            : '';
        this.formValues.ghgAmount.value = this.emission?.ghgAmount ? this.emission.ghgAmount.toString() : '';

        // this is needed because otherwise otherwise on closing and opening the lightbox, the old values
        // will still be visible
        this.formValues = { ...this.formValues };
    },
});

export default EmissionSummary;
