import { EmissionFactorNames } from '@/models/emission-factor-input';

export const EmissionInputs: EmissionFactorNames = {
    FUEL_CONSUMPTION: 'activityDataAmount',
    EMISSION_FACTOR: 'emissionsFactor',
    GHG: 'ghgAmount',
    GHG_BIOGENIC: 'ghgAmountBiogenicShare',
    FACTOR: 'factor',
    CALORIFIC_VALUE: 'calorificValue',
    HBEF: 'heatBasedEmissionFactor',
    CARBON_CONTENT: 'carbonContent',
    CONVERSION_FACTOR: 'conversionFactor',
    OXIDATION_FACTOR: 'oxidationFactor',
    BIOGENIC_SHARE: 'biogen',
    ADJUSTMENT_FACTOR: 'activityDataAmountAdjustmentFactor',
};
