import { HttpStatusCodes } from '@/enums/http-status-codes';
import { PowerPlantListEndpointRequest, PowerPlantRisks } from '@/models/power-plant';
import { RiskClassificationEndpointRequest } from '@/models/risk-classification';
import { ClassificatorDto, ClassificatorRiskLevel, RiskAllocationDto } from '@/service-proxies/service-proxies.g';
import { LocationService } from '@/services/location-service';
import { RiskClassificationService } from '@/services/risk-classification-service';
import { PowerPlantNormalizer } from '@/utils/helpers/state-normalizer';
import axios, { AxiosError } from 'axios';
import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ApplicationState, StateWithModules } from '..';

export const riskClassificationService: RiskClassificationService = new RiskClassificationService();

export interface ClassificatorDataState {
    powerPlants: PowerPlantRisks[];
    classificatorData: PowerPlantRisks[];
    locationService: LocationService;
    countryId: number;
    periodStart?: number;
    periodEnd?: number;
    scopeId?: number;
}

const state: ClassificatorDataState = {
    powerPlants: [],
    locationService: new LocationService(),
    classificatorData: [],
    scopeId: 3,
    countryId: -1,
    periodStart: 2021,
    periodEnd: 2025,
};

const getters: GetterTree<ClassificatorDataState, ApplicationState> = {
    GET_CLASSIFICATOR_DATA: (state): PowerPlantRisks[] => {
        return state.classificatorData;
    },
    GET_CLASSIFICATOR_STORE_COUNTRY: (state): number => {
        return state.countryId;
    },
};

const mutations: MutationTree<ClassificatorDataState> = {
    SET_START_DATE(state: ClassificatorDataState, date: number | string) {
        if (typeof date === 'string') {
            date = parseInt(date);
        }
        state.periodStart = date;
        state.periodEnd = date + 4;
    },
    SET_POWER_PLANT(state: ClassificatorDataState, powerPlant: PowerPlantRisks) {
        state.powerPlants.push(powerPlant);
    },
    CLEAR_ASSETS(state: ClassificatorDataState) {
        state.powerPlants.splice(0);
    },
    SET_CLASSIFICATOR_DATA(state: ClassificatorDataState, payload: { data: ClassificatorDto[]; countryId: number }) {
        state.classificatorData = state.powerPlants;
        state.countryId = payload.countryId;
        for (const plant of state.classificatorData) {
            for (const asset of plant.units) {
                const classificator = payload.data.find((c) => c.machineSid == asset.sid);
                if (classificator) {
                    asset.riskClassification = classificator;
                } else {
                    const emptyAllocation = new Array<RiskAllocationDto>(5);
                    for (let i = 0; i < 5; i++) {
                        emptyAllocation[i] = new RiskAllocationDto({
                            countrySid: payload.countryId,
                            machineSid: asset.sid,
                            riskYear: state.periodStart! + i,
                            value: ClassificatorRiskLevel.Unset,
                        });
                    }
                    asset.riskClassification = new ClassificatorDto({
                        id: 0,
                        countrySid: payload.countryId,
                        plantSid: plant.sid,
                        machineSid: asset.sid,
                        periodStart: state.periodStart,
                        periodEnd: state.periodEnd,
                        riskAllocations: emptyAllocation,
                    });
                }
            }
            plant.units.sort((a, b) => a.name.localeCompare(b.name));
        }
        state.classificatorData.sort((a, b) => a.name.localeCompare(b.name));
    },
    UPDATE_CLASSIFICATOR(state: ClassificatorDataState, classificator: ClassificatorDto) {
        const plantIndex = state.classificatorData.findIndex((plant) => plant.sid == classificator.plantSid);
        const unitIndex = state.classificatorData[plantIndex].units.findIndex((u) => u.sid == classificator.machineSid);
        state.classificatorData[plantIndex].units[unitIndex].riskClassification?.init(classificator);
    },
};

const actions: ActionTree<ClassificatorDataState, ApplicationState> = {
    async GET_ASSETS({
        state,
        commit,
        rootState,
    }: ActionContext<ClassificatorDataState, ApplicationState>): Promise<number[]> {
        const filter = {
            scopeId: state.scopeId,
            countryId: (rootState as StateWithModules).country.selectedCountryId,
        } as PowerPlantListEndpointRequest;
        const powerPlants = (await state.locationService.getPowerPlantsForDistributonType(filter)).result ?? []; // todo error handling
        commit('CLEAR_ASSETS');
        const normalizer = new PowerPlantNormalizer();
        powerPlants.forEach((powerPlant: any) => {
            commit('SET_POWER_PLANT', normalizer.prepareRawPowerPlant(powerPlant));
        });
        return powerPlants.map((powerPlant: any) => powerPlant.sid ?? 0);
    },
    async GET_CLASSIFICATOR_DATA({
        commit,
        rootState,
    }: ActionContext<ClassificatorDataState, ApplicationState>): Promise<HttpStatusCodes> {
        const countryId = (rootState as StateWithModules).country.selectedCountryId;
        const filter = {
            countrySid: countryId,
            periodStart: state.periodStart,
            periodEnd: state.periodEnd,
            page: 1,
            size: 1000,
        } as RiskClassificationEndpointRequest;
        try {
            await this.dispatch('classificatorData/GET_ASSETS');
            const response = await riskClassificationService.getClassificators(filter);
            const payload = {
                data: response.result?.items ?? [],
                countryId: countryId,
            };
            commit('SET_CLASSIFICATOR_DATA', payload);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return (e as AxiosError).response?.status ?? HttpStatusCodes.InternalServerError;
            } else {
                console.error(`An unknown error occurred while fetching the classificatorData data.`);
                console.error(e);
                return HttpStatusCodes.InternalServerError;
            }
        }
        return HttpStatusCodes.Ok;
    },

    async PUBLISH_CLASSIFICATOR(
        { commit }: ActionContext<ClassificatorDataState, ApplicationState>,
        classificator: ClassificatorDto,
    ): Promise<HttpStatusCodes> {
        try {
            if (classificator.id == 0) {
                const response = await riskClassificationService.addClassificator(classificator);
                classificator = response.result!;
            } else {
                const response = await riskClassificationService.putClassificator(classificator);
                classificator = response.result!.item!;
            }
            commit('UPDATE_CLASSIFICATOR', classificator);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return (e as AxiosError).response?.status ?? HttpStatusCodes.InternalServerError;
            } else {
                console.error(`An unknown error occurred while commiting the classificator data`);
                console.error(e);
                return HttpStatusCodes.InternalServerError;
            }
        }
        return HttpStatusCodes.Ok;
    },
};

const namespaced = true;

export const classificatorData: Module<ClassificatorDataState, ApplicationState> = {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};
