
import { EmissionFactorType } from '@/enums/emission-factor-type';
import { ConfirmAction } from '@/models';
import { LocationTableData } from '@/models/table';
import {
    ApprovalStatus,
    EmissionWithParkedEmissionDto,
    Granularity,
    PowerPlantDto,
    UnitDto,
    UnitOfMeasurementEmissionFactorDto,
    BadRequestResponse,
} from '@/service-proxies/service-proxies.g';
import { createDates } from '@/utils/helpers/emission/new-emission-dates';
import {
    DateDisplayOptions,
    formatActivityDataDisplayName,
    formatDate,
    formatLastUpdated,
} from '@/utils/helpers/formatters';
import cloneDeep from 'lodash.clonedeep';
import Vue, { inject } from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import EmissionSummary from './emission-modal-sections/emission-summary.vue';
import FactorDetails from '@/components/detailed-view/emission-factor-details.vue';
import AdditionalInformation from '@/components/detailed-view/additional-info.vue';
import IndividualEmissionFactor from './emission-modal-sections/individual-emission-factor.vue';
import SelectEmissionFactor from './emission-modal-sections/select-emission.vue';
import ParkedEmission from '@/components/detailed-view/parked-emission.vue';
import FuelCorrection from '@/components/detailed-view/fuel-correction.vue';
import { functionalPermissions, EventBus } from '@/utils';
import RelatedEmissions from '@/components/detailed-view/related-emissions.vue';

const EmissionModal = Vue.extend({
    name: 'EmissionModal',
    components: {
        RelatedEmissions,
        EmissionSummary,
        FactorDetails,
        AdditionalInformation,
        SelectEmissionFactor,
        IndividualEmissionFactor,
        ParkedEmission,
        FuelCorrection,
    },
    data(): {
        unit: UnitDto | null;
        powerPlant: PowerPlantDto | null;
        emission: EmissionWithParkedEmissionDto | null;
        isSaving: boolean;
        fetchedUOMs: boolean;
        validSummary: boolean;
        validIndividual: boolean;
        validAdjustment: boolean;
    } {
        return {
            unit: null,
            powerPlant: null,
            emission: null,
            isSaving: false,
            fetchedUOMs: false,
            validSummary: true,
            validIndividual: true,
            validAdjustment: true,
        };
    },
    computed: {
        ...mapGetters({ scope: 'scope/GET_SELECTED_SCOPE' }),
        ...mapGetters({ country: 'country/GET_SELECTED_COUNTRY' }),
        ...mapGetters('detailedView', {
            getEditedEmission: 'GET_EDITED_EMISSION',
            getImportedIndividualEmissionFactor: 'GET_IMPORTED_INDIVIDUAL_EMISSION_FACTOR',
            getEditedIndividualEmissionFactor: 'GET_EDITED_INDIVIDUAL_EMISSION_FACTOR',
            getStandardEmissionFactor: 'GET_STANDARD_EMISSION_FACTOR',
            getTypeOfEmissionFactor: 'GET_TYPE_OF_EMISSION_FACTOR',
            getConsumptionUOMs: 'GET_CONSUMPTION_UOMS',
        }),
        ...mapGetters('location', {
            startPeriod: 'start',
            granularity: 'granularity',
        }),
        isPreliminary(): boolean {
            return this.emission?.approvalStatus === ('Preliminary' as unknown as ApprovalStatus);
        },
        completeGroup(): boolean {
            return !!this.powerPlant?.isComplete || !!this.unit?.isComplete;
        },
        canSave(): boolean {
            return this.isPreliminary && !this.completeGroup;
        },
        showFactorDetails(): boolean {
            return !this.isPreliminary && this.emission?.emissionFactor?.type === EmissionFactorType.Individual;
        },
        canOverWrite(): boolean {
            return (
                functionalPermissions.userCanWithdrawApproval() &&
                !this.isPreliminary &&
                !!this.emission?.parkedEmission &&
                !this.completeGroup
            );
        },
        canWithdrawApproval(): boolean {
            return (
                functionalPermissions.userCanWithdrawApproval() &&
                !this.isPreliminary &&
                !this.emission?.parkedEmission &&
                !this.completeGroup
            );
        },
        canViewFuelAdjustment(): boolean {
            return functionalPermissions.userCanViewFuelAdjustment();
        },
        canViewRelatedEmissions(): boolean {
            return functionalPermissions.userCanViewRelatedEmissions();
        },
        scopeName(): string {
            return this.$t(`scope${this.scope?.subScope?.replace('.', '_') ?? ''}`).toString();
        },
        activityName(): string {
            return formatActivityDataDisplayName(this.emission?.activityData);
        },
        consumptionUOMOptions(): any[] {
            return this.getConsumptionUOMs.map((entry: UnitOfMeasurementEmissionFactorDto) => {
                return {
                    label: entry.unitOfMeasurement?.shortName,
                    value: entry.unitOfMeasurement?.id,
                };
            });
        },
        factorUOM(): string {
            const emissionUom = this.getEditedEmission.ghgUnitOfMeasurement.shortName ?? '';
            if (!this.getEditedEmission.activityDataUnitOfMeasurement) return '??';
            const consumptionShortName = this.getEditedEmission.activityDataUnitOfMeasurement.shortName ?? '';
            const consumptionLabel =
                consumptionShortName.length > 5 ? consumptionShortName.slice(0, 3) : consumptionShortName;
            return `${emissionUom}/${consumptionLabel}`;
        },
        showIndividual(): boolean {
            return this.getTypeOfEmissionFactor !== EmissionFactorType.Global;
        },
        plantUnitName(): string {
            return [this.powerPlant?.name, this.unit?.name].filter(Boolean).join(' - ');
        },
        validInputs(): boolean {
            return this.validSummary && this.validIndividual && this.validAdjustment;
        },
        lastUpdated(): Record<string, string> {
            return formatLastUpdated(
                this.emission,
                this.$t('comment.lastUpdated') as string,
                this.$t('draft') as string,
            );
        },
    },
    methods: {
        ...mapActions('detailedView', {
            setEmissionFactor: 'SET_EMISSION_FACTORS',
            fetchUOMs: 'FETCH_UOMS',
        }),
        ...mapActions('location', {
            acceptParked: 'OVERRIDE_APPROVAL',
        }),
        ...mapMutations('detailedView', {
            setEditedEmission: 'SET_EDITED_EMISSION',
            setEditedIndividualEmissionFactor: 'SET_EDITED_INDIVIDUAL_EMISSION_FACTOR',
            setStandardEmissionFactor: 'SET_STANDARD_EMISSION_FACTOR',
            setIndividualHasBeenChanged: 'SET_INDIVIDUAL_HAS_BEEN_CHANGED',
            setTypeOfEF: 'SET_TYPE_OF_EMISSION_FACTOR',
            setUOMs: 'SET_CONSUMPTION_UOMS',
        }),

        async open(data: Record<string, LocationTableData>): Promise<void> {
            this.fetchedUOMs = false;

            // Power plant may not exist if the master data level is on the unit
            this.powerPlant = (data.powerPlant?.meta?.object as PowerPlantDto) || null;
            this.unit = (data.unit?.meta?.object as UnitDto) || {};

            this.emission = data.activity.meta.object as EmissionWithParkedEmissionDto;

            if (this.emission) {
                this.setEmissionFactor(this.emission);
                // this is to render the select consumption UOM select element
                this.setUOMs([
                    {
                        emissionFactor: {},
                        unitOfMeasurement: this.emission.activityDataUnitOfMeasurement,
                    },
                ]);
            }

            // open modal
            (this as any).$refs.detailsBox.open();

            //emissionfactoroptions don't get updated in UPDATE_EMISSION action, so this covers the edge case for
            //saving an emission and the user opens the detailedview of the same emission
            if (
                this.emission.emissionFactorOptions?.globalEmissionFactorOption?.activityDataUnitOfMeasurement?.id &&
                this.emission.activityDataUnitOfMeasurement?.id &&
                this.emission.emissionFactorOptions.globalEmissionFactorOption.activityDataUnitOfMeasurement.id !==
                    this.emission.activityDataUnitOfMeasurement?.id
            ) {
                await this.loadUOMs();
                const entry: UnitOfMeasurementEmissionFactorDto = this.getConsumptionUOMs.find(
                    (uom: UnitOfMeasurementEmissionFactorDto) =>
                        uom.unitOfMeasurement?.id === this.emission?.activityDataUnitOfMeasurement?.id,
                );
                this.updateStandardEmissionFactor(entry);
            }
        },

        close(): void {
            (this as any).$refs.detailsBox.close();
        },

        async save(): Promise<void> {
            if (this.isSaving || !this.validInputs) return;
            if (!this.emission) {
                (this as any).$refs.detailsBox.close();
                return;
            }

            this.isSaving = true;

            const sendEmission = cloneDeep(this.getEditedEmission) as EmissionWithParkedEmissionDto;
            if (this.getTypeOfEmissionFactor === EmissionFactorType.Individual) {
                sendEmission.emissionFactor = this.getEditedIndividualEmissionFactor;
            } else {
                sendEmission.emissionFactor = this.getStandardEmissionFactor;
            }

            if (sendEmission.emissionFactor?.emissionsFactor === null) {
                sendEmission.emissionFactor = { ...sendEmission.emissionFactor, emissionsFactor: 0 } as any;
            }

            //TODO remove when emission drafts add dates in backend
            if (sendEmission.id === 0) {
                const { createDate, startDate, endDate } = createDates(this.startPeriod);
                sendEmission.createDate = createDate;
                sendEmission.startDate = startDate;
                sendEmission.endDate = endDate;
            }

            if (sendEmission.emissionFactor?.emissionsFactor === null) {
                sendEmission.emissionFactor = { ...sendEmission.emissionFactor, emissionsFactor: 0 } as any;
            }

            if (sendEmission.emissionFactor && !sendEmission.emissionFactor.type) {
                sendEmission.emissionFactor.type = EmissionFactorType.Individual;
            }

            try {
                const emissionDto = await this.$store.dispatch('location/UPDATE_EMISSION', sendEmission);
                (this as any).$pui.toast({
                    type: 'success',
                    title: this.$t('notification.emissions.save.title'),
                    copy: '',
                });

                this.emission = { ...emissionDto };
            } catch (e) {
                const message = (e as BadRequestResponse).message ?? '';
                (this as any).$pui.toast({
                    type: 'error',
                    title: this.$t('error.emissions.save.title'),
                    copy: message,
                });
                console.log(e);
                throw new Error();
            } finally {
                this.isSaving = false;

                (this as any).$refs.detailsBox.close();
            }
        },

        async overrideApproved(): Promise<void> {
            (this as any).$refs.detailsBox.close();
            EventBus.$emit(EventBus.VIEWS.MAIN.OPEN_OVERWRITE_APPROVAL, {
                activity: this.emission,
                unit: this.unit,
                powerPlant: this.powerPlant,
            });
        },

        formatDate(dateString: string, locale: string, granularity: Granularity): string {
            return formatDate(dateString, locale, this.mapGranularityToDateDisplayOptions(granularity));
        },

        mapGranularityToDateDisplayOptions(granularity: Granularity): DateDisplayOptions {
            switch (granularity) {
                case Granularity.Yearly:
                    return DateDisplayOptions.YearOnly;
                case Granularity.Monthly:
                    return DateDisplayOptions.MonthOnly;
                default:
                    return DateDisplayOptions.Default;
            }
        },

        showAllChangesWillBeLostModal(approveCallback?: () => void, cancelCallback?: () => void): void {
            const confirmAction: ConfirmAction | undefined = inject('ConfirmAction');
            if (confirmAction) {
                confirmAction({
                    title: this.$t('actions.changesWillBeLost.title'),
                    cancel: this.$t('actions.cancel'),
                    confirm: this.$t('actions.confirm'),
                    content: this.$t('actions.changesWillBeLost.genericContent'),
                })
                    .then(() => {
                        if (approveCallback) {
                            approveCallback();
                        }
                    })
                    .catch(() => {
                        if (cancelCallback) {
                            cancelCallback();
                        }
                    });
            }
        },

        updateSummary(newValue: boolean): void {
            this.validSummary = newValue;
        },
        updateIndividual(newValue: boolean): void {
            this.validIndividual = newValue;
        },
        updateAdjustment(newValue: boolean): void {
            this.validAdjustment = newValue;
        },

        async loadUOMs(): Promise<void> {
            if (this.fetchedUOMs) return;
            this.isSaving = true;
            try {
                await this.fetchUOMs();
                this.fetchedUOMs = true;
            } catch (e) {
                const message = (e as BadRequestResponse).message ?? '';
                (this as any).$pui.toast({
                    type: 'error',
                    title: this.$t('toast.error'),
                    copy: message,
                });
                console.log(e);
                this.fetchedUOMs = false;
            } finally {
                this.isSaving = false;
            }
        },

        selectConsumptionUnitOfMeasure(id: number): void {
            const entry: UnitOfMeasurementEmissionFactorDto = this.getConsumptionUOMs.find(
                (uom: UnitOfMeasurementEmissionFactorDto) => uom.unitOfMeasurement?.id === id,
            );
            if (!entry) {
                throw new Error();
            }

            const unitOfMeasurement = entry.unitOfMeasurement;
            if (unitOfMeasurement?.id === this.getEditedEmission?.activityDataUnitOfMeasurement?.id) {
                return;
            }

            this.setEditedEmission({
                ...this.getEditedEmission,
                activityDataUnitOfMeasurement: unitOfMeasurement,
                activityDataAmount: null,
            });

            let individualEmissionFactor = {
                ...this.getEditedIndividualEmissionFactor,
                ...entry.emissionFactorOptions?.individualEmissionFactorOption,
            };
            if (!individualEmissionFactor.source)
                individualEmissionFactor.source = this.getEditedIndividualEmissionFactor.source ?? 'Web App';
            if (!individualEmissionFactor.type) individualEmissionFactor.type = EmissionFactorType.Individual;

            this.setEditedIndividualEmissionFactor(individualEmissionFactor);

            this.updateStandardEmissionFactor(entry);
        },

        updateStandardEmissionFactor(entry: UnitOfMeasurementEmissionFactorDto): void {
            // there is a bug that if there is no global factor option, it just copies the individual option
            if (entry.emissionFactorOptions?.globalEmissionFactorOption?.type !== EmissionFactorType.Global) {
                this.setTypeOfEF(EmissionFactorType.Individual);
                this.setStandardEmissionFactor(undefined);
            } else {
                let standardEmissionFactor = {
                    ...this.getStandardEmissionFactor,
                    ...entry.emissionFactorOptions?.globalEmissionFactorOption,
                };
                if (!standardEmissionFactor.source)
                    standardEmissionFactor.source = this.getStandardEmissionFactor.source ?? 'Web App';
                if (!standardEmissionFactor.type) standardEmissionFactor.type = EmissionFactorType.Global;

                if (this.getEditedIndividualEmissionFactor.id === 0) {
                    this.setTypeOfEF(EmissionFactorType.Global);
                }

                this.setStandardEmissionFactor(standardEmissionFactor);
            }
        },

        openWithdrawApprovalModal(): void {
            EventBus.$emit(EventBus.VIEWS.MAIN.OPEN_WITHDRAW_APPROVAL, {
                activity: this.emission,
                unit: this.unit,
                powerPlant: this.powerPlant,
            });
            this.close();
        },
    },
});

export default EmissionModal;
