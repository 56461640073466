
import {
    EmissionWithParkedEmissionDto,
    UnitDto,
    PowerPlantDto,
    BadRequestResponse,
} from '@/service-proxies/service-proxies.g';
import Vue, { nextTick } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import {
    formatDate,
    DateDisplayOptions,
    formatActivityDataDisplayName,
    formatLocationName,
} from '@/utils/helpers/formatters';

interface Data {
    emission: EmissionWithParkedEmissionDto | null;
    bodyMessage: string;
}

export default Vue.extend({
    name: 'DeleteEmissionModal',

    data(): Data {
        return {
            emission: null,
            bodyMessage: '',
        };
    },

    computed: {
        ...mapGetters({ loading: 'isLoading' }),
    },

    methods: {
        open(data: any) {
            this.emission = data;
            this.bodyMessage = this.$t('actions.deleteEmission.content') as string;
            (this as any).$refs.deleteEmissionModal.open();
        },

        async onConfirmCallback() {
            if (this.loading || this.emission === null) return;
            this.$store.commit('loading');

            try {
                await this.$store.dispatch('location/DELETE_EMISSION', this.emission);
                (this as any).$pui.toast({
                    type: 'success',
                    title: this.$t('notification.emissions.delete.title'),
                    copy: '',
                });
            } catch (e) {
                const message = (e as BadRequestResponse).message ?? '';
                (this as any).$pui.toast({
                    type: 'error',
                    title: this.$t('error.emissions.delete.title'),
                    copy: message,
                });
                throw new Error();
            } finally {
                this.$store.commit('loading');
                this.onCloseCallback();
            }
        },

        resetValues() {
            this.emission = null;
        },

        onCloseCallback() {
            if (this.loading) return;
            this.resetValues();
            (this as any).$refs.deleteEmissionModal.close();
        },
    },
});
