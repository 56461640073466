import {
    ActivityDataGetEndpointResponseOkResponse,
    ActivityDataServiceProxy,
} from '@/service-proxies/service-proxies.g';
import { ActivityDataListEndpointRequest } from '@/models/activity-data';

/**
 * @name ActivityDataService
 * @description This class is used to query the backend server for activity data
 */
export class ActivityDataService {
    private activityDataServiceProxy: ActivityDataServiceProxy;

    constructor() {
        this.activityDataServiceProxy = new ActivityDataServiceProxy();
    }

    /**
     * Get filtered list of activities.
     * @param filter Activity Filter.
     */
    public getActivities = async (
        filter: ActivityDataListEndpointRequest | undefined = undefined,
    ): Promise<ActivityDataGetEndpointResponseOkResponse> => {
        return await this.activityDataServiceProxy.getActivityData(filter?.scopeId);
    };
}
