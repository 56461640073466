
import {
    EmissionDto,
    EmissionFactorDto,
    GlobalEmissionFactorWithBlockingEmissionsDto,
} from '@/service-proxies/service-proxies.g';
import { BulkBlockedErrorBody, BulkEditValues } from '@/store/modules/standard-emission';
import {
    DateDisplayOptions,
    formatActivityDataDisplayName,
    formatDate,
    formatLocationName,
} from '@/utils/helpers/formatters';
import BulkEditValuesList from '../bulk-edit-values-list.vue';
import Vue from 'vue';
import { translateWithPlaceholders } from '@/utils/helpers/i18n';

interface ModalActions {
    open: () => void;
    close: () => void;
}

interface Data {
    blockedEmissionFactors: GlobalEmissionFactorWithBlockingEmissionsDto[];
    bulkEditValues: BulkEditValues;
}

const BulkErrorModal = Vue.extend({
    name: 'BulkErrorModal',
    data(): Data {
        return {
            blockedEmissionFactors: [],
            bulkEditValues: {
                startDate: '',
                source: '',
                linkToSource: '',
                additionalInformation: '',
                publicationDate: '',
            },
        };
    },
    computed: {
        modalRef(): ModalActions {
            return this.$refs.bulkErrorModal as unknown as ModalActions;
        },
        infoMessage(): string {
            const startDate = formatDate(
                this.bulkEditValues?.startDate!,
                this.$i18n.locale,
                DateDisplayOptions.MonthOnly,
            );
            return translateWithPlaceholders('standardEF.blocked.bulkMessage', { startdate: startDate });
        },
    },
    methods: {
        close(): void {
            this.blockedEmissionFactors = [];
            this.bulkEditValues = {
                startDate: '',
                source: '',
                linkToSource: '',
                additionalInformation: '',
                publicationDate: '',
            };

            this.modalRef.close();
        },
        async open(data: BulkBlockedErrorBody): Promise<void> {
            this.blockedEmissionFactors = data.blockedEmissionFactors ?? [];
            this.bulkEditValues = data.bulkEditValues;

            (this as any).$refs.bulkErrorModal.open();
        },
        blockedFactorsInfo(factor: EmissionFactorDto): string {
            var infoString = '';
            const fuel = formatActivityDataDisplayName(factor.activityData);
            const uom = factor.activityDataUnitOfMeasurement?.shortName;
            infoString += `${fuel}, ${uom}:\n`;
            return infoString;
        },
        blockingEmissionsInfo(emission: EmissionDto): string {
            var infoString = '';
            const startDate = formatDate(emission.startDate!, this.$i18n.locale, DateDisplayOptions.MonthOnly);
            const endDate = formatDate(emission.endDate!, this.$i18n.locale, DateDisplayOptions.MonthOnly);
            const locationName = formatLocationName(emission.powerPlant?.name, emission.unit?.name);
            infoString += `${locationName}, \
                ${startDate} - \
                ${endDate}\n`;
            return infoString;
        },
    },
});

export default BulkErrorModal;
