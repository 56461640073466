
import { EmissionFactorDto, EmissionFactorGroupDto } from '@/service-proxies/service-proxies.g';
import { BulkEditResponse, BulkEditValues } from '@/store/modules/standard-emission';
import { DateDisplayOptions, formatActivityDataDisplayName, formatDate } from '@/utils/helpers/formatters';
import { translateWithPlaceholders } from '@/utils/helpers/i18n';
import Vue from 'vue';
import BulkEditValuesList from '../bulk-edit-values-list.vue';

interface ModalActions {
    open: () => void;
    close: () => void;
}

interface Data {
    updatedEmissionFactors?: EmissionFactorGroupDto[] | undefined;
    skippedEmissionFactors?: EmissionFactorGroupDto[] | undefined;
    bulkEditValues: BulkEditValues;
}

const BulkResponseInfoModal = Vue.extend({
    name: 'BulkResponseInfoModal',
    components: {
        BulkEditValuesList,
    },
    data(): Data {
        return {
            updatedEmissionFactors: [],
            skippedEmissionFactors: [],
            bulkEditValues: {
                startDate: '',
                source: '',
                linkToSource: '',
                additionalInformation: '',
                publicationDate: '',
            },
        };
    },
    computed: {
        modalRef(): ModalActions {
            return this.$refs.bulkResponseInfoModal as unknown as ModalActions;
        },
        skippedFactorsCount(): number {
            if (!this.skippedEmissionFactors) {
                return 0;
            }
            var sum = 0;
            this.skippedEmissionFactors.forEach((g) => (sum += this.countFactorsInGroup(g)));
            return sum;
        },
        updatedFactorsCount(): number {
            if (!this.updatedEmissionFactors) {
                return 0;
            }
            var sum = 0;
            this.updatedEmissionFactors.forEach((g) => (sum += this.countFactorsInGroup(g)));
            return sum;
        },
        infoMessage(): string {
            const startDate = formatDate(
                this.bulkEditValues?.startDate!,
                this.$i18n.locale,
                DateDisplayOptions.MonthOnly,
            );
            return translateWithPlaceholders('standardEF.bulkInfo.completed', { startdate: startDate });
        },
        updatedMessage(): string {
            return `${this.updatedFactorsCount} ${this.$t('standardEF.bulkInfo.updated')}`;
        },
        skippedMessage(): string {
            return `${this.skippedFactorsCount} ${this.$t('standardEF.bulkInfo.skipped')}`;
        },
    },
    methods: {
        close(): void {
            this.updatedEmissionFactors = [];
            this.skippedEmissionFactors = [];
            this.bulkEditValues = {
                startDate: '',
                source: '',
                linkToSource: '',
                additionalInformation: '',
                publicationDate: '',
            };
            this.modalRef.close();
        },
        async open(data: BulkEditResponse): Promise<void> {
            this.updatedEmissionFactors = data.updatedEmissionFactors?.sort(this.sortFactorGroupByName);
            this.skippedEmissionFactors = data.skippedEmissionFactors?.sort(this.sortFactorGroupByName);
            this.bulkEditValues = data.bulkEditValues;

            (this as any).$refs.bulkResponseInfoModal.open();
        },
        emissionFactorGroupInfo(factorGroup: EmissionFactorGroupDto): string {
            var infoString = '';
            const fuel = formatActivityDataDisplayName(factorGroup.activity);
            const uom = factorGroup.unitOfMeasurement?.shortName;
            const ghgs =
                '' +
                (factorGroup.emissionFactorCO2
                    ? factorGroup.emissionFactorCO2.greenhouseGas?.chemicalFormula + ', '
                    : '') +
                (factorGroup.emissionFactorCH4
                    ? factorGroup.emissionFactorCH4.greenhouseGas?.chemicalFormula + ', '
                    : '') +
                (factorGroup.emissionFactorN2O ? factorGroup.emissionFactorN2O.greenhouseGas?.chemicalFormula : '');
            infoString += `${fuel}, ${uom}: ${ghgs}\n`;
            return infoString;
        },
        factorGroupId(factorGroup: EmissionFactorGroupDto): number {
            // create an id to use as a key for v-for
            return (factorGroup.activity?.id ?? 0) * 100 + (factorGroup.unitOfMeasurement?.id ?? 0);
        },
        sortFactorGroupByName(a: EmissionFactorGroupDto, b: EmissionFactorGroupDto): number {
            const aActivity = formatActivityDataDisplayName(a.activity).toLocaleLowerCase();
            const bActivity = formatActivityDataDisplayName(b.activity).toLocaleLowerCase();
            const aUoM = a.unitOfMeasurement?.shortName?.toLocaleLowerCase() ?? '';
            const bUoM = b.unitOfMeasurement?.shortName?.toLocaleLowerCase() ?? '';

            return aActivity.localeCompare(bActivity) || aUoM?.localeCompare(bUoM);
        },
        countFactorsInGroup(factorGroup: EmissionFactorGroupDto): number {
            var count = 0;
            factorGroup.emissionFactorCO2 && count++;
            factorGroup.emissionFactorCH4 && count++;
            factorGroup.emissionFactorN2O && count++;
            return count;
        },
    },
});

export default BulkResponseInfoModal;
