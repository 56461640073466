var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"unit"},[_c('div',{staticClass:"title-section"},[_c('div',{staticClass:"title"},[_vm._v("\n            "+_vm._s(_vm.unit.name)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"unit-buttons"},[_c('pui-icon',{class:[
                    'save-icon',
                    {
                        'save-icon--hide': !_vm.hasChanges,
                        'save-icon--disabled': _vm.isSaving,
                    },
                ],attrs:{"icon-name":"check-solid","size":"3rem"},nativeOn:{"click":function($event){return _vm.submit.apply(null, arguments)}}}),_vm._v(" "),_c('pui-icon',{class:[
                    'save-icon',
                    {
                        'save-icon--hide': !_vm.hasChanges,
                        'save-icon--disabled': _vm.isSaving,
                    },
                ],attrs:{"icon-name":"dismiss","size":"3rem"},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}})],1)]),_vm._v(" "),_vm._l((_vm.updatedRisk.riskAllocations),function(riskItem,index){return _c('div',{key:_vm.toNum(riskItem.id) * 10 + index},[_c('pui-form-group',{attrs:{"label":_vm.getYear(index),"has-label-padding":false}},[_c('pui-form-select',{attrs:{"label":"A","options":_vm.riskLevelOptions(),"value":riskItem.value},on:{"change":function($event){return _vm.updateRisk(index, $event)}}})],1)],1)}),_vm._v(" "),_c('unit-period-comment-field',{ref:"commentField",staticClass:"comment-field",attrs:{"text":_vm.updatedRisk.comment,"last-changed":_vm.updateDateString(),"last-changed-by":_vm.updateKid(),"deletion-pending":_vm.commentDeletionPending,"has-saved-comment":_vm.hasSavedComment},on:{"change":_vm.updateComment}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }